import React, {
  useState,
  useEffect,
  useRef,
  memo,
  useCallback,
  useMemo
} from 'react'

import { useTranslation } from 'react-i18next'

import StyledModal from 'components/StyledModal'
import StyledRadio from 'components/StyledRadio'
import StyledButton from 'components/StyledButton'
import StyledDotsStepper from 'components/StyledDotsStepper'

import { Wrapper, ModalTitle, ModalSubTitle } from './styles'

import InfoSensor from './StepContent/InfoSensor'
import SubmitSensor from './StepContent/SubmitSensor'
import SubmitGateways from './StepContent/SubmitGateways'
import SubmitPanels from './StepContent/SubmitPanels'
import SubmitRepeater from './StepContent/SubmitRepeater'

import isEqual from 'lodash.isequal'

const CreateNewAssetModal = ({
  children,
  type,
  setType,
  typeOptions,
  form,
  handleChange,
  checkIfError,
  hasSubmitted,
  handleBackButton,
  handleNextButton,
  active,
  setActive,
  steps,
  error,
  monitoringOptions,
  areas,
  gateways,
  gatewaysTypeOptions,
  open,
  onClose,
  allowedOptions,
  submitting,
  fetchLocationOptions
}) => {
  const { t } = useTranslation()

  const [filteredGateways, setFilteredGateways] = useState([])

  const searchInputTimeoutRef = useRef(null)
  const [searchOptions, setSeachOptions] = useState([])
  const [textInput, setTextInput] = useState('')
  const [locationSelected, setLocationSelected] = useState(null)

  useEffect(() => {
    if (searchInputTimeoutRef.current !== null) {
      clearTimeout(searchInputTimeoutRef.current)
    }
    searchInputTimeoutRef.current = setTimeout(() => {
      searchInputTimeoutRef.current = null
      if (fetchLocationOptions) {
        fetchLocationOptions({ params: { q: textInput, format: 'jsonv2' } })
          .then(response => {
            if (response.data) {
              const { data } = response
              setSeachOptions(data)
            } else {
              setSeachOptions([])
            }
          })
          .catch(err => {
            console.log({ err })
            setSeachOptions([])
          })
      }
    }, 500)
    // eslint-disable-next-line
  }, [textInput])

  const helperTextGenerator = useCallback(
    property => {
      if (hasSubmitted && form[property] != null) {
        const apiError = checkIfError(property)
        if (apiError != null) {
          return apiError
        } else if (form[property].message) {
          return t(`errors.${form[property].message}`)
        }
      }
      return null
    },
    // eslint-disable-next-line
    [hasSubmitted, form]
  )

  const memoSetType = useCallback(
    e => setType(e.target.value),
    // eslint-disable-next-line
    []
  )

  const filterGatewaysWithArea = useCallback(
    area => {
      if (
        steps[active] === 'submit_sensor' &&
        areas != null &&
        gateways != null
      ) {
        if (area != null && area !== '' && form.gateway != null) {
          const areaFound = areas.find(el => el.id === area)
          if (areaFound != null && areaFound.id != null) {
            if (form?.gateway?.value == null) {
              // TROCAR PARA IDS
              const gatewaysInArea = gateways.filter(
                gw => gw.area == null || gw.area.id === areaFound.id
              )
              setFilteredGateways(gatewaysInArea)
            } else {
              const gwFound = gateways.find(gw => gw.id === form.gateway.value)
              // TROCAR UID POR ID @fcardoso
              if (
                gwFound != null &&
                gwFound.area != null &&
                gwFound.area.id !== areaFound.id
              ) {
                handleChange('gateway', null)
              }
            }
          } else {
            setFilteredGateways(gateways)
          }
        } else {
          setFilteredGateways(gateways)
        }
      }
    },
    [active, areas, form?.gateway, gateways, handleChange, steps]
  )

  useEffect(() => {
    if (
      steps[active] === 'submit_sensor' &&
      areas != null &&
      gateways != null &&
      form != null &&
      form.area != null
    ) {
      filterGatewaysWithArea(form.area.value)
    }
    // eslint-disable-next-line
  }, [form, active])

  const onSearchChange = useCallback(event => setTextInput(event), [
    setTextInput
  ])
  const onSearchInputChange = useCallback(
    (event, newValue) => setLocationSelected(newValue),
    [setLocationSelected]
  )

  const getOptionLabel = useCallback(option => option.displayName, [])

  const mapSearchProps = useMemo(
    () => ({
      value: textInput,
      onChange: onSearchChange,
      inputValue: locationSelected,
      onInputChange: onSearchInputChange,
      options: searchOptions,
      label: t('map.search_location'),
      getOptionLabel: getOptionLabel
    }),
    [
      getOptionLabel,
      searchOptions,
      onSearchInputChange,
      locationSelected,
      onSearchChange,
      textInput,
      t
    ]
  )

  const getStepContent = useCallback(() => {
    switch (steps[active]) {
      case 'type':
        return (
          <>
            <StyledRadio
              label=''
              name={t('assets.type')}
              value={type}
              onChange={memoSetType}
              options={typeOptions}
              error={error}
              errorMessage={error ? t('errors.required') : ''}
            />
          </>
        )
      case 'info_sensor':
        if (form != null) {
          return (
            <InfoSensor
              {...{
                form,
                hasSubmitted,
                locationSelected,
                textInput,
                searchOptions,
                handleChange,
                checkIfError,
                helperTextGenerator,
                setTextInput,
                setLocationSelected,
                mapSearchProps
              }}
            />
          )
        }
        setActive(0)
        return null
      case 'submit_sensor':
        if (form != null) {
          return (
            <SubmitSensor
              {...{
                form,
                areas,
                monitoringOptions,
                filteredGateways,
                allowedOptions,
                hasSubmitted,
                handleChange,
                checkIfError,
                helperTextGenerator,
                mapSearchProps
              }}
            />
          )
        }
        setActive(0)
        return null
      case 'submit_gateways':
        if (form != null) {
          return (
            <SubmitGateways
              {...{
                form,
                hasSubmitted,
                locationSelected,
                textInput,
                searchOptions,
                areas,
                gatewaysTypeOptions,
                handleChange,
                checkIfError,
                helperTextGenerator,
                setTextInput,
                setLocationSelected,
                mapSearchProps
              }}
            />
          )
        }
        setActive(0)
        return null
      case 'submit_panels':
        if (form != null) {
          return (
            <SubmitPanels
              {...{
                form,
                hasSubmitted,
                locationSelected,
                textInput,
                searchOptions,
                areas,
                handleChange,
                checkIfError,
                helperTextGenerator,
                setTextInput,
                setLocationSelected,
                mapSearchProps
              }}
            />
          )
        }
        setActive(0)
        return null
      case 'submit_repeater':
        if (form != null) {
          return (
            <SubmitRepeater
              {...{
                form,
                hasSubmitted,
                locationSelected,
                textInput,
                searchOptions,
                areas,
                handleChange,
                checkIfError,
                helperTextGenerator,
                setTextInput,
                setLocationSelected,
                mapSearchProps
              }}
            />
          )
        }
        setActive(0)
        return null
      default:
        setActive(0)
        return null
    }
  }, [
    active,
    allowedOptions,
    areas,
    checkIfError,
    error,
    filteredGateways,
    form,
    gatewaysTypeOptions,
    handleChange,
    hasSubmitted,
    helperTextGenerator,
    locationSelected,
    mapSearchProps,
    memoSetType,
    monitoringOptions,
    searchOptions,
    setActive,
    steps,
    t,
    textInput,
    type,
    typeOptions
  ])

  return (
    <StyledModal open={open} onClose={onClose}>
      <Wrapper>
        <ModalTitle>{t('assets.new')}</ModalTitle>
        <ModalSubTitle>
          {type != null && active !== 0 ? t(`assets.new_${type}`) : ''}
        </ModalSubTitle>
        {getStepContent()}
        <StyledDotsStepper
          steps={steps.length}
          activeStep={active}
          backButton={
            <StyledButton
              disabled={active === 0 || submitting}
              onClick={handleBackButton}
            >
              {t('assets.previous')}
            </StyledButton>
          }
          nextButton={
            <StyledButton
              onClick={handleNextButton}
              type='primary'
              disabled={submitting}
            >
              {active < steps.length - 1 || active === 0
                ? t('assets.next')
                : t('assets.create')}
            </StyledButton>
          }
        />
      </Wrapper>
    </StyledModal>
  )
}

export default memo(CreateNewAssetModal, isEqual)

import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <title>icons/small/close-popup</title>
      <path
        d='M16 13.608L13.608 16 8 10.379l-5.542 5.555-2.392-2.392L5.621 8 0 2.392 2.392 0 8 5.621 13.542.066l2.392 2.392L10.379 8z'
        fill={props.fill || '#00A4CC'}
        fillRule='evenodd'
      />
    </svg>
  )
}

export default SvgComponent

import React from 'react'
import styled from 'styled-components'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

export default ({ children, ...props }) => {
  const { onClick, ...remain } = props
  return (
    <Wrapper keepMounted {...remain}>
      {props.options.map((option, index) => (
        <StyledMenuItem
          key={`styled-menu-item-${index}`}
          onClick={() => props.onClick(option.value)}
        >
          {option.label ?? '-'}
        </StyledMenuItem>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled(Menu)``

const StyledMenuItem = styled(MenuItem)``

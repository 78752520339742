import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { Context as AuthContext } from 'providers/AuthProvider/authProvider'

export default ({ children, ...props }) => {
  const {
    store: { isLoggedIn }
  } = useContext(AuthContext)
  const { component: Component, redirectTo = '/login/', ...rest } = props
  return (
    <Route
      render={props =>
        isLoggedIn ? <Component {...props} /> : <Redirect to={redirectTo} />}
      {...rest}
    />
  )
}

import React, { useEffect } from 'react'
import { MOBILE_DEEPLINK } from 'config'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Container from 'components/Container'
import MobileIlustration from 'icons/MobileIlustration'

const RedirectToMobile = () => {
  const { t } = useTranslation()

  useEffect(() => {
    const currentURL = window.location.href

    window.location = currentURL.replace(/^https?/g, MOBILE_DEEPLINK)
  }, [])
  return (
    <Wrapper>
      <Container height='100%'>
        <ContentWrapper>
          <MobileIlustration />
          <Title>{t('mobile')}</Title>
          <Message>{t('redirect_to_mobile')}</Message>
        </ContentWrapper>
      </Container>
    </Wrapper>
  )
}

export default RedirectToMobile

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  height: ${({ height }) => height};
  position: relative;
  overflow: auto;

  background-color: var(--backgroud-color);
`

const ContentWrapper = styled.div`
  grid-column: 1/5;
  margin: auto;

  text-align: center;

  ${({ theme }) => theme.smallDesktop`
grid-column: 3/10;

  `}
`

const Title = styled.h1`
  color: var(--primary-color);
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1em;

  margin: 2.5rem auto 1rem;
`
const Message = styled.p`
  color: var(--error-page-text-color);
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: 1.5em;

  margin-bottom: 3rem;
`

import React, { useCallback, useMemo, memo } from 'react'
import { useTranslation } from 'react-i18next'
import isEqual from 'lodash.isequal'

import InputAndLabel from 'containers/InputAndLabel'
import MapContainer from 'containers/MapContainer'
import SelectAndLabel from 'containers/SelectAndLabel'

import {
  SameLineInputs,
  MapHelperText,
  MapAndLabel,
  MapWrapper
} from '../styles'

const SubmitPanels = ({
  form,
  hasSubmitted,
  locationSelected,
  textInput,
  searchOptions,
  areas,
  handleChange,
  checkIfError,
  helperTextGenerator,
  setTextInput,
  setLocationSelected,
  mapSearchProps
}) => {
  const { t } = useTranslation()

  const memoHandleChange = useCallback(name => e => handleChange(name, e), [
    handleChange
  ])

  const memoHandleChangeValue = useCallback(
    name => e => handleChange(name, e?.target?.value),
    [handleChange]
  )

  const memoAreasOptions = useMemo(
    () =>
      areas
        ? [
          ...areas.map(el => ({
            value: el.id,
            label: el.name ?? el.id
          }))
        ]
        : [],
    [areas]
  )

  const flyToLocation = useMemo(
    () =>
      locationSelected?.boundingbox != null
        ? [
          [
              locationSelected?.boundingbox?.[2],
              locationSelected?.boundingbox?.[0]
          ],
          [
              locationSelected?.boundingbox?.[3],
              locationSelected?.boundingbox?.[1]
          ]
        ]
        : null,
    [locationSelected?.boundingbox]
  )

  return (
    <>
      <SameLineInputs>
        <InputAndLabel
          type='text'
          name='name'
          value={form?.name?.value ?? ''}
          onChange={memoHandleChange('name')}
          label={t('table.name')}
          error={
            hasSubmitted &&
            (form?.name?.message != null || !!checkIfError('name'))
          }
          helperText={helperTextGenerator('name')}
          maxLength={255}
          minLength={1}
          masked
          mask={/^[\w\s]+$/}
        />
        <SelectAndLabel
          value={form?.area?.value ?? []}
          multiple
          onChange={memoHandleChangeValue('area')}
          label={t('table.area')}
          options={memoAreasOptions}
          id='area' // OBRIGATORIO POR CAUSA DA LABEL/
          error={
            hasSubmitted &&
            (form?.area?.message != null || !!checkIfError('area'))
          }
          helperText={helperTextGenerator('area')}
        />
      </SameLineInputs>
      <MapAndLabel>
        <MapWrapper
          error={
            hasSubmitted &&
            (form?.geometry?.message != null || !!checkIfError('geometry'))
          }
        >
          <MapContainer
            data={
              form?.geometry?.value != null
                ? [{ geometry: form?.geometry?.value }]
                : []
            }
            hideLegends
            type='assets'
            isDrawAvailable
            setGeometry={memoHandleChange('geometry')}
            enableSearch
            search={mapSearchProps}
            flyToLocation={flyToLocation}
          />
        </MapWrapper>
        <MapHelperText
          error={
            hasSubmitted &&
            (form?.geometry?.message != null || !!checkIfError('geometry'))
          }
        >
          {checkIfError('geometry') || t('map.asset_helper_text')}
        </MapHelperText>
      </MapAndLabel>
      <SameLineInputs>
        <InputAndLabel
          type='text'
          name='uid'
          value={form?.uid?.value ?? ''}
          onChange={memoHandleChange('uid')}
          label={t('table.uid')}
          error={
            hasSubmitted &&
            (form?.uid?.message != null || !!checkIfError('uid'))
          }
          helperText={helperTextGenerator('uid')}
          maxLength={255}
          minLength={1}
          masked
          mask={/^\w+$/}
        />
      </SameLineInputs>
    </>
  )
}

export default memo(SubmitPanels, isEqual)

import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useMemo,
  memo,
  useCallback
} from 'react'
import styled from 'styled-components'
import { AUTOMATIC_EDIT_PERMISSIONS } from 'config'

import Badge from '@material-ui/core/Badge'

import { Context as AuthContext } from 'providers/AuthProvider/authProvider'
import { Context as SocketContext } from 'providers/SocketProvider/socketProvider'
import withResponsive from 'providers/ResponsiveHandler/withResponsive'

import { useTranslation } from 'react-i18next'
import {
  useHistory,
  useRouteMatch,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import StyledButton from 'components/StyledButton'
import StyledBreadCrumb from 'components/StyledBreadCrumb'
import StyledAutoComplete from 'components/StyledAutoComplete'
import StyledSelect from 'components/StyledSelect'
import toast from 'components/StyledToast'

import MapContainer from 'containers/MapContainer'
import AreaList from 'containers/AreaList'
import AreaDetails from 'containers/AreaDetails'
import FiltersModal from 'containers/FiltersModal'

import UploadIcon from 'icons/UploadIcon'
import FilterIcon from 'icons/FilterIcon'

import logic from './logic'
import columns from './columns'

import { monitoringTypeOptions, occupationOptions } from 'utils/options'

import hasCRUDPermissions from 'utils/hasCRUDPermissions'

const Areas = ({ children, ...props }) => {
  const { responsiveHandlers } = props

  const { actions, store } = useContext(AuthContext)
  const { store: wsStore } = useContext(SocketContext)

  const { t } = useTranslation()
  const { path } = useRouteMatch()

  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [isEditing, setIsEditing] = useState(false)
  const [open, setOpen] = useState(false)
  const [geometry, setGeometry] = useState(null)
  const [areaState, setAreaState] = useState({
    areasCount: 0,
    areas: []
  })
  const [filters, setFilters] = useState({
    monitoring_type: '',
    name: '',
    verboseOccupation: '',
    uid: ''
  })
  const [pagination, setPagination] = useState({ offset: 1, limit: 10 })
  const [triggerSet, setTriggerSet] = useState(false)

  const nameInputTimeoutRef = useRef(null)
  const uidInputTimeoutRef = useRef(null)

  const [searchOptions, setSeachOptions] = useState({
    name: [],
    uid: []
  })
  const [textInput, setTextInput] = useState({
    name: '',
    uid: ''
  })

  const [areaInDetail, setAreaInDetail] = useState(null)
  const [update, setUpdate] = useState(false)

  const [mapHelperText, setMapHelperText] = useState(null)
  const [fetchedArea, setFetchedArea] = useState(null)

  const [title, setTitle] = useState('')

  const locationInputTimeoutRef = useRef(null)
  const [locationOptions, setLocationOptions] = useState([])
  const [locationTextInput, setLocationTextInput] = useState('')
  const [locationSelected, setLocationSelected] = useState(null)

  const hiddenFileInput = useRef(null)

  const [submitState, setSubmitState] = useState({
    submitting: false,
    hasSubmitted: false
  })

  const [openFilters, setOpenFilters] = useState(false)

  const onFilterChange = useCallback(
    (key, value) => {
      setFilters({
        ...filters,
        [key]: value
      })
    },
    [filters]
  )

  const handleAutocompleteInputs = useCallback(
    (property, value) => {
      setTextInput({
        ...textInput,
        [property]: value
      })
      onFilterChange(property, value)
    },
    [textInput, onFilterChange]
  )

  useEffect(() => {
    if (locationInputTimeoutRef.current !== null) {
      clearTimeout(locationInputTimeoutRef.current)
    }
    locationInputTimeoutRef.current = setTimeout(() => {
      locationInputTimeoutRef.current = null
      if (logic.fetchLocationOptions) {
        logic
          .fetchLocationOptions({
            params: { q: locationTextInput, format: 'jsonv2' }
          })
          .then(response => {
            if (response.data) {
              const { data } = response
              setLocationOptions(data)
            } else {
              setLocationOptions([])
            }
          })
          .catch(err => {
            console.log({ err })
            setLocationOptions([])
          })
      }
    }, 500)
    // eslint-disable-next-line
  }, [locationTextInput])

  const handleSetAreaInDetail = useCallback(
    area => {
      if (area != null && area.id != null) {
        setAreaInDetail(area)
        history.push(`${path}${area.id}/`)
      } else {
        setAreaInDetail(null)
        setFetchedArea(null)
        setIsEditing(false)
        setTriggerSet(true)
        history.push(`${path}`)
      }
    },
    [history, path]
  )

  const resetParent = useCallback(() => {
    setLocationTextInput('')
    setLocationSelected(null)
    setLocationOptions([])
  }, [])

  useEffect(() => {
    if (nameInputTimeoutRef.current !== null) {
      clearTimeout(nameInputTimeoutRef.current)
    }

    nameInputTimeoutRef.current = setTimeout(() => {
      nameInputTimeoutRef.current = null
      logic
        .fetchAreasOptions({
          params: { name: textInput.name !== '' ? textInput.name : 'all' }
        })
        .then(response => {
          if (response.data) {
            const { data } = response
            setSeachOptions(searchOptions => ({
              ...searchOptions,
              name: data.results
            }))
          } else {
            setSeachOptions(searchOptions => ({ ...searchOptions, name: [] }))
          }
        })
        .catch(err => {
          console.log({ err })
          setSeachOptions(searchOptions => ({ ...searchOptions, name: [] }))
        })
    }, 500)
  }, [textInput.name])

  useEffect(() => {
    if (uidInputTimeoutRef.current !== null) {
      clearTimeout(uidInputTimeoutRef.current)
    }

    uidInputTimeoutRef.current = setTimeout(() => {
      uidInputTimeoutRef.current = null
      logic
        .fetchAreasOptions({
          params: { uid: textInput.uid !== '' ? textInput.uid : 'all' }
        })
        .then(response => {
          if (response.data) {
            const { data } = response
            setSeachOptions(searchOptions => ({
              ...searchOptions,
              uid: data.results
            }))
          } else {
            setSeachOptions(searchOptions => ({ ...searchOptions, uid: [] }))
          }
        })
        .catch(err => {
          console.log({ err })
          setSeachOptions(searchOptions => ({ ...searchOptions, uid: [] }))
        })
    }, 500)
  }, [textInput.uid])

  const fetchAreaAndUpdate = useCallback(
    id => {
      logic.getAreaDetails(id).then(response => {
        if (response.data) {
          const { data } = response
          const areasCopy = JSON.parse(JSON.stringify(areaState.areas))
          const found = areasCopy.findIndex(el => el.id === id)
          if (found >= 0) {
            areasCopy[found] = data
            setAreaState({
              ...areaState,
              areas: areasCopy
            })
          }
        }
      })
    },
    [areaState]
  )

  const fetchAreas = useCallback(() => {
    setLoading(true)
    setTriggerSet(false)
    logic
      .getAreas({
        params: {
          ...{
            limit: 'max'
          },
          ...filters
        }
      })
      .then(response => {
        if (response.data) {
          const { data } = response
          setAreaState({
            areasCount: data.count,
            areas: data.results
          })
        } else {
          setAreaState({ areasCount: 0, areas: [] })
        }
        setLoading(false)
      })
      .catch(err => {
        console.log({ err })
        setAreaState({ areasCount: 0, areas: [] })
        setLoading(false)
        if (err == null || err.response == null) {
          actions.serverError()
        }
      })
  }, [actions, filters])

  const canEdit = useCallback(
    property => {
      if (areaInDetail != null && areaInDetail.mode === 'automatic') {
        return AUTOMATIC_EDIT_PERMISSIONS.area.includes(property)
      }
      return true
    },
    [areaInDetail]
  )

  const resetFilters = useCallback(() => {
    setFilters({
      monitoring_type: '',
      name: '',
      verboseOccupation: '',
      uid: ''
    })
    setTextInput({ uid: '', name: '' })
    setTriggerSet(true)
  }, [])

  useEffect(() => {
    if (areaInDetail == null) {
      fetchAreas()
      setPagination({ offset: 1, limit: 10 })
    }
    // eslint-disable-next-line
  }, [triggerSet])

  const handleClick = useCallback(event => {
    if (hiddenFileInput != null && hiddenFileInput.current != null) {
      hiddenFileInput.current.click()
    }
  }, [])

  const handleChange = useCallback(
    async event => {
      const fileUploaded = await event.target.files[0]

      if (fileUploaded != null) {
        setSubmitState({
          submitting: true,
          hasSubmitted: true
        })
        setLoading(true)
        // eslint-disable-next-line
        const formData = new FormData()

        formData.append('file', fileUploaded)
        await logic
          .uploadCSV(formData)
          .then(response => {
            setSubmitState({
              ...submitState,
              submitting: false
            })
            if (response.status === 200) {
              toast.success(t('areas.create_success'))
            }
          })
          .catch(err => {
            console.log({ err })
            setSubmitState({
              ...submitState,
              submitting: false
            })

            toast.error(t('areas.create_error'))
            if (err == null || err.response == null) {
              actions.serverError()
            }
          })
          .finally(() => setTriggerSet(true))
      }
    },
    [actions, submitState, t]
  )

  const handleWSMessages = useCallback(
    msg => {
      if (
        msg != null &&
        (msg.sender === 'area' || msg.sender === 'areaevent')
      ) {
        if (areaInDetail && msg.content.id === areaInDetail.id) {
          if (msg.type === 'update') {
            setUpdate(!update)
          } else if (msg.type === 'delete' && msg.content != null) {
            setAreaInDetail(null)
          }
        } else if (areaInDetail == null) {
          if (msg.type === 'new' || msg.type === 'delete') {
            fetchAreas()
          } else if (msg.type === 'update' && msg?.content?.id) {
            fetchAreaAndUpdate(msg?.content?.id)
          } else {
            fetchAreas()
          }
        }
      }
    },
    [areaInDetail, fetchAreaAndUpdate, fetchAreas, update]
  )

  useEffect(() => {
    if (wsStore.message != null) {
      handleWSMessages(wsStore.message)
    }
  }, [wsStore.message, handleWSMessages])

  const hasCRUDPermission = useMemo(() => hasCRUDPermissions(store.user), [
    store.user
  ])

  const memoMonitoringOptions = useMemo(
    () =>
      monitoringTypeOptions.map(el => ({
        ...el,
        label: t(`table.${el.label}`)
      })),
    [t]
  )

  const memoHandleAutocompleteInputs = useCallback(
    name => e => handleAutocompleteInputs(name, e),
    [handleAutocompleteInputs]
  )
  const memoHandleAutocompleteInputsValue = useCallback(
    name => e => handleAutocompleteInputs(name, e.target.value),
    [handleAutocompleteInputs]
  )

  const memoOnFilterChange = useCallback(
    name => (e, newValue) => onFilterChange(name, newValue),
    [onFilterChange]
  )

  const memoFilterMonitoringOptions = useMemo(
    () => [
      {
        label: t('table.all_filter'),
        value: ''
      },
      ...memoMonitoringOptions
    ],
    [memoMonitoringOptions, t]
  )

  const memoOccupationOptions = useMemo(
    () =>
      occupationOptions.map(el => ({
        ...el,
        label: t(`table.${el.label}`)
      })),
    [t]
  )

  const filtersInputs = useMemo(
    () => (
      <React.Fragment key='filters-inputs'>
        <StyledAutoComplete
          value={textInput.uid}
          onChange={memoHandleAutocompleteInputs('uid')}
          inputValue={filters.uid}
          onInputChange={memoOnFilterChange('uid')}
          options={searchOptions.uid}
          label={t('table.uid')}
          name='uid'
          variant='standard'
          id='uid'
        />
        <StyledAutoComplete
          value={textInput.name}
          onChange={memoHandleAutocompleteInputs('name')}
          inputValue={filters.name}
          onInputChange={memoOnFilterChange('name')}
          options={searchOptions.name}
          label={t('table.name')}
          name='name'
          variant='standard'
          id='name'
        />

        <StyledSelect
          value={filters.monitoring_type}
          onChange={memoHandleAutocompleteInputsValue('monitoring_type')}
          label={t('table.monitoring_type')}
          options={memoFilterMonitoringOptions}
          id='monitoring_type' // OBRIGATORIO POR CAUSA DA LABEL
        />
        <StyledSelect
          value={filters.verboseOccupation}
          onChange={memoHandleAutocompleteInputsValue('verboseOccupation')}
          label={t('table.occupation_all')}
          options={memoOccupationOptions}
          id='verboseOccupation' // OBRIGATORIO POR CAUSA DA LABEL
        />
      </React.Fragment>
    ),
    [
      t,
      filters.uid,
      filters.name,
      filters.verboseOccupation,
      filters.monitoring_type,
      searchOptions.name,
      searchOptions.uid,
      textInput.name,
      textInput.uid,
      memoHandleAutocompleteInputs,
      memoHandleAutocompleteInputsValue,
      memoOnFilterChange,
      memoFilterMonitoringOptions,
      memoOccupationOptions
    ]
  )

  const handleResetFilters = useCallback(() => {
    resetFilters()
    setOpenFilters(false)
  }, [resetFilters])

  const applyFilters = useCallback(() => {
    setTriggerSet(true)
    setOpenFilters(false)
  }, [])

  const filtersButtons = useMemo(
    () => (
      <React.Fragment key='filters-buttons'>
        <StyledButton
          onClick={handleResetFilters}
          disabled={submitState.submitting}
        >
          {t('table.reset')}
        </StyledButton>
        <StyledButton
          type='primary'
          onClick={applyFilters}
          disabled={submitState.submitting}
        >
          {t('table.apply')}
        </StyledButton>
      </React.Fragment>
    ),
    [handleResetFilters, applyFilters, submitState.submitting, t]
  )

  const resetAreaInDetail = useCallback(() => {
    handleSetAreaInDetail(null)
  }, [handleSetAreaInDetail])

  const breadcrumbItems = useMemo(
    () => [
      {
        path: path,
        label: 'sections.areas',
        onClick: resetAreaInDetail
      },
      {
        path: '',
        label: `${t(title)} ${
          areaInDetail?.name ? `- ${areaInDetail?.name}` : ''
        }`
      }
    ],
    [path, title, resetAreaInDetail, t, areaInDetail?.name]
  )

  const openNewPopup = useCallback(() => setOpen(true), [])

  const mapData = useMemo(
    () =>
      areaInDetail != null && fetchedArea != null
        ? fetchedArea.geometry != null
          ? [fetchedArea]
          : null
        : areaState.areas,
    [areaInDetail, fetchedArea, areaState.areas]
  )

  const flyToLocation = useMemo(
    () =>
      locationSelected?.boundingbox != null
        ? [
          [locationSelected.boundingbox[2], locationSelected.boundingbox[0]],
          [locationSelected.boundingbox[3], locationSelected.boundingbox[1]]
        ]
        : null,
    [locationSelected?.boundingbox]
  )

  const onSearchChange = useCallback(event => setLocationTextInput(event), [])
  const onSearchInputChange = useCallback(
    (event, newValue) => setLocationSelected(newValue),
    []
  )

  const getOptionLabel = useCallback(option => option.displayName, [])

  const mapSearchProps = useMemo(
    () => ({
      value: locationTextInput,
      onChange: onSearchChange,
      inputValue: locationSelected,
      onInputChange: onSearchInputChange,
      options: locationOptions,
      label: t('map.search_location'),
      getOptionLabel: getOptionLabel
    }),
    [
      locationTextInput,
      locationSelected,
      getOptionLabel,
      locationOptions,
      onSearchChange,
      onSearchInputChange,
      t
    ]
  )

  const showSpots = useMemo(() => areaInDetail != null && fetchedArea != null, [
    areaInDetail,
    fetchedArea
  ])
  const isDrawAvailable = useMemo(() => isEditing && canEdit('geometry'), [
    isEditing,
    canEdit
  ])

  const memoSetTriggerSet = useCallback(() => setTriggerSet(true), [])

  const onCloseFilters = useCallback(() => setOpenFilters(false), [])
  const onOpenFilters = useCallback(() => setOpenFilters(true), [])

  return (
    <Wrapper>
      <BreadCrumbRow>
        <StyledBreadCrumb items={breadcrumbItems} />
        {areaInDetail == null && hasCRUDPermission && (
          <ButtonWrapper>
            <StyledButton
              type='primary'
              onClick={openNewPopup}
              disabled={submitState.submitting}
            >
              {t('areas.new')}
            </StyledButton>
            <StyledButton
              tooltip={t('upload_csv')}
              type='primary'
              onClick={handleClick}
              disabled={submitState.submitting}
            >
              <UploadIcon height={24} width={24} fill='var(--white-color)' />
              <input
                type='file'
                ref={hiddenFileInput}
                onChange={handleChange}
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.csv,.xls,.xlsx'
                style={{ display: 'none' }}
              />
            </StyledButton>
            {!responsiveHandlers.isDesktop && (
              <StyledButton
                tooltip={t('filters')}
                type='primary'
                onClick={onOpenFilters}
              >
                <Badge
                  color='secondary'
                  variant='dot'
                  invisible={
                    JSON.stringify(filters) ===
                    JSON.stringify({
                      monitoring_type: '',
                      name: '',
                      verboseOccupation: ''
                    })
                  }
                >
                  <FilterIcon
                    height={24}
                    width={24}
                    fill='var(--white-color)'
                  />
                </Badge>
              </StyledButton>
            )}
          </ButtonWrapper>
        )}
      </BreadCrumbRow>
      {areaInDetail == null && responsiveHandlers.isDesktop && (
        <FiltersWrapper>
          <FiltersRow>{[filtersInputs, filtersButtons]}</FiltersRow>
        </FiltersWrapper>
      )}
      <MapAndTextWrapper>
        <MapWrapper>
          <MapContainer
            data={mapData}
            hideListLegend
            type='areas'
            isDrawAvailable={isDrawAvailable}
            geometry={geometry}
            setGeometry={setGeometry}
            showSpots={showSpots}
            inDetail={areaInDetail}
            enableSearch={isDrawAvailable}
            search={mapSearchProps}
            flyToLocation={flyToLocation}
          />
        </MapWrapper>
        {isDrawAvailable && <>{mapHelperText}</>}
      </MapAndTextWrapper>
      <Switch>
        <Route path={`${path}:id/`} exact>
          <AreaDetails
            setAreaInDetail={handleSetAreaInDetail}
            areaInDetail={areaInDetail}
            monitoringTypeOptions={memoMonitoringOptions}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            setGeometry={setGeometry}
            geometry={geometry}
            setTitle={setTitle}
            setMapHelperText={setMapHelperText}
            canEdit={canEdit}
            fetchedArea={fetchedArea}
            setFetchedArea={setFetchedArea}
            resetParent={resetParent}
            hasCRUDPermission={hasCRUDPermission}
            update={update}
          />
        </Route>
        <Route path={path} exact>
          <AreaList
            loading={loading}
            areas={areaState.areas}
            areasCount={areaState.areasCount}
            columns={columns}
            monitoringTypeOptions={memoMonitoringOptions}
            setAreaInDetail={handleSetAreaInDetail}
            open={open}
            setOpen={setOpen}
            setTitle={setTitle}
            pagination={pagination}
            setPagination={setPagination}
            triggerSet={memoSetTriggerSet}
          />
        </Route>
        <Route path='*'>
          <Redirect to={path} />
        </Route>
      </Switch>
      <FiltersModal
        open={openFilters}
        onClose={onCloseFilters}
        inputs={filtersInputs}
        buttons={filtersButtons}
        title={t('filters')}
      />
    </Wrapper>
  )
}

export default memo(withResponsive(Areas))

const Wrapper = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;

  ${({ theme }) => theme.smallDesktop`
    padding: 1.5rem 2rem;
  `}
`

const MapWrapper = styled.div`
  width: 100%;
  min-height: 22rem;
  height: 100%;
`

const MapAndTextWrapper = styled.div`
  margin: 1.5rem 0;
  /* height: calc(22rem + 0.875rem + 0.5rem); */
  /* 100vh - navbar - wrapper_padding - breadcrumb_height - filters_height+margin - this.margin - table_maxheight - pagination */
  min-height: 22rem;

  height: calc(
    100vh - 3.75rem - 4rem - 4.125rem - 4rem - 3rem - 25rem - 3.75rem
  );
  flex: 0 0 auto;
`

const BreadCrumbRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  flex: 0 0 auto;

  ${({ theme }) => theme.smallDesktop`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 2.5rem;
  `}
`
const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 10rem 2.5rem 2.5rem;
  column-gap: 0.5rem;
  margin-right: auto;
  ${({ theme }) => theme.smallDesktop`
    grid-template-columns: 14.625rem 2.5rem;
    column-gap: 0.5rem;
    margin: 0;
  `}
`

const FiltersWrapper = styled.div`
  margin: 1.5rem 0 0rem;
`

const FiltersRow = styled.div`
  display: none;
  ${({ theme }) => theme.smallDesktop`
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    display: grid;
    column-gap: 1rem;
    row-gap: 1rem;

  `}
`

// import { hot } from 'react-hot-loader/root'
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import './translations/i18n'
import App from './containers/App'
import AuthProvider from 'providers/AuthProvider/authProvider'
import ResponsiveProvider from 'providers/ResponsiveHandler/responsiveProvider'

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <ResponsiveProvider>
        <App />
      </ResponsiveProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

/**
 * StyledSelect Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from 'react'
import styled, { css } from 'styled-components'

const AnimatedArrow = props => (
  <AnimatedArrowWrapper>
    <svg width={20} height={20} viewBox='0 0 24 24' transform={props.transform}>
      <path
        fill={props.fill || 'var(--platinium-gray-color)'}
        d='M7,10L12,15L17,10H7Z'
      />
    </svg>
  </AnimatedArrowWrapper>
)

const AnimatedArrowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  height: ${props => props.height};
  width: ${props => props.width};
  transform: ${({ transform }) => transform || ''};
  transition: 0.5s all;
`

class ToggleFilter extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
    this.wrapperRef = React.createRef()
  }

  componentDidUpdate (oldProps) {
    if (this.props.variableToWatch !== oldProps.variableToWatch) {
      this.setState({ open: false })
    }
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = event => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.current.contains(event.target) &&
      this.state.open
    ) {
      this.setState({ open: false })
    }
  };

  render () {
    const { open } = this.state
    const { text, children, active } = this.props
    // console.log(active)
    return (
      <Wrapper ref={this.wrapperRef}>
        <ToggleButtonWrapper
          open={open}
          onClick={() => this.setState({ open: !open })}
        >
          <ToggleText active={active}>{text}</ToggleText>
          <AnimatedArrow
            transform={open ? 'rotate(180)' : 'rotate(0)'}
            fill={open ? 'var(--primary-color)' : 'var(--platinium-gray-color)'}
          />
        </ToggleButtonWrapper>
        <ReferenceWrapper>
          <DynamicWrapper style={{ maxHeight: open ? '1000px' : '0px' }}>
            {children}
          </DynamicWrapper>
        </ReferenceWrapper>
      </Wrapper>
    )
  }
}

export default ToggleFilter

const Wrapper = styled.div`
  position: relative;

  z-index: 1000;
  user-select: none;
`

const ReferenceWrapper = styled.div`
  position: absolute;
  box-shadow: 0px 0px 1.5rem 0px rgba(204, 204, 204, 1);
  top: 3.5rem;
  left: 0;
  ${({ theme }) => theme.smallDesktop`
    right: 0px;
    left: auto;
  `}
`

const DynamicWrapper = styled.div`
  height: auto;
  overflow: hidden;
  position: relative;
  right: 0;
`

const ToggleButtonWrapper = styled.div`
  height: 2.375rem;
  padding: 0.5rem 2rem 0.5rem 0.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--secondary-color);
  transition: border-color 0.5s, color 0.5s;
  margin: ${({ margin }) => margin};
  background-color: var(--white-color);
  border: 1px solid var(--platinium-gray-color);
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid rgba(0, 0, 0, 0.87);
  }

  ${props =>
    props.open &&
    css`
      border: 2px solid var(--primary-color);
      &:hover,
      &:focus,
      &:active {
        border: 2px solid var(--primary-color);
      }
    `}
`

const ToggleText = styled.span`
  color: var(--platinium-gray-color);
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1rem;
  text-transform: uppercase;

  &:focus,
  &:active {
    color: var(--primary-color);
  }

  ${props =>
    props.active &&
    css`
      color: var(--breadcrumb-gray-color);
    `}
`

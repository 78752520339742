import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-name='Layer 1'
      width={708}
      height={555.867}
      {...props}
    >
      <path
        data-name='Path 133'
        d='M644.465 507.448a211.722 211.722 0 01-46.196 37.27c-.392.242-.79.468-1.19.701l-27.639-24.462c.29-.27.612-.573.959-.905 20.593-19.555 122.83-156.28 129.643-189.575-.47 2.774 6.147 111.55-55.577 176.971z'
        fill='#f0f0f0'
      />
      <path
        data-name='Path 134'
        d='M603.602 549.439c-.523.125-1.055.241-1.588.348l-20.735-18.353c.405-.141.878-.309 1.42-.495 8.897-3.15 35.394-12.66 61.764-23.492 28.335-11.644 56.533-24.8 62.598-33.227-1.301 1.958-41.375 61.336-103.46 75.219z'
        fill='#f0f0f0'
      />
      <path
        d='M644.082 145.58h-2.953V64.686a46.819 46.819 0 00-46.82-46.819H422.927a46.819 46.819 0 00-46.82 46.82v443.788a46.819 46.819 0 0046.82 46.82H594.31a46.819 46.819 0 0046.819-46.82V203.162h2.953z'
        fill='#3f3d56'
      />
      <path
        d='M631.163 65.008v443.143a34.957 34.957 0 01-34.97 34.957H423.984a34.954 34.954 0 01-34.957-34.957V65.008a34.954 34.954 0 0134.957-34.957h20.899a16.619 16.619 0 0015.383 22.88h98.182a16.601 16.601 0 0015.384-22.88h22.361a34.957 34.957 0 0134.97 34.957z'
        fill='#fff'
      />
      <path
        d='M577.595 295.289H426.228a10.011 10.011 0 01-10-10v-4.978a10.011 10.011 0 0110-10h151.367a10.011 10.011 0 0110 10v4.978a10.011 10.011 0 01-10 10zM577.595 338.196H426.228a10.011 10.011 0 01-10-10v-4.977a10.011 10.011 0 0110-10h151.367a10.011 10.011 0 0110 10v4.977a10.011 10.011 0 01-10 10z'
        fill='#f0f0f0'
      />
      <path
        d='M585.166 285.693H433.8a10.512 10.512 0 01-10.5-10.5v-4.978a10.512 10.512 0 0110.5-10.5h151.366a10.512 10.512 0 0110.5 10.5v4.978a10.512 10.512 0 01-10.5 10.5zM433.8 261.715a8.51 8.51 0 00-8.5 8.5v4.978a8.51 8.51 0 008.5 8.5h151.366a8.51 8.51 0 008.5-8.5v-4.978a8.51 8.51 0 00-8.5-8.5zM585.166 328.6H433.8a10.512 10.512 0 01-10.5-10.5v-4.978a10.512 10.512 0 0110.5-10.5h151.366a10.512 10.512 0 0110.5 10.5v4.978a10.512 10.512 0 01-10.5 10.5zM433.8 304.622a8.51 8.51 0 00-8.5 8.5v4.978a8.51 8.51 0 008.5 8.5h151.366a8.51 8.51 0 008.5-8.5v-4.978a8.51 8.51 0 00-8.5-8.5z'
        fill='#3f3d56'
      />
      <path
        d='M631.163 65.008v96.39A103.539 103.539 0 01501.28 61.323q0-4.24.341-8.392h56.827a16.601 16.601 0 0015.384-22.88h22.361a34.957 34.957 0 0134.97 34.957z'
        fill='#f0f0f0'
      />
      <path
        d='M476.04 537.088c0 2.032-.075 4.038-.239 6.02h-51.817a34.954 34.954 0 01-34.957-34.957v-47.4a77.073 77.073 0 0187.014 76.337zM58.121 453.919a6.007 6.007 0 007.911-3.067l46.68-105.794a13.38 13.38 0 00-17.078-17.876 13.2 13.2 0 00-6.75 5.78 13.649 13.649 0 00-.654 1.293L41.55 440.05a6 6 0 003.068 7.912z'
        fill='#00a4cc'
      />
      <path
        d='M110.795 388.491l-46.171-10.26 11.428-30.233a31.95 31.95 0 0134.299-23.628l.444.05z'
        fill='#2f2e41'
      />
      <path
        d='M204.055 455.432a7.028 7.028 0 01-6.412-4.177l-46.679-105.794a14.38 14.38 0 0125.61-12.997 14.618 14.618 0 01.702 1.388l46.68 105.794a7 7 0 01-3.58 9.23l-13.96 6.16-.008-.016a6.944 6.944 0 01-2.353.412zM139.485 419.103a6.007 6.007 0 00-6 6v123.014a6.007 6.007 0 006 6h14.76a6.007 6.007 0 006-6V425.103a6.007 6.007 0 00-6-6zM110.293 419.103a6.007 6.007 0 00-6 6v123.014a6.007 6.007 0 006 6h14.76a6.007 6.007 0 006-6V425.103a6.007 6.007 0 00-6-6z'
        fill='#00a4cc'
      />
      <circle cx={131.828} cy={239.224} r={51} fill='#00a4cc' />
      <path
        d='M148.8 260.633c3.306-.092 7.42-.207 10.59-2.522a8.133 8.133 0 003.2-6.073 5.47 5.47 0 00-1.86-4.493c-1.656-1.399-4.073-1.727-6.679-.961l2.7-19.726-1.982-.271-3.173 23.19 1.655-.76c1.918-.88 4.551-1.327 6.188.055a3.515 3.515 0 011.152 2.896 6.147 6.147 0 01-2.38 4.528c-2.468 1.802-5.747 2.034-9.467 2.138zM166.55 228.559h10.772v2H166.55zM132.55 228.559h10.772v2H132.55zM182.362 456.053l-18.356-134a6 6 0 00-5.945-5.186h-13.856a6.025 6.025 0 00.09-1v-5a6 6 0 00-6-6h-12a6 6 0 00-6 6v5a6.025 6.025 0 00.09 1H106.53a6 6 0 00-5.945 5.186l-18.356 134a6 6 0 005.944 6.814h88.244a6 6 0 005.945-6.814z'
        fill='#2f2e41'
      />
      <path
        d='M153.795 388.491V324.42l.445-.05a31.961 31.961 0 0134.316 23.68l11.41 30.18zM129.26 291.816l-35.276-13.692c-5.768-2.24-11.568-4.41-17.306-6.726a19.726 19.726 0 01-6.662-3.926 13.09 13.09 0 01-3.32-6.427c-1.224-5.172-1.003-10.825-.916-16.103a122.7 122.7 0 011.422-17.24 83.286 83.286 0 0110.65-30.38c9.893-16.232 26.938-28.444 46.543-27.47 9.077.451 18.206 3.708 24.95 9.914a15.751 15.751 0 002.355 2.283 3.268 3.268 0 001.875.126q1.235-.08 2.471-.122a51.043 51.043 0 018.566.36 27.121 27.121 0 0114.13 5.867c3.37 2.9 6.112 6.992 6.395 11.54a13.66 13.66 0 01-4.615 11.15c-4.208 3.547-10.213 2.3-15.261 2.033l-18.494-.98-9.37-.496c-1.93-.102-1.924 2.898 0 3l24.905 1.32c4.002.211 8.127.731 12.132.491a13.574 13.574 0 008.334-3.352 16.628 16.628 0 005.036-15.601c-1.284-6.138-5.852-11.215-11.206-14.235-7.287-4.11-15.988-4.504-24.141-3.896l1.06.439c-9.222-9.898-23.348-14.077-36.61-12.615-14.002 1.544-26.456 9.356-35.404 20.038-9.976 11.909-15.413 26.76-17.526 42.03a139.839 139.839 0 00-1.082 24.88 31.35 31.35 0 001.852 10.75 15.39 15.39 0 007.225 7.746 103.398 103.398 0 0011.468 4.711l12.996 5.045 25.528 9.908 6.498 2.522c1.801.7 2.581-2.2.798-2.892z'
        fill='#2f2e41'
      />
      <path
        d='M73.795 189.867a15.5 15.5 0 1115.5-15.5 15.517 15.517 0 01-15.5 15.5zm0-28a12.5 12.5 0 1012.5 12.5 12.514 12.514 0 00-12.5-12.5z'
        fill='#2f2e41'
      />
      <path
        fill='#f0f0f0'
        d='M299.35 260.263l33.55 25.66-25.66 33.549-33.549-25.66zM143.295 56.867a33.949 33.949 0 0127.278 13.703l19.59-25.614L131.382 0 86.429 58.779l25.334 19.376a34.003 34.003 0 0131.533-21.288z'
      />
      <path d='M707 555.867H1a1 1 0 010-2h706a1 1 0 010 2z' fill='#3f3d56' />
      <path
        d='M534.687 389.937h-71.862a10.011 10.011 0 01-10-10v-4.978a10.011 10.011 0 0110-10h71.862a10.011 10.011 0 0110 10v4.978a10.011 10.011 0 01-10 10z'
        fill='#00a4cc'
      />
      <path
        d='M542.26 380.341h-71.863a10.512 10.512 0 01-10.5-10.5v-4.977a10.512 10.512 0 0110.5-10.5h71.862a10.512 10.512 0 0110.5 10.5v4.977a10.512 10.512 0 01-10.5 10.5zm-71.863-23.977a8.51 8.51 0 00-8.5 8.5v4.977a8.51 8.51 0 008.5 8.5h71.862a8.51 8.51 0 008.5-8.5v-4.977a8.51 8.51 0 00-8.5-8.5z'
        fill='#3f3d56'
      />
    </svg>
  )
}

export default SvgComponent

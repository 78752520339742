import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#prefix__clip0)'>
        <rect
          y={12}
          width={16.971}
          height={16.971}
          rx={2}
          transform='rotate(-45 0 12)'
          fill='#006982'
        />
        <rect
          x={0.707}
          y={12}
          width={15.971}
          height={15.971}
          rx={1.5}
          transform='rotate(-45 .707 12)'
          stroke='#fff'
          strokeOpacity={0.1}
        />
        <path
          d='M15.89 8.094a.322.322 0 00-.456.456 4.823 4.823 0 011.421 3.433 4.823 4.823 0 01-1.42 3.434.322.322 0 00.455.456 5.464 5.464 0 001.61-3.89c0-1.47-.572-2.85-1.61-3.889z'
          fill='#fff'
        />
        <path
          d='M14.914 9.07a.322.322 0 00-.455.456 3.452 3.452 0 011.017 2.457c0 .93-.362 1.802-1.018 2.458a.322.322 0 00.456.456 4.093 4.093 0 001.206-2.914 4.093 4.093 0 00-1.206-2.913z'
          fill='#fff'
        />
        <path
          d='M13.482 10.047a.322.322 0 01.456 0 2.72 2.72 0 01.801 1.936 2.72 2.72 0 01-.801 1.937.322.322 0 11-.456-.456 2.08 2.08 0 00.613-1.48 2.08 2.08 0 00-.613-1.482.322.322 0 010-.455zM7.145 11.983c0-1.297.504-2.516 1.42-3.433a.322.322 0 00-.455-.456 5.463 5.463 0 00-1.61 3.89c0 1.47.572 2.85 1.61 3.889a.322.322 0 00.456-.456 4.823 4.823 0 01-1.421-3.434z'
          fill='#fff'
        />
        <path
          d='M9.541 9.526a3.452 3.452 0 00-1.017 2.457c0 .93.362 1.802 1.018 2.458a.322.322 0 01-.456.456 4.093 4.093 0 01-1.206-2.914c0-1.1.428-2.135 1.206-2.913a.322.322 0 01.455.456z'
          fill='#fff'
        />
        <path
          d='M10.518 10.047a.322.322 0 00-.456 0 2.72 2.72 0 00-.802 1.936c0 .732.285 1.42.802 1.937a.322.322 0 00.456-.456 2.08 2.08 0 01-.613-1.48c0-.56.218-1.086.613-1.482a.322.322 0 000-.455zM10.642 11.983c0-.748.61-1.357 1.358-1.357.749 0 1.358.609 1.358 1.357A1.36 1.36 0 0112 13.341a1.36 1.36 0 01-1.358-1.357z'
          fill='#fff'
        />
        <g filter='url(#prefix__filter0_d)'>
          <circle cx={19} cy={5} r={3} fill='#969696' />
          <circle cx={19} cy={5} r={2.5} stroke='#fff' strokeOpacity={0.3} />
        </g>
      </g>
      <defs>
        <clipPath id='prefix__clip0'>
          <path fill='#fff' d='M0 0h24v24H0z' />
        </clipPath>
        <filter
          id='prefix__filter0_d'
          x={12}
          y={-2}
          width={14}
          height={14}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent

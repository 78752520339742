import React from 'react'
import styled from 'styled-components'
import Datetime from 'react-datetime'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { API_DATE_FORMAT, DATE_FORMAT } from 'config'
import moment from 'moment'

export default ({ children, ...props }) => {
  const { i18n } = useTranslation()
  if (isMobile) {
    return (
      <MobileWrapper>
        <MobileInputCalendarView>
          {moment(props.value).format(DATE_FORMAT)}
        </MobileInputCalendarView>
        <MobileCalendarPick
          value={moment(props.value).format(API_DATE_FORMAT)}
          onChange={event =>
            event.target.value != null &&
            event.target.value !== '' &&
            props.onChange(moment(event.target.value))}
          type='date'
        />
      </MobileWrapper>
    )
  }
  return (
    <Wrapper {...props} locale={i18n.language === 'pt' ? 'pt' : 'en-gb'} />
  )
}

const Wrapper = styled(Datetime)`
  width: 15.625rem;
  margin: 1rem;
  height: 100%;

  input {
    width: 100%;
    padding: 10px;
    height: 2.375rem;
    border-radius: 0;
    border: var(--table-border);

    &:active, &:focus{
      border: 2px solid var(--primary-color);
      outline: none;
    }
  }

  .rdtPicker {
    width: 100%;
    margin-top: 10px;
    th {
      height: 33px;
      border: 0px;
    }
  }

  .rdtMonth {
    transition: 0s all;
    :hover {
      background-color: var(--primary-color) !important;
      border: 1px solid var(--primary-color) !important;
      color: ${props => props.theme.white};
    }
    &.rdtActive {
      background-color: var(--primary-color) !important;
    }
  }
  .rdtYear {
    transition: 0s all;
    :hover {
      background-color: var(--primary-color) !important;
      border: 1px solid var(--primary-color) !important;
      color: ${props => props.theme.white};
    }
    &.rdtActive {
      background-color: var(--primary-color) !important;
    }
  }
  .rdtDay {
    transition: 0s all;
    :hover {
      background-color: var(--primary-color) !important;
      border: 1px solid var(--primary-color) !important;
      color: ${props => props.theme.white};
    }
    &.rdtActive {
      background-color: var(--primary-color) !important;
    }
  }
  &.rdtOpen .rdtPicker{
    position: relative;
  }
`

const MobileWrapper = styled.div`
  width: 100%;
  position: relative;
  background: transparent;
  display: flex;
  align-items: center;
  margin: 1rem 0;
`

const MobileCalendarPick = styled.input`
  opacity: 0;
  width: 100%;
`

const MobileInputCalendarView = styled.span`
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  -webkit-appearance: none;

  padding: 0 0.5rem;
  background-color: transparent;
  border: none;
  font-size: 0.875rem;
  line-height: 1em;

  height: 2rem;
  border-radius: 0.25rem;
  border: var(--table-border);

  &:focus,
  &:active {
    outline: none;
    border: var(--table-border) !important;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`

import React, { useCallback, useMemo, memo } from 'react'
import { useTranslation } from 'react-i18next'
import isEqual from 'lodash.isequal'

import InputAndLabel from 'containers/InputAndLabel'
import SelectAndLabel from 'containers/SelectAndLabel'

import { SameLineInputs } from '../styles'

const SubmitSensor = ({
  form,
  areas,
  monitoringOptions,
  filteredGateways,
  allowedOptions,
  hasSubmitted,
  handleChange,
  checkIfError,
  helperTextGenerator
}) => {
  const { t } = useTranslation()

  const memoHandleChange = useCallback(name => e => handleChange(name, e), [
    handleChange
  ])

  const memoHandleChangeValue = useCallback(
    name => e => handleChange(name, e?.target?.value),
    [handleChange]
  )

  const memoAreasOptions = useMemo(
    () =>
      areas
        ? [
          { value: '', label: t('table.remove') },
          ...areas.map(el => ({
            value: el.id,
            label: el.name ?? el.id
          }))
        ]
        : [],
    [areas, t]
  )

  const memoMonitoringOptions = useMemo(
    () =>
      monitoringOptions?.map(el => ({
        ...el,
        label: t(`table.${el.label}`)
      })) ?? [],
    [monitoringOptions, t]
  )

  const memoFilteredGateways = useMemo(
    () =>
      filteredGateways != null
        ? [
          { value: '', label: t('table.remove') },
          ...filteredGateways.map(el => ({
            value: el.id,
            label: el.uid ?? el.id
          }))
        ]
        : [],
    [filteredGateways, t]
  )

  const memoAllowedOptions = useMemo(
    () =>
      allowedOptions.map(el => ({
        ...el,
        label: t(`table.${el.label}`)
      })),
    [allowedOptions, t]
  )

  return (
    <>
      <SameLineInputs>
        <InputAndLabel
          type='text'
          name='description'
          value={form?.description?.value ?? ''}
          onChange={memoHandleChange('description')}
          label={t('table.description')}
          error={
            hasSubmitted &&
            (form?.description?.message != null ||
              !!checkIfError('description'))
          }
          helperText={helperTextGenerator('description')}
          maxLength={255}
          masked
          mask={/^[\w\s]+$/}
        />
        <SelectAndLabel
          value={form?.monitoringType?.value ?? ''}
          onChange={memoHandleChangeValue('monitoringType')}
          label={t('table.monitoring_type')}
          options={memoMonitoringOptions}
          id='monitoringType' // OBRIGATORIO POR CAUSA DA LABEL/
          error={
            hasSubmitted &&
            (form?.monitoringType?.message != null ||
              !!checkIfError('monitoringType'))
          }
          helperText={helperTextGenerator('monitoringType')}
        />
      </SameLineInputs>

      <SameLineInputs>
        <InputAndLabel
          name='distanceThreshold'
          value={form?.distanceThreshold?.value ?? ''}
          onChange={memoHandleChangeValue('distanceThreshold')}
          label={t('table.distance_threshold')}
          error={
            hasSubmitted &&
            (form?.distanceThreshold?.message != null ||
              !!checkIfError('distanceThreshold'))
          }
          helperText={helperTextGenerator('distanceThreshold')}
          number
          max={65535}
          min={0}
        />
        <InputAndLabel
          name='softwareVersion'
          value={form?.softwareVersion?.value ?? ''}
          onChange={memoHandleChangeValue('softwareVersion')}
          label={t('table.software_version')}
          error={
            hasSubmitted &&
            (form?.softwareVersion?.message != null ||
              !!checkIfError('softwareVersion'))
          }
          helperText={helperTextGenerator('softwareVersion')}
          number
          max={255}
          min={0}
        />
      </SameLineInputs>

      <SameLineInputs>
        <SelectAndLabel
          value={form?.area?.value ?? ''}
          onChange={memoHandleChangeValue('area')}
          label={t('table.area')}
          options={memoAreasOptions}
          id='area' // OBRIGATORIO POR CAUSA DA LABEL/
          error={
            hasSubmitted &&
            (form?.area?.message != null || !!checkIfError('area'))
          }
          helperText={helperTextGenerator('area')}
        />
        <SelectAndLabel
          value={form?.gateway?.value ?? ''}
          onChange={memoHandleChangeValue('gateway')}
          label={t('table.gateway')}
          options={memoFilteredGateways}
          id='gateway' // OBRIGATORIO POR CAUSA DA LABEL/
          error={
            hasSubmitted &&
            form.gateway != null &&
            (form.gateway.message != null || !!checkIfError('gateway'))
          }
          helperText={helperTextGenerator('gateway')}
        />
      </SameLineInputs>

      <SameLineInputs>
        <SelectAndLabel
          value={form?.allowed?.value ?? ''}
          onChange={memoHandleChangeValue('allowed')}
          label={t('table.allowed')}
          options={memoAllowedOptions}
          id='allowed' // OBRIGATORIO POR CAUSA DA LABEL/
          error={
            hasSubmitted &&

            (form?.allowed?.message != null || !!checkIfError('allowed'))
          }
          helperText={helperTextGenerator('allowed')}
        />
      </SameLineInputs>
    </>
  )
}

export default memo(SubmitSensor, isEqual)

import axios from 'axios'
import ValidationError from 'utils/ValidationError'

import { setAuthorizationInterceptor } from 'interceptors'
import { BASE_API_URL } from 'config'
const PROFILE_URL = BASE_API_URL + '/profile/me/'
const REFRESHURL = BASE_API_URL + '/token/refresh/'

const logic = {
  _token (accessToken) {
    if (typeof accessToken !== 'undefined') {
      window.localStorage.setItem('access_token', accessToken)
      return
    }

    return window.localStorage.getItem('access_token')
  },

  retrieveUser () {
    return axios.get(PROFILE_URL)
  },

  isLoggedIn () {
    const res = !!this._token()
    return res
  },

  checkUserAuth (payload) {
    return Promise.resolve().then(() => {
      const refreshToken = window.localStorage.getItem('refresh_token')
      if (refreshToken) {
        const params = {
          refresh: refreshToken
        }
        return axios.post(REFRESHURL, params)
      } else {
        throw new ValidationError()
      }
    })
  },

  login (data) {
    const { access, refresh } = data
    if (access != null) {
      setAuthorizationInterceptor(access)
      window.localStorage.setItem('access_token', access)
    }
    if (refresh != null) {
      window.localStorage.setItem('refresh_token', refresh)
    }
  },

  logout () {
    window.localStorage.clear()
  }
}

export default logic

import React, { useState, memo, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import get from 'lodash.get'
import isEqual from 'lodash.isequal'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

const StyledTable = ({ data, columns, defaultOrder = 'asc' }) => {
  const location = useLocation()
  const { t } = useTranslation()
  const [order, setOrder] = useState(location.pathname === '/' ? 'desc' : defaultOrder)
  const [orderBy, setOrderBy] = useState(null)

  useEffect(() => {
    if (columns != null && columns[0] != null) {
      setOrderBy(columns[0].key)
    }
  }, [columns])

  const descendingComparator = useCallback((a, b, orderBy) => {
    const first = get(a, orderBy)
    const second = get(b, orderBy)

    if (
      first != null &&
      second != null &&
      (typeof first === 'string' || first instanceof String) &&
      (typeof second === 'string' || second instanceof String)
    ) {
      return first.localeCompare(second, undefined, {
        numeric: true,
        sensitivity: 'base'
      })
    }

    if (second < first) {
      return -1
    }
    if (second > first) {
      return 1
    }
    return 0
  }, [])

  const getComparator = useCallback((order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
    // eslint-disable-next-line
  },[])

  const handleRequestSort = useCallback(property => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }, [order, orderBy])

  const stableSort = useCallback((array, comparator) => {
    const stabilizedThis = array?.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis?.map(el => el[0])
  }, [])

  const renderCell = useCallback((element, column) => {
    if (column.render != null) {
      return column.render({ ...element, t })
    }
    if (column.key != null) {
      return t(get(element, column.key, '-'))
    }
    return '-'
    // eslint-disable-next-line
  }, [])

  return (
    <Table size='small'>
      <StyledTableHead>
        <StyledTableRow>
          {columns != null &&
            columns.map((column, index) => (
              <StyledTableCell key={`table-header-cell-${index}`}>
                {column.icon ? (
                  <Tooltip title={column.label} placement='top' arrow>
                    <TableSortLabel
                      active={orderBy === column.key}
                      direction={orderBy === column.key ? order : 'asc'}
                      onClick={() => handleRequestSort(column.key)}
                    >
                      {column.icon || column.label}
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  <TableSortLabel
                    active={orderBy === column.key}
                    direction={orderBy === column.key ? order : 'asc'}
                    onClick={() => handleRequestSort(column.key)}
                  >
                    {column.icon || column.label}
                  </TableSortLabel>
                )}
              </StyledTableCell>
            ))}
        </StyledTableRow>
      </StyledTableHead>
      <StyledTableBody>
        {data != null &&
          stableSort(data, getComparator(order, orderBy)).map((el, index) => (
            <StyledTableRow key={`assets-row-${index}`}>
              {columns != null &&
                columns.map((column, columnIndex) => (
                  <StyledTableCell
                    align='left'
                    key={`table-cell-${index}-${columnIndex}`}
                  >
                    {column != null && renderCell(el, column)}
                  </StyledTableCell>
                ))}
            </StyledTableRow>
          ))}
      </StyledTableBody>
    </Table>
  )
}

export default memo(StyledTable, isEqual)

const StyledTableRow = styled(TableRow)`
  background-color: var(--white-color);
  &:nth-of-type(even) {
    background-color: rgba(0, 0, 50, 0.02);
  }
`

var StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    border: none;
    color: var(--text-color);
    font-size: 0.875rem;
    letter-spacing: 0;
    line-height: 1.4285714286em;
    padding: 0.5rem;
    max-width: 20ch;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
`

const StyledTableHead = styled(TableHead)`
  & ${StyledTableRow} {
    background-color: var(--primary-color);

    & ${StyledTableCell} {
      color: var(--white-color);
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 1.4285714286em;
    }
  }
`

const StyledTableBody = styled(TableBody)`
  &.MuiTableBody-root {
    border: var(--table-border);
  }
`

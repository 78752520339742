import React from 'react'
import { Context } from 'providers/AuthProvider/authProvider'

const withAuthState = ComposedComponent => props => (
  <Context.Consumer>
    {({ store, actions }) => (
      <ComposedComponent store={store} actions={actions} {...props} />
    )}
  </Context.Consumer>
)

export default withAuthState

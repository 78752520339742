import React, {
  useState,
  useEffect,
  useContext,
  memo,
  useCallback,
  useMemo
} from 'react'
import { useTranslation } from 'react-i18next'
import get from 'lodash.get'
import isEqual from 'lodash.isequal'

import findMinStepError from 'utils/findMinStepError'

import { Context as AuthContext } from 'providers/AuthProvider/authProvider'
import withResponsive from 'providers/ResponsiveHandler/withResponsive'

import logic, { types, STEP_VALIDATIONS } from './logic'
import { stepsByType } from './utils'

import StyledTable from 'components/StyledTable'
import StyledSkeleton from 'components/StyledSkeleton'
import CreateNewAssetModal from 'containers/CreateNewAssetModal'
import StyledPagination from 'components/StyledPagination'
import HardwareTemplate from 'components/HardwareTemplate'
import StyledMobileTable from 'components/StyledMobileTable'
import toast from 'components/StyledToast'

const HardwareList = ({ children, ...props }) => {
  const { actions } = useContext(AuthContext)

  const {
    loading,
    hardware,
    hardwareCount,
    columns,
    monitoringOptions,
    setHardwareInDetail,
    open,
    setOpen,
    allowedOptions,
    fetchedAreas,
    fetchedGateways,
    setTitle,
    pagination,
    setPagination,
    triggerSet,
    gatewaysTypeOptions,
    responsiveHandlers
  } = props

  const { t } = useTranslation()

  const [form, setForm] = useState(null)

  const [type, setType] = useState(null)

  const [submitState, setSubmitState] = useState({
    hasSubmitted: false,
    submitting: false,
    apiResponse: null
  })

  const [active, setActive] = useState(0)
  const [steps, setSteps] = useState(['type'])
  const [error, setError] = useState(null)

  const reset = useCallback(() => {
    setForm(null)
    setSubmitState({
      hasSubmitted: false,
      submitting: false,
      apiResponse: null
    })
  }, [])

  const handleClose = useCallback(() => {
    setType(null)
    setOpen(false)
    reset()
  }, [reset, setOpen, setType])

  const handleSubmit = useCallback(() => {
    setSubmitState(submitState => ({
      ...submitState,
      hasSubmitted: true,
      submitting: true
    }))
    logic
      .submitNewHardware(type, form, form => setForm({ ...form }))
      .then(response => {
        setSubmitState(submitState => ({
          ...submitState,
          submitting: false
        }))
        if (response.data) {
          handleClose()
          triggerSet()
          toast.success(t('assets.create_success'))
        }
      })
      .catch(err => {
        console.log({ err })
        toast.error(t('assets.create_error'))
        setSubmitState(submitState => ({
          ...submitState,
          submitting: false
        }))

        if (err == null || err.response == null) {
          actions.serverError()
        } else {
          if (err.response.data != null) {
            setSubmitState(submitState => ({
              ...submitState,
              submitting: false,
              apiResponse: err.response.data
            }))
            const errorStep = findMinStepError(
              err.response.data,
              STEP_VALIDATIONS,
              type
            )
            if (errorStep != null) {
              setActive(errorStep + 1) // first step is choose type
            }
          } else {
            setSubmitState(submitState => ({
              ...submitState,
              submitting: false
            }))
          }
        }
      })
  }, [form, type, actions, handleClose, t, triggerSet])

  const handleBackButton = useCallback(() => {
    setActive(active - 1)
  }, [active])

  useEffect(() => {
    setHardwareInDetail(null)
    reset()
    setTitle('assets.filter_title')
    // eslint-disable-next-line
  }, [])

  const handleNextButton = useCallback(() => {
    if (type != null && stepsByType != null) {
      if (active === 0) {
        setSteps(stepsByType[type])
        setActive(active + 1)
        return
      }
      setSubmitState(submitState => ({
        ...submitState,
        hasSubmitted: true
      }))

      const found = steps.find(el => el.includes('submit'))
      if (found != null && active === steps.indexOf(found)) {
        handleSubmit()
        return
      }
      logic
        .validateStepForm(type, stepsByType[type][active], form, form =>
          setForm({ ...form })
        )
        .then(response => {
          setActive(active + 1)
        })
        .catch(err => {
          console.log({ err })
        })
    } else {
      setError(true)
    }
  }, [type, steps, form, active, handleSubmit])

  useEffect(() => {
    if (!open) {
      reset()
    }
  }, [open, reset])

  useEffect(() => {
    if (type != null && types.includes(type)) {
      setForm(logic.defaultValues[type])
    } else {
      setForm(null)
    }
  }, [type])

  const checkIfError = useCallback(
    property => {
      const error = get(submitState.apiResponse, property, null)
      if (
        submitState.apiResponse != null &&
        error != null &&
        error[0] != null
      ) {
        return error[0]
      }
      return null
    },
    [submitState]
  )

  const handleChange = useCallback(
    (key, value) => {
      setForm({
        ...form,
        [key]: {
          value: value
        }
      })
    },
    [form]
  )

  const handlePaginationChange = useCallback(
    (event, page) => setPagination({ ...pagination, offset: page }),
    [pagination, setPagination]
  )

  const memoizedItems = useMemo(() => {
    return hardware
      .slice(
        (pagination.offset - 1) * pagination.limit,
        (pagination.offset - 1) * pagination.limit + pagination.limit
      )
      .map(el => ({ ...el, setHardwareInDetail }))
  }, [hardware, pagination, setHardwareInDetail])

  const memoizedColumns = useMemo(() => {
    return columns.map(el => ({ ...el, label: t(el.label) }))
  }, [columns, t])

  const memoizedLoadingTable = useMemo(
    () =>
      [...Array(6)].map((el, index) => (
        <StyledSkeleton
          key={`table-loading-row-${index}`}
          variant='rect'
          width='100%'
          height='2.5rem'
        />
      )),
    []
  )

  const memoizedTypes = useMemo(
    () =>
      types.map(el => ({
        value: el,
        label: t(`assets.new_${el}`)
      })),
    // eslint-disable-next-line
    []
  )

  return (
    <>
      {!loading ? (
        <>
          {hardware != null && responsiveHandlers.isDesktop && (
            <StyledTable data={memoizedItems} columns={memoizedColumns} />
          )}
          {hardware != null && !responsiveHandlers.isDesktop && (
            <StyledMobileTable
              data={memoizedItems}
              template={HardwareTemplate}
              setInDetail={setHardwareInDetail}
            />
          )}
          {hardware != null && (
            <StyledPagination
              count={Math.ceil(hardwareCount / pagination.limit)}
              page={pagination.offset}
              onChange={handlePaginationChange}
            />
          )}
        </>
      ) : (
        <>{memoizedLoadingTable}</>
      )}
      {open && (
        <CreateNewAssetModal
          open={open}
          onClose={handleClose}
          type={type}
          setType={setType}
          active={active}
          setActive={setActive}
          steps={steps}
          setSteps={setSteps}
          error={error}
          setError={setError}
          typeOptions={memoizedTypes}
          form={form}
          handleChange={handleChange}
          checkIfError={checkIfError}
          hasSubmitted={submitState.hasSubmitted}
          submitting={submitState.submitting}
          handleNextButton={handleNextButton}
          handleBackButton={handleBackButton}
          monitoringOptions={monitoringOptions}
          areas={fetchedAreas}
          gateways={fetchedGateways}
          gatewaysTypeOptions={gatewaysTypeOptions}
          allowedOptions={allowedOptions}
          fetchLocationOptions={logic.fetchLocationOptions}
        />
      )}
    </>
  )
}

export default memo(withResponsive(HardwareList), isEqual)

import axios from 'axios'
import mapValues from 'lodash/mapValues'
import ValidationError from 'utils/ValidationError'

import * as Check from 'utils/validations'
import { BASE_API_URL, NOMINATIM_BASE_URL, NOMINATIM_OPTIONS } from 'config'
const NEWSPOT_URL = BASE_API_URL + '/spots/'
const AVAILABLESENSORS_URL = BASE_API_URL + '/sensors/'
const NOMINATIM_SEARCH_URL = NOMINATIM_BASE_URL + '/search'

const VALIDATIONS = {
  uid: [Check.isRequired],
  sensor: [],
  spotType: [Check.isRequired],
  geometry: [Check.isRequired]
}

const logic = {
  defaultValues: {
    uid: { value: null },
    sensor: { value: null },
    spotType: { value: null },
    geometry: { value: null }
  },
  submitNewSpot: (form, setForm) =>
    Promise.resolve().then(() => {
      const validation = Check.checkValidation(form, VALIDATIONS)
      if (validation.invalid) {
        setForm({ ...validation.form })
        throw new ValidationError()
      }

      const params = mapValues(form, ({ value }) => value)
      return axios.post(NEWSPOT_URL, { ...params })
    }),
  fetchAvailableSensors: () =>
    Promise.resolve().then(() => axios.get(AVAILABLESENSORS_URL)),
  fetchLocationOptions: options =>
    Promise.resolve().then(() =>
      axios.get(NOMINATIM_SEARCH_URL, {
        ...options,
        params: {
          ...options.params,
          ...NOMINATIM_OPTIONS
        }
      })
    )
}

export default logic

import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  memo
} from 'react'
import { useTranslation } from 'react-i18next'
import toast from 'components/StyledToast'
import isEqual from 'lodash.isequal'

import { Context as AuthContext } from 'providers/AuthProvider/authProvider'
import withResponsive from 'providers/ResponsiveHandler/withResponsive'

import logic from './logic'

import StyledTable from 'components/StyledTable'
import StyledSkeleton from 'components/StyledSkeleton'
import StyledPagination from 'components/StyledPagination'

import SpotsTemplate from 'components/SpotsTemplate'
import StyledMobileTable from 'components/StyledMobileTable'
import CreateNewSpotModal from 'containers/CreateNewSpotModal'

const SpotsList = ({ children, ...props }) => {
  const { actions } = useContext(AuthContext)

  const {
    loading,
    spots,
    spotsCount,
    columns,
    setSpotInDetail,
    open,
    setOpen,
    spotTypeOptions,
    setTitle,
    pagination,
    setPagination,
    triggerSet,
    responsiveHandlers
  } = props
  const { t } = useTranslation()

  const [form, setForm] = useState(logic.defaultValues)

  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [apiResponse, setApiResponse] = useState(null)
  const [fetchedSensors, setFetchedSensors] = useState([])

  const reset = useCallback(() => {
    setForm(logic.defaultValues)
    setHasSubmitted(false)
    setSubmitting(false)
    setApiResponse(null)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
    reset()
  }, [reset, setOpen])

  const handleChange = useCallback(
    (key, value) => {
      setForm({
        ...form,
        [key]: {
          value: value
        }
      })
    },
    [form]
  )

  useEffect(() => {
    setSpotInDetail(null)
    reset()
    setTitle('spots.filter_title')
    logic
      .fetchAvailableSensors()
      .then(response => {
        if (response.data != null && response.data.results != null) {
          setFetchedSensors(response.data.results)
        }
      })
      .catch(err => {
        console.log({ err })
        setFetchedSensors([])
        // ERROR
      })
    // eslint-disable-next-line
  }, [])

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      setHasSubmitted(true)
      setSubmitting(true)
      logic
        .submitNewSpot(form, form => setForm({ ...form }))
        .then(response => {
          if (response.data) {
            handleClose()
            triggerSet()
            toast.success(t('spots.create_success'))
          }
        })
        .catch(err => {
          console.log({ err })

          toast.error(t('spots.create_error'))

          if (err == null || err.response == null) {
            actions.serverError()
          } else {
            if (err.response.data != null) {
              setApiResponse(err.response.data)
            }
          }
        })
        .finally(() => setSubmitting(false))
    },
    [actions, form, handleClose, t, triggerSet]
  )

  const memoizedItems = useMemo(
    () =>
      spots
        .slice(
          (pagination.offset - 1) * pagination.limit,
          (pagination.offset - 1) * pagination.limit + pagination.limit
        )
        .map(el => ({ ...el, t, setSpotInDetail })),
    [spots, pagination.limit, pagination.offset, setSpotInDetail, t]
  )

  const memoizedColumns = useMemo(() => {
    return columns.map(el => ({ ...el, label: t(el.label) }))
  }, [columns, t])

  const memoizedLoadingTable = useMemo(
    () =>
      [...Array(6)].map((el, index) => (
        <StyledSkeleton
          key={`table-loading-row-${index}`}
          variant='rect'
          width='100%'
          height='2.5rem'
        />
      )),
    []
  )

  const handlePaginationChange = useCallback(
    (event, page) => setPagination({ ...pagination, offset: page }),
    [pagination, setPagination]
  )

  return (
    <>
      {!loading ? (
        <>
          {spots != null && responsiveHandlers.isDesktop && (
            <StyledTable data={memoizedItems} columns={memoizedColumns} />
          )}
          {spots != null && !responsiveHandlers.isDesktop && (
            <StyledMobileTable
              data={memoizedItems}
              template={SpotsTemplate}
              setInDetail={setSpotInDetail}
            />
          )}
          <StyledPagination
            count={Math.ceil(spotsCount / pagination.limit)}
            page={pagination.offset}
            onChange={handlePaginationChange}
          />
        </>
      ) : (
        <>{memoizedLoadingTable}</>
      )}
      {open && (
        <CreateNewSpotModal
          form={form}
          open={open}
          onClose={handleClose}
          handleChange={handleChange}
          apiResponse={apiResponse}
          hasSubmitted={hasSubmitted}
          spotTypeOptions={spotTypeOptions}
          fetchedSensors={fetchedSensors}
          handleSubmit={handleSubmit}
          submitting={submitting}
          fetchLocationOptions={logic.fetchLocationOptions}
        />
      )}
    </>
  )
}

export default memo(withResponsive(SpotsList), isEqual)

import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useMemo,
  useCallback
} from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { AVAILABLE_SPOT_TYPES, API_DATE_FORMAT } from 'config'

import Badge from '@material-ui/core/Badge'

import { Context as AuthContext } from 'providers/AuthProvider/authProvider'
import { Context as SocketContext } from 'providers/SocketProvider/socketProvider'
import withResponsive from 'providers/ResponsiveHandler/withResponsive'

import columns from './columns'
import logic from './logic'

import { monitoringTypeOptions, intervalOptions } from 'utils/options'

import getModifiedEndDateMoment from 'utils/getModifiedEndDateMoment'

import useWindowSize from 'hooks/useWindowSize'

import StyledBreadCrumb from 'components/StyledBreadCrumb'
import StyledTable from 'components/StyledTable'
import StyledSelect from 'components/StyledSelect'
import StyledSkeleton from 'components/StyledSkeleton'
import ToggleFilter from 'components/ToggleFilter'
import StyledButton from 'components/StyledButton'
import StyledPagination from 'components/StyledPagination'
import EventsTemplate from 'components/EventsTemplate'
import StyledMobileTable from 'components/StyledMobileTable'

import IntervalPicker from 'containers/IntervalPicker'
import FiltersModal from 'containers/FiltersModal'

import FilterIcon from 'icons/FilterIcon'

const removalHeight = 17.375
const lineHeight = 2.25

export default withResponsive(({ children, ...props }) => {
  const { actions } = useContext(AuthContext)
  const { store: wsStore } = useContext(SocketContext)
  const { responsiveHandlers } = props

  const defaultFilter = useMemo(
    () => ({
      area__monitoringType: '',
      spotType: '',
      dateAfter: moment()
        .startOf('month')
        .format(API_DATE_FORMAT),
      dateBefore: moment().format(API_DATE_FORMAT),
      interval: 'day'
    }),
    []
  )

  const { t } = useTranslation()

  const size = useWindowSize()
  const wrapperRef = useRef(null)

  const convertRemToPixels = rem => {
    if (wrapperRef != null && wrapperRef.current != null) {
      return (
        rem * parseFloat(window.getComputedStyle(wrapperRef.current).fontSize)
      )
    }
    return (
      rem *
      parseFloat(
        window.getComputedStyle(document.getElementById('events-wrapper'))
          .fontSize
      )
    )
  }

  const [eventsState, setEventsState] = useState({
    eventsCount: 0,
    events: []
  })
  const [filters, setFilters] = useState(defaultFilter)
  const [pagination, setPagination] = useState({ offset: 1, limit: 10 })
  const [loading, setLoading] = useState(true)

  const [triggerSet, setTriggerSet] = useState(false)

  const [openFilters, setOpenFilters] = useState(false)

  // input filter state
  // it is used as the input internal state, before the filters are applied

  const onFilterChange = useCallback(
    (key, value) => {
      setFilters({
        ...filters,
        [key]: value
      })
    },
    [filters]
  )

  useEffect(() => {
    let wrapperHeight
    if (wrapperRef != null && wrapperRef.current != null) {
      wrapperHeight = wrapperRef.current.offsetHeight
    } else {
      wrapperHeight = document.getElementById('events-wrapper').offsetHeight
    }
    const limit = Math.floor(
      (wrapperHeight - convertRemToPixels(removalHeight)) /
        convertRemToPixels(lineHeight)
    )
    setPagination({ offset: 1, limit: limit > 5 ? limit : 5 })
  }, [])

  // const fetchEventAndUpdate = useCallback(
  //   async id => {
  //     try {
  //       const response = await logic.getEventDetails(id)
  //       if (
  //         response?.data &&
  //         (filters?.['area__monitoringType'] === '' ||
  //           filters?.['area__monitoringType'] ===
  //             response?.data?.area?.monitoringType) &&
  //         (filters?.spotType === 'all' ||
  //           filters?.spotType === response?.data?.spotType) &&
  //         moment(response?.data?.createdAt).isSameOrAfter(
  //           moment(filters?.dateAfter)
  //         ) &&
  //           moment(response?.data?.createdAt).isSameOrBefore(
  //             moment(filters?.dateBefore)
  //           )
  //       ) {
  //         const stateCopy = JSON.parse(JSON.stringify(eventsState?.events))
  //         const newEventsArray = [...stateCopy.slice(0, -1), response.data]
  //         setEventsState(currState => ({
  //           ...currState,
  //           events: newEventsArray
  //         }))
  //       }
  //     } catch (error) {
  //       console.log({ error })
  //     }
  //   },
  //   [eventsState?.events, filters]
  // )

  const fetchEvents = useCallback(() => {
    setLoading(true)
    setTriggerSet(false)

    logic
      .getEvents({
        params: {
          ...{
            ...pagination,
            offset: (pagination.offset - 1) * pagination.limit
          },
          ...filters,
          dateBefore: moment(
            getModifiedEndDateMoment(filters.interval, filters.dateBefore)
          ).format(API_DATE_FORMAT)
        }
      })
      .then(response => {
        if (response.data) {
          const { data } = response

          setEventsState({
            eventsCount: data.count,
            events: data.results
          })
        } else {
          setEventsState({ eventsCount: 0, events: [] })
        }
        setLoading(false)
      })
      .catch(err => {
        console.log({ err })
        setEventsState({ eventsCount: 0, events: [] })
        setLoading(false)
        if (err == null || err.response == null) {
          actions.serverError()
        }
      })
  }, [actions, filters, pagination])

  useEffect(() => {
    fetchEvents()
    // if i had fetch events, the submit button is useless, since it updates everytime i change a filter
    // eslint-disable-next-line
  }, [triggerSet, size, pagination])

  const resetFilters = useCallback(async () => {
    await setFilters(() => defaultFilter)
    await setTriggerSet(() => true)
  }, [setFilters, setTriggerSet, defaultFilter])

  const handleWSMessages = useCallback(
    msg => {
      if (msg != null && msg.sender === 'occupancyhistory') {
        // if (msg.content != null && msg.content.id != null) {
        //   fetchEventAndUpdate(msg.content.id)
        // } else {
        fetchEvents()
        // }
      }
    },
    [fetchEvents]
  )

  useEffect(() => {
    if (wsStore.message != null) {
      handleWSMessages(wsStore.message)
    }
  }, [wsStore.message, handleWSMessages])

  const spotTypeOptions = useMemo(
    () => [
      {
        label: t('table.all_filter'),
        value: ''
      },
      ...AVAILABLE_SPOT_TYPES?.map(el => ({
        label: t(`table.${el}`),
        value: el
      }))
    ],
    [t]
  )

  const memoFilterMonitoringOptions = useMemo(
    () => [
      {
        label: t('table.all_filter'),
        value: ''
      },
      ...monitoringTypeOptions.map(el => ({
        ...el,
        label: t(`table.${el.label}`)
      }))
    ],
    [t]
  )

  const memoOnFilterChangeValue = useCallback(
    name => e => onFilterChange(name, e.target.value),
    [onFilterChange]
  )

  const handleReplaceForm = useCallback(
    form =>
      setFilters({
        ...filters,
        ...form
      }),
    [filters]
  )

  const handleResetForm = useCallback(() => setFilters(defaultFilter), [
    defaultFilter
  ])

  const filtersInputs = useMemo(() => {
    let tempFormat = 'YYYY'
    switch (filters.interval) {
      case 'year':
        tempFormat = 'YYYY'
        break
      case 'month':
        tempFormat = 'MM-YYYY'
        break
      case 'day':
        tempFormat = 'DD-MM-YYYY'
        break
      default:
        tempFormat = 'DD-MM-YYYY'
        break
    }

    return (
      <React.Fragment key='filters-inputs'>
        <StyledSelect
          value={filters.area__monitoringType}
          onChange={memoOnFilterChangeValue('area__monitoringType')}
          label={t('table.monitoring_type')}
          options={memoFilterMonitoringOptions}
          id='monitoring_type' // OBRIGATORIO POR CAUSA DA LABEL
        />
        <StyledSelect
          value={filters.spotType}
          onChange={memoOnFilterChangeValue('spotType')}
          label={t('table.spot_type')}
          options={spotTypeOptions}
          id='spot_type' // OBRIGATORIO POR CAUSA DA LABEL
        />
        <ToggleFilter
          text={
            defaultFilter.dateAfter === filters.dateAfter &&
            defaultFilter.dateBefore === filters.dateBefore &&
            defaultFilter.interval === filters.interval
              ? t(filters.interval)
              : `${moment(filters.dateAfter).format(tempFormat)} ${t(
                  'to'
                )} ${moment(filters.dateBefore).format(tempFormat)}`
          }
          /* eslint-disable  */
          active={
            !(
              defaultFilter.dateAfter === filters.dateAfter &&
              defaultFilter.dateBefore === filters.dateBefore &&
              defaultFilter.interval === filters.interval
            )
            /* eslint-enable */
          }
        >
          <IntervalPicker
            form={filters}
            intervalOptions={intervalOptions}
            changeForm={onFilterChange}
            replaceForm={handleReplaceForm}
            resetForm={handleResetForm}
            defaultForm={defaultFilter}
          />
        </ToggleFilter>
      </React.Fragment>
    )
  }, [
    filters,
    memoOnFilterChangeValue,
    spotTypeOptions,
    memoFilterMonitoringOptions,
    handleReplaceForm,
    handleResetForm,
    onFilterChange,
    defaultFilter,
    t
  ])

  const handleReset = useCallback(() => {
    resetFilters()
    setOpenFilters(false)
  }, [resetFilters])

  const handleApply = useCallback(() => {
    setTriggerSet(true)
    setOpenFilters(false)
  }, [])

  const filtersButtons = useMemo(
    () => (
      <React.Fragment key='filters-buttons'>
        <StyledButton onClick={handleReset}>{t('table.reset')}</StyledButton>
        <StyledButton type='primary' onClick={handleApply}>
          {t('table.apply')}
        </StyledButton>
      </React.Fragment>
    ),
    [t, handleApply, handleReset]
  )

  const memoizedLoadingTable = useMemo(
    () =>
      [...Array(6)].map((el, index) => (
        <StyledSkeleton
          key={`table-loading-row-${index}`}
          variant='rect'
          width='100%'
          height='2.5rem'
        />
      )),
    []
  )
  const onCloseFilters = useCallback(() => setOpenFilters(false), [])
  const onOpenFilters = useCallback(() => setOpenFilters(true), [])
  const handlePaginationChange = useCallback(
    (event, page) => setPagination({ ...pagination, offset: page }),
    [pagination, setPagination]
  )

  const memoizedColumns = useMemo(() => {
    return columns.map(el => ({ ...el, label: t(el.label) }))
  }, [t])

  const breadCrumbItems = useMemo(
    () => [
      {
        path: '/events/',
        label: 'sections.events'
      }
    ],
    []
  )

  return (
    <Wrapper ref={wrapperRef} id='events-wrapper'>
      <BreadCrumbRow>
        <StyledBreadCrumb items={breadCrumbItems} />
        {!responsiveHandlers.isDesktop && (
          <ButtonWrapper>
            <StyledButton
              tooltip={t('filters')}
              type='primary'
              onClick={onOpenFilters}
            >
              <Badge
                color='secondary'
                variant='dot'
                invisible={
                  JSON.stringify(filters) === JSON.stringify(defaultFilter)
                }
              >
                <FilterIcon height={24} width={24} fill='var(--white-color)' />
              </Badge>
            </StyledButton>
          </ButtonWrapper>
        )}
      </BreadCrumbRow>
      {responsiveHandlers.isDesktop && (
        <FiltersWrapper>
          <FiltersRow>{[filtersInputs, filtersButtons]}</FiltersRow>
        </FiltersWrapper>
      )}
      {!loading && (
        <>
          {eventsState.events != null && responsiveHandlers.isDesktop && (
            <StyledTable
              defaultOrder='desc'
              data={eventsState.events}
              columns={memoizedColumns}
            />
          )}
          {eventsState.events != null && !responsiveHandlers.isDesktop && (
            <StyledMobileTable
              data={eventsState.events}
              template={EventsTemplate}
              setInDetail={() => {}}
            />
          )}
          {eventsState.events != null && (
            <StyledPagination
              count={Math.ceil(eventsState.eventsCount / pagination.limit)}
              page={pagination.offset}
              onChange={handlePaginationChange}
            />
          )}
        </>
      )}
      {loading && <>{memoizedLoadingTable}</>}
      <FiltersModal
        open={openFilters}
        onClose={onCloseFilters}
        inputs={filtersInputs}
        buttons={filtersButtons}
        title={t('filters')}
      />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  ${({ theme }) => theme.smallDesktop`
    padding: 1.5rem 2rem;
  `}
`

const BreadCrumbRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  flex: 0 0 auto;

  ${({ theme }) => theme.smallDesktop`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 2.5rem;
  `}
`

const FiltersWrapper = styled.div`
  margin: 1.5rem 0 2rem;
`

// const FiltersTitle = styled.h3`
//   color: var(--breadcrumb-gray-color);
//   font-size: 1.125rem;
//   letter-spacing: 0;
//   line-height: 1em;
//   margin-bottom: 1rem;
// `

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 2.5rem;
  column-gap: 0.5rem;
  margin-right: auto;
  ${({ theme }) => theme.smallDesktop`
    grid-template-columns:  2.5rem;
    column-gap: 0.5rem;
    margin: 0;
  `}
`

const FiltersRow = styled.div`
  display: none;
  ${({ theme }) => theme.smallDesktop`
    grid-template-columns: repeat(5, minmax(10rem, 1fr));
    display: grid;
    column-gap: 1rem;
    max-height: 2.5rem;

  `}
`

import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import ReactMapboxGl, {
  ZoomControl,
  Layer,
  Feature,
  Popup,
  Marker,
  Cluster
} from 'react-mapbox-gl'
import mapboxgl from 'mapbox-gl'
import DrawControl from 'react-mapbox-gl-draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import moment from 'moment'
import isEqual from 'lodash.isequal'

import { Link } from 'react-router-dom'

import { withTranslation } from 'react-i18next'

import { MAP_BOX_KEY, MAPCENTER, MAPZOOM, DATETIME_FORMAT } from 'config'
import centroid from '@turf/centroid'

import GatewayGreenIcon from 'icons/GatewayGreenIcon'
import GatewayGrayIcon from 'icons/GatewayGrayIcon'
import GatewayRedIcon from 'icons/GatewayRedIcon'
import GatewayYellowIcon from 'icons/GatewayYellowIcon'

import RepeaterYellowIcon from 'icons/RepeaterYellowIcon'
import RepeaterGreenIcon from 'icons/RepeaterGreenIcon'
import RepeaterRedIcon from 'icons/RepeaterRedIcon'
import RepeaterGrayIcon from 'icons/RepeaterGrayIcon'

import CarSensorYellowIcon from 'icons/CarSensorYellowIcon'
import CarSensorGreenIcon from 'icons/CarSensorGreenIcon'
import CarSensorRedIcon from 'icons/CarSensorRedIcon'
import CarSensorGrayIcon from 'icons/CarSensorGrayIcon'

import GateSensorYellowIcon from 'icons/GateSensorYellowIcon'
import GateSensorGreenIcon from 'icons/GateSensorGreenIcon'
import GateSensorRedIcon from 'icons/GateSensorRedIcon'
import GateSensorGrayIcon from 'icons/GateSensorGrayIcon'

import PanelYellowIcon from 'icons/PanelYellowIcon'
import PanelGreenIcon from 'icons/PanelGreenIcon'
import PanelRedIcon from 'icons/PanelRedIcon'
import PanelGrayIcon from 'icons/PanelGrayIcon'

import ClusterIcon from 'icons/ClusterIcon'

// import SpotRedIcon from 'icons/SpotRedIcon'
// import SpotGreenIcon from 'icons/SpotGreenIcon'
// import SpotGrayIcon from 'icons/SpotGrayIcon'

import SpotInsideRedIcon from 'icons/SpotInsideRedIcon'
import SpotInsideGreenIcon from 'icons/SpotInsideGreenIcon'
import SpotInsideGrayIcon from 'icons/SpotInsideGrayIcon'
import SpotInsideYellowIcon from 'icons/SpotInsideYellowIcon'

import VideoSensorYellowIcon from 'icons/VideoSensorYellowIcon'
import VideoSensorGreenIcon from 'icons/VideoSensorGreenIcon'
import VideoSensorRedIcon from 'icons/VideoSensorRedIcon'
import VideoSensorGrayIcon from 'icons/VideoSensorGrayIcon'

// import SearchIcon from "icons/SearchIcon";

import StyledAccordion from 'components/StyledAccordion'
import StyledAutoComplete from 'components/StyledAutoComplete'
import StyledTable from 'components/StyledTable'
import StyledPagination from 'components/StyledPagination'

import {
  percentages,
  getColorsByOccupancy,
  getColorsByStatus,
  getBorderColorsByOccupancy,
  getPolygonCenter
} from './utils'
import { styles } from './drawStyles'
import StyledSelect from 'components/StyledSelect'

const Map = ReactMapboxGl({
  accessToken: MAP_BOX_KEY
})

class MapContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      center: MAPCENTER,
      zoom: MAPZOOM,
      legendOpen: false,
      dashboardOpen: true,
      bounds: new mapboxgl.LngLatBounds(MAPCENTER, MAPCENTER),
      boundsMapped: null,
      reduced: null,
      selected: null,
      popupOpen: false,
      drawReady: false,
      popupType: null,
      canFly: true
    }
    this.drawControl = React.createRef()
    this._mapRef = React.createRef()
  }

  onRefChange = ref => {
    this.drawControl = ref
    this.setState({ drawReady: true })
  }

  componentDidMount () {
    const reduced = this.mapThroughData()
    if (
      this.props.data != null &&
      this.props.data.length === 1 &&
      this.props.data[0] != null
    ) {
      const { geometry } = this.props.data[0]
      if (
        this.props.isDrawAvailable &&
        this.drawControl != null &&
        this.drawControl.draw != null
      ) {
        const { features } = this.drawControl.draw.getAll()
        if (features.length === 0 && (this.props.geometry != null) != null) {
          this.flyToCoordinates(this.props.geometry != null, this.props.data[0])
          this.drawControl.draw.add(this.props.geometry != null)
          this.setState({ reduced: null })
        }
      } else {
        this.flyToCoordinates(geometry, this.props.data[0])
        this.setState({ reduced })
      }
    } else {
      if (reduced != null) {
        const boundsMapped = this.mapBoundsToArray(reduced.bounds)
        this.setState({
          reduced,
          bounds: reduced.bounds,
          boundsMapped: boundsMapped,
          occupiedSpots: reduced.occupiedSpots,
          totalSpots: reduced.totalSpots
        })
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (
      (prevProps.data !== this.props.data ||
        (prevState.drawReady !== this.state.drawReady &&
          this.state.drawReady)) &&
      this.props.data != null &&
      this.props.type != null
    ) {
      const reduced = this.mapThroughData()

      if (this.props.data.length === 1 && this.props.data[0] != null) {
        const { geometry } = this.props.data[0]

        if (
          this.props.isDrawAvailable &&
          this.drawControl != null &&
          this.drawControl.draw != null
        ) {
          const { features } = this.drawControl.draw.getAll()
          if (features.length === 0 && this.props.geometry != null) {
            this.flyToCoordinates(this.props.geometry, this.props.data[0])
            this.drawControl.draw.add(this.props.geometry)
            this.setState({ reduced: reduced })
          }
        } else {
          this.flyToCoordinates(geometry, this.props.data[0])
          this.setState({
            reduced
          })
        }
      } else {
        if (
          reduced != null &&
          this.props.flyToLocation == null &&
          this.props.selected == null &&
          this.state.canFly
        ) {
          const boundsMapped = this.mapBoundsToArray(reduced.bounds)
          this.setState({
            reduced,
            bounds: reduced.bounds,
            boundsMapped:
              this.props.inDetail == null
                ? boundsMapped
                : this.state.boundsMapped,
            occupiedSpots: reduced.occupiedSpots,
            totalSpots: reduced.totalSpots,
            canFly: false
          })
        }
      }
    }

    if (
      !isEqual(prevProps.flyToLocation, this.props.flyToLocation) &&
      this.props.flyToLocation != null &&
      this.props.enableSearch
    ) {
      this.setState({
        boundsMapped: this.props.flyToLocation,
        selected: null,
        popupOpen: false,
        canFly: false
      })
    }

    if (
      prevProps.inDetail !== this.props.inDetail ||
      this.props.data?.length !== prevProps.data?.length ||
      !isEqual(this.props.filters, prevProps.filters)
    ) {
      this.setState({
        canFly: true
      })
    }
  }

  flyToCoordinates = (geometry, el, openPopup) => {
    if (this.props.type === 'areas') {
      if (geometry != null) {
        const { coordinates, type } = geometry
        if (type === 'Point' && coordinates != null) {
          this.setState({
            boundsMapped: [coordinates, coordinates],
            selected: el,
            legendOpen: true,
            popupOpen: openPopup
          })
        } else if (
          type === 'Polygon' &&
          coordinates != null &&
          coordinates[0] != null
        ) {
          const newBounds = this.coordinatesToBounds(coordinates[0])
          const newBoundsMapped = this.mapBoundsToArray(newBounds)
          this.setState({
            boundsMapped: newBoundsMapped,
            selected: el,
            legendOpen: true,
            popupOpen: openPopup,
            popupType: null
          })
        }
      }
    } else {
      if (geometry != null) {
        const { coordinates, type } = geometry
        if (type === 'Point' && coordinates != null) {
          this.setState({
            boundsMapped: [coordinates, coordinates],
            selected: el,
            popupOpen: openPopup,
            popupType: null
          })
        }
      }
    }
  }

  coordinatesToBounds = array => {
    if (array != null && array[0] != null) {
      const start = new mapboxgl.LngLatBounds(array[0], array[0])
      return array.reduce((acum, bounds) => acum.extend(bounds), start)
    }
    return null
  }

  mapBoundsToArray = boundsObject => {
    if (boundsObject != null) {
      return [
        [boundsObject._sw.lng, boundsObject._sw.lat],
        [boundsObject._ne.lng, boundsObject._ne.lat]
      ]
    }
  }

  generatePopup = type => {
    const { t } = this.props
    const { selected } = this.state
    if (selected != null) {
      const { bg, color } = getColorsByStatus(selected, type)
      return (
        <PopupWrapper>
          <PopupTitleWrapper backgroundColor={bg} color={color}>
            <PopupTitle>{t(`assets.popup_title_${type}`)}</PopupTitle>
            {type === 'assets' && selected.deviceType != null && (
              <StyledLink
                to={`/${type}/${selected.deviceType}/${selected.id}/`}
              >
                <PopupSubTitle>{selected.uid}</PopupSubTitle>
              </StyledLink>
            )}

            {(type === 'spots' || type === 'areas') && (
              <StyledLink to={`/${type}/${selected.id}/`}>
                <PopupSubTitle>{selected.uid}</PopupSubTitle>
              </StyledLink>
            )}
          </PopupTitleWrapper>
          <PopupBodyWrapper color={bg}>
            <PopupBodyLine>
              {type === 'assets' && selected.deviceType != null && (
                <>
                  <PopupBodyValue>
                    {t(`table.${selected.deviceType}`)}
                  </PopupBodyValue>
                  <PopupBodyLabel>{t('map.type')}</PopupBodyLabel>
                </>
              )}
              {type === 'spots' && selected.spotType != null && (
                <>
                  <PopupBodyValue>
                    {t(`table.${selected.spotType}`)}
                  </PopupBodyValue>
                  <PopupBodyLabel>{t('map.type')}</PopupBodyLabel>
                </>
              )}
              {type === 'areas' && selected.monitoringType != null && (
                <>
                  <PopupBodyValue>
                    {t(`table.${selected.monitoringType}`)}
                  </PopupBodyValue>
                  <PopupBodyLabel>{t('table.monitoring_type')}</PopupBodyLabel>
                </>
              )}
            </PopupBodyLine>

            {selected.area != null && selected.area.name != null && (
              <PopupBodyLine>
                <PopupBodyValue>{selected.area.name}</PopupBodyValue>
                <PopupBodyLabel>{t('map.area')}</PopupBodyLabel>
              </PopupBodyLine>
            )}

            <PopupBodyLine>
              {type === 'assets' && (
                <PopupBodyValue>
                  {selected.lastSeen != null
                    ? moment(selected.lastSeen).format(DATETIME_FORMAT)
                    : '-'}
                </PopupBodyValue>
              )}
              {type === 'spots' && (
                <PopupBodyValue>
                  {selected.lastCommunication != null
                    ? moment(selected.lastCommunication).format(DATETIME_FORMAT)
                    : '-'}
                </PopupBodyValue>
              )}
              {type !== 'areas' && (
                <PopupBodyLabel>{t('map.last_communication')}</PopupBodyLabel>
              )}
            </PopupBodyLine>
          </PopupBodyWrapper>
        </PopupWrapper>
      )
    }
    return null
  }

  generateLegends = () => {
    const { type, data, columns, t, hideLegends, hideListLegend, alarms, alarmsCount, pagination, changePagination, alarmsColumns, timestamp, timestampOptions, timestampChange } = this.props
    const { reduced } = this.state

    const sortedColumns = typeof columns === 'function'
      ? columns(this.flyToCoordinates).map(el => ({ ...el, label: t(el.label) }))
      : columns
    const changeTimestamp = e => timestampChange(e.target.value)

    const mapLegends = []
    if (type === 'areas' && data != null && reduced != null && !hideLegends) {
      const { legendOpen, dashboardOpen } = this.state
      mapLegends.push(
        <React.Fragment key='type-areas'>
          <InfoWrapper key='info-legend'>
            <InfoTitle>{t('table.occupation_all')}:</InfoTitle>
            {percentages.map((el, index) => (
              <InfoLine key={`info-line-${index}`}>
                <ColorSquare bg={el.color} borderColor={el.borderColor} />
                <InfoText>{t(`table.${el.text}`)}</InfoText>
              </InfoLine>
            ))}
          </InfoWrapper>
        </React.Fragment>
      )

      if (!hideListLegend) {
        const sortedAlarmsColumns = alarmsColumns.map(el => ({ ...el, label: t(el.label) }))
        const sortedTimestampOptions = timestampOptions.map(el => ({ ...el, label: t(`map.${el.label}`) }))
        mapLegends.push(
          <AreasAlarmsWrapper key='areas-dashboard-legend'>
            {alarms && (
              <DashboardWrapper key='info-dashboard'>
                <StyledAccordion
                  expanded={dashboardOpen}
                  onChange={(event, isExpanded) =>
                    this.setState({ dashboardOpen: isExpanded })}
                  summary={
                    <AreaLegendTitle>
                      <AreaLegendTitleText>
                        {!dashboardOpen ? t('map.show_alarms') : t('map.hide_alarms')}
                      </AreaLegendTitleText>
                      {!dashboardOpen && (
                        <Badge>{alarms.length}</Badge>
                      )}
                    </AreaLegendTitle>
                  }
                  details={
                    <DashboardBodyWrapper>
                      <DashboardFilterLine
                        onClick={() => {
                          if (data != null && data.length === 1) {
                            const { geometry } = this.props.data[0]
                            this.flyToCoordinates(geometry, this.props.data[0])
                          } else {
                            this.setState({
                              selected: null,
                              boundsMapped: this.mapBoundsToArray(reduced.bounds)
                            })
                          }
                        }}
                      >
                        <TotalAreasText>{t('map.alarms_interval')}</TotalAreasText>
                        <StyledSelect
                          value={timestamp}
                          onChange={changeTimestamp}
                          options={sortedTimestampOptions}
                        />
                      </DashboardFilterLine>
                      <StyledTable data={alarms} columns={sortedAlarmsColumns} />
                      {alarms != null && (
                        <StyledPagination
                          count={Math.ceil(alarmsCount / pagination.limit)}
                          page={pagination.page}
                          onChange={changePagination}
                        />
                      )}
                    </DashboardBodyWrapper>
                  }
                />
              </DashboardWrapper>
            )}
            <AreasLegendWrapper key='areas-legend'>
              <StyledAccordion
                expanded={legendOpen}
                onChange={(event, isExpanded) =>
                  this.setState({ legendOpen: isExpanded })}
                summary={
                  <AreaLegendTitle>
                    <AreaLegendTitleText>
                      {!legendOpen ? t('map.show_areas') : t('map.hide_areas')}
                    </AreaLegendTitleText>
                    {!legendOpen && (
                      <Badge>{`${reduced.occupiedSpots}/${reduced.totalSpots}`}</Badge>
                    )}
                  </AreaLegendTitle>
                }
                details={
                  <AreasBodyWrapper>
                    <TotalAreasLine
                      onClick={() => {
                        if (data != null && data.length === 1) {
                          const { geometry } = this.props.data[0]
                          this.flyToCoordinates(geometry, this.props.data[0])
                        } else {
                          this.setState({
                            selected: null,
                            boundsMapped: this.mapBoundsToArray(reduced.bounds)
                          })
                        }
                      }}
                    >
                      <TotalAreasText>{t('map.all_areas')}</TotalAreasText>
                      <Badge type='secondary'>
                        {`${reduced.occupiedSpots}/${reduced.totalSpots}`}
                      </Badge>
                    </TotalAreasLine>
                    <StyledTable data={data} columns={sortedColumns} />
                  </AreasBodyWrapper>
                }
              />
            </AreasLegendWrapper>
          </AreasAlarmsWrapper>
        )
      }
    }
    return mapLegends
  }

  clusterMarker = (coordinates, type, count) => {
    return (
      <Marker
        coordinates={coordinates}
        style={{
          cursor: 'pointer',
          zIndex: 1000
        }}
        anchor='center'
        key={`key${coordinates.join(',')}`}
        tabIndex={1}
      >
        <ClusterIcon
          height={40}
          width={40}
          style={{ zIndex: 2 }}
          text={count}
        />
      </Marker>
    )
  }

  mapThroughData = () => {
    const { data, type } = this.props
    let startingBounds = new mapboxgl.LngLatBounds(MAPCENTER, MAPCENTER)
    if (data != null && type != null) {
      if (type === 'areas') {
        return data.reduce(
          (acum, current, index) => {
            if (current != null && current.geometry != null) {
              if (
                current.geometry.type === 'Polygon' &&
                current.geometry.coordinates != null &&
                current.geometry.coordinates[0] != null
              ) {
                acum.polygons.push(current)
                current.geometry.coordinates[0].forEach((coords, polyIndex) => {
                  if (index === 0 && polyIndex === 0) {
                    acum.bounds = new mapboxgl.LngLatBounds(coords, coords)
                  }
                  acum.bounds.extend(coords)
                })
              } else if (current.geometry.type === 'Point') {
                acum.points.push(current)
                if (index === 0) {
                  acum.bounds = new mapboxgl.LngLatBounds(
                    current.geometry.coordinates,
                    current.geometry.coordinates
                  )
                }
                acum.bounds.extend(current.geometry.coordinates)
              }
              if (current.capacity != null && !isNaN(current.capacity)) {
                acum.totalSpots += current.capacity
              }
              if (
                current.occupation &&
                !isNaN(+current.occupation.all.split('/')[0])
              ) {
                acum.occupiedSpots += +current.occupation.all.split('/')[0]
              }
            }
            return acum
          },
          {
            polygons: [],
            points: [],
            lines: [],
            bounds: new mapboxgl.LngLatBounds(MAPCENTER, MAPCENTER),
            totalSpots: 0,
            occupiedSpots: 0
          }
        )
      } else {
        if (
          data != null &&
          data[0] != null &&
          data[0].geometry != null &&
          data[0].geometry.coordinates != null
        ) {
          startingBounds = new mapboxgl.LngLatBounds(
            data[0].geometry.coordinates,
            data[0].geometry.coordinates
          )
        }
        return data.reduce(
          (acum, current, index) => {
            if (current != null && current.geometry != null) {
              if (current.geometry.type === 'Point') {
                acum.points.push(current)
                acum.bounds.extend(current.geometry.coordinates)
              }
            }
            return acum
          },
          {
            points: [],
            bounds: startingBounds
          }
        )
      }
    }
  }

  showSpots = spotsArray => {
    const { showSpots } = this.props
    if (showSpots && spotsArray != null) {
      return spotsArray.map(
        (spot, index) =>
          spot.geometry != null && (
            <Marker
              key={`area-spot-${index}`}
              coordinates={spot.geometry.coordinates}
              onClick={() =>
                this.setState({
                  popupOpen: true,
                  selected: spot,
                  popupType: 'spots'
                })}
              anchor='center'
              style={{
                cursor: 'pointer'
              }}
            >
              <>
                {spot.occupied != null && spot.occupied === true && (
                  <SpotInsideRedIcon height={20} width={20} />
                )}
                {spot.occupied != null && spot.occupied === false && (
                  <SpotInsideGreenIcon height={20} width={20} />
                )}
                {spot.occupied == null && (
                  <SpotInsideYellowIcon height={20} width={20} />
                )}
              </>
            </Marker>
          )
      )
    }
  }

  showSensors = sensorsArray => {
    /*eslint-disable */

    const { showSensors } = this.props
    if (showSensors && sensorsArray != null) {
      return sensorsArray.map(
        (sensor, index) =>
          sensor.geometry != null && (
            <Marker
              key={`area-sensor-${index}`}
              coordinates={sensor.geometry.coordinates}
              onClick={() =>
                this.setState({
                  popupOpen: true,
                  selected: sensor,
                  popupType: 'assets',
                })
              }
              anchor="center"
              style={{
                cursor: 'pointer',
              }}
            >
              {sensor.status === 'ok' && (
                <SpotInsideGreenIcon height={20} width={20} />
              )}
              {sensor.status === 'not_ok' && (
                <SpotInsideRedIcon height={20} width={20} />
              )}
              {sensor.status === 'unknown' && (
                <SpotInsideYellowIcon height={20} width={20} />
              )}
              {sensor.status == null && (
                <SpotInsideGrayIcon height={20} width={20} />
              )}
            </Marker>
          )
      )
    }
  }

  generateLayers = () => {
    const { type, data, isDrawAvailable, inDetail } = this.props
    const { reduced, currentZoom } = this.state
    const mapLayers = []
    const assetHeight = inDetail ? 48 : 24 + ((3 / 4) * currentZoom ?? 0)
    const assetWidth = inDetail ? 48 : 24 + ((3 / 4) * currentZoom ?? 0)
    if (!isDrawAvailable) {
      if (type === 'areas' && data != null && reduced != null) {
        const features = [
          reduced.polygons.map((el, index) => {
            return (
              <React.Fragment key={`polygon-areas-${index}`}>
                <Layer
                  type="fill"
                  paint={{
                    'fill-color': getColorsByOccupancy(el.verboseOccupation),
                    'fill-outline-color': getBorderColorsByOccupancy(
                      el.verboseOccupation
                    ),
                    'fill-opacity': 0.25,
                  }}
                  key={`polygon-${index}`}
                  onMouseEnter={e =>
                    (this._mapRef.getCanvas().style.cursor = 'pointer')
                  }
                  onMouseLeave={e =>
                    (this._mapRef.getCanvas().style.cursor = '')
                  }
                >
                  <Feature
                    onClick={e => {
                      e.originalEvent.preventDefault()
                      this.flyToCoordinates(el.geometry, el, true)
                    }}
                    coordinates={el.geometry.coordinates}
                    style={{ cursor: 'pointer' }}
                  />
                </Layer>
                <Layer
                  type="line"
                  paint={{
                    'line-color': getBorderColorsByOccupancy(
                      el.verboseOccupation
                    ),
                    'line-width': (1 / 8) * currentZoom,
                  }}
                  lineLayout={{
                    'line-cap': 'round',
                    'line-join': 'round',
                  }}
                  key={`polygon-line-${index}`}
                >
                  <Feature
                    key={`polygon-line-${index}`}
                    coordinates={el.geometry.coordinates[0]}
                    onClick={e => {
                      e.originalEvent.preventDefault()
                      this.flyToCoordinates(el.geometry, el, true)
                    }}
                  />
                </Layer>
                <Layer
                  type="circle"
                  key={`polygon-circle-${index}`}
                  paint={{
                    'circle-color': 'rgb(0, 105, 130)',
                    'circle-radius': (1 / 1.1) * (currentZoom ?? 16),
                  }}
                >
                  <Feature
                    key={`polygon-circle-${index}`}
                    coordinates={getPolygonCenter(el.geometry.coordinates[0])}
                    onClick={e => {
                      e.originalEvent.preventDefault()
                      this.flyToCoordinates(el.geometry, el, true)
                    }}
                  />
                </Layer>
                <Layer
                  type="symbol"
                  layout={{
                    'text-field': el.name,
                    'text-size': (1 / 1.1) * (currentZoom ?? 16),
                  }}
                  paint={{
                    'text-color': '#ffffff'
                  }}
                  key={`polygon-name-${index}`}
                >
                  <Feature
                    key={`polygon-name-${index}`}
                    coordinates={getPolygonCenter(el.geometry.coordinates[0])}
                    onClick={e => {
                      e.originalEvent.preventDefault()
                      this.flyToCoordinates(el.geometry, el, true)
                    }}
                  />
                </Layer>
                <>{this.showSpots(el.spots)}</>
                <>{this.showSensors(el.sensors)}</>
              </React.Fragment>
            )
          }),
        ]

        const points = [
          reduced.points.map((el, index) => (
            <Layer
              type="circle"
              key={`points-layer-${index}`}
              paint={{
                'circle-color': getColorsByOccupancy(el.verboseOccupation),
                'circle-opacity': 0.25,
                'circle-radius': (1 / 3) * currentZoom,
                'circle-stroke-color': getBorderColorsByOccupancy(
                  el.verboseOccupation
                ),
                'circle-stroke-opacity': 1,
                'circle-stroke-width': (1 / 5) * currentZoom,
              }}
              onMouseEnter={e =>
                (this._mapRef.getCanvas().style.cursor = 'pointer')
              }
              onMouseLeave={e => (this._mapRef.getCanvas().style.cursor = '')}
            >
              <Feature
                coordinates={el.geometry.coordinates}
                onClick={() => this.flyToCoordinates(el.geometry, el)}
                style={{ cursor: 'pointer' }}
              />
            </Layer>
          )),
        ]
        mapLayers.push(...[...features, ...points])
      } else if (type === 'assets' && data != null && reduced != null) {
        /*eslint-disable */
        const cluster = (
          <Cluster
            ClusterMarkerFactory={(coordinates, pointCount, getLeaves) =>
              this.clusterMarker(coordinates, type, pointCount)
            }
            zoomOnClick={true}
            key={`${type}-cluster`}
            radius={40}
            tabIndex={2}
          >
            {reduced?.points?.map((el, index) => {
              const isPanelMonitoringEquipment = el.deviceType === 'panel_monitoring_equipment';
              return (
                <Marker
                  key={el.uid}
                  coordinates={el.geometry.coordinates}
                  onClick={() => this.flyToCoordinates(el.geometry, el, true)}
                  anchor="bottom"
                  style={{
                    cursor: 'pointer',
                    zIndex: 10 + index,
                  }}
                  tabIndex={10}
                >
                  {el.deviceType === 'sensor' &&
                    el.status === 'ok' &&
                    el.monitoringType === 'individual_sensor' && (
                      <CarSensorGreenIcon
                        height={assetHeight}
                        width={assetWidth}
                      />
                    )}
                  {el.deviceType === 'sensor' &&
                    el.status === 'not_ok' &&
                    el.monitoringType === 'individual_sensor' && (
                      <CarSensorRedIcon
                        height={assetHeight}
                        width={assetWidth}
                      />
                    )}
                  {el.deviceType === 'sensor' &&
                    el.status === 'unknown' &&
                    el.monitoringType === 'individual_sensor' && (
                      <CarSensorYellowIcon
                        height={assetHeight}
                        width={assetWidth}
                      />
                    )}
                  {el.deviceType === 'sensor' &&
                    el.status == null &&
                    el.monitoringType === 'individual_sensor' && (
                      <CarSensorGrayIcon
                        height={assetHeight}
                        width={assetWidth}
                      />
                    )}
                  {el.deviceType === 'sensor' &&
                    el.status === 'ok' &&
                    el.monitoringType === 'counting_sensor' && (
                      <GateSensorGreenIcon
                        height={assetHeight}
                        width={assetWidth}
                      />
                    )}
                  {el.deviceType === 'sensor' &&
                    el.status === 'not_ok' &&
                    el.monitoringType === 'counting_sensor' && (
                      <GateSensorRedIcon
                        height={assetHeight}
                        width={assetWidth}
                      />
                    )}
                  {el.deviceType === 'sensor' &&
                    el.status === 'unknown' &&
                    el.monitoringType === 'counting_sensor' && (
                      <GateSensorYellowIcon
                        height={assetHeight}
                        width={assetWidth}
                      />
                    )}
                  {el.deviceType === 'sensor' &&
                    el.status == null &&
                    el.monitoringType === 'counting_sensor' && (
                      <GateSensorGrayIcon
                        height={assetHeight}
                        width={assetWidth}
                      />
                    )}
                  {el.deviceType === 'sensor' &&
                    el.status === 'ok' &&
                    el.monitoringType === 'video_feed' && (
                      <VideoSensorGreenIcon
                        height={assetHeight}
                        width={assetWidth}
                      />
                    )}
                  {el.deviceType === 'sensor' &&
                    el.status === 'not_ok' &&
                    el.monitoringType === 'video_feed' && (
                      <VideoSensorRedIcon
                        height={assetHeight}
                        width={assetWidth}
                      />
                    )}
                  {el.deviceType === 'sensor' &&
                    el.status === 'unknown' &&
                    el.monitoringType === 'video_feed' && (
                      <VideoSensorYellowIcon
                        height={assetHeight}
                        width={assetWidth}
                      />
                    )}
                  {el.deviceType === 'sensor' &&
                    el.status == null &&
                    el.monitoringType === 'video_feed' && (
                      <VideoSensorGrayIcon
                        height={assetHeight}
                        width={assetWidth}
                      />
                    )}

                  {el.deviceType === 'gateway' && el.status === 'ok' && (
                    <GatewayGreenIcon height={assetHeight} width={assetWidth} />
                  )}
                  {el.deviceType === 'gateway' && el.status === 'not_ok' && (
                    <GatewayRedIcon height={assetHeight} width={assetWidth} />
                  )}
                  {el.deviceType === 'gateway' && el.status === 'unknown' && (
                    <GatewayYellowIcon
                      height={assetHeight}
                      width={assetWidth}
                    />
                  )}
                  {el.deviceType === 'gateway' && el.status == null && (
                    <GatewayGrayIcon height={assetHeight} width={assetWidth} />
                  )}

                  {isPanelMonitoringEquipment && el.status === 'ok' && (
                    <PanelGreenIcon height={assetHeight} width={assetWidth} />
                  )}
                  {isPanelMonitoringEquipment && el.status === 'not_ok' && (
                    <PanelRedIcon height={assetHeight} width={assetWidth} />
                  )}
                  {isPanelMonitoringEquipment && el.status === 'unknown' && (
                    <PanelYellowIcon height={assetHeight} width={assetWidth} />
                  )}
                  {isPanelMonitoringEquipment && el.status == null && (
                    <PanelGrayIcon height={assetHeight} width={assetWidth} />
                  )}

                  {(el.deviceType === 'repeater' ||
                    el.deviceType === 'signal_repeater') &&
                    el.status === 'ok' && (
                      <RepeaterGreenIcon
                        height={assetHeight}
                        width={assetWidth}
                      />
                    )}
                  {(el.deviceType === 'repeater' ||
                    el.deviceType === 'signal_repeater') &&
                    el.status === 'not_ok' && (
                      <RepeaterRedIcon
                        height={assetHeight}
                        width={assetWidth}
                      />
                    )}
                  {(el.deviceType === 'repeater' ||
                    el.deviceType === 'signal_repeater') &&
                    el.status === 'unknown' && (
                      <RepeaterYellowIcon
                        height={assetHeight}
                        width={assetWidth}
                      />
                    )}
                  {(el.deviceType === 'repeater' ||
                    el.deviceType === 'signal_repeater') &&
                    el.status == null && (
                      <RepeaterGrayIcon
                        height={assetHeight}
                        width={assetWidth}
                      />
                    )}

                  {el.deviceType === 'panel' && el.status === 'ok' && (
                    <PanelGreenIcon height={assetHeight} width={assetWidth} />
                  )}
                  {el.deviceType === 'panel' && el.status === 'not_ok' && (
                    <PanelRedIcon height={assetHeight} width={assetWidth} />
                  )}
                  {el.deviceType === 'panel' && (el.status === 'unknown' || el.status == null) && (
                    <PanelYellowIcon height={assetHeight} width={assetWidth} />
                  )}
                  {/* {el.deviceType === 'panel' && el.status == null && (
                    <PanelGrayIcon height={assetHeight} width={assetWidth} />
                  )} */}
                </Marker>
              )
            })}
          </Cluster>
        )

        const otherMarkes = reduced?.points?.length === 1 && (
          <>
            <>{this.showSpots(reduced?.points?.[0]?.spot)}</>
            <>{this.showSensors(reduced?.points?.[0]?.sensors)}</>
          </>
        )
        mapLayers.push(...[cluster])
      } else if (type === 'spots' && data != null && reduced != null) {
        const cluster = (
          <Cluster
            ClusterMarkerFactory={(coordinates, pointCount, getLeaves) =>
              this.clusterMarker(coordinates, 'type-spots', getLeaves()?.length)
            }
            zoomOnClick={true}
            key={`type-spots-cluster`}
            radius={40}
            tabIndex={2}
          >
            {reduced.points.map((el, index) => (
              <Marker
                key={el.uid}
                coordinates={el.geometry.coordinates}
                onClick={() => this.flyToCoordinates(el.geometry, el, true)}
                anchor="center"
                style={{
                  cursor: 'pointer',
                }}
              >
                <>
                  {el.occupied != null && el.occupied === true && (
                    <SpotInsideRedIcon
                      height={assetHeight}
                      width={assetWidth}
                    />
                  )}
                  {el.occupied != null && el.occupied === false && (
                    <SpotInsideGreenIcon
                      height={assetHeight}
                      width={assetWidth}
                    />
                  )}
                  {el.occupied == null && (
                    <SpotInsideYellowIcon
                      height={assetHeight}
                      width={assetWidth}
                    />
                  )}
                </>
              </Marker>
            ))}
          </Cluster>
        )

        mapLayers.push(...[cluster])
      }
    }
    return mapLayers
  }

  handleDrawCreate = ({ features }) => {
    const { setGeometry } = this.props
    if (features != null && features[0] != null) {
      const { features: featuresDrawn } = this.drawControl.draw.getAll()
      if (featuresDrawn.length > 1) {
        this.drawControl.draw.delete(
          featuresDrawn.filter(el => el.id !== features[0].id).map(el => el.id)
        )
      }

      setGeometry(features[0].geometry)
    }
  }

  handleDrawUpdate = ({ features }) => {
    const { setGeometry } = this.props
    if (features != null && features[0] != null) {
      setGeometry(features[0].geometry)
    }
  }

  handleDelete = ({ features }) => {
    const { setGeometry } = this.props
    setGeometry(null)
  }

  handleMapClick = (e, event) => {
    if (event.originalEvent.defaultPrevented) {
      return
    }
    const { popupOpen } = this.state

    if (popupOpen) {
      this.setState({ popupOpen: false, popupType: null })
    }
  }

  getControls = () => {
    const { type } = this.props

    if (type === 'areas') {
      return {
        point: false,
        line_string: false,
        polygon: true,
        trash: true,
        combine_features: false,
        uncombine_features: false,
      }
    } else {
      return {
        point: true,
        line_string: false,
        polygon: false,
        trash: true,
        combine_features: false,
        uncombine_features: false,
      }
    }
  }

  getPopupPosition = () => {
    const { type, inDetail } = this.props
    const { popupType } = this.state
    if (inDetail) {
      return (popupType ?? type) === 'spots' ? [30, -8] : [35, -30]
    }

    return (popupType ?? type) === 'spots' ? [20, -5] : [20, -24]
  }

  render() {
    const {
      center,
      zoom,
      boundsMapped,
      popupOpen,
      selected,
      popupType,
    } = this.state
    const {
      isDrawAvailable,
      type,
      enableSearch,
      hidePopups,
      data,
      flyToLocation,
    } = this.props
    const controls = isDrawAvailable && this.getControls()

    return (
      <Wrapper>
        {this.generateLegends()}
        <Map
          // eslint-disable-next-line
          style="mapbox://styles/mapbox/streets-v9"
          containerStyle={{
            height: '100%',
            width: '100%',
          }}
          center={center}
          zoom={zoom}
          fitBounds={
            (data != null && Array.isArray(data) && data.length > 0) ||
            flyToLocation
              ? boundsMapped
              : null
          }
          fitBoundsOptions={{ padding: 50 }}
          onClick={this.handleMapClick}
          onStyleLoad={map => {
            this._mapRef = map
          }}
          onZoom={map => {
            this.setState({ currentZoom: map.getZoom() })
          }}
          renderChildrenInPortal={true}
        >
          <ZoomControl position="bottom-left" />

          {isDrawAvailable && controls && (
            <DrawControl
              ref={this.onRefChange}
              position="top-left"
              displayControlsDefault={false}
              onDrawCreate={this.handleDrawCreate}
              onDrawUpdate={this.handleDrawUpdate}
              onDrawDelete={this.handleDelete}
              controls={controls}
              styles={styles}
            />
          )}

          {this.generateLayers()}
          {!hidePopups &&
            popupOpen &&
            selected != null &&
            type !== 'areas' &&
            popupType !== 'areas' && (
              /*eslint-disable */
              <Popup
                key="marker-popup"
                coordinates={selected?.geometry?.coordinates}
                anchor="left"
                style={{ zIndex: 1000 }}
                offset={this.getPopupPosition()}
              >
                {this.generatePopup(popupType ?? type)}
              </Popup>
              /* eslint-enable */
          )}
          {!hidePopups &&
            popupOpen &&
            selected != null &&
            (type === 'areas' || popupType === 'areas') && (
          /*eslint-disable */
              <Popup
                key="marker-popup"
                coordinates={
                  centroid(selected?.geometry)?.geometry?.coordinates
                }
                anchor="left"
                style={{ zIndex: 1000 }}
                offset={[15, -20]}
              >
                {this.generatePopup(popupType ?? type)}
              </Popup>
              /* eslint-enable */
          )}

          {enableSearch && this.props?.search != null && (
          /*eslint-disable */

            <SearchWrapper>
              <StyledAutoComplete
                value={this.props?.search?.value}
                onChange={this.props?.search?.onChange}
                inputValue={this.props?.search?.inputValue}
                onInputChange={this.props?.search?.onInputChange}
                options={this.props?.search?.options}
                label={this.props?.search?.label}
                getOptionLabel={this.props?.search?.getOptionLabel}
                name="search"
                variant="standard"
                id="search"
                mapOptions
              />
            </SearchWrapper>
            /* eslint-enable */
          )}
        </Map>
      </Wrapper>
    )
  }
}

export default withTranslation()(MapContainer)

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  &:focus {
    outline: none;
  }

  & .mapboxgl-ctrl-top-left .mapboxgl-ctrl {
    margin: 0.5rem 0 0 0.5rem;
  }
  & .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
    margin: 0 0 0.5rem 3.5rem;
  }

  #zoomIn,
  #zoomOut,
  .mapboxgl-ctrl-group {
    border-radius: 0 !important;
    box-shadow: none !important;
  }
  #zoomIn,
  #zoomOut {
    background-color: white !important;

    &:hover {
      background-color: rgba(249, 249, 249) !important;
    }
  }
  .mapboxgl-map > div {
    box-shadow: none !important;
  }
`

const AreasBodyWrapper = styled.div`
  width: 100%;

  & .MuiTable-root {
    background: var(--white-color);
  }
`

const AreasAlarmsWrapper = styled.div`
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  display: flex;
  width: 100%;
  max-width: calc(100vw - 8rem);
  justify-content: space-between;
  margin: auto;

  @media (max-width: 1219px) {
    max-width: calc(100vw - 3rem);
  }

  @media (max-width: 899px) {
    flex-direction: column;
  }
`

const TotalAreasLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;

  cursor: pointer;
`

const TotalAreasText = styled.div`
  font-weight: 700;
`

const InfoWrapper = styled.div`
  position: absolute;
  z-index: 100;
  bottom: 1.5rem;
  right: 1.5rem;
  padding: 1rem;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
`

const InfoLine = styled.div`
  display: flex;

  & + & {
    margin-top: 0.5rem;
  }
`

const ColorSquare = styled.div`
  height: 1rem;
  width: 1rem;
  background-color: ${({ bg }) => bg || 'var(--primary-color)'};
  border-style: solid;
  border-width: 0.125rem;
  border-radius: 0.25rem;
  border-color: ${({ borderColor }) =>
    borderColor || 'var(--default-blue-color)'};
`

const InfoTitle = styled.h5`
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`

const InfoText = styled.p`
  margin-left: 0.5rem;
  font-size: 0.875rem;
  line-height: 1em;
  font-weight: 400;
`

const AreasLegendWrapper = styled.div`
  z-index: 100;

  & .MuiTableSortLabel-root.MuiTableSortLabel-active {
    &.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
      color: #fff;
    }
  }
`

const AreaLegendTitle = styled.div`
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;

  min-width: 15rem;
`

const AreaLegendTitleText = styled.p`
  color: var(--white-color);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.5rem;
`

const Badge = styled.div`
  --bg-color: var(--white-color);
  --color: var(--primary-color);

  padding: 0.25rem 0.5rem;
  background-color: var(--bg-color);
  border-radius: 1rem;
  display: flex;
  color: var(--color);
  font-weight: 700;
  font-size: 0.75rem;

  ${AreaLegendTitleText} + & {
    margin-left: 0.5rem;
  }
  ${TotalAreasText} + & {
    margin-left: 0.5rem;
  }

  ${props =>
    props.type === 'secondary' &&
    css`
      --bg-color: var(--sonic-silver-color);
      --color: var(--white-color);
    `}
`

const DashboardWrapper = styled.div`
  z-index: 99;
  background-color: var(--white-color);
`

const DashboardBodyWrapper = styled.div`
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - (60px + 9rem));

  @media (max-width: 900px) {
    max-height: calc(50vh - 2rem);
  }

  & .MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: var(--white-color);

    &.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
      color: #fff;
    }
  }
`

const DashboardFilterLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  gap: 10px;
`

const PopupWrapper = styled.div`
  position: wrapper;
  background-color: var(--white-color);
  min-width: 13.125rem;
  overflow: hidden;
`

const PopupBodyLabel = styled.p`
  text-transform: uppercase;
  color: inherit;
  font-size: 0.625rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.4em;
`

const PopupBodyWrapper = styled.div`
  padding: 0.625rem 1.25rem 1.25rem;

  & ${PopupBodyLabel} {
    color: var(--success-color);
    color: ${({ color }) => color};
  }
`

const PopupTitleWrapper = styled.div`
  padding: 0.5rem 1.25rem 0.75rem;
  background-color: var(--success-color);
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: var(--white-color);
  color: ${({ color }) => color};

  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.4285714286em;
  display: flex;
  flex-direction: column;
`
const PopupTitle = styled.p`
  text-transform: uppercase;
`
const PopupSubTitle = styled.p`
  margin-top: 0.25rem;
`
const PopupBodyValue = styled.div`
  color: var(--breadcrumb-gray-color);
  font-size: 1.125rem;
  letter-spacing: 0;
  line-height: 1.4444444444em;
  text-transform: uppercase;
`
const PopupBodyLine = styled.div`
  & + & {
    margin-top: 0.5rem;
  }
`

const SearchWrapper = styled.div`
  position: absolute;

  display: flex;

  left: 3.5rem;
  top: 0.5rem;
  width: 16.125rem;
`

const StyledLink = styled(Link)`
  width: fit-content;
  &:hover {
    text-decoration: underline;
  }
`

import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import isEqual from 'lodash.isequal'

import StyledSelect from 'components/StyledSelect'

const SelectAndLabel = ({ children, ...props }) => (
  <Wrapper error={props.error} disabled={props.isEditing && props.disabled}>
    <Label disabled={props.isEditing && props.disabled}>{props.label}</Label>
    <StyledSelect {...props} label={null} fullWidth padding='0.75rem 0.5rem' />
  </Wrapper>
)
export default memo(SelectAndLabel, isEqual)

const Wrapper = styled.div`
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  ${props =>
    props.error &&
    css`
      margin-bottom: calc(1.5rem - 1.66em);
    `}
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
`

const Label = styled.p`
  color: var(--breadcrumb-gray-color);
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1em;

  margin-bottom: 0.5rem;

  ${props =>
    props.disabled &&
    css`
      color: var(--platinium-gray-color);
    `}
`

import React, { memo } from 'react'
import styled from 'styled-components'
import isEqual from 'lodash.isequal'

import TitleStyle from 'styled/TitleStyle'

import StyledModal from 'components/StyledModal'

const FiltersModal = ({ children, ...props }) => (
  <StyledModal open={props.open} onClose={props.onClose}>
    <Wrapper {...props}>
      <Title>{props.title}</Title>
      <InputsWrapper>{props.inputs}</InputsWrapper>
      <ButtonsWrapper>{props.buttons}</ButtonsWrapper>
    </Wrapper>
  </StyledModal>
)

export default memo(FiltersModal, isEqual)
const Wrapper = styled.div`
  width: 100%;
  position: relative;
`

const InputsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
`
const ButtonsWrapper = styled.div`
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
`

const Title = styled.h1`
  ${TitleStyle};

  margin-bottom: 1.5rem;
`

import React from 'react'
import styled, { css } from 'styled-components'

import InfoIcon from 'icons/InfoIcon'

import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import FormHelperText from '@material-ui/core/FormHelperText'
import Tooltip from '@material-ui/core/Tooltip'

export default ({ children, ...props }) => {
  const {
    id,
    label,
    helperText,
    newButton,
    isEditing,
    disabledOptions,
    tooltip,
    ...remain
  } = props

  const generateOptions = () => {
    const mappedOptions = []
    if (newButton != null) {
      mappedOptions.push(
        // <StyledMenuItem key={`styled-select-new-button-menu-item`} value={""}>
        newButton
        // </StyledMenuItem>
      )
    }
    if (props.multiple) {
      return [
        ...mappedOptions,
        ...props.options.map((option, index) => (
          <StyledMenuItem
            key={`styled-select-${id}-menu-item-${index}`}
            value={option.value}
            disabled={
              disabledOptions != null &&
              Array.isArray(disabledOptions) &&
              disabledOptions.includes(option.value)
            }
          >
            <Checkbox
              color='primary'
              checked={props.value.indexOf(option.value) > -1}
            />
            <ListItemText primary={option.label ?? option.value} />
            {disabledOptions != null &&
              Array.isArray(disabledOptions) &&
              disabledOptions.includes(option.value) && (
            /*eslint-disable */
                <StyledToolTip
                  title={tooltip}
                  placement="top"
                  PopperProps={{ style: { pointerEvents: 'none' } }}
                >
                  <InfoIconWrapper
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                  >
                    <InfoIcon fill="var(--dark-gray-color)" />
                  </InfoIconWrapper>
                </StyledToolTip>
                /* eslint-enable */
            )}
          </StyledMenuItem>
        ))
      ]
    } else {
      return [
        ...mappedOptions,
        ...props.options.map((option, index) => (
          <StyledMenuItem
            value={option.value}
            key={`styled-select-${id}-menu-item-${index}`}
          >
            {option.label ?? '-'}
          </StyledMenuItem>
        ))
      ]
    }
  }

  return (
    <Wrapper variant='outlined' size='small'>
      {label != null && label !== '' && (
        <StyledLabel id={`styled-select-label-${id}`}>{label}</StyledLabel>
      )}
      <StyledSelect
        id={`styled-select-${id}`}
        labelId={`styled-select-label-${id}`}
        label={label}
        MenuProps={{ disableScrollLock: true }}
        isediting={isEditing ? 1 : 0}
        renderValue={selected => {
          if (props.multiple) {
            return selected
              .map(el => {
                if (el != null) {
                  const found = props.options.find(obj => obj.value === el)

                  return found != null ? found.label : el
                }
                return null
              })
              .filter(el => el != null)
              .join(', ')
          } else {
            const found = props.options.find(obj => obj.value === selected)
            return found != null ? found.label : selected
          }
        }}
        {...remain}
      >
        {props.options != null && generateOptions()}
      </StyledSelect>
      {helperText && <StyledFormHelperText>{helperText}</StyledFormHelperText>}
    </Wrapper>
  )
}

const Wrapper = styled(FormControl)`
  &.MuiFormControl-root {
    width: 100%;
  }
`

const StyledSelect = styled(Select)`
  & .MuiOutlinedInput-notchedOutline {
    border-color: var(--platinium-gray-color);
    border-radius: 0;

    ${props =>
      (props.label == null || props.label === '') &&
      css`
        legend {
          width: 0;
        }
      `}
  }

  & .MuiSelect-icon {
    color: var(--platinium-gray-color);
  }
  &.MuiOutlinedInput-root.Mui-focused .MuiSelect-icon {
    color: var(--primary-color);
  }

  & .MuiSelect-select:focus {
    background-color: var(--white-color);
  }

  & .MuiInputBase-input {
    color: var(--breadcrumb-gray-color);
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1em;
  }

  &.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: var(--platinium-gray-color);

    legend {
      width: 0;
    }
  }

  ${props =>
    props.isediting &&
    css`
      & .MuiInputBase-input.Mui-disabled {
        color: var(--platinium-gray-color);
        cursor: not-allowed;
      }
    `}
`

const StyledMenuItem = styled(MenuItem)`
  position: relative;
`

const StyledLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    color: var(--platinium-gray-color);
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1rem;
    text-transform: uppercase;
  }
  &.MuiInputLabel-outlined.MuiInputLabel-shrink {
    text-transform: capitalize;
  }
`

const StyledFormHelperText = styled(FormHelperText)`
  &.MuiFormHelperText-contained {
    margin: 0;
    color: var(--error-color);
  }
`

const InfoIconWrapper = styled.div`
  position: absolute;
  right: 1.5rem;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0;
`

const StyledToolTip = styled(Tooltip)`
  pointer-events: auto;
`

import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg viewBox='0 0 30 30' {...props}>
      <g fillRule='nonzero' fill={props.fill || '#FFF'}>
        <path d='M29.375 28.75h-.625V10.625a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625V28.75h-2.5V18.125a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625V28.75h-2.5V15.625a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625V28.75h-2.5v-6.875a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625v6.875H.625a.625.625 0 000 1.25h28.75a.625.625 0 000-1.25z' />
        <path d='M26.25 0a2.503 2.503 0 00-2.5 2.5c0 .462.135.89.354 1.263l-4.092 4.09A2.482 2.482 0 0018.75 7.5c-.784 0-1.476.37-1.935.936l-3.071-.876c0-.02.006-.039.006-.06 0-1.379-1.121-2.5-2.5-2.5a2.503 2.503 0 00-2.5 2.5c0 .364.081.706.221 1.017l-3.906 3.117a2.465 2.465 0 00-1.315-.384 2.503 2.503 0 00-2.5 2.5c0 1.379 1.121 2.5 2.5 2.5s2.5-1.121 2.5-2.5c0-.439-.124-.846-.324-1.205l3.829-3.054c.419.315.933.51 1.495.51.916 0 1.711-.502 2.146-1.24l2.897.827a2.368 2.368 0 00-.043.412c0 1.379 1.121 2.5 2.5 2.5s2.5-1.121 2.5-2.5c0-.462-.135-.89-.354-1.262l4.093-4.093c.371.22.799.355 1.261.355 1.379 0 2.5-1.121 2.5-2.5S27.63 0 26.25 0z' />
      </g>
    </svg>
  )
}

export default SvgComponent

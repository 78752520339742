import React, {
  useState,
  // useContext,
  useRef,
  useEffect,
  useCallback
} from 'react'
import styled from 'styled-components'
// import { useTranslation } from 'react-i18next'

// // import { SUPERSETURL, BASE_SUPERSETURL } from 'config'

// import { Context as AuthContext } from 'providers/AuthProvider/authProvider'

// import CircularProgress from '@material-ui/core/CircularProgress'

// import toast from 'components/StyledToast'
import FullPageLoading from 'components/FullPageLoading'
import StyledBreadCrumb from 'components/StyledBreadCrumb'
// import StyledButton from 'components/StyledButton'

import logic from './logic'

const Metrics = ({ children, ...props }) => {
  // const { actions } = useContext(AuthContext)
  // const { t } = useTranslation()
  const token = window.localStorage.getItem('access_token')
  const isTokenGood = useRef(null)
  const timeout = useRef(5000)
  const waiting = useRef(null)

  // const [downloadLoading, setDownloadLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [superSetData, setSuperSetData] = useState(null)

  const fetchTokenStatus = useCallback(hadError => {
    if (isTokenGood.current == null) {
      logic
        .getSuperset()
        .then(res => {
          isTokenGood.current = true
          if (hadError) {
            setLoading(true)
          }
          if (res.data) {
            setSuperSetData(res.data)
          }
        })
        .catch(err => {
          console.log({ err })
          clearInterval(waiting.current)
          isTokenGood.current = null
          setSuperSetData(null)
          fetchTokenStatus(true)
        })
    }
  }, [])

  useEffect(() => {
    fetchTokenStatus()
    waiting.current = setInterval(() => {
      isTokenGood.current = null
      fetchTokenStatus()
    }, timeout.current)
    return () => {
      clearInterval(waiting.current)
    }
    // eslint-disable-next-line
  }, [])

  // const fetchMetricsReport = useCallback(async () => {
  //   setDownloadLoading(true)
  //   await logic
  //     .triggerReportGeneration()
  //     .then(response => {
  //       if (
  //         response != null &&
  //         response.data != null &&
  //         response.data.url != null
  //       ) {
  //         logic.downloadFile(response.data.url)
  //       }
  //     })
  //     .catch(err => {
  //       console.log({ err })
  //       toast.error(t('metrics.download_error'))
  //       if (err == null || err.response == null) {
  //         actions.serverError()
  //       }
  //     })
  //     .finally(() => setDownloadLoading(false))
  // }, [actions, t])
  const removeLoading = useCallback(e => setLoading(false), [])
  return (
    <Wrapper>
      {loading ? <FullPageLoading /> : null}

      <BreadCrumbRow>
        <StyledBreadCrumb
          margin={0}
          items={[
            {
              path: '/metrics/',
              label: 'sections.metrics'
            }
          ]}
        />
        {/* <DownloadWrapper>
          <StyledButton onClick={fetchMetricsReport} disabled={downloadLoading}>
            {t('metrics.download_button')}
          </StyledButton>
          {downloadLoading && <ButtonLoading size={24} />}
        </DownloadWrapper> */}
      </BreadCrumbRow>
      {superSetData != null && (
        <iframe
          style={{
            width: '100%',
            height: '100%',
            borderWidth: '0px',
            backgroundColor: '#273041'
          }}
          title='superset'
          src={`${superSetData?.baseUrl}/token_login/?access_token=${token}&token_type=JWT&standalone=2&redirect=${superSetData?.baseUrl}${superSetData?.relativeUrl}`}
          onLoad={removeLoading}
        />
      )}
    </Wrapper>
  )
}

export default Metrics

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`
// const DownloadWrapper = styled.div`
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin: 0.5rem 0px 0.5rem auto;
//   height: 100%;
// `

// const ButtonLoading = styled(CircularProgress)`
//   position: absolute;

//   right: 0;
//   left: 0;
//   top: 0;
//   bottom: 0;
//   margin: auto;

//   z-index: 1001;
// `
const BreadCrumbRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  flex: 0 0 auto;
  padding: 0 2rem;

  ${({ theme }) => theme.smallDesktop`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 2.5rem;
  `}
`

import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
    >
      <g>
        <path
          fill='#fff'
          d='M 1.441406 17.761719 L 1.441406 21.070312 C 1.441406 21.496094 1.78125 21.839844 2.207031 21.839844 L 4.128906 21.839844 C 4.554688 21.839844 4.894531 21.496094 4.894531 21.070312 L 4.894531 17.761719 Z M 1.441406 17.761719 '
        />
        <path
          fill='#fff'
          d='M 4.824219 1.921875 C 4.265625 1.921875 3.792969 2.257812 3.585938 2.738281 L 0.105469 10.898438 C 0.0390625 11.058594 0 11.238281 0 11.425781 L 0 15.9375 C 0 16.679688 0.597656 17.28125 1.34375 17.28125 L 12.046875 17.28125 L 12.046875 8.875 L 2.789062 8.898438 C 3.402344 7.363281 4.625 4.289062 4.625 4.289062 C 4.855469 3.710938 4.871094 3.632812 5.203125 3.632812 L 12.046875 3.640625 L 12.046875 1.921875 Z M 4.824219 1.921875 '
        />
        <path
          fill='var(--primary-color)'
          d='M 4.800781 12.960938 C 4.800781 13.886719 4.046875 14.640625 3.121094 14.640625 C 2.191406 14.640625 1.441406 13.886719 1.441406 12.960938 C 1.441406 12.03125 2.191406 11.28125 3.121094 11.28125 C 4.046875 11.28125 4.800781 12.03125 4.800781 12.960938 Z M 4.800781 12.960938 '
        />
        <path
          fill='#fff'
          d='M 22.558594 17.761719 L 22.558594 21.070312 C 22.558594 21.496094 22.21875 21.839844 21.792969 21.839844 L 19.871094 21.839844 C 19.445312 21.839844 19.105469 21.496094 19.105469 21.070312 L 19.105469 17.761719 Z M 22.558594 17.761719 '
        />
        <path
          fill='#fff'
          d='M 19.175781 1.921875 C 19.734375 1.921875 20.207031 2.257812 20.414062 2.738281 L 23.894531 10.898438 C 23.960938 11.058594 24 11.238281 24 11.425781 L 24 15.9375 C 24 16.679688 23.402344 17.28125 22.65625 17.28125 L 11.953125 17.28125 L 11.953125 8.875 L 21.210938 8.898438 C 20.597656 7.363281 19.375 4.289062 19.375 4.289062 C 19.144531 3.710938 19.128906 3.632812 18.796875 3.632812 L 11.953125 3.640625 L 11.953125 1.921875 Z M 19.175781 1.921875 '
        />
        <path
          fill='var(--primary-color)'
          d='M 19.199219 12.960938 C 19.199219 13.886719 19.953125 14.640625 20.878906 14.640625 C 21.808594 14.640625 22.558594 13.886719 22.558594 12.960938 C 22.558594 12.03125 21.808594 11.28125 20.878906 11.28125 C 19.953125 11.28125 19.199219 12.03125 19.199219 12.960938 Z M 19.199219 12.960938 '
        />
      </g>
    </svg>
  )
}

export default SvgComponent

import axios from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { AUTH_TOKEN_TYPE } from 'config'

let languageInterceptor, authorizationInterceptor

const decamelizeKeysTransformer = function (data) {
  return data && JSON.stringify(decamelizeKeys(data))
}

export const setLanguageInterceptor = language => {
  axios.interceptors.request.eject(languageInterceptor)
  languageInterceptor = axios.interceptors.request.use(
    config => {
      if (language) {
        config.headers['Accept-Language'] = `${language};q=0.8`
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )
}

export const setAuthorizationInterceptor = authToken => {
  axios.interceptors.request.eject(authorizationInterceptor)
  authorizationInterceptor = axios.interceptors.request.use(
    config => {
      const token = window.localStorage.getItem('access_token')
      if (token) {
        config.headers.Authorization = `${AUTH_TOKEN_TYPE} ${token}`
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )
}

function timeout (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const setResponseInterceptor = (
  history,
  checkUserAuthentication,
  serverError
) => {
  axios.interceptors.response.use(
    response => {
      return response
    },
    async error => {
      if (
        error != null &&
        error.response != null &&
        error.response.status === 401 &&
        history.location.match !== '/login/'
      ) {
        await checkUserAuthentication(error.config)
        await timeout(1000)
        const token = window.localStorage.getItem('access_token')
        if (token != null) {
          delete error.config.headers
          error.config.headers = {
            Authorization: `${AUTH_TOKEN_TYPE} ${token}`
          }
          error.config.baseURL = undefined

          return axios.request(error.config)
        }
      } else if (
        error == null ||
        error.response == null ||
        error.response.status === 500
      ) {
        serverError()
      }
      return Promise.reject(error)
    }
  )
}

// Converts all responses for CamelCase
axios.interceptors.response.use(
  response => {
    response.data = camelizeKeys(response.data)
    return response
  },
  error => {
    return Promise.reject(camelizeKeys(error))
  }
)

// Converts all requests to under-cased
axios.interceptors.request.use(
  config => {
    const currentContentType = config.headers['Content-Type']

    // Converts URL get params to underscored
    if (config.params) {
      config.params = decamelizeKeys(config.params)
    }

    if (!currentContentType) {
      config.headers['Content-Type'] = 'application/json'
      config.transformRequest = [decamelizeKeysTransformer]
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default axios

import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  memo
} from 'react'
import styled, { css } from 'styled-components'
import get from 'lodash.get'
import isEqual from 'lodash.isequal'
import { useTranslation } from 'react-i18next'

import StyledModal from 'components/StyledModal'
import StyledButton from 'components/StyledButton'

import InputAndLabel from 'containers/InputAndLabel'
import MapContainer from 'containers/MapContainer'
import SelectAndLabel from 'containers/SelectAndLabel'

import TitleStyle from 'styled/TitleStyle'

const CreateNewSpotModal = ({ children, ...props }) => {
  const {
    form,
    open,
    onClose,
    handleChange,
    apiResponse,
    hasSubmitted,
    spotTypeOptions,
    fetchedSensors,
    handleSubmit,
    submitting,
    fetchLocationOptions,
    sensor
  } = props
  const { t } = useTranslation()

  const searchInputTimeoutRef = useRef(null)
  const [searchOptions, setSeachOptions] = useState([])
  const [textInput, setTextInput] = useState('')
  const [locationSelected, setLocationSelected] = useState(null)

  useEffect(() => {
    if (sensor != null) {
      handleChange('sensor', [sensor?.id])
    }
    // eslint-disable-next-line
  }, [sensor])

  useEffect(() => {
    if (searchInputTimeoutRef.current !== null) {
      clearTimeout(searchInputTimeoutRef.current)
    }
    searchInputTimeoutRef.current = setTimeout(() => {
      searchInputTimeoutRef.current = null
      if (fetchLocationOptions) {
        fetchLocationOptions({
          params: { q: textInput, format: 'jsonv2', addressdetails: 1 }
        })
          .then(response => {
            if (response.data) {
              const { data } = response
              setSeachOptions(data)
            } else {
              setSeachOptions([])
            }
          })
          .catch(err => {
            console.log({ err })
            setSeachOptions([])
          })
      }
    }, 500)
    // eslint-disable-next-line
  }, [textInput])

  const checkIfError = useCallback(
    property => {
      const error = get(apiResponse, property, null)
      if (apiResponse != null && error != null && error[0] != null) {
        return error[0]
      }
      return null
    },
    [apiResponse]
  )

  const helperTextGenerator = useCallback(
    property => {
      if (hasSubmitted && form[property] != null) {
        const apiError = checkIfError(property)
        if (apiError != null) {
          return apiError
        } else if (form[property].message) {
          return t(`errors.${form[property].message}`)
        }
      }
      return null
    },
    [checkIfError, form, hasSubmitted, t]
  )

  const memoHandleChange = useCallback(name => e => handleChange(name, e), [
    handleChange
  ])

  const memoHandleChangeValue = useCallback(
    name => e => handleChange(name, e?.target?.value),
    [handleChange]
  )

  const onSearchChange = useCallback(event => setTextInput(event), [
    setTextInput
  ])
  const onSearchInputChange = useCallback(
    (event, newValue) => setLocationSelected(newValue),
    [setLocationSelected]
  )

  const getOptionLabel = useCallback(option => option.displayName, [])

  const mapSearchProps = useMemo(
    () => ({
      value: textInput,
      onChange: onSearchChange,
      inputValue: locationSelected,
      onInputChange: onSearchInputChange,
      options: searchOptions,
      label: t('map.search_location'),
      getOptionLabel: getOptionLabel
    }),
    [
      getOptionLabel,
      searchOptions,
      onSearchInputChange,
      locationSelected,
      onSearchChange,
      textInput,
      t
    ]
  )

  const memoSpotTypeOptions = useMemo(
    () =>
        spotTypeOptions?.filter(el => el.value !== 'all').map(el => ({
          ...el,
          label: t(`table.${el.label}`)
        })) ?? [],
    [spotTypeOptions, t]
  )

  const memoSensorsOptions = useMemo(
    () =>
      sensor == null
        ? fetchedSensors?.map(el => ({
          value: el.id,
          label: el.uid
        })) ?? []
        : [
          {
            value: sensor?.id,
            label: sensor?.uid
          }
        ],
    [sensor, fetchedSensors]
  )

  const flyToLocation = useMemo(
    () =>
      locationSelected?.boundingbox != null
        ? [
          [
              locationSelected?.boundingbox?.[2],
              locationSelected?.boundingbox?.[0]
          ],
          [
              locationSelected?.boundingbox?.[3],
              locationSelected?.boundingbox?.[1]
          ]
        ]
        : null,
    [locationSelected?.boundingbox]
  )

  return (
    <StyledModal open={open} onClose={onClose}>
      <NewSpotWrapper>
        <ModalTitle>{t('spots.new')}</ModalTitle>
        <SameLineInputs>
          <InputAndLabel
            type='text'
            name='uid'
            value={form?.uid?.value ?? ''}
            onChange={memoHandleChange('uid')}
            label={t('table.uid')}
            error={
              hasSubmitted &&
              (form?.uid?.message != null || !!checkIfError('uid'))
            }
            helperText={helperTextGenerator('uid')}
            maxLength={255}
            minLength={1}
            masked
            mask={/^\w+$/}
          />
        </SameLineInputs>
        <NewSensorMapAndLabel>
          <NewSensorMapWrapper
            error={
              hasSubmitted &&
              (form?.geometry?.message != null || !!checkIfError('geometry'))
            }
          >
            <MapContainer
              data={form?.geometry?.value != null ? [form.geometry.value] : []}
              hideLegends
              type='spots'
              isDrawAvailable
              setGeometry={memoHandleChange('geometry')}
              enableSearch
              search={mapSearchProps}
              flyToLocation={flyToLocation}
            />
          </NewSensorMapWrapper>
          <NewSensorMapHelperText
            error={
              hasSubmitted &&
              (form?.geometry?.message != null || !!checkIfError('geometry'))
            }
          >
            {checkIfError('geometry') || t('map.spot_helper_text')}
          </NewSensorMapHelperText>
        </NewSensorMapAndLabel>
        <SameLineInputs>
          <SelectAndLabel
            value={form?.spotType?.value ?? ''}
            onChange={memoHandleChangeValue('spotType')}
            label={t('table.spot_type')}
            options={memoSpotTypeOptions}
            id='spotType' // OBRIGATORIO POR CAUSA DA LABEL/
            error={
              hasSubmitted &&
              (form?.spotType?.message != null || !!checkIfError('spotType'))
            }
            helperText={helperTextGenerator('spotType')}
          />
          <SelectAndLabel
            multiple
            value={form?.sensor?.value ?? []}
            onChange={memoHandleChangeValue('sensor')}
            label={t('table.sensors')}
            /*eslint-disable */
            options={memoSensorsOptions}
            /* eslint-enable */
            id='sensor' // OBRIGATORIO POR CAUSA DA LABEL/
            error={
              hasSubmitted &&
              (form?.sensor?.message != null || !!checkIfError('sensor'))
            }
            helperText={helperTextGenerator('sensor')}
            disabled={sensor != null}
          />
        </SameLineInputs>
        <NewSensorButtonWrapper>
          <Blank />
          <Blank />
          <StyledButton
            type='primary'
            onClick={handleSubmit}
            disabled={submitting}
          >
            {t('create')}
          </StyledButton>
        </NewSensorButtonWrapper>
      </NewSpotWrapper>
    </StyledModal>
  )
}

export default memo(CreateNewSpotModal, isEqual)

const NewSpotWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ModalTitle = styled.h2`
  ${TitleStyle};

  margin-bottom: 1.5rem;
`

const SameLineInputs = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  ${({ theme }) => theme.smallDesktop`
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  column-gap: 1rem;
`}
`

const NewSensorMapHelperText = styled.p`
  color: var(--primary-color);
  font-size: 0.875rem;
  font-style: italic;
  letter-spacing: 0;
  line-height: 1em;

  margin-top: 0.25rem;

  ${props =>
    props.error &&
    css`
      color: var(--error-color);
    `}
`

const NewSensorMapAndLabel = styled.div`
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  ${props =>
    props.error &&
    css`
      margin-bottom: calc(1.5rem - 1.66em);
    `}
`
const NewSensorMapWrapper = styled.div`
  width: 100%;
  height: 20rem;

  ${({ theme }) => theme.smallDesktop`
  height: 11rem;

`}
`

const NewSensorButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(10rem, 1fr);

  ${({ theme }) => theme.smallDesktop`
  grid-template-columns: minmax(12.375rem, 1fr) minmax(9rem, 1fr) minmax(
      12.375rem,
      1fr
    );
`}
`

const Blank = styled.div`
  grid-column: span 1;
`

import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24'>
      <g>
        <path
          fill='#fff'
          d='M 12 0.960938 C 5.914062 0.960938 0.960938 5.914062 0.960938 12 C 0.960938 18.085938 5.914062 23.039062 12 23.039062 C 18.085938 23.039062 23.039062 18.085938 23.039062 12 C 23.039062 5.914062 18.085938 0.960938 12 0.960938 Z M 3.359375 12 C 3.359375 10.050781 4.019531 8.257812 5.113281 6.808594 L 17.191406 18.886719 C 15.742188 19.980469 13.949219 20.640625 12 20.640625 C 7.234375 20.640625 3.359375 16.765625 3.359375 12 Z M 18.886719 17.191406 L 6.808594 5.113281 C 8.257812 4.019531 10.050781 3.359375 12 3.359375 C 16.765625 3.359375 20.640625 7.234375 20.640625 12 C 20.640625 13.949219 19.980469 15.742188 18.886719 17.191406 Z M 18.886719 17.191406 '
        />
      </g>
    </svg>
  )
}

export default SvgComponent

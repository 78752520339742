import React from 'react'
import styled from 'styled-components'
import MobileStepper from '@material-ui/core/MobileStepper'

export default ({ children, ...props }) => (
  <Wrapper variant='dots' position='static' {...props} />
)

const Wrapper = styled(MobileStepper)`
  &.MuiMobileStepper-root {
    margin-top: 1rem;
    background: transparent;
    padding: 0;

  }

  & .MuiMobileStepper-dots{
    width: 100%;
    justify-content: center;
  }
  
`

export const styles = [
  {
    id: 'gl-draw-polygon-fill-inactive.cold',
    type: 'fill',
    filter: [
      'all',
      [
        '==',
        'active',
        'false'
      ],
      [
        '==',
        '$type',
        'Polygon'
      ],
      [
        '!=',
        'mode',
        'static'
      ]
    ],
    paint: {
      'fill-color': '#00A4CC',
      'fill-outline-color': '#00A4CC',
      'fill-opacity': 0.1
    }

  },
  {
    id: 'gl-draw-polygon-fill-active.cold',
    type: 'fill',
    filter: [
      'all',
      [
        '==',
        'active',
        'true'
      ],
      [
        '==',
        '$type',
        'Polygon'
      ]
    ],
    paint: {
      'fill-color': '#004454',
      'fill-outline-color': '#004454',
      'fill-opacity': 0.1
    }

  },
  {
    id: 'gl-draw-polygon-midpoint.cold',
    type: 'circle',
    filter: [
      'all',
      [
        '==',
        '$type',
        'Point'
      ],
      [
        '==',
        'meta',
        'midpoint'
      ]
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#004454'
    }

  },
  {
    id: 'gl-draw-polygon-stroke-inactive.cold',
    type: 'line',
    filter: [
      'all',
      [
        '==',
        'active',
        'false'
      ],
      [
        '==',
        '$type',
        'Polygon'
      ],
      [
        '!=',
        'mode',
        'static'
      ]
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#00A4CC',
      'line-width': 2
    }

  },
  {
    id: 'gl-draw-polygon-stroke-active.cold',
    type: 'line',
    filter: [
      'all',
      [
        '==',
        'active',
        'true'
      ],
      [
        '==',
        '$type',
        'Polygon'
      ]
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#004454',
      'line-dasharray': [
        0.2,
        2
      ],
      'line-width': 2
    }

  },
  {
    id: 'gl-draw-line-inactive.cold',
    type: 'line',
    filter: [
      'all',
      [
        '==',
        'active',
        'false'
      ],
      [
        '==',
        '$type',
        'LineString'
      ],
      [
        '!=',
        'mode',
        'static'
      ]
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#00A4CC',
      'line-width': 2
    }

  },
  {
    id: 'gl-draw-line-active.cold',
    type: 'line',
    filter: [
      'all',
      [
        '==',
        '$type',
        'LineString'
      ],
      [
        '==',
        'active',
        'true'
      ]
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#004454',
      'line-dasharray': [
        0.2,
        2
      ],
      'line-width': 2
    }

  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive.cold',
    type: 'circle',
    filter: [
      'all',
      [
        '==',
        'meta',
        'vertex'
      ],
      [
        '==',
        '$type',
        'Point'
      ],
      [
        '!=',
        'mode',
        'static'
      ]
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff'
    }

  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive.cold',
    type: 'circle',
    filter: [
      'all',
      [
        '==',
        'meta',
        'vertex'
      ],
      [
        '==',
        '$type',
        'Point'
      ],
      [
        '!=',
        'mode',
        'static'
      ]
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#004454'
    }

  },
  {
    id: 'gl-draw-point-point-stroke-inactive.cold',
    type: 'circle',
    filter: [
      'all',
      [
        '==',
        'active',
        'false'
      ],
      [
        '==',
        '$type',
        'Point'
      ],
      [
        '==',
        'meta',
        'feature'
      ],
      [
        '!=',
        'mode',
        'static'
      ]
    ],
    paint: {
      'circle-radius': 8,
      'circle-opacity': 1,
      'circle-color': '#fff'
    }

  },
  {
    id: 'gl-draw-point-inactive.cold',
    type: 'circle',
    filter: [
      'all',
      [
        '==',
        'active',
        'false'
      ],
      [
        '==',
        '$type',
        'Point'
      ],
      [
        '==',
        'meta',
        'feature'
      ],
      [
        '!=',
        'mode',
        'static'
      ]
    ],
    paint: {
      'circle-radius': 8,
      'circle-color': '#00A4CC'
    }

  },
  {
    id: 'gl-draw-point-stroke-active.cold',
    type: 'circle',
    filter: [
      'all',
      [
        '==',
        '$type',
        'Point'
      ],
      [
        '==',
        'active',
        'true'
      ],
      [
        '!=',
        'meta',
        'midpoint'
      ]
    ],
    paint: {
      'circle-radius': 8,
      'circle-color': '#fff'
    }

  },
  {
    id: 'gl-draw-point-active.cold',
    type: 'circle',
    filter: [
      'all',
      [
        '==',
        '$type',
        'Point'
      ],
      [
        '!=',
        'meta',
        'midpoint'
      ],
      [
        '==',
        'active',
        'true'
      ]
    ],
    paint: {
      'circle-radius': 8,
      'circle-color': '#004454'
    }

  },
  {
    id: 'gl-draw-polygon-fill-static.cold',
    type: 'fill',
    filter: [
      'all',
      [
        '==',
        'mode',
        'static'
      ],
      [
        '==',
        '$type',
        'Polygon'
      ]
    ],
    paint: {
      'fill-color': '#404040',
      'fill-outline-color': '#404040',
      'fill-opacity': 0.1
    }

  },
  {
    id: 'gl-draw-polygon-stroke-static.cold',
    type: 'line',
    filter: [
      'all',
      [
        '==',
        'mode',
        'static'
      ],
      [
        '==',
        '$type',
        'Polygon'
      ]
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#404040',
      'line-width': 2
    }

  },
  {
    id: 'gl-draw-line-static.cold',
    type: 'line',
    filter: [
      'all',
      [
        '==',
        'mode',
        'static'
      ],
      [
        '==',
        '$type',
        'LineString'
      ]
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#404040',
      'line-width': 2
    }

  },
  {
    id: 'gl-draw-point-static.cold',
    type: 'circle',
    filter: [
      'all',
      [
        '==',
        'mode',
        'static'
      ],
      [
        '==',
        '$type',
        'Point'
      ]
    ],
    paint: {
      'circle-radius': 8,
      'circle-color': '#404040'
    }

  },
  {
    id: 'gl-draw-polygon-fill-inactive.hot',
    type: 'fill',
    filter: [
      'all',
      [
        '==',
        'active',
        'false'
      ],
      [
        '==',
        '$type',
        'Polygon'
      ],
      [
        '!=',
        'mode',
        'static'
      ]
    ],
    paint: {
      'fill-color': '#00A4CC',
      'fill-outline-color': '#00A4CC',
      'fill-opacity': 0.1
    }

  },
  {
    id: 'gl-draw-polygon-fill-active.hot',
    type: 'fill',
    filter: [
      'all',
      [
        '==',
        'active',
        'true'
      ],
      [
        '==',
        '$type',
        'Polygon'
      ]
    ],
    paint: {
      'fill-color': '#004454',
      'fill-outline-color': '#004454',
      'fill-opacity': 0.1
    }

  },
  {
    id: 'gl-draw-polygon-midpoint.hot',
    type: 'circle',
    filter: [
      'all',
      [
        '==',
        '$type',
        'Point'
      ],
      [
        '==',
        'meta',
        'midpoint'
      ]
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#004454'
    }

  },
  {
    id: 'gl-draw-polygon-stroke-inactive.hot',
    type: 'line',
    filter: [
      'all',
      [
        '==',
        'active',
        'false'
      ],
      [
        '==',
        '$type',
        'Polygon'
      ],
      [
        '!=',
        'mode',
        'static'
      ]
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#00A4CC',
      'line-width': 2
    }

  },
  {
    id: 'gl-draw-polygon-stroke-active.hot',
    type: 'line',
    filter: [
      'all',
      [
        '==',
        'active',
        'true'
      ],
      [
        '==',
        '$type',
        'Polygon'
      ]
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#004454',
      'line-dasharray': [
        0.2,
        2
      ],
      'line-width': 2
    }

  },
  {
    id: 'gl-draw-line-inactive.hot',
    type: 'line',
    filter: [
      'all',
      [
        '==',
        'active',
        'false'
      ],
      [
        '==',
        '$type',
        'LineString'
      ],
      [
        '!=',
        'mode',
        'static'
      ]
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#00A4CC',
      'line-width': 2
    }

  },
  {
    id: 'gl-draw-line-active.hot',
    type: 'line',
    filter: [
      'all',
      [
        '==',
        '$type',
        'LineString'
      ],
      [
        '==',
        'active',
        'true'
      ]
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#004454',
      'line-dasharray': [
        0.2,
        2
      ],
      'line-width': 2
    }

  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive.hot',
    type: 'circle',
    filter: [
      'all',
      [
        '==',
        'meta',
        'vertex'
      ],
      [
        '==',
        '$type',
        'Point'
      ],
      [
        '!=',
        'mode',
        'static'
      ]
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff'
    }

  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive.hot',
    type: 'circle',
    filter: [
      'all',
      [
        '==',
        'meta',
        'vertex'
      ],
      [
        '==',
        '$type',
        'Point'
      ],
      [
        '!=',
        'mode',
        'static'
      ]
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#004454'
    }

  },
  {
    id: 'gl-draw-point-point-stroke-inactive.hot',
    type: 'circle',
    filter: [
      'all',
      [
        '==',
        'active',
        'false'
      ],
      [
        '==',
        '$type',
        'Point'
      ],
      [
        '==',
        'meta',
        'feature'
      ],
      [
        '!=',
        'mode',
        'static'
      ]
    ],
    paint: {
      'circle-radius': 8,
      'circle-opacity': 1,
      'circle-color': '#fff'
    }

  },
  {
    id: 'gl-draw-point-inactive.hot',
    type: 'circle',
    filter: [
      'all',
      [
        '==',
        'active',
        'false'
      ],
      [
        '==',
        '$type',
        'Point'
      ],
      [
        '==',
        'meta',
        'feature'
      ],
      [
        '!=',
        'mode',
        'static'
      ]
    ],
    paint: {
      'circle-radius': 8,
      'circle-color': '#00A4CC'
    }

  },
  {
    id: 'gl-draw-point-stroke-active.hot',
    type: 'circle',
    filter: [
      'all',
      [
        '==',
        '$type',
        'Point'
      ],
      [
        '==',
        'active',
        'true'
      ],
      [
        '!=',
        'meta',
        'midpoint'
      ]
    ],
    paint: {
      'circle-radius': 8,
      'circle-color': '#fff'
    }

  },
  {
    id: 'gl-draw-point-active.hot',
    type: 'circle',
    filter: [
      'all',
      [
        '==',
        '$type',
        'Point'
      ],
      [
        '!=',
        'meta',
        'midpoint'
      ],
      [
        '==',
        'active',
        'true'
      ]
    ],
    paint: {
      'circle-radius': 8,
      'circle-color': '#004454'
    }

  },
  {
    id: 'gl-draw-polygon-fill-static.hot',
    type: 'fill',
    filter: [
      'all',
      [
        '==',
        'mode',
        'static'
      ],
      [
        '==',
        '$type',
        'Polygon'
      ]
    ],
    paint: {
      'fill-color': '#404040',
      'fill-outline-color': '#404040',
      'fill-opacity': 0.1
    }

  },
  {
    id: 'gl-draw-polygon-stroke-static.hot',
    type: 'line',
    filter: [
      'all',
      [
        '==',
        'mode',
        'static'
      ],
      [
        '==',
        '$type',
        'Polygon'
      ]
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#404040',
      'line-width': 2
    }

  },
  {
    id: 'gl-draw-line-static.hot',
    type: 'line',
    filter: [
      'all',
      [
        '==',
        'mode',
        'static'
      ],
      [
        '==',
        '$type',
        'LineString'
      ]
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#404040',
      'line-width': 2
    }

  },
  {
    id: 'gl-draw-point-static.hot',
    type: 'circle',
    filter: [
      'all',
      [
        '==',
        'mode',
        'static'
      ],
      [
        '==',
        '$type',
        'Point'
      ]
    ],
    paint: {
      'circle-radius': 8,
      'circle-color': '#404040'
    }

  }
]

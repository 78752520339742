import axios from 'axios'

import { BASE_API_URL, BASE_MEDIA_URL } from 'config'
const METRICS_URL = BASE_API_URL + '/metrics/get-report/'
const PROFILE_URL = BASE_API_URL + '/profile/me/'
const SUPERSET_URL = BASE_API_URL + '/metrics-dashboard/'

const logic = {
  getSuperset: options =>
    Promise.resolve().then(() => {
      return axios.get(SUPERSET_URL, options)
    }),
  triggerReportGeneration: () => {
    return Promise.resolve().then(() => {
      return axios.get(METRICS_URL)
    })
  },
  downloadFile: fileUrl => {
    const instance = axios.create()

    instance
      .get(`${BASE_MEDIA_URL}${fileUrl}`, {
        responseType: 'blob'
      })
      .then(response => {
        const url = window.URL.createObjectURL(
          // eslint-disable-next-line
          new Blob([response.data], { type: 'application/pdf' })
        )
        // opens new window
        // window.open(url, '_blank')
        // download file
        const link = document.createElement('a')
        link.href = url
        link.target = '_blank'
        link.setAttribute('download', 'report.pdf')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
  },
  getUserInfo: () => {
    return axios.get(PROFILE_URL)
  }
}

export default logic

import moment from 'moment'
import { DATETIME_FORMAT } from 'config'

const columns = [
  {
    key: 'id',
    label: 'table.id'
  },
  {
    key: 'area.name',
    label: 'table.area'
  },
  {
    key: 'area.monitoringType',
    label: 'table.monitoring_type',
    render: ({ t, ...element }) => t(`table.${element.area.monitoringType}`)
  },
  {
    key: 'spotType',
    label: 'table.spot_type',
    render: ({ t, ...element }) => t(`table.${element.spotType}`)
  },
  {
    key: 'occupation',
    label: 'table.occupancy_rate'
  },
  {
    key: 'unknownSpots',
    label: 'table.unknown_spots'
  },
  {
    key: 'createdAt',
    label: 'table.date',
    render: element => moment(element.createdAt).format(DATETIME_FORMAT)
  }
]

export default columns

import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
    >
      <g>
        <path
          fill='#fff'
          d='M 0.0390625 21.558594 C 0.0390625 15.226562 0.0390625 8.890625 0.0546875 2.53125 C 0.289062 1.285156 0.996094 0.492188 2.183594 0.132812 C 2.296875 0.101562 2.410156 0.0703125 2.519531 0.0390625 C 8.839844 0.0390625 15.160156 0.0390625 21.507812 0.0546875 C 22.707031 0.207031 23.691406 1.085938 23.960938 2.242188 C 23.984375 2.347656 24.011719 2.453125 24.039062 2.558594 C 24.039062 8.882812 24.039062 15.203125 24.027344 21.546875 C 23.804688 22.808594 22.964844 23.703125 21.757812 23.976562 C 21.679688 23.996094 21.601562 24.019531 21.519531 24.039062 C 15.199219 24.039062 8.878906 24.039062 2.53125 24.027344 C 1.359375 23.808594 0.582031 23.164062 0.1875 22.066406 C 0.128906 21.898438 0.0898438 21.730469 0.0390625 21.558594 M 9.460938 20.160156 C 9.664062 20.160156 9.871094 20.160156 10.089844 20.160156 C 10.089844 18.390625 10.089844 16.640625 10.089844 14.871094 C 10.175781 14.871094 10.25 14.871094 10.320312 14.871094 C 11.402344 14.871094 12.480469 14.875 13.5625 14.875 C 14.382812 14.871094 15.183594 14.742188 15.929688 14.394531 C 17.738281 13.542969 18.839844 12.136719 19.175781 10.160156 C 19.390625 8.902344 19.1875 7.695312 18.546875 6.59375 C 17.445312 4.699219 15.769531 3.742188 13.578125 3.722656 C 11.414062 3.707031 9.246094 3.71875 7.078125 3.71875 C 7.015625 3.71875 6.953125 3.71875 6.890625 3.71875 C 6.890625 9.214844 6.890625 14.683594 6.890625 20.160156 C 7.742188 20.160156 8.582031 20.160156 9.460938 20.160156 Z M 9.460938 20.160156 '
        />
        <path
          fill='none'
          d='M 24.039062 2.542969 C 24.011719 2.453125 23.984375 2.347656 23.960938 2.242188 C 23.691406 1.085938 22.707031 0.207031 21.527344 0.0546875 C 22.355469 0.0390625 23.195312 0.0390625 24.039062 0.0390625 C 24.039062 0.867188 24.039062 1.695312 24.039062 2.542969 Z M 24.039062 2.542969 '
        />
        <path
          fill='none'
          d='M 21.539062 24.039062 C 21.601562 24.019531 21.679688 23.996094 21.757812 23.976562 C 22.964844 23.703125 23.804688 22.808594 24.027344 21.566406 C 24.039062 22.382812 24.039062 23.207031 24.039062 24.039062 C 23.210938 24.039062 22.386719 24.039062 21.539062 24.039062 Z M 21.539062 24.039062 '
        />
        <path
          fill='none'
          d='M 2.5 0.0390625 C 2.410156 0.0703125 2.296875 0.101562 2.183594 0.132812 C 0.996094 0.492188 0.289062 1.285156 0.0546875 2.511719 C 0.0390625 1.695312 0.0390625 0.871094 0.0390625 0.0390625 C 0.855469 0.0390625 1.667969 0.0390625 2.5 0.0390625 Z M 2.5 0.0390625 '
        />
        <path
          fill='none'
          d='M 0.0390625 21.578125 C 0.0898438 21.730469 0.128906 21.898438 0.1875 22.066406 C 0.582031 23.164062 1.359375 23.808594 2.511719 24.027344 C 1.695312 24.039062 0.871094 24.039062 0.0390625 24.039062 C 0.0390625 23.226562 0.0390625 22.410156 0.0390625 21.578125 Z M 0.0390625 21.578125 '
        />
        <path
          fill='none'
          d='M 9.441406 20.160156 C 8.582031 20.160156 7.742188 20.160156 6.890625 20.160156 C 6.890625 14.683594 6.890625 9.214844 6.890625 3.71875 C 6.953125 3.71875 7.015625 3.71875 7.078125 3.71875 C 9.246094 3.71875 11.414062 3.707031 13.578125 3.722656 C 15.769531 3.742188 17.445312 4.699219 18.546875 6.59375 C 19.1875 7.695312 19.390625 8.902344 19.175781 10.160156 C 18.839844 12.136719 17.738281 13.542969 15.929688 14.394531 C 15.183594 14.742188 14.382812 14.871094 13.5625 14.875 C 12.480469 14.875 11.402344 14.871094 10.320312 14.871094 C 10.25 14.871094 10.175781 14.871094 10.089844 14.871094 C 10.089844 16.640625 10.089844 18.390625 10.089844 20.160156 C 9.871094 20.160156 9.664062 20.160156 9.441406 20.160156 M 10.085938 9.378906 C 10.085938 10.144531 10.085938 10.910156 10.085938 11.679688 C 10.128906 11.6875 10.152344 11.695312 10.179688 11.695312 C 11.421875 11.695312 12.671875 11.71875 13.914062 11.679688 C 15.382812 11.628906 16.472656 10.140625 16.136719 8.710938 C 15.863281 7.535156 14.902344 6.769531 13.6875 6.761719 C 12.539062 6.753906 11.394531 6.757812 10.25 6.757812 C 10.199219 6.757812 10.148438 6.765625 10.085938 6.773438 C 10.085938 7.636719 10.085938 8.488281 10.085938 9.378906 Z M 10.085938 9.378906 '
        />
        <path
          fill='#fff'
          d='M 10.085938 9.359375 C 10.085938 8.488281 10.085938 7.636719 10.085938 6.773438 C 10.148438 6.765625 10.199219 6.757812 10.25 6.757812 C 11.394531 6.757812 12.539062 6.753906 13.6875 6.761719 C 14.902344 6.769531 15.863281 7.535156 16.136719 8.710938 C 16.472656 10.140625 15.382812 11.628906 13.914062 11.679688 C 12.671875 11.71875 11.421875 11.695312 10.179688 11.695312 C 10.152344 11.695312 10.128906 11.6875 10.085938 11.679688 C 10.085938 10.910156 10.085938 10.144531 10.085938 9.359375 Z M 10.085938 9.359375 '
        />
      </g>
    </svg>
  )
}

export default SvgComponent

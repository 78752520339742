import React from 'react'
import { Context } from 'providers/ResponsiveHandler/responsiveProvider'

const withResponsive = ComposedComponent => props => (
  <Context.Consumer>
    {({ responsiveHandlers }) => (
      <ComposedComponent responsiveHandlers={responsiveHandlers} {...props} />
    )}
  </Context.Consumer>
)

export default withResponsive

import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg viewBox='0 0 30 30' {...props}>
      <defs>
        <path
          d='M29.167 0L28.9.05 20 3.5 10 0 .6 3.167c-.35.116-.6.416-.6.8v25.2c0 .466.367.833.833.833l.267-.05L10 26.5 20 30l9.4-3.167c.35-.116.6-.416.6-.8V.833A.825.825 0 0029.167 0zM20 26.667L10 23.15V3.333L20 6.85v19.817z'
          id='prefix__a'
        />
      </defs>
      <use fill={props.fill || '#FFF'} fillRule='nonzero' xlinkHref='#prefix__a' />
    </svg>
  )
}

export default SvgComponent

import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg viewBox='0 0 80 80' {...props}>
      <defs>
        <circle id='prefix__a_flag-pt' cx={40} cy={40} r={40} />
        <rect id='prefix__c_flag-pt' x={0} y={0} width={112} height={80} rx={8} />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='prefix__b_flag-pt' fill='#fff'>
          <use xlinkHref='#prefix__a_flag-pt' />
        </mask>
        <use fill='#D8D8D8' xlinkHref='#prefix__a_flag-pt' />
        <g mask='url(#prefix__b_flag-pt)'>
          <g transform='translate(-6)'>
            <mask id='prefix__d_flag-pt' fill='#fff'>
              <use xlinkHref='#prefix__c_flag-pt' />
            </mask>
            <use fill='#FFF' xlinkHref='#prefix__c_flag-pt' />
            <path fill='#FF2936' mask='url(#prefix__d_flag-pt)' d='M0 0h112v80H0z' />
            <path fill='#128415' mask='url(#prefix__d_flag-pt)' d='M0 0h42.667v80H0z' />
            <circle
              fill='#FAF94F'
              mask='url(#prefix__d_flag-pt)'
              cx={42.667}
              cy={40}
              r={10.667}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent

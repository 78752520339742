import React, { useState, useContext, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Context as AuthContext } from 'providers/AuthProvider/authProvider'
import withResponsive from 'providers/ResponsiveHandler/withResponsive'

import TitleStyle from 'styled/TitleStyle'

import Container from 'components/Container'
import StyledButton from 'components/StyledButton'

import InputAndLabel from 'containers/InputAndLabel'

import Background from 'images/background_smart_parking.jpg'

import BackArrowIcon from 'icons/BackArrowIcon'

import logic from './logic'

const RecoverPassword = ({ children, ...props }) => {
  const { actions } = useContext(AuthContext)
  const { responsiveHandlers } = props

  const { t } = useTranslation()
  const [form, setForm] = useState(logic.defaultValues)
  const [submitState, setSubmitState] = useState({
    hasSubmitted: false,
    submitting: false,
    error: null
  })

  const handleSubmit = useCallback(event => {
    event.preventDefault()
    setSubmitState(submitState => ({
      ...submitState,
      error: null,
      hasSubmitted: true,
      submitting: true
    }))
    logic
      .recover(form, form => setForm({ ...form }))
      .then(response => {
        setSubmitState(submitState => ({
          ...submitState,
          success: true,
          submitting: false
        }))
      })
      .catch(err => {
        console.log({ err })
        if (err == null || err.response == null) {
          actions.serverError()
        }
        if (err.response != null && err.response.data != null) {
          setSubmitState(submitState => ({
            ...submitState,
            submitting: false,
            error: t('errors.recover')
          }))
        } else {
          setSubmitState(submitState => ({
            ...submitState,
            submitting: false
          }))
        }
      })
  }, [actions, form, t])

  const handleChange = useCallback((key, value) => {
    setForm({
      ...form,
      [key]: {
        ...form[key],
        value: value
      }
    })
  }, [form])

  const memoHandleChangeValue = useCallback(
    name => e => {
      handleChange(name, e.target.value)
    },
    [handleChange]
  )

  return (
    <Wrapper>
      <Overlay />
      <BackgroundImage
        src={Background}
        alt='parking image'
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: -10,
          left: 0,
          top: 0,
          objectFit: 'cover',
          opacity: 0.3
        }}
        loading='eager'
      />
      <Container height='100%'>
        {submitState.success && (
          <SuccessWrapper>
            <Title>{t('recover_password')}</Title>
            <SuccessMessage>{t('recover_success')}</SuccessMessage>
            <GoBackLink to='/login/'>{t('back_main_page')}</GoBackLink>
          </SuccessWrapper>
        )}
        {form != null && !submitState.success && (
          <StyledForm onSubmit={handleSubmit}>
            <TitleWrapper>
              <IconWrapper to='/login/'>
                <BackArrowIcon
                  height={responsiveHandlers.isDesktop ? '1.5rem' : '1.25rem'}
                  width={responsiveHandlers.isDesktop ? '1.5rem' : '1.25rem'}
                  fill='var(--primary-color)'
                />
              </IconWrapper>
              <Title>{t('recover_password')}</Title>
            </TitleWrapper>
            <InputWrapper>
              <InputAndLabel
                type='text'
                value={
                  form?.email?.value ?? ''
                }
                name='email'
                label={t('email')}
                onChange={memoHandleChangeValue('email')}
                error={
                  submitState.hasSubmitted &&
                  form.email != null &&
                  form.email.message != null
                }
                helperText={
                  submitState.hasSubmitted &&
                  form?.email?.message != null
                    ? t(`errors.${form.email.message}`)
                    : ''
                }
                autoComplete='email'
                minLength={1}
              />
            </InputWrapper>

            {submitState.error != null && (
              <ErrorText>{submitState.error}</ErrorText>
            )}
            <ButtonWrapper>
              <StyledButton type='primary' disabled={submitState.submitting}>
                {t('recover_password')}
              </StyledButton>
            </ButtonWrapper>
          </StyledForm>
        )}
      </Container>
    </Wrapper>
  )
}

RecoverPassword.propTypes = {
  email: PropTypes.string
}

export default withResponsive(RecoverPassword)

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  height: ${({ height }) => height};
  position: relative;
  overflow: visible;

  background-color: var(--backgroud-color);
`

const StyledForm = styled.form`
  grid-column: 1/5;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;

  padding: 2rem;
  margin: auto 0;

  border: 1px solid var(--platinium-gray-color);
  box-shadow: 0 0 0.375rem 0 var(--box-shadow-black-half-opacity);

  ${({ theme }) => theme.smallDesktop`
    grid-column: 5/9;

  `}
`

const SuccessWrapper = styled.div`
  grid-column: 1/5;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;

  padding: 2rem;
  margin: auto 0;

  border: 1px solid var(--platinium-gray-color);
  box-shadow: 0 0 0.375rem 0 var(--box-shadow-black-half-opacity);

  ${({ theme }) => theme.smallDesktop`
    grid-column: 5/9;

  `}
`

const SuccessMessage = styled.p`
  font-size: 1rem;
  text-align: center;
  margin: 1.5rem auto;
  line-height: 1.25rem;
`

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  display: grid;
  grid-template-columns: minmax(12.375rem, 1fr);
`

const ErrorText = styled.p`
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  line-heihgt: 1em;
  color: var(--error-color);
`

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -10;
  left: 0;
  top: 0;
  object-fit: cover;
  opacity: 0.3;
  background: ${({ src }) => `url(${src}) center / cover`};
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  opacity: 0.25;
  background-color: var(--overlay-background-color);
`

const Title = styled.h1`
  ${TitleStyle}
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 2rem;
`

const IconWrapper = styled(Link)`
  display: flex;
  align-items: center;
  margin: auto 0.5rem auto 0;
`

const GoBackLink = styled(Link)`
  width: fit-content;

  margin: 1rem auto;
  font-size: 0.875rem;
  text-decoration: underline;
  color: var(--primary-color);
  transition: opacity 0.3s ease-in-out;

  &:hover {
    transition: opacity 0.3s ease-in-out;
    opacity: 0.5;
  }
`

import React from 'react'
import styled from 'styled-components'
import { DATETIME_FORMAT } from 'config'
import moment from 'moment'

export const mapStatusToColors = status => {
  switch (status) {
    case 'ok':
      return 'var(--success-color)'
    case 'not_ok':
      return 'var(--error-color)'
    case 'unknown':
      return 'var(--warning-color)'
    default:
      return 'var(--unknown-color)'
  }
}

const columns = [
  {
    key: 'uid',
    label: 'table.uid',
    render: ({ setHardwareInDetail, t, ...element }) => (
      <StyledLink onClick={() => setHardwareInDetail(element)}>
        {element.uid}
      </StyledLink>
    )
  },
  {
    key: 'name',
    label: 'table.name'

  },
  {
    key: 'deviceType',
    label: 'table.device_type',
    render: ({ t, ...element }) => t(`table.${element.deviceType}`)
  },
  // {
  //   key: 'monitoringType',
  //   label: 'table.counting_type',
  //   render: ({ t, ...element }) => {
  //     if (element.deviceType != null && element.deviceType !== 'panel') {
  //       if (element.monitoringType != null) {
  //         return t(`table.${element.monitoringType}`)
  //       }
  //     }
  //     return '-'
  //   }
  // },
  {
    key: 'area',
    label: 'table.area',
    render: element => {
      if (element?.area != null && Array.isArray(element?.area)) {
        const joined = element?.area?.map(el => el.name)?.join(', ')
        return <span title={joined}>{joined}</span>
      } else {
        return element?.area?.name
      }
    }
  },
  {
    key: 'status',
    label: 'table.status',
    render: ({ t, ...element }) => (
      <StatusWithColor
        color={mapStatusToColors(
          element.status != null
            ? element.status !== undefined
              ? element.status
              : ''
            : 'unknown'
        )}
      >
        {element.status != null
          ? element.status !== undefined
            ? t(`table.${element.status}`)
            : '-'
          : t('table.unknown')}
      </StatusWithColor>
    )
  },
  {
    key: 'lastSeen',
    label: 'table.last_communication',
    render: element =>
      element.lastSeen
        ? moment(element.lastSeen).format(DATETIME_FORMAT)
        : '-'
  }
]

export default columns

const StatusWithColor = styled.p`
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: ${({ color }) => color || 'var(--black-color)'};
`

const StyledLink = styled.span`
  color: var(--primary-color);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

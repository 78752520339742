import React from 'react'
import styled from 'styled-components'
import { DATETIME_FORMAT } from 'config'
import moment from 'moment'
const columns = [
  {
    key: 'uid',
    label: 'table.uid',
    render: ({ setSpotInDetail, t, ...element }) => (
      <StyledLink onClick={() => setSpotInDetail(element)}>
        {element.uid}
      </StyledLink>
    )
  },
  {
    key: 'spotType',
    label: 'table.spot_type',
    render: ({ t, ...element }) => t(`table.${element.spotType}`)
  },
  {
    key: 'occupied',
    label: 'table.status',
    render: ({ t, ...element }) =>
      element.occupied != null ? (
        <StatusWithColor
          color={
            element.occupied ? 'var(--error-color)' : 'var(--success-color)'
          }
        >
          {t(element.occupied ? 'table.occupied' : 'table.free')}
        </StatusWithColor>
      ) : (
        <StatusWithColor color='var(--warning-color)'>
          {t('table.unknown')}
        </StatusWithColor>
      )
  },
  {
    key: 'lastCommunication',
    label: 'table.last_communication',
    render: element =>
      element.lastCommunication != null
        ? moment(element.lastCommunication).format(DATETIME_FORMAT)
        : '-'
  }

]

export default columns

const StatusWithColor = styled.p`
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: ${({ color }) => color || 'var(--black-color)'};
`

const StyledLink = styled.span`
  color: var(--primary-color);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

// const StyledRouterLink = styled(Link)`
//   width: fit-content;
//   color: var(--primary-color);
//   cursor: pointer;
//   &:hover {
//     text-decoration: underline;
//   }
// `;

// const SensorsWrapper = styled.div`
//   width: fit-content;
//   display: flex;
//   flex-direction: column;
// `;

import React from 'react'
import styled from 'styled-components'
import { DATETIME_FORMAT } from 'config'
import moment from 'moment'

import { getColorsByOccupancy, getBorderColorsByOccupancy } from 'containers/MapContainer/utils'

import ParkingIcon from 'icons/ParkingIcon'
import CarIcon from 'icons/CarIcon'
import DisabledIcon from 'icons/DisabledIcon'
import MotorhomeIcon from 'icons/MotorhomeIcon'
import InfraestruturasPortugalIcon from 'icons/InfraestruturasPortugalIcon'
import NotAllowedIcon from 'icons/NotAllowedIcon'

const getOccupiedValues = occupation => {
  if (!occupation || occupation === '0/0') return '-'
  if (+occupation) return occupation

  const occupiedPlaces = occupation.split('/')[0]
  return +occupiedPlaces
}

export const dashboardColumns = [
  {
    key: 'time',
    label: 'table.time',
    render: (element) =>
      element.updatedAt != null
        ? moment(element.updatedAt).format(DATETIME_FORMAT).split(' ')[1]
        : '-'
  },
  {
    key: 'parkingId',
    label: 'table.area',
    render: (element) =>
      element.parkingId != null
        ? <strong>{element.parkingId}</strong>
        : '-'
  },
  {
    key: 'description',
    label: 'table.description',
    render: (element) =>
      element.description != null
        ? <Description>{element.description}</Description>
        : '-'
  }
]

const columns = (flyToCoordinates) => [
  {
    key: 'name',
    label: '',
    render: (element) =>
      <AreaLineWrapper
        onClick={() =>
          flyToCoordinates(element.geometry, element)}
      >
        <StatusWrapper
          bg={getColorsByOccupancy(element.verboseOccupation)}
          borderColor={getBorderColorsByOccupancy(
            element.verboseOccupation
          )}
        >
          {element.name ? element.name : element.uid}
        </StatusWrapper>
      </AreaLineWrapper>
  },
  {
    key: 'capacity',
    label: 'table.occupation_max',
    icon: <ParkingIcon />,
    render: (element) => element.capacity ? element.capacity : '-'
  },
  {
    key: 'normal',
    label: 'table.normal_places',
    icon: <CarIcon />,
    render: (element) => getOccupiedValues(element.occupation?.normal)
  },
  {
    key: 'handicapped',
    label: 'table.handicapped',
    icon: <DisabledIcon />,
    render: (element) => getOccupiedValues(element.occupation?.handicapped)
  },
  {
    key: 'motorhome',
    label: 'table.motorhomes',
    icon: <MotorhomeIcon />,
    render: (element) => getOccupiedValues(element.occupation?.motorhome)
  },
  {
    key: 'cp',
    label: 'table.cp_label',
    icon: <InfraestruturasPortugalIcon />,
    render: (element) => getOccupiedValues(element.occupation?.cp)
  },
  {
    key: 'forbiddenTransitCount30Min',
    label: 'table.not_allowed',
    icon: <NotAllowedIcon />,
    render: (element) => getOccupiedValues(element.forbiddenTransitCount30Min)
  }
]

export default columns

const AreaLineWrapper = styled.div`
  border-top: 1px solid var(--platinium-gray-color);
  background-color: var(--white-color);
  width: 34px;

  cursor: pointer;
`

const StatusWrapper = styled.div`
  --bg-color: ${({ bg }) => bg || 'var(--primary-color)'};
  --border-color: ${({ borderColor }) =>
    borderColor || 'var(--default-blue-color)'};
  background-color: var(--bg-color);
  border-style: solid;
  border-width: 0.125rem;
  border-radius: 0.25rem;
  border-color: var(--border-color);
  padding: 5px;
  width: 34px;
  text-align: center;
  color: var(--white-color);
`
const Description = styled.p`
white-space: normal;
`

import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg
      id='prefix__aa03ddf9_not_found-f8f2-4819-a4ce-be9b0a220741'
      x={0}
      y={0}
      viewBox='0 0 1119.6 699'
      xmlSpace='preserve'
      {...props}
    >
      <style>
        {
          '.prefix__st0_not_found{fill:#f2f2f2}.prefix__st1_not_found{fill:#2f2e41}.prefix__st2_not_found{fill:#00a4cc}.prefix__st5_not_found{opacity:.1;enable-background:new}.prefix__st6_not_found{fill:#3f3d56}.prefix__st7_not_found{fill:none;stroke:#2f2e41;stroke-width:2;stroke-miterlimit:10}.prefix__st8_not_found,.prefix__st9_not_found{opacity:.4;fill:#00a4cc;enable-background:new}.prefix__st9_not_found{opacity:.8}.prefix__st10_not_found{fill:#fff}'
        }
      </style>
      <circle className='prefix__st0_not_found' cx={292.6} cy={213} r={213} />
      <path
        className='prefix__st1_not_found'
        d='M0 51.1c0 77.5 48.6 140.2 108.7 140.2'
      />
      <path
        className='prefix__st2_not_found'
        d='M108.7 191.4C108.7 113 163 49.6 230 49.6M39.4 58.2c0 73.6 31 133.2 69.3 133.2'
      />
      <path
        className='prefix__st1_not_found'
        d='M108.7 191.4c0-100.1 62.7-181.2 140.2-181.2'
      />
      <path
        d='M85.8 192.3s15.4-.5 20.1-3.8 23.7-7.3 24.9-2 23.2 26.4 5.8 26.5-40.4-2.7-45.1-5.5-5.7-15.2-5.7-15.2z'
        fill='#a8a8a8'
      />
      <path
        d='M136.8 211.3c-17.4.1-40.4-2.7-45.1-5.5-3.5-2.2-4.9-9.9-5.4-13.4h-.5s1 12.4 5.6 15.3 27.7 5.7 45.1 5.5c5 0 6.8-1.8 6.7-4.5-.7 1.6-2.6 2.6-6.4 2.6z'
        opacity={0.2}
      />
      <ellipse
        className='prefix__st2_not_found'
        cx={198.6}
        cy={424.5}
        rx={187}
        ry={25.4}
      />
      <ellipse
        className='prefix__st5_not_found'
        cx={198.6}
        cy={424.5}
        rx={157}
        ry={21.4}
      />
      <ellipse
        className='prefix__st6_not_found'
        cx={836.6}
        cy={660.5}
        rx={283}
        ry={38.5}
      />
      <ellipse
        className='prefix__st6_not_found'
        cx={310.6}
        cy={645.5}
        rx={170}
        ry={23.1}
      />
      <path
        className='prefix__st7_not_found'
        d='M462.6 626c90 23 263-30 282-90M309.6 259s130-36 138 80-107 149-17 172M184 537.3s39.1-10.8 41.5 24c2.4 34.9-32.2 44.8-5.1 51.7'
      />
      <path
        className='prefix__st2_not_found'
        d='M778.7 563.2l-7.9 50.3s-38.8 20.6-11.5 21.2 155.7 0 155.7 0 24.8 0-14.5-21.8l-7.9-52.7-113.9 3z'
      />
      <path
        className='prefix__st5_not_found'
        d='M753.8 634.2c6.2-5.5 17-11.3 17-11.3l7.9-50.3 113.9.1 7.9 49.6c9.2 5.1 14.9 9 18.2 12 5.1-1.2 10.6-5.4-18.2-21.4l-7.9-52.7-113.9 3-7.9 50.3s-32.6 17.3-17 20.7z'
      />
      <path
        className='prefix__st2_not_found'
        d='M596.5 212.7h477.2c10 0 18 8.1 18 18v321.4c0 10-8.1 18-18 18H596.5c-10 0-18-8.1-18-18V230.7c-.1-9.9 8-18 18-18z'
      />
      <path
        className='prefix__st6_not_found'
        d='M595.7 231.8h478.7v267.8H595.7z'
      />
      <circle className='prefix__st0_not_found' cx={835.1} cy={223.3} r={3} />
      <path
        className='prefix__st2_not_found'
        d='M1091.7 520.8v31.3c0 10-8.1 18-18 18H596.5c-10 0-18-8.1-18-18v-31.3h513.2zM969 667.5v6H643v-4.8l.4-1.2 8.1-21.8h310.8zM1094.4 661.5c-.6 2.5-2.8 5.2-7.9 7.8-18.2 9.1-55.1-2.4-55.1-2.4s-28.5-4.8-28.5-17.6c.8-.5 1.6-1 2.5-1.5 7.6-4 33-14 77.9.4 3.3 1 6.3 3 8.5 5.6 1.9 2.2 3.3 4.9 2.6 7.7z'
      />
      <path
        className='prefix__st5_not_found'
        d='M1094.4 661.5c-22.3 8.5-42.1 9.2-62.4-5-10.3-7.1-19.6-8.9-26.6-8.8 7.6-4 33-14 77.9.4 3.3 1 6.3 3 8.5 5.6 1.9 2.3 3.3 5 2.6 7.8z'
      />
      <ellipse
        className='prefix__st0_not_found'
        cx={1066.5}
        cy={654.1}
        rx={7.9}
        ry={2.4}
      />
      <circle
        className='prefix__st0_not_found'
        cx={835.1}
        cy={545.7}
        r={11.5}
      />
      <path
        className='prefix__st5_not_found'
        d='M969 667.4v6.1l-326 .1v-4.9l.4-1.2z'
      />
      <path className='prefix__st1_not_found' d='M108.6 159h208v242h-208z' />
      <path
        className='prefix__st6_not_found'
        d='M87.6 135h250v86h-250zM87.6 237h250v86h-250zM87.6 339h250v86h-250z'
      />
      <path className='prefix__st8_not_found' d='M271.6 150h16v16h-16z' />
      <path className='prefix__st9_not_found' d='M294.6 150h16v16h-16z' />
      <path className='prefix__st2_not_found' d='M317.6 150h16v16h-16z' />
      <path className='prefix__st8_not_found' d='M271.6 251h16v16h-16z' />
      <path className='prefix__st9_not_found' d='M294.6 251h16v16h-16z' />
      <path className='prefix__st2_not_found' d='M317.6 251h16v16h-16z' />
      <path className='prefix__st8_not_found' d='M271.6 352h16v16h-16z' />
      <path className='prefix__st9_not_found' d='M294.6 352h16v16h-16z' />
      <path className='prefix__st2_not_found' d='M317.6 352h16v16h-16z' />
      <circle className='prefix__st1_not_found' cx={316.6} cy={538} r={79} />
      <path
        className='prefix__st1_not_found'
        d='M280.6 600h24v43h-24zM328.6 600h24v43h-24z'
      />
      <ellipse
        className='prefix__st1_not_found'
        cx={300.6}
        cy={643.5}
        rx={20}
        ry={7.5}
      />
      <ellipse
        className='prefix__st1_not_found'
        cx={348.6}
        cy={642.5}
        rx={20}
        ry={7.5}
      />
      <circle className='prefix__st10_not_found' cx={318.6} cy={518} r={27} />
      <circle className='prefix__st6_not_found' cx={318.6} cy={518} r={9} />
      <path
        className='prefix__st2_not_found'
        d='M240 464.5c-6.4-28.6 14-57.4 45.5-64.5s62.3 10.4 68.6 39-14.5 39.1-46.1 46.1-61.6 8-68-20.6z'
      />
      <ellipse
        transform='rotate(-23.176 385.762 510.837)'
        className='prefix__st1_not_found'
        cx={385.8}
        cy={510.8}
        rx={39.5}
        ry={12.4}
      />
      <ellipse
        transform='rotate(-23.176 237.768 563.823)'
        className='prefix__st1_not_found'
        cx={237.8}
        cy={563.8}
        rx={39.5}
        ry={12.4}
      />
      <path
        className='prefix__st10_not_found'
        d='M362.6 561c0 7.7-19.9 23-42 23s-43-14.3-43-22 20.9-6 43-6 42-2.7 42 5z'
      />
    </svg>
  )
}

export default SvgComponent

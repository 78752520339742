
import styled, { css } from 'styled-components'

import TitleStyle from 'styled/TitleStyle'

export const NewAreaWrapper = styled.div`
display: flex;
flex-direction: column;
`

export const SameLineInputs = styled.div`
display: grid;
grid-template-columns: minmax(0, 1fr);

${({ theme }) => theme.smallDesktop`
grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
column-gap: 1rem;
`}
`

export const NewAreaMapAndLabel = styled.div`
margin-bottom: 1.5rem;
font-size: 0.75rem;
${props =>
  props.error &&
  css`
    margin-bottom: calc(1.5rem - 1.66em);
  `}
`
export const NewAreaMapWrapper = styled.div`
width: 100%;
height: 20rem;

${({ theme }) => theme.smallDesktop`
height: 11rem;

`}
`

export const ModalTitle = styled.h2`
${TitleStyle};

margin-bottom: 1.5rem;
`

export const NewAreaMapHelperText = styled.p`
color: var(--primary-color);
font-size: 0.875rem;
font-style: italic;
letter-spacing: 0;
line-height: 1em;

margin-top: 0.25rem;

${props =>
  props.error &&
  css`
    color: var(--error-color);
  `}
`

export const NewAreaButtonWrapper = styled.div`
display: grid;
grid-template-columns: minmax(10rem, 1fr);

${({ theme }) => theme.smallDesktop`
grid-template-columns: minmax(12.375rem, 1fr) minmax(9rem, 1fr) minmax(
    12.375rem,
    1fr
  );
`}
`

export const Blank = styled.div`
grid-column: span 1;
`

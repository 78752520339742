import axios from 'axios'
import mapValues from 'lodash/mapValues'
import ValidationError from 'utils/ValidationError'

import * as Check from 'utils/validations'
import { BASE_API_URL, NOMINATIM_BASE_URL, NOMINATIM_OPTIONS } from 'config'
const DETAILS_URL = BASE_API_URL + '' // without / to better understand on axios
const AVAIALABLESENSORS_URL = BASE_API_URL + '/sensors/without_gateway/'
const AVAIALABLESPOTS_URL = BASE_API_URL + '/spots/'
const NOMINATIM_SEARCH_URL = NOMINATIM_BASE_URL + '/search'

export const types = ['sensor', 'gateway', 'panel', 'repeater']
export const typesUrls = ['sensors', 'gateways', 'panels', 'repeaters']

export const keysToId = ['area']

const VALIDATIONS = {
  sensor: {
    uid: [Check.isRequired],
    factoryUid: [Check.isRequired],
    area: [],
    description: [],
    gateway: [],
    geometry: [Check.isRequired],
    monitoringType: [Check.isRequired],
    name: [],
    number: [],
    magnetometerThreshold: [],
    distanceThreshold: [],
    allowed: [Check.isRequired],
    softwareVersion: [],
    spot: []
  },
  gateway: {
    uid: [Check.isRequired],
    factoryUid: [Check.isRequired],
    geometry: [Check.isRequired],
    area: [],
    name: [],
    networkKey: [Check.isRequired],
    type: [Check.isRequired],
    sensors: []
  },
  panel: {
    uid: [Check.isRequired],
    area: [],
    name: [Check.isRequired],
    lastValue: [Check.isRequired],
    lastSeen: [Check.isRequired],
    geometry: [Check.isRequired]
  },
  repeater: {
    uid: [Check.isRequired],
    area: [Check.isRequired],
    name: [Check.isRequired],
    geometry: [Check.isRequired],
    factoryUid: [Check.isRequired]

  },
  spot: {
    uid: [Check.isRequired],
    sensor: [Check.isRequired],
    spotType: [Check.isRequired],
    geometry: [Check.isRequired]
  }
}

const logic = {
  defaultValues: {
    sensor: {
      uid: { value: null },
      factoryUid: { value: null },
      area: { value: null },
      description: { value: null },
      gateway: { value: null },
      geometry: { value: null },
      monitoringType: { value: null },
      name: { value: null },
      number: { value: null },
      magnetometerThreshold: { value: null },
      distanceThreshold: { value: null },
      allowed: { value: true },
      softwareVersion: { value: null },
      spot: { value: null }
    },
    gateway: {
      uid: { value: null },
      factoryUid: { value: null },
      area: { value: null },
      name: { value: null },
      networkKey: { value: null },
      type: { value: null },
      geometry: { value: null },
      sensors: { value: null }
    },
    panel: {
      uid: { value: null },
      area: { value: [] },
      name: { value: null },
      lastValue: { value: null },
      lastSeen: { value: null },
      geometry: { value: null }
    },
    repeater: {
      uid: { value: null },
      area: { value: null },
      name: { value: null },
      geometry: { value: null },
      factoryUid: { value: null }

    }
  },
  spotDefaultValues: {
    uid: { value: null },
    sensor: { value: null },
    spotType: { value: null },
    geometry: { value: null }
  },
  getHardwareDetails: (id, type) => {
    const typeIndex = types.indexOf(type)
    const typeUrl = typeIndex !== -1 ? typesUrls[typeIndex] : null
    if (typeUrl != null) {
      return Promise.resolve().then(() =>
        axios.get(`${DETAILS_URL}/${typeUrl}/${id}/`)
      )
    }
  },
  fetchAvailableSensors: options =>
    Promise.resolve().then(() => axios.get(AVAIALABLESENSORS_URL, options)),
  fetchAvailableSpots: options =>
    Promise.resolve().then(() => axios.get(AVAIALABLESPOTS_URL, options)),
  updateHardwareDetails: (id, type, form, setForm) =>
    Promise.resolve().then(() => {
      const validation = Check.checkValidation(
        form,
        VALIDATIONS[type] != null ? VALIDATIONS[type] : {}
      )
      if (validation.invalid) {
        setForm({ ...validation.form })
        throw new ValidationError()
      }
      const typeIndex = types.indexOf(type)
      const typeUrl = typeIndex !== -1 ? typesUrls[typeIndex] : null
      if (typeUrl != null) {
        const params = mapValues(form, ({ value }) => value)
        return axios.put(`${DETAILS_URL}/${typeUrl}/${id}/`, { ...params })
      }
    }),
  deleteHardware: (id, type) => {
    const typeIndex = types.indexOf(type)
    const typeUrl = typeIndex !== -1 ? typesUrls[typeIndex] : null
    if (typeUrl != null) {
      return Promise.resolve().then(() =>
        axios.delete(`${DETAILS_URL}/${typeUrl}/${id}/`)
      )
    }
  },
  submitNewSpot: (form, setForm) =>
    Promise.resolve().then(() => {
      const validation = Check.checkValidation(form, VALIDATIONS.spot)
      if (validation.invalid) {
        setForm({ ...validation.form })
        throw new ValidationError()
      }

      const params = mapValues(form, ({ value }) => value)
      return axios.post(AVAIALABLESPOTS_URL, { ...params })
    }),
  fetchLocationOptions: options =>
    Promise.resolve().then(() =>
      axios.get(NOMINATIM_SEARCH_URL, {
        ...options,
        params: {
          ...options.params,
          ...NOMINATIM_OPTIONS
        }
      })
    )
}

export default logic

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import moment from 'moment'
import { DATETIME_FORMAT } from 'config'

export default ({ children, ...props }) => {
  const { element, setInDetail } = props
  const { t } = useTranslation()

  return (
    <Wrapper onClick={() => setInDetail(element)}>
      <Name>{element.id}</Name>
      <Occupancy>{element.occupation}%</Occupancy>
      <Uid>{element.area.name}</Uid>
      <p />

      <Label>{t('table.monitoring_type')}</Label>
      <Value>{t(`table.${element.area.monitoringType}`)}</Value>

      <Label>{t('table.spot_type')}</Label>
      <Value>{t(`table.${element.spotType}`)}</Value>

      <Label>{t('table.unknown_spots')}</Label>
      <Value>{element.unknownSpots}</Value>

      <Label>{t('table.date')}</Label>
      <Value>{moment(element.createdAt).format(DATETIME_FORMAT)}</Value>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: white;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-template-rows: auto auto auto;
  column-gap: 8px;

  border-bottom: 1px solid var(--breadcrumb-gray-color);

  color: var(--text-color);
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1em;
  font-family: var(--primary-font);
`

const Name = styled.h1`
  margin-bottom: 0.25rem;

  font-size: 0.75rem;
  color: var(--breadcrumb-gray-color);
`

const Uid = styled.p`
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
`

const Label = styled.p`
  font-weight: 700;
  font-size: 0.875rem;
`
const Value = styled.p`
  font-size: 0.75rem;
  color: var(--breadcrumb-gray-color);
  text-align: right;
`

const Occupancy = styled.p`
  font-size: 1.5rem;
  grid-column: 2/3;
  grid-row: 1/4;
  text-align: right;
`

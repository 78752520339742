export const checkValidation = (form, validations) => {
  const clone = { ...form }
  let invalid = false

  for (const key in clone) {
    if (Object.prototype.hasOwnProperty.call(clone, key)) {
      const element = clone[key]
      const validationList = validations[key]
      element.valid = true

      if (validationList && validationList.length) {
        for (let index = 0; index < validationList.length; index++) {
          const check = validationList[index]
          const response = check(element.value, form)

          if (response.result === 'end') {
            // put valid true and empty message like default value
            element.valid = true
            element.message = null
            break
          }

          if (!response.result) {
            element.valid = false
            element.message = response.message
            invalid = true
          } else {
            element.valid = true
            element.message = null
          }
        }
      }
    }
  }

  return { invalid, form: clone }
}

export const isValidEmail = (value, state) => {
  const message = 'email'
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!regex.test(value)) {
    return { result: false, message }
  }

  return { result: true }
}

export const isRequired = (value, state) => {
  const message = 'required'

  if (value == null || value === '') {
    return { result: false, message }
  }

  return { result: true }
}

export const isValidWebsite = (value, state) => {
  const message = 'website'
  const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
  if (!regex.test(value)) {
    return { result: false, message }
  }

  return { result: true }
}

export const passwordsMatch = (password, confirmPassword) => {
  const message = 'passwords_match'
  const regex =
    password != null && confirmPassword != null && password === confirmPassword
  if (!regex) {
    return { valid: false, message, value: confirmPassword }
  }

  return { valid: true, value: confirmPassword }
}

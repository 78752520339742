import styled from 'styled-components'

export default styled.div`
  display: grid;
  grid-template-rows: auto;

  max-width: 100%;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1.5rem;
  width: calc(100% - 3rem);
  margin: ${({ margin }) => margin || '0 1.5rem'};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  align-items: ${({ alignItems }) => alignItems};

  height: ${({ height }) => height || 'fit-content'};
  flex-grow: ${({ flexgrow }) => flexgrow || '1'};
  position: ${({ position }) => position};

  ${({ theme }) => theme.smallDesktop`
    overflow: visible;
    max-width: 60rem;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 2rem;
    width: 100%;
    margin: ${({ margin }) => margin || '0 auto'};
    margin-bottom: ${({ marginBottom }) => marginBottom};
  `}
  ${({ theme }) => theme.desktop`
    max-width: 85rem;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 2rem;
    width: 100%;
    margin: ${({ margin }) => margin || '0 auto'};
    margin-bottom: ${({ marginBottom }) => marginBottom};
  `}

  ${({ theme }) => theme.largeDesktop`
    max-width: 109rem;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 2rem;
    width: 100%;
    margin: ${({ margin }) => margin || '0 auto'};
    margin-bottom: ${({ marginBottom }) => marginBottom};
  `}
`

import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  memo
} from 'react'
import styled from 'styled-components'

import { Context as AuthContext } from 'providers/AuthProvider/authProvider'
import { Context as SocketContext } from 'providers/SocketProvider/socketProvider'
import withResponsive from 'providers/ResponsiveHandler/withResponsive'

import MapContainer from 'containers/MapContainer'

import logic from './logic'

import columns, { dashboardColumns } from './columns'

const convertTimeToLocal = timestamp => {
  const TIMESTAMP_TIME = new Date()
  switch (timestamp) {
    case 'today':
      TIMESTAMP_TIME.setHours(0)
      TIMESTAMP_TIME.setMinutes(0)
      break
    case 'last_hour':
      TIMESTAMP_TIME.setHours(TIMESTAMP_TIME.getHours() - 1)
      break
    default:
      TIMESTAMP_TIME.setMinutes(TIMESTAMP_TIME.getMinutes() - 30)
  }
  return TIMESTAMP_TIME.toLocaleString('sv').replace(' ', 'T')
}

const HomePage = ({ children, ...props }) => {
  const { responsiveHandlers } = props
  const { actions } = useContext(AuthContext)
  const { store: wsStore } = useContext(SocketContext)

  const [areaState, setAreaState] = useState({
    areas: [],
    loading: true
  })
  const [alarmsState, setAlarmsState] = useState({
    alarms: [],
    alarmsCount: 0,
    timestamp: logic.timestampOptions[0].value,
    loading: true
  })

  const [pagination, setPagination] = useState({
    page: 1,
    offset: 1,
    limit: 100
  })

  const fetchAreas = useCallback(() => {
    logic
      .getAreas()
      .then(response => {
        if (response.data) {
          const { data } = response
          setAreaState({ areas: data.results, loading: false })
        } else {
          setAreaState({ areas: [], loading: false })
        }
      })
      .catch(err => {
        console.log({ err })
        setAreaState({ areas: [], loading: false })
        if (err == null || err.response == null) {
          actions.serverError()
        }
      })
  }, [actions])

  const fetchAlarmsFiltered = useCallback(() => {
    logic
      .getAlarmsFiltered(
        convertTimeToLocal(alarmsState.timestamp),
        pagination.limit,
        pagination.offset
      )
      .then(response => {
        if (response.data) {
          const { data } = response
          setAlarmsState(prev => ({
            ...prev,
            alarms: data.results,
            alarmsCount: data.count,
            loading: false
          }))
        } else {
          setAlarmsState(prev => ({
            ...prev,
            alarms: [],
            alarmsCount: 0,
            loading: false
          }))
        }
      })
      .catch(err => {
        setAlarmsState(prev => ({
          ...prev,
          alarms: [],
          alarmsCount: 0,
          loading: false
        }))
        if (err == null || err.response == null) {
          actions.serverError()
        }
      })
  }, [actions, alarmsState.timestamp, pagination.offset, pagination.limit])

  const fetchAreaAndUpdate = useCallback(
    id => {
      logic.getAreaDetails(id).then(response => {
        if (response.data) {
          const { data } = response
          const areasCopy = JSON.parse(JSON.stringify(areaState.areas))
          const found = areasCopy.findIndex(el => el.id === id)
          if (found >= 0) {
            areasCopy[found] = data
            setAreaState({
              ...areaState,
              areas: areasCopy
            })
          }
        }
      })
    },
    [areaState]
  )

  const changeTimestamp = useCallback(newTimestamp => {
    setAlarmsState(prev => ({
      ...prev,
      timestamp: newTimestamp
    }))
  }, [])

  const changePagination = useCallback(
    (event, page) =>
      setPagination({
        ...pagination,
        page: page,
        offset: (page - 1) * pagination.limit + 1
      }),
    [pagination, setPagination]
  )

  useEffect(() => {
    fetchAreas()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    fetchAlarmsFiltered()
    // eslint-disable-next-line
  }, [alarmsState.timestamp, pagination.page, pagination.offset])

  useEffect(() => {
    if (wsStore.message != null) {
      handleWSMessages(wsStore.message)
    }
    // eslint-disable-next-line
  }, [wsStore.message])

  const handleWSMessages = useCallback(
    msg => {
      if (
        msg != null &&
        (msg.sender === 'area' || msg.sender === 'areaevent')
      ) {
        if (msg.type === 'new' || msg.type === 'delete') {
          fetchAlarmsFiltered()
          fetchAreas()
        } else if (msg.type === 'update' && msg?.content?.id) {
          fetchAreaAndUpdate(msg?.content?.id)
        } else {
          fetchAlarmsFiltered()
          fetchAreas()
        }
      }
    },
    [fetchAreaAndUpdate, fetchAreas, fetchAlarmsFiltered]
  )

  return (
    <Wrapper>
      <MapContainer
        data={areaState.areas}
        alarms={alarmsState.alarms}
        alarmsCount={alarmsState.alarmsCount}
        pagination={pagination}
        changePagination={changePagination}
        timestamp={alarmsState.timestamp}
        timestampChange={changeTimestamp}
        timestampOptions={logic.timestampOptions}
        columns={columns}
        alarmsColumns={dashboardColumns}
        type='areas'
        hidePopups
        responsiveHandlers={responsiveHandlers}
      />
    </Wrapper>
  )
}

export default memo(withResponsive(HomePage))

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`

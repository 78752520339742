import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
  useMemo,
  memo
} from 'react'
import toast from 'components/StyledToast'

import { Context as AuthContext } from 'providers/AuthProvider/authProvider'
import withResponsive from 'providers/ResponsiveHandler/withResponsive'

import get from 'lodash.get'
import isEqual from 'lodash.isequal'

import { useTranslation } from 'react-i18next'

import StyledTable from 'components/StyledTable'
import StyledSkeleton from 'components/StyledSkeleton'
import StyledPagination from 'components/StyledPagination'
import AreasTemplate from 'components/AreasTemplate'
import StyledMobileTable from 'components/StyledMobileTable'

import logic from './logic'
import CreateNewAreaModal from 'containers/CreateNewAreaModal'

const HardwareList = ({ children, ...props }) => {
  const { actions } = useContext(AuthContext)

  const {
    loading,
    areas,
    areasCount,
    columns,
    monitoringTypeOptions,
    setAreaInDetail,
    open,
    setOpen,
    setTitle,
    pagination,
    setPagination,
    triggerSet,
    responsiveHandlers
  } = props

  const { t } = useTranslation()

  const [form, setForm] = useState(logic.defaultValues)
  const [locationSelected, setLocationSelected] = useState(null)

  const [submitState, setSubmitState] = useState({
    hasSubmitted: false,
    submitting: false,
    apiResponse: null
  })
  const [searchOptions, setSeachOptions] = useState([])
  const searchInputTimeoutRef = useRef(null)
  const [textInput, setTextInput] = useState('')

  useEffect(() => {
    if (searchInputTimeoutRef.current !== null) {
      clearTimeout(searchInputTimeoutRef.current)
    }
    searchInputTimeoutRef.current = setTimeout(() => {
      searchInputTimeoutRef.current = null
      if (logic.fetchLocationOptions) {
        logic
          .fetchLocationOptions({ params: { q: textInput } })
          .then(response => {
            if (response.data) {
              const { data } = response
              setSeachOptions(data)
            } else {
              setSeachOptions([])
            }
          })
          .catch(err => {
            console.log({ err })
            setSeachOptions([])
          })
      }
    }, 500)
  }, [textInput])

  const reset = useCallback(() => {
    setForm(logic.defaultValues)
    setSubmitState({
      hasSubmitted: false,
      submitting: false,
      apiResponse: null
    })
    setTextInput('')
    setSeachOptions([])
    setLocationSelected(null)
  }, [])

  useEffect(() => {
    setAreaInDetail(null)
    reset()
    setTitle('areas.filter_title')
    // eslint-disable-next-line
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
    reset()
  }, [reset, setOpen])

  const checkIfError = useCallback(
    property => {
      const error = get(submitState.apiResponse, property, null)
      if (
        submitState.apiResponse != null &&
        error != null &&
        error[0] != null
      ) {
        return error[0]
      }
      return null
    },
    [submitState.apiResponse]
  )

  const handleChange = useCallback(
    (key, value) => {
      setForm({
        ...form,
        [key]: {
          value: value
        }
      })
    },
    [form]
  )

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      setSubmitState({
        ...submitState,
        submitting: true,
        hasSubmitted: true
      })
      logic
        .submitNewArea(form, form => setForm({ ...form }))
        .then(response => {
          if (response.data) {
            handleClose()
            triggerSet()
            toast.success(t('areas.create_success'))
            setSubmitState(submitState => ({
              ...submitState,
              submitting: false
            }))
          }
        })
        .catch(err => {
          console.log({ err })

          if (err == null || err.response == null) {
            actions.serverError()
            setSubmitState(submitState => ({
              ...submitState,
              submitting: false
            }))
          } else {
            if (err.response.data != null) {
              setSubmitState(submitState => ({
                ...submitState,
                submitting: false,
                apiResponse: err.response.data
              }))
            } else {
              setSubmitState(submitState => ({
                ...submitState,
                submitting: false
              }))
            }
          }
          toast.error(t('areas.create_error'))
        })
    },
    [actions, form, handleClose, submitState, t, triggerSet]
  )

  const handlePaginationChange = useCallback(
    (event, page) => setPagination({ ...pagination, offset: page }),
    [pagination, setPagination]
  )

  const memoizedItems = useMemo(
    () =>
      areas
        .sort((a, b) => (a.uid > b.uid ? -1 : b.uid < a.uid ? 1 : 0))
        .slice(
          (pagination.offset - 1) * pagination.limit,
          (pagination.offset - 1) * pagination.limit + pagination.limit
        )
        .map(el => ({ ...el, setAreaInDetail })),
    [areas, pagination.limit, pagination.offset, setAreaInDetail]
  )

  const memoizedColumns = useMemo(() => {
    return columns.map(el => ({ ...el, label: t(el.label) }))
  }, [columns, t])

  const memoizedLoadingTable = useMemo(
    () =>
      [...Array(6)].map((el, index) => (
        <StyledSkeleton
          key={`table-loading-row-${index}`}
          variant='rect'
          width='100%'
          height='2.5rem'
        />
      )),
    []
  )

  return (
    <>
      {loading && <>{memoizedLoadingTable}</>}
      {!loading && (
        <>
          {areas != null && responsiveHandlers.isDesktop && (
            <StyledTable data={memoizedItems} columns={memoizedColumns} />
          )}
          {areas != null && !responsiveHandlers.isDesktop && (
            <StyledMobileTable
              data={memoizedItems}
              template={AreasTemplate}
              setInDetail={setAreaInDetail}
            />
          )}
          <StyledPagination
            count={Math.ceil(areasCount / pagination.limit)}
            page={pagination.offset}
            onChange={handlePaginationChange}
          />
        </>
      )}
      {open && (
        <CreateNewAreaModal
          open={open}
          handleClose={handleClose}
          form={form}
          handleChange={handleChange}
          checkIfError={checkIfError}
          handleSubmit={handleSubmit}
          monitoringTypeOptions={monitoringTypeOptions}
          submitState={submitState}
          textInput={textInput}
          setTextInput={setTextInput}
          responsiveHandlers={responsiveHandlers}
          searchOptions={searchOptions}
          setSeachOptions={setSeachOptions}
          locationSelected={locationSelected}
          setLocationSelected={setLocationSelected}
        />
      )}
    </>
  )
}

export default memo(withResponsive(HardwareList), isEqual)

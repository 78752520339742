import axios from 'axios'

import { BASE_API_URL } from 'config'
const EVENTS_URL = BASE_API_URL + '/events/'

const logic = {
  getEvents: options => {
    return Promise.resolve().then(() => {
      return axios.get(EVENTS_URL, options)
    })
  },
  getEventDetails: id =>
    Promise.resolve().then(() => {
      return axios.get(EVENTS_URL + `${id}/`)
    })
}

export default logic

import { createGlobalStyle, css } from 'styled-components'
import { normalize } from 'styled-normalize'
import { createMuiTheme } from '@material-ui/core/styles'

const mediaQuery = (...query) => (literals, ...placeholders) => css`
  @media ${css`
      ${query}
    `} {
    ${css(literals, ...placeholders)}
  }
`

export const Theme = {
  breakpoints: {
    tablet: {
      min: 768
    },
    smallDesktop: {
      min: 1220
    },
    desktop: {
      min: 1440
    },
    largeDesktop: {
      min: 1800
    }
  }
}

export const media = {
  tablet: mediaQuery(`(min-width: ${Theme.breakpoints.tablet.min / 16}em)`),

  smallDesktop: mediaQuery(
    `(min-width: ${Theme.breakpoints.smallDesktop.min / 16}em)`
  ),
  desktop: mediaQuery(`(min-width: ${Theme.breakpoints.desktop.min / 16}em)`),
  largeDesktop: mediaQuery(
    `(min-width: ${Theme.breakpoints.largeDesktop.min / 16}em)`
  )
}

export const materialTheme = createMuiTheme({
  typography: {
    fontFamily: 'var(--primary-font)'
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#00A4CC',
      dark: '#006982'
    },
    secondary: {
      main: '#FFFFFF'
    }
  }
})

export default createGlobalStyle`
 ${normalize};
 :root{
    --primary-font: 'PT Sans', 'Roboto', sans-serif;

    --primary-color: #00A4CC;
    --dark-primary-color: #0087A7;
    --darker-primary-color: #006982;
    --platinium-gray-color: #E3E3E3;
    --sonic-silver-color: #777;
    --dark-jungle-green-color: #222720;
    --white-color: #FFF;
    --black-color: #000;
    --breadcrumb-gray-color: #969696;
    --dark-gray-color: #686868;
    --light-gray-color: #CDCDCD;
    --error-page-text-color: #78797d;
    --material-error-color: #f44336;
  --overlay-background-color: #A1EDFF;
    --table-border-color: rgba(34,36,38,0.15);
    --input-border-color: rgba(34,36,38,0.87);
    --table-border: 1px solid var(--table-border-color);


    --default-blue-color: #0056CC;
    --success-color: #70b84c;
    --success-border-color: #2F8405;
    --warning-color: #FCBD16;
    --warning-border-color: #C17100;
    --error-color: #E63C2F;
    --error-border-color: #BA1508;
    --dark-error-border-color: #710D05;
    --dark-error-color: #ac0005;
    --unknown-color: #969696;
    --info-color: #00A4CC;

    --box-shadow-black-half-opacity: rgba(0, 0, 0, 0.05);
    --background-color: var(--white-color);
    --text-color: var(--dark-jungle-green-color);
 }


 html , #root, main{
    height: 100%;
  }
  
  html {
    box-sizing: border-box;
    font-size: 1em;
    overflow-x: hidden;
  }


  *, *:before, *:after {
    box-sizing: inherit;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  ol, ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }

  a {
    color: inherit; 
    text-decoration: inherit; 
  }


  body {
    height: 100%;
    min-height: 100vh;
    position: relative;
    width: 100%;
    font-family: var(--primary-font);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-x: hidden;
    background-color: var(--background-color);
    color: var(--text-color);
  }

  .mapboxgl-map, .mapboxgl-canvas, .mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-drag-pan, .mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-drag-pan .mapboxgl-canvas{
    &:focus{
      outline: none;
    }
  }

  .mapboxgl-popup .mapboxgl-popup-content{
    padding: 0;
  }


  /*!
 * https://github.com/arqex/react-datetime
 */

.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  min-width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-of-type th {
  cursor: pointer;
}
.rdtPicker thead tr:first-of-type th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

${''}

.success-toast {
    color: var(--dark-primary-color);
    min-height: 3.75rem;
    background: var(--white-color)  !important;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25em;
  }

  .error-toast {
    color: var(--dark-primary-color);
    min-height: 3.75rem;
    background: var(--white-color)  !important;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25em;
  }

  .info-toast {
    color: var(--dark-primary-color);
    min-height: 3.75rem;
    background: var(--white-color) !important;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25em;
  }

  .Toastify__toast {
    flex-direction: row;
    font-family: var(--primary-font);
    padding: 0.75rem 1.625rem !important;
    max-width: 20rem;
 
  }

  .Toastify__toast-container {
    width: fit-content !important;
    padding: 1rem !important;
  }


`

import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg viewBox='0 0 24 24' {...props}>
      <path fill={props.fill || 'var(--white-color)'} d='M9 16v-6H5l7-7 7 7h-4v6H9m-4 4v-2h14v2H5z' />
    </svg>
  )
}

export default SvgComponent

import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#prefix__clip0)'>
        <path
          d='M19.213 3.788C17.31 1.99 14.777 1 12.083 1c-2.693 0-5.225.99-7.13 2.788C3.05 5.586 2 7.977 2 10.52s1.049 4.933 2.953 6.731l5.357 5.058a2.566 2.566 0 001.773.691c.671 0 1.3-.245 1.773-.691l5.357-5.058c1.905-1.798 2.954-4.189 2.954-6.731 0-2.543-1.05-4.934-2.954-6.732z'
          fill='#08323B'
        />
        <path
          d='M18.87 4.152h0c1.808 1.707 2.797 3.967 2.797 6.368 0 2.4-.989 4.66-2.797 6.367l.343.364-.343-.364-5.357 5.058.343.364-.343-.364a2.066 2.066 0 01-1.43.555c-.548 0-1.053-.2-1.43-.555l-5.356-5.058h0C3.489 15.181 2.5 12.92 2.5 10.52s.989-4.661 2.797-6.368C7.105 2.444 9.514 1.5 12.083 1.5c2.57 0 4.978.944 6.787 2.652z'
          stroke='#fff'
          strokeOpacity={0.1}
        />
        <path
          d='M14.972 8.198l-.49-1.637a.87.87 0 00-.839-.623h-3.286a.87.87 0 00-.838.623l-.491 1.637A.876.876 0 008.5 9v1.75c0 .242.196.438.438.438v.437c0 .242.195.438.437.438h.438a.438.438 0 00.437-.438v-.438h3.5v.438c0 .242.196.438.438.438h.437a.438.438 0 00.438-.438v-.438a.438.438 0 00.437-.437V9a.876.876 0 00-.528-.802zm-4.615-1.386h3.286l.394 1.313H9.963l.394-1.313zm-.107 3.282a.438.438 0 110-.875.438.438 0 010 .875zm3.5 0a.438.438 0 110-.875.438.438 0 010 .875z'
          fill='#fff'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.167 14.5c0-.497.374-.901.833-.901.46 0 .833.404.833.901s-.374.901-.833.901c-.46 0-.833-.404-.833-.901zm.833.3c-.153 0-.278-.134-.278-.3 0-.166.125-.3.278-.3.153 0 .278.134.278.3 0 .166-.125.3-.278.3zM9 14.5c0 .722.26 1.401.732 1.912a.263.263 0 00.392 0 .318.318 0 000-.425 2.18 2.18 0 01-.569-1.487c0-.562.202-1.09.57-1.487a.318.318 0 000-.425.263.263 0 00-.393 0A2.802 2.802 0 009 14.5zm4.876 1.487a2.18 2.18 0 00.569-1.487 2.18 2.18 0 00-.57-1.487.318.318 0 010-.425.263.263 0 01.393 0c.472.51.732 1.19.732 1.912s-.26 1.401-.732 1.912a.263.263 0 01-.392 0 .318.318 0 010-.425zm-2.929-.212a.263.263 0 01-.392 0 1.868 1.868 0 01-.488-1.275c0-.482.173-.934.488-1.275a.263.263 0 01.392 0 .318.318 0 010 .425c-.21.227-.325.529-.325.85 0 .321.115.623.325.85a.318.318 0 010 .425zm2.106-.425c.21-.227.325-.529.325-.85 0-.321-.115-.623-.325-.85a.318.318 0 010-.425.263.263 0 01.392 0c.315.34.488.793.488 1.275 0 .482-.173.934-.488 1.275a.263.263 0 01-.392 0 .318.318 0 010-.425z'
          fill='#fff'
        />
        <g filter='url(#prefix__filter0_d)'>
          <circle cx={20} cy={4} r={3} fill='#969696' />
          <circle cx={20} cy={4} r={2.5} stroke='#fff' strokeOpacity={0.3} />
        </g>
      </g>
      <defs>
        <clipPath id='prefix__clip0'>
          <path fill='#fff' d='M0 0h24v24H0z' />
        </clipPath>
        <filter
          id='prefix__filter0_d'
          x={13}
          y={-3}
          width={14}
          height={14}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent

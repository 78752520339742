import React, { Component } from 'react'
import logic from './authLogic'
import {
  setAuthorizationInterceptor,
  setResponseInterceptor,
  setLanguageInterceptor
} from 'interceptors'
import { withRouter, matchPath } from 'react-router'

import Routes from 'routes'

export const Context = React.createContext()

class AuthProvider extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoggedIn: logic.isLoggedIn(),
      user: null
    }
  }

  serverError = () => {
    this.props.history.push('/error/')
  }

  checkUserAuth = payload => {
    logic
      .checkUserAuth()
      .then(response => {
        if (response.data) {
          this.onLogin(response.data)
          if (payload != null && payload.config != null) {
          }
        }
      })
      .catch(err => {
        console.log({ err })
        this.onLogout()
        if (err == null || err.response == null) {
          this.serverError()
        }
      })
  }

  componentDidMount () {
    setLanguageInterceptor('pt-PT')
    const match = matchPath(this.props.location.pathname, '/login/')
    if (logic.isLoggedIn()) {
      const token = window.localStorage.getItem('access_token')
      setAuthorizationInterceptor('access_token', token)
      this.retrieveUser()
      setResponseInterceptor(
        this.props.history,
        this.checkUserAuth,
        this.serverError
      )
      if (match != null && match.isExact === true) {
        this.props.history.push('/')
      }
    } else {
      this.checkUserAuth()
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const match = matchPath(this.props.location.pathname, '/login/')
    const prevMatch = matchPath(prevProps.location.pathname, '/login/')

    if (
      prevState.isLoggedIn !== this.state.isLoggedIn &&
      this.state.isLoggedIn &&
      prevMatch != null &&
      prevMatch.isExact === true
    ) {
      this.retrieveUser()
      setResponseInterceptor(
        this.props.history,
        this.checkUserAuth,
        this.serverError
      )
      this.props.history.push('/')
    }

    if (
      prevState.isLoggedIn !== this.state.isLoggedIn &&
      !this.state.isLoggedIn &&
      match == null
    ) {
      this.onLogout()
    }
  }

  get actions () {
    return {
      login: this.onLogin,
      logout: this.onLogout,
      retrieveUser: this.retrieveUser,
      serverError: this.serverError
    }
  }

  onLogin = data => {
    logic.login(data)
    this.setState({ isLoggedIn: true })
  }

  onLogout = e => {
    this.setState({ user: null, isLoggedIn: false })
    logic.logout()
    const privateRoutes =
      Routes != null ? Routes.map(el => el.private === true) : []
    const redirect = privateRoutes.some(el =>
      matchPath(this.props.location.pathname, el.path)
    )
    if (redirect) {
      this.props.history.push('/login/')
    }
  }

  retrieveUser = () => {
    logic
      .retrieveUser()
      .then(response => {
        this.setState({ user: response.data })
      })
      .catch(err => {
        console.log({ err })
        this.onLogout()
        if (err == null || err.response == null) {
          this.serverError()
        }
      })
  }

  render () {
    const { children } = this.props
    return (
      <Context.Provider
        value={{
          store: this.state,
          actions: this.actions
        }}
      >
        {children}
      </Context.Provider>
    )
  }
}

export default withRouter(AuthProvider)

import React from 'react'
import styled from 'styled-components'

import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'

import CloseIcon from 'icons/CloseIcon'

export default ({ children, ...props }) => (
  <Wrapper {...props}>
    <Fade in={props.open}>
      <ModalWrapper>
        <CloseWrapper onClick={props.onClose}>
          <CloseIcon />
        </CloseWrapper>
        {children}
      </ModalWrapper>
    </Fade>
  </Wrapper>
)
const Wrapper = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
`

const ModalWrapper = styled.div`
  background-color: var(--white-color);
  outline: none;
  width: 100%;
  position: relative;
  max-width: calc(100% - 2rem);
  padding: 1.5rem;
  max-height: calc(100% - 4rem);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  ${({ theme }) => theme.smallDesktop`
  max-width: 38.75rem;
  padding: 2.5rem;
`}
`

const CloseWrapper = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  z-index: 100;

  ${({ theme }) => theme.smallDesktop`

  top: 2.5rem;
  right: 2.5rem;
`}
`

import axios from 'axios'

import {
  BASE_API_URL,
  WS_BASE_URL,
  WS_JOIN_GROUP,
  NOMINATIM_BASE_URL,
  NOMINATIM_OPTIONS
} from 'config'
const SPOTS_URL = BASE_API_URL + '/spots/'
const DETAILS_URL = BASE_API_URL + '/spots' // without / to better understand on axios
const AREASEARCH_URL = BASE_API_URL + '/areas/search/'
const SENSORSEARCH_URL = BASE_API_URL + '/sensors/search/'
const SPOTSSEARCH_URL = BASE_API_URL + '/spots/search/'
const CSV_URL = BASE_API_URL + '/spots/csv/'

const NOMINATIM_SEARCH_URL = NOMINATIM_BASE_URL + '/search'

const logic = {
  getSpots: options => {
    return Promise.resolve().then(() => {
      return axios.get(SPOTS_URL, options)
    })
  },
  fetchAreasOptions: options => {
    return Promise.resolve().then(() => axios.get(AREASEARCH_URL, options))
  },
  fetchSensorsOptions: options => {
    return Promise.resolve().then(() => axios.get(SENSORSEARCH_URL, options))
  },
  fetchUidOptions: options => {
    return Promise.resolve().then(() => axios.get(SPOTSSEARCH_URL, options))
  },
  createWebSocketConnection () {
    return new Promise((resolve, reject) => {
      const actualToken = window.localStorage.getItem('access_token')
      if (actualToken != null) {
        const socket = new window.WebSocket(
          `${WS_BASE_URL}?token=${actualToken}`
        )

        socket.onopen = function () {
          socket.send(JSON.stringify({ command: 'join', group: WS_JOIN_GROUP }))
          console.info(
            `%c🔌 WebSocket: joined #${WS_JOIN_GROUP}`,
            'color:green'
          )
          resolve(socket)
        }

        socket.onerror = function (evt) {
          reject(new Error(evt))
        }
      } else {
        reject(new Error('403'))
      }
    })
  },
  fetchLocationOptions: options =>
    Promise.resolve().then(() =>
      axios.get(NOMINATIM_SEARCH_URL, {
        ...options,
        params: {
          ...options.params,
          ...NOMINATIM_OPTIONS
        }
      })
    ),
  uploadCSV: options =>
    Promise.resolve().then(() =>
      axios.post(CSV_URL, options, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    ),
  getSpotDetails: id =>
    Promise.resolve().then(() => {
      return axios.get(`${DETAILS_URL}/${id}/`)
    })
}

export default logic

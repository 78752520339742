import React from 'react'
import styled, { css } from 'styled-components'

export default ({ children, ...props }) => (
  <Wrapper {...props} isActive={props.open}>
    <HamburgerBox isActive={props.open}>
      <HamburgerInner isActive={props.open} />
    </HamburgerBox>
  </Wrapper>
)

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  height: 1rem;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  margin-left: 1rem;
  overflow: visible;

  &:focus, &:active {
    -webkit-tap-highlight-color: transparent;
  }
`

const HamburgerBox = styled.div`
  width: 1.5rem;
  height: 2rem;
  display: inline-block;
  position: relative;
`

const HamburgerInner = styled.div`
  display: block;
  top: 50%;
  margin: auto;
  &,
  &::before,
  &::after {
    width: 1.75rem;
    height: 0.2rem;
    background-color: var(--white-color);
    border-radius: 0.25rem;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  &::before,
  &::after {
    content: '';
    display: block;
  }
  &::before {
    top: -0.5rem;
  }
  &::after {
    bottom: -0.5rem;
  }

  ${props =>
    props.isActive &&
    css`
      &,
      &::before,
      &::after {
        background-color: var(--white-color);
      }

      & {
        transform: rotate(225deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
      }
      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease-out,
          transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    `}

  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  ${'' /* ${Wrapper} &::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
  }
  ${Wrapper} &::after {
    transition: bottom 0.1s 0.25s ease-in,
      transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  } */}
`

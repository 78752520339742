import axios from 'axios'
import mapValues from 'lodash/mapValues'
import ValidationError from 'utils/ValidationError'

import * as Check from 'utils/validations'
import { BASE_API_URL } from 'config'
const RECOVER_URL = BASE_API_URL + '/request-password-reset/'

const VALIDATIONS = {
  email: [Check.isRequired, Check.isValidEmail]
}

const logic = {
  defaultValues: {
    email: { value: '' }
  },
  recover: (form, setForm) => {
    return Promise.resolve().then(() => {
      const validation = Check.checkValidation(form, VALIDATIONS)
      if (validation.invalid) {
        setForm({ ...validation.form })
        throw new ValidationError()
      }
      setForm({ ...validation.form })

      const params = mapValues(form, ({ value }) => value)
      return axios.post(RECOVER_URL, { ...params })
    })
  }
}

export default logic


import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import PortugalIcon from 'icons/PortugalIcon'
import UKIcon from 'icons/UKIcon'

export default ({ children, ...props }) => {
  const { i18n } = useTranslation()
  const [isVisible, setIsVisible] = useState(false)

  const onPtFlagClick = () => {
    i18n.changeLanguage('pt')
  }
  const onEnFlagClick = () => {
    i18n.changeLanguage('en')
  }

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [isVisible])

  const renderFlags = () => {
    if (i18n.language === 'pt') {
      return (
        <>
          <SwitchButton onClick={onPtFlagClick}>
            <PortugalIcon height={27} />
          </SwitchButton>
          <SwitchButton onClick={onEnFlagClick}>
            <UKIcon height={27} />
          </SwitchButton>
        </>
      )
    } else {
      return (
        <>
          <SwitchButton onClick={onEnFlagClick}>
            <UKIcon height={27} />
          </SwitchButton>
          <SwitchButton onClick={onPtFlagClick}>
            <PortugalIcon height={27} />
          </SwitchButton>
        </>
      )
    }
  }
  return (
    <IconWrapper onClick={() => setIsVisible(!isVisible)}>
      {i18n.language === 'pt' && <PortugalIcon height={27} width={27} />}
      {i18n.language === 'en' && <UKIcon height={27} width={27} />}
      <BackgroundOverlay isOpen={isVisible} />
      <Switch isVisible={isVisible}>
        <SwitchContent isOpen={isVisible}>{renderFlags()}</SwitchContent>
      </Switch>
    </IconWrapper>
  )
}

export const Switch = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 150;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};

  transition: all 0.2s;

  height: 100px;
`

export const SwitchContent = styled.div`
  height: ${({ isOpen }) => (isOpen ? 'auto' : '60px')};
  overflow: hidden;
  z-index: 10;

  display: flex;
  flex-direction: column;

  padding: 8px;
  border-radius: 40px;

  background-color: ${({ theme, isOpen }) =>
    isOpen ? 'var(--white-color)' : 'transparent'};
  box-shadow: ${({ isOpen }) =>
    isOpen ? '0 20px 24px 0 rgba(0, 0, 0, 0.1)' : 'none'};

  transition: all 200ms ease-in;
`

export const SwitchButton = styled.div`
  display: block;
  cursor: pointer;

  & > svg {
    display: block;
  }

  &:not(:first-child) {
    &::before {
      content: "";
      display: block;

      max-width: 100%;
      height: 1px;
      margin: 12px 4px;

      background: ${({ theme }) => theme.inactiveColor};
      opacity: 0.2;
    }
  }
`

export const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(17, 17, 17, 0.2);
  z-index: 30;

  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};

  transition: all 100ms ease-in;
`

const IconWrapper = styled.div`
  cursor: pointer;
  margin-left: 1rem;

  height: 2rem;
  width: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
`

import React, {
  createContext,
  useEffect,
  useContext,
  useRef,
  useState
} from 'react'
import logic from './socketLogic'
import { camelizeKeys } from 'humps'

import { Context as AuthContext } from 'providers/AuthProvider/authProvider'

export const Context = createContext()

const SocketProvider = ({ children, ...props }) => {
  const { store } = useContext(AuthContext)
  const [message, setMessage] = useState()

  const socket = useRef(null)
  const timeout = useRef(5000)
  const waiting = useRef(null)

  const setSocket = newSocket => {
    socket.current = newSocket
  }

  const connectToWSS = () => {
    if (socket.current == null && waiting.current == null && store.isLoggedIn) {
      logic
        .createWebSocketConnection({ setSocket })
        .then(ws => {
          setSocket(ws)

          ws.onmessage = e => {
            const obj = camelizeKeys(JSON.parse(e.data))
            // setMessage(prev => [...prev, obj] );
            setMessage(obj)
          }

          timeout.current = 5000
        })
        .catch(err => {
          setSocket(null)
          console.log('WSS ERROR: ', { err })
          console.info(
            `%c🔌 Trying to connect. Timout at ${timeout.current}.`,
            'color:red'
          )
          waiting.current = setTimeout(() => {
            waiting.current = null
            console.info('%c🔌 Reconnecting...', 'color:red')
            connectToWSS()
            timeout.current = timeout.current + 5000
          }, timeout.current)
        })
    }
  }

  useEffect(() => {
    if (store.isLoggedIn) {
      connectToWSS()
    }
    // eslint-disable-next-line
  }, [store.isLoggedIn])

  return (
    <Context.Provider
      value={{
        store: { socket: socket.current, message },
        actions: { setSocket, connectToWSS }
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default SocketProvider

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DATETIME_FORMAT } from 'config'
import moment from 'moment'

import { mapStatusToColors } from 'scenes/Hardware/columns'

export default ({ children, ...props }) => {
  const { element, setInDetail } = props
  const { t } = useTranslation()
  return (
    <Wrapper onClick={() => setInDetail(element)}>
      <Name>
        {t(`table.${element.deviceType}`)}{' '}
        {element.deviceType != null && element.deviceType !== 'panel'
          ? element.monitoringType != null &&
            ` - ${t(`table.${element.monitoringType}`)}`
          : ''}
      </Name>
      <p />
      <Uid>{element.uid}</Uid>

      <StatusWithColor
        color={mapStatusToColors(
          element.status != null ? element.status : 'unknown'
        )}
      >
        {element.status != null
          ? t(`table.${element.status}`)
          : t('table.unknown')}
      </StatusWithColor>
      <Label>{t('table.area')}</Label>
      <Value>
        {element.area != null && element.area.name != null
          ? element.area.name
          : '-'}
      </Value>
      <Label>{t('table.last_communication')}</Label>
      <Value>
        {element.lastSeen != null
          ? moment(element.lastSeen).format(DATETIME_FORMAT)
          : '-'}
      </Value>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  background: white;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  grid-template-rows: auto auto auto;
  column-gap: 8px;

  border-bottom: 1px solid var(--breadcrumb-gray-color);

  color: var(--text-color);
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1em;
  font-family: var(--primary-font);
`

const StatusWithColor = styled.p`
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-align: right;
  color: ${({ color }) => color || 'var(--black-color)'};

  grid-column: 2/3;
  grid-row: 1/4;
`

const Uid = styled.p`
  font-size: 1.5rem;
  color: var(--primary-color);
  line-height: 1.2em;
  margin-bottom: 1rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Name = styled.h1`
  margin-bottom: 0.25rem;

  font-size: 0.75rem;
  color: var(--breadcrumb-gray-color);
`

const Label = styled.p`
  font-weight: 700;
  font-size: 0.875rem;
`

const Value = styled.p`
  font-size: 0.75rem;
  color: var(--breadcrumb-gray-color);
  text-align: right;
`

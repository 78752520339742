import React from 'react'
import styled from 'styled-components'

import UbiwhereLogo from 'images/ubiwhere_logo.png'
import UrbsenseLogo from 'images/urbsense_black_logo.png'

export default ({ children, ...props }) => (
  <Wrapper {...props}>
    <ImageLogo src={UbiwhereLogo} />
    <ImageLogo src={UrbsenseLogo} />
  </Wrapper>
)

const Wrapper = styled.div`
  /* position: fixed;
  bottom: 0;
  right: 0; */
  margin-top: auto;
  margin-left: 5rem;
  padding: 1rem 3rem 1rem;
  width: calc(100% - 5rem);
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ImageLogo = styled.img`
  height: 2.5rem;
  object-fit: contain;

  & + & {
    margin-left: 2rem;
  }
`

import React from 'react'
import styled from 'styled-components'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

export default ({ children, ...props }) => (
  <Wrapper {...props}>
    <Title>{props.summary}</Title>
    <Body>{props.details}</Body>
  </Wrapper>
)

const Wrapper = styled(Accordion)``

const Title = styled(AccordionSummary)`
  &.MuiAccordionSummary-root,
  &.MuiAccordionSummary-root.Mui-expanded {
    padding: 0;
    min-height: 100%;
  }

  & .MuiAccordionSummary-content,
  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
`

const Body = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0;
  }
`

import axios from 'axios'
import mapValues from 'lodash/mapValues'
import ValidationError from 'utils/ValidationError'

import * as Check from 'utils/validations'
import { BASE_API_URL } from 'config'
const SET_PASSWORD_URL = BASE_API_URL + '/password-reset-confirm/'

const VALIDATIONS = {
  password: [Check.isRequired],
  passwordConfirm: [Check.isRequired],
  token: []
}

const logic = {
  defaultValues: {
    password: { value: '' },
    passwordConfirm: { value: '' },
    token: { value: null }
  },
  setPassword: (form, setForm) => {
    return Promise.resolve().then(() => {
      const validation = Check.checkValidation(form, VALIDATIONS)
      const passwordValidation = Check.passwordsMatch(
        form.password.value,
        form.passwordConfirm.value
      )
      if (validation.invalid || !passwordValidation.valid) {
        setForm({
          ...validation.form,
          passwordConfirm: {
            ...validation.form.passwordConfirm,
            ...passwordValidation
          }
        })
        throw new ValidationError()
      }
      setForm({ ...validation.form })

      const params = mapValues(form, ({ value }) => value)
      return axios.post(SET_PASSWORD_URL, { ...params })
    })
  }
}

export default logic

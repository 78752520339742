import React, { useState, useEffect, useContext, useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useQuery from 'utils/useQuery'

import { Context as AuthContext } from 'providers/AuthProvider/authProvider'

import TitleStyle from 'styled/TitleStyle'

import Container from 'components/Container'
import StyledButton from 'components/StyledButton'

import InputAndLabel from 'containers/InputAndLabel'

import Background from 'images/background_smart_parking.jpg'
import ErrorIcon from 'icons/ErrorIcon'
import SuccessIcon from 'icons/SuccessIcon'

import logic from './logic'

const AccountSuccess = ({ children, ...props }) => {
  const { actions } = useContext(AuthContext)

  const { t } = useTranslation()
  const query = useQuery()
  const token = query.get('token')
  const [form, setForm] = useState(logic.defaultValues)
  const [submitState, setSubmitState] = useState({
    hasSubmitted: false,
    submitting: false,
    error: null,
    success: false
  })

  useEffect(() => {
    if (token != null) {
      handleChange('token', token)
    }
    // eslint-disable-next-line
  }, [])

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      // juntar estes states
      setSubmitState(submitState => ({
        ...submitState,
        error: null,
        hasSubmitted: true,
        submitting: true
      }))
      logic
        .setPassword(form, form => setForm({ ...form }))
        .then(response => {
          setSubmitState(submitState => ({
            ...submitState,
            success: true,
            submitting: false
          }))
        })
        .catch(err => {
          console.log({ err })

          if (err == null || err.response == null) {
            actions.serverError()
          }
          if (err.response != null && err.response.data != null) {
            setSubmitState(submitState => ({
              ...submitState,
              submitting: false,
              error: true
            }))
          } else {
            setSubmitState(submitState => ({
              ...submitState,
              submitting: false
            }))
          }
        })
    },
    [actions, form]
  )

  const handleChange = useCallback(
    (key, value) => {
      setForm({
        ...form,
        [key]: {
          ...form[key],
          value: value
        }
      })
    },
    [form]
  )

  const memoHandleChangeValue = useCallback(
    name => e => {
      handleChange(name, e.target.value)
    },
    [handleChange]
  )

  const buildBody = useCallback(() => {
    if (token != null) {
      if (form != null && !submitState.success && !submitState.error) {
        return (
          <StyledForm onSubmit={handleSubmit}>
            <TitleWrapper>
              <Title>{t('set_password.title')}</Title>
            </TitleWrapper>
            <InputWrapper>
              <InputAndLabel
                type='password'
                value={form?.password?.value ?? ''}
                name='password'
                label={t('password')}
                onChange={memoHandleChangeValue('password')}
                error={
                  submitState.hasSubmitted && form?.password?.message != null
                }
                helperText={
                  submitState.hasSubmitted && form?.password?.message != null
                    ? t(`errors.${form?.password?.message}`)
                    : ''
                }
                autoComplete='new-password'
                minLength={1}
              />
              <InputAndLabel
                type='password'
                value={form?.passwordConfirm?.value ?? ''}
                name='password'
                label={t('set_password.confirmation_label')}
                onChange={memoHandleChangeValue('passwordConfirm')}
                error={
                  submitState.hasSubmitted &&
                  form?.passwordConfirm?.message != null
                }
                helperText={
                  submitState.hasSubmitted &&
                  form?.passwordConfirm?.message != null
                    ? t(`errors.${form.passwordConfirm.message}`)
                    : ''
                }
                autoComplete='new-password'
                minLength={1}
              />
            </InputWrapper>
            <ButtonWrapper>
              <StyledButton type='primary' disabled={submitState.submitting}>
                {t('set_password.title')}
              </StyledButton>
            </ButtonWrapper>
          </StyledForm>
        )
      } else if (submitState.success && !submitState.error) {
        return (
          <SuccessWrapper>
            <SuccessIcon height={80} style={{ margin: '0 auto' }} />
            <SuccessMessage>{t('set_password.success_text')}</SuccessMessage>
            <GoBackLink to='/login/'>{t('back_main_page')}</GoBackLink>
          </SuccessWrapper>
        )
      }
    }
    return (
      <SuccessWrapper>
        <ErrorIcon height={80} style={{ margin: '0 auto' }} />
        <SuccessMessage>{t('set_password.error_text')}</SuccessMessage>
        <GoBackLink to='/recover/'>{t('set_password.try_again')}</GoBackLink>
      </SuccessWrapper>
    )
  }, [form, handleSubmit, submitState, t, token, memoHandleChangeValue])

  return (
    <Wrapper>
      <Overlay />
      <BackgroundImage
        src={Background}
        alt='parking image'
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: -10,
          left: 0,
          top: 0,
          objectFit: 'cover',
          opacity: 0.3
        }}
        loading='eager'
      />
      <Container height='100%'>{buildBody()}</Container>
    </Wrapper>
  )
}

export default AccountSuccess

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  height: ${({ height }) => height};
  position: relative;
  overflow: visible;

  background-color: var(--backgroud-color);
`

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -10;
  left: 0;
  top: 0;
  object-fit: cover;
  opacity: 0.3;
  background: ${({ src }) => `url(${src}) center / cover`};
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  opacity: 0.25;
  background-color: var(--overlay-background-color);
`

const Title = styled.h1`
  ${TitleStyle}
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 2rem;
`

const StyledForm = styled.form`
  grid-column: 1/5;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;

  padding: 2rem;
  margin: auto 0;

  border: 1px solid var(--platinium-gray-color);
  box-shadow: 0 0 0.375rem 0 var(--box-shadow-black-half-opacity);

  ${({ theme }) => theme.smallDesktop`
    grid-column: 5/9;

  `}
`

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  display: grid;
  grid-template-columns: minmax(12.375rem, 1fr);
`

const GoBackLink = styled(Link)`
  width: fit-content;

  margin: 1rem auto;
  font-size: 0.875rem;
  text-decoration: underline;
  color: var(--primary-color);
  transition: opacity 0.3s ease-in-out;

  &:hover {
    transition: opacity 0.3s ease-in-out;
    opacity: 0.5;
  }
`

const SuccessWrapper = styled.div`
  grid-column: 1/5;

  background-color: var(--white-color);
  display: flex;
  flex-direction: column;

  padding: 2rem;
  margin: auto 0;

  border: 1px solid var(--platinium-gray-color);
  box-shadow: 0 0 0.375rem 0 var(--box-shadow-black-half-opacity);
  ${({ theme }) => theme.smallDesktop`
  grid-column: 5/9;
  
  `}
`

const SuccessMessage = styled.p`
  font-size: 1rem;
  text-align: center;
  margin: 1.5rem auto;
  line-height: 1.25rem;
`

import axios from 'axios'
import mapValues from 'lodash/mapValues'
import ValidationError from 'utils/ValidationError'

import * as Check from 'utils/validations'
import { BASE_API_URL } from 'config'
const DETAILS_URL = BASE_API_URL + '/spots' // without / to better understand on axios
const AVAILABLESENSORS_URL = BASE_API_URL + '/sensors/'

const VALIDATIONS = {
  uid: [Check.isRequired],
  sensor: [Check.isRequired],
  spotType: [Check.isRequired],
  geometry: [Check.isRequired]
}

const logic = {
  defaultValues: {
    uid: { value: null },
    sensor: { value: null },
    spotType: { value: null },
    geometry: { value: null }
  },
  getSpotDetails: id =>
    Promise.resolve().then(() => {
      return axios.get(`${DETAILS_URL}/${id}/`)
    }),
  updateSpotDetails: (id, form, setForm) =>
    Promise.resolve().then(() => {
      const validation = Check.checkValidation(form, VALIDATIONS)
      if (validation.invalid) {
        setForm({ ...validation.form })
        throw new ValidationError()
      }

      const params = mapValues(form, ({ value }) => value)
      return axios.put(`${DETAILS_URL}/${id}/`, { ...params })
    }),
  deleteSpot: id =>
    Promise.resolve().then(() => axios.delete(`${DETAILS_URL}/${id}/`)),
  fetchAvailableSensors: () =>
    Promise.resolve().then(() => axios.get(AVAILABLESENSORS_URL))
}
export default logic

import React from 'react'
import styled, { css } from 'styled-components'

import TextField from '@material-ui/core/TextField'
import MaskedInput from 'components/MaskedInput'
import NumberInput from 'components/NumberInput'

export default ({ children, ...props }) => {
  const {
    masked,
    InputLabelProps,
    fullWidth,
    number,
    helperText,
    isEditing,
    ...other
  } = props
  if (masked) {
    return <MaskedInput {...other} border={1} unmask='typed' />
  } else if (number) {
    return (
      <Wrapper
        variant='outlined'
        size='small'
        InputLabelProps={InputLabelProps}
        fullWidth={fullWidth}
        helperText={helperText}
        {...other}
        InputProps={{
          inputComponent: NumberInput,
          inputProps: {
            ...other,
            decimalScale: 0,
            allowNegative: false,
            allowEmptyFormatting: true,
            isAllowed: values => {
              const { floatValue } = values
              if (floatValue != null && floatValue !== '') {
                if (props.min != null && props.max != null) {
                  return floatValue >= props.min && floatValue <= props.max
                } else if (props.max != null) {
                  return floatValue <= props.max
                } else if (props.min != null) {
                  return floatValue >= props.min
                } else {
                  return true
                }
              }
              return true
            }
          }
        }}
      />
    )
  }
  return (
    <Wrapper
      variant='outlined'
      size='small'
      InputLabelProps={InputLabelProps}
      fullWidth={fullWidth}
      helperText={helperText}
      {...other}
    />
  )
}

const Wrapper = styled(TextField)`
  & .MuiOutlinedInput-notchedOutline {
    border-color: var(--platinium-gray-color);
    border-radius: 0;
  }

  & .MuiFormLabel-root {
    color: var(--platinium-gray-color);
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1rem;
    text-transform: uppercase;

    ${'' /* width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */}
  }
  & .MuiInputLabel-outlined.MuiInputLabel-shrink {
    text-transform: none;
    &:first-letter{
      text-transform: capitalize;
    }
  }

  & .MuiInputBase-input {
    color: var(--breadcrumb-gray-color);
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1em;
  }

  & .MuiInputBase-root {
    background-color: white;
  }

  & .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: var(--platinium-gray-color);
  }

  & .MuiFormHelperText-contained {
    margin: 0;
  }


  ${props =>
    props.isEditing &&
    css`
      & .MuiInputBase-input.Mui-disabled {
        color: var(--platinium-gray-color);
        cursor: not-allowed;
      }
    `}
`

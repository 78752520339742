import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg
      height={24}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <defs>
        <path
          d='M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zM8 15l-5-5 1.41-1.41L8 12.17l7.59-7.59L17 6l-9 9z'
          id='prefix__a__success'
        />
      </defs>
      <use
        fill={props.fill || '#00BF70'}
        fillRule='nonzero'
        xlinkHref='#prefix__a__success'
        transform='translate(2 2)'
      />
    </svg>
  )
}

export default SvgComponent

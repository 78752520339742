export const percentages = [
  {
    color: 'var(--dark-error-color)',
    borderColor: 'var(--dark-error-border-color)',
    text: 'maxed_out'
  },
  {
    color: 'var(--error-color)',
    borderColor: 'var(--error-border-color)',
    text: 'full'
  },
  {
    color: 'var(--warning-color)',
    borderColor: 'var(--warning-border-color)',
    text: 'high'
  },
  {
    color: 'var(--success-color)',
    borderColor: 'var(--success-border-color)',
    text: 'medium'
  },
  {
    color: 'var(--info-color)',
    borderColor: 'var(--default-blue-color)',
    text: 'low'
  }
]

export const getBorderColorsByOccupancy = occupancy => {
  switch (occupancy) {
    case 'maxed_out':
      return '#710D05'
    case 'full':
      return '#BA1508'
    case 'high':
      return '#C17100'
    case 'medium':
      return '#2F8405'
    case 'low':
      return '#0056CC'
    default:
      return '#0056CC'
  }
}

export const getColorsByOccupancy = occupancy => {
  switch (occupancy) {
    case 'maxed_out':
      return '#B32116'
    case 'full':
      return '#E63C2F'
    case 'high':
      return '#FCBD16'
    case 'medium':
      return '#70b84c'
    case 'low':
      return '#00A4CC'
    default:
      return '#00A4CC'
  }
}

export const getColorsByStatus = (device, type) => {
  switch (type) {
    case 'spots':
      switch (device.occupied) {
        case true:
          return {
            bg: 'var(--error-color)',
            color: 'var(--white-color)'
          }
        case false:
          return {
            bg: 'var(--success-color)',
            color: 'var(--white-color)'
          }
        default:
          return {
            bg: 'var(--warning-color)',
            color: 'var(--white-color)'
          }
      }
    case 'assets':
      switch (device.status) {
        case 'ok':
          return {
            bg: 'var(--success-color)',
            color: 'var(--white-color)'
          }
        case 'not_ok':
          return {
            bg: 'var(--error-color)',
            color: 'var(--white-color)'
          }
        case 'unknown':
          return {
            bg: 'var(--warning-color)',
            color: 'var(--white-color)'
          }
        default:
          return {
            bg: 'var(--warning-color)',
            color: 'var(--white-color)'
          }
      }
    case 'areas':
      switch (device.verboseOccupation) {
        case 'maxed_out':
          return {
            bg: 'var(--dark-error-color)',
            color: 'var(--white-color)'
          }
        case 'full':
          return {
            bg: 'var(--error-color)',
            color: 'var(--white-color)'
          }
        case 'high':
          return {
            bg: 'var(--warning-color)',
            color: 'var(--white-color)'
          }
        case 'medium':
          return {
            bg: 'var(--success-color)',
            color: 'var(--white-color)'
          }
        case 'low':
          return {
            bg: 'var(--info-color)',
            color: 'var(--white-color)'
          }
        default:
          return {
            bg: 'var(--unknown-color)',
            color: 'var(--black-color)'
          }
      }
    default:
      return {
        bg: 'var(--primary-color)',
        color: 'var(--white-color)'
      }
  }
}

export const getPolygonCenter = (vertexes) => {
  const lons = vertexes.map(vertex => vertex[0])
  const lats = vertexes.map(vertex => vertex[1])
  const centerLon = (Math.min(...lons) + Math.max(...lons)) / 2
  const centerLat = (Math.min(...lats) + Math.max(...lats)) / 2
  return [centerLon, centerLat]
}

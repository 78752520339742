import React, { useContext, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link, withRouter, matchPath } from 'react-router-dom'

import { Context as AuthContext } from 'providers/AuthProvider/authProvider'
import withResponsive from 'providers/ResponsiveHandler/withResponsive'

import { UNAVAILABLE_FEATURES } from 'config'

import Container from 'components/Container'
import LanguageSwitch from 'components/LanguageSwitch'
import HamburgerMenu from 'components/HamburgerMenu'
import SidebarItem from 'components/SidebarItem'

import { SECTIONS } from 'containers/Sidebar/utils'

import BrandIcon from 'icons/BrandIcon'
import UserIcon from 'icons/UserIcon'
import LogoutIcon from 'icons/LogoutIcon'

export default withResponsive(
  withRouter(({ children, ...props }) => {
    const {
      store: { isLoggedIn, user },
      actions: { logout }
    } = useContext(AuthContext)
    const { t } = useTranslation()

    const [open, setOpen] = useState(false)

    const { location, responsiveHandlers } = props
    const isActive = path => {
      const match = matchPath(path.replace(/\//g, ''), {
        path:
          location.pathname.split('/').filter(el => el !== '')[0] != null
            ? location.pathname.split('/').filter(el => el !== '')[0]
            : '',
        exact: true
      })
      return match != null
    }

    useEffect(() => {
      setOpen(false)
    }, [location])

    return (
      <Wrapper>
        <CustomContainer alignItems='center'>
          <BrandWrapper>
            <Brand to='/'>
              <BrandIcon height={32} />
            </Brand>
          </BrandWrapper>
          {responsiveHandlers.isDesktop && (
            <RightSide>
              <LanguageSwitch />

              {isLoggedIn && user != null && (
                <AccountWrapper>
                  <Username>{user.username}</Username>
                  <UserIcon height={32} />
                  <ProfileMenuWrapper>
                    <ProfileMenu>
                      <MenuItem onClick={logout}>
                        <MenuLabel>{t('logout')}</MenuLabel>
                        <LogoutIcon height={32} fill='var(--white-color)' />
                      </MenuItem>
                    </ProfileMenu>
                  </ProfileMenuWrapper>
                </AccountWrapper>
              )}
            </RightSide>
          )}
          <HamburgerWrapper>
            <LanguageSwitch key='mobile-language-switch' />
            {isLoggedIn && user != null && (
              <>
                <HamburgerMenu
                  open={open}
                  onClick={() => setOpen(open => !open)}
                />
                <MobileMenu isActive={open}>
                  {SECTIONS.filter(
                    el => !UNAVAILABLE_FEATURES.includes(el.key)
                  ).map((route, index) => (
                    <SidebarItem
                      {...route}
                      active={isActive(route.path) ? 1 : 0}
                      key={`sidebar-item-${index}`}
                    />
                  ))}
                  <StyledBottomItemsWrapper>
                    <StyledMenuItem>
                      <UserIcon height={32} fill='var(--white-color)' />
                      <MenuLabel>{user.username}</MenuLabel>
                    </StyledMenuItem>
                    <StyledMenuItem onClick={logout}>
                      <LogoutIcon height={32} fill='var(--white-color)' />
                      <MenuLabel>{t('logout')}</MenuLabel>
                    </StyledMenuItem>
                  </StyledBottomItemsWrapper>
                </MobileMenu>
              </>
            )}
          </HamburgerWrapper>
        </CustomContainer>
      </Wrapper>
    )
  })
)

const CustomContainer = styled(Container)`
  ${({ theme }) => theme.smallDesktop`

  max-width: calc(100% - 4.5rem);
  margin-left: 1.5rem;
  `}
`

const Wrapper = styled.div`
  position: fixed;

  width: 100%;
  background-color: var(--primary-color);
  padding: 0.625rem 0;

  display: flex;
  align-items: center;

  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.4);

  z-index: 101;
`

const BrandWrapper = styled.div`
  grid-column: 1/3;
  display: flex;
  align-items: center;

  height: 2.5rem;
`

const Brand = styled(Link)`
  color: var(--white-color);
  font-size: 1.5rem;
  line-height: 1em;
  height: fit-content;
  margin: auto 0;
`

const Username = styled.p`
  margin-right: 0.5rem;
  text-transform: uppercase;
  color: inherit;
  font-weight: 500;
`

const RightSide = styled.div`
  display: none;
  ${({ theme }) => theme.smallDesktop`
    grid-column: 11/13;
    display: flex;
    justify-content: flex-end;

  `}
`

const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0 0.5rem;
  cursor: pointer;
  color: var(--primary-color);
  background-color: var(--white-color);

  & path {
    fill: var(--primary-color);
  }

  &:hover,
  &:active,
  &:focus {
    background-color: var(--primary-color);
    color: var(--white-color);

    & path {
      fill: var(--white-color);
    }
  }
`

const ProfileMenuWrapper = styled.div`
  top: 2rem;
  right: 0rem;

  display: none;
  position: absolute;
  background-color: transparent;
  z-index: 10;
`

const ProfileMenu = styled.div`
  background-color: var(--white-color);
  padding: 0.5rem 0;
  min-width: 200px;
  margin-top: 0.5rem;

  box-shadow: 0 0.5rem 1rem 0px rgba(0, 0, 0, 0.2);
`

const AccountWrapper = styled.div`
  margin-left: 1rem;
  display: flex;
  align-items: center;
  color: var(--white-color);
  position: relative;

  &:hover ${ProfileMenuWrapper} {
    display: block;
  }
`

const MenuLabel = styled.p`
  color: inherit;
  font-size: 1rem;
  line-height: 1em;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 1rem;

  ${({ theme }) => theme.smallDesktop`
    margin-left: 0;
    margin-right: 1rem;
  `}
`

const HamburgerWrapper = styled.div`
  grid-column: 3/5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${({ theme }) => theme.smallDesktop`
    display: none;
  `}
`

const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  height: calc(100vh - 3.75rem);
  width: 100vw;
  opacity: 0;
  z-index: 100;
  background: var(--primary-color);
  top: 3.75rem;
  left: 0;
  transform: translateX(-100%);
  transition: all 0.3s ease-out;
  padding: 2rem 0 0;
  ${props =>
    props.isActive &&
    css`
      transition: all 0.3s ease-out;
      opacity: 1;
      transform: translateX(0);
    `}
  ${({ theme }) => theme.smallDesktop`
    display: none;
  `}
`

const StyledBottomItemsWrapper = styled.div`
  margin: auto 0 0;
`
const StyledMenuItem = styled.div`
  padding: 0.5rem 1.5rem;

  display: flex;
  align-items: center;

  color: var(--white-color);
  transition: background-color 0.3s ease-in-out;
  overflow: hidden;

  &:hover,
  &:focus,
  &:active {
    background-color: var(--darker-primary-color);
    transition: background-color 0.3s ease-in-out;
  }

  & + & {
    background-color: var(--dark-primary-color);
  }
`

import React, { memo } from 'react'
import styled from 'styled-components'
import Skeleton from '@material-ui/lab/Skeleton'

const StyledSkeleton = ({ children, ...props }) => (
  <Wrapper variant='rect' animation='wave' {...props} />
)

export default memo(StyledSkeleton)

const Wrapper = styled(Skeleton)`
  &.MuiSkeleton-root {
    margin: ${({ margin }) => margin};
    & + & {
      margin-top: 0.5rem;
      margin-top: ${({ marginTop }) => marginTop};
    }
  }
`

import React from 'react'
import NumberFormat from 'react-number-format'

export default ({ children, ...props }) => {
  const { inputRef, onChange, error, ...other } = props

  return (
    <NumberFormat
      error={error ? 1 : 0}
      {...other}
      decimalScale={0}
      allowNegative={false}
      allowEmptyFormatting
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue != null ? values.floatValue : null
          }
        })
      }}
    />
  )
}

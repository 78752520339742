import styled, { css } from 'styled-components'
import TitleStyle from 'styled/TitleStyle'

export const Wrapper = styled.div`
  width: 100%;
`

export const ModalTitle = styled.h2`
  ${TitleStyle};

  margin-bottom: 0.25rem;
`

export const ModalSubTitle = styled.h4`
  margin-bottom: 1.5rem;

  color: var(--breadcrumb-gray-color);
  font-size: 1.125rem;
  letter-spacing: 0;
  line-height: 1.3333333333em;
`

export const SameLineInputs = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  ${({ theme }) => theme.smallDesktop`
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  column-gap: 1rem;
`}
`

export const MapHelperText = styled.p`
  color: var(--primary-color);
  font-size: 0.875rem;
  font-style: italic;
  letter-spacing: 0;
  line-height: 1em;

  margin-top: 0.25rem;

  ${props =>
    props.error &&
    css`
      color: var(--error-color);
    `}
`

export const MapAndLabel = styled.div`
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  ${props =>
    props.error &&
    css`
      margin-bottom: calc(1.5rem - 1.66em);
    `}
`
export const MapWrapper = styled.div`
  width: 100%;
  height: 20rem;

  ${({ theme }) => theme.smallDesktop`
  height: 11rem;

`}
`

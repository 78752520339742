import React, { memo, useState, useCallback, useMemo } from 'react'
import styled, { withTheme } from 'styled-components'
import { withRouter, matchPath } from 'react-router-dom'
import { SIDEBAR_LOGO, UNAVAILABLE_FEATURES } from 'config'

import { animated, useSpring } from 'react-spring'

import { SECTIONS } from './utils'

import SidebarItem from 'components/SidebarItem'

// import Citibrain from 'images/citibrain_logo.png'
import Urbsense from 'images/urbsense_logo.png'

const variants = {
  open: '19rem',
  closed: '5rem'
}

const Sidebar = ({ children, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { location } = props
  const isActive = useCallback(
    path => {
      const match = matchPath(path?.replace(/\//g, ''), {
        path:
          location?.pathname?.split('/')?.filter(el => el !== '')?.[0] ?? '',
        exact: true
      })
      return match != null
    },
    [location?.pathname]
  )

  const { width } = useSpring({
    width: isOpen ? variants.open : variants.closed
  })

  const items = useMemo(() => SECTIONS.filter(el => !UNAVAILABLE_FEATURES.includes(el.key)).map(
    (route, index) => (
      <SidebarItem
        {...route}
        active={isActive(route.path) ? 1 : 0}
        key={`sidebar-item-${index}`}
      />
    )
  ), [isActive])

  const handleEnter = useCallback(
    () => setIsOpen(true),
    []
  )

  const handleLeave = useCallback(
    () => setIsOpen(false),
    []
  )

  return (
    <SidebarWrapper
      native={1}
      style={{ width }}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    >
      {items}
      {/* {SIDEBAR_LOGO === 'citibrain' && (
        <StyledLink
          href='http://www.citibrain.com/pt/'
          target='_blank'
          rel='noopener'
        >
          <StyledImage src={Citibrain} />
        </StyledLink>
      )} */}
      {SIDEBAR_LOGO === 'urbsense' && (
        <StyledLink href='https://urbsense.com/' target='_blank' rel='noopener'>
          <StyledImage src={Urbsense} />
        </StyledLink>
      )}
    </SidebarWrapper>
  )
}

export default memo(withTheme(withRouter(Sidebar)))

const SidebarWrapper = styled(animated.div)`
  display: none;

  ${({ theme }) => theme.smallDesktop`
    width: 5rem;
    min-height: calc(100vh - 3.75rem);
    height: calc(100% - 3.75rem);
    position: fixed;

    top: 3.75rem;
    left: 0;

    background-color: var(--dark-primary-color);
    z-index: 101;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`}
`

const StyledImage = styled.img`
  object-fit: contain;
  width: 6.25rem;
  max-width: 6.25rem;

  overflow: hidden;
`

const StyledLink = styled.a`
  width: 19rem;
  display: flex;
  justify-content: center;
  margin: auto 0 2.5rem;
`

import { css } from 'styled-components'

export default css`
  color: var(--primary-color);
  font-size: 1.25rem;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1em;

  ${({ theme }) => theme.smallDesktop`
    font-size: 1.5rem;
  `}
`

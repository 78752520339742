import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DATETIME_FORMAT } from 'config'
import moment from 'moment'

export default ({ children, ...props }) => {
  const { element, setInDetail } = props
  const { t } = useTranslation()
  return (
    <Wrapper onClick={() => setInDetail(element)}>
      <Name>{t(`table.${element.spotType}`)}</Name>
      <p />
      <Uid>{element.uid}</Uid>
      {element.occupied != null ? (
        <StatusWithColor
          color={
            element.occupied ? 'var(--error-color)' : 'var(--success-color)'
          }
        >
          {t(element.occupied ? 'table.occupied' : 'table.free')}
        </StatusWithColor>
      ) : (
        <StatusWithColor color='var(--default-blue-color)'>
          {t('table.unknown')}
        </StatusWithColor>
      )}

      <Label>{t('table.last_communication')}</Label>
      <Value>
        {element.lastCommunication != null
          ? moment(element.lastCommunication).format(DATETIME_FORMAT)
          : '-'}
      </Value>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  background: white;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  grid-template-rows: auto auto auto;
  column-gap: 8px;

  border-bottom: 1px solid var(--breadcrumb-gray-color);

  color: var(--text-color);
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1em;
  font-family: var(--primary-font);
`

const StatusWithColor = styled.p`
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-align: right;
  color: ${({ color }) => color || 'var(--black-color)'};

  grid-column: 2/3;
  grid-row: 1/4;
`

const Uid = styled.p`
  font-size: 1.5rem;
  color: var(--primary-color);
  line-height: 1em;
  margin-bottom: 1rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Name = styled.h1`
  margin-bottom: 0.25rem;

  font-size: 0.75rem;
  color: var(--breadcrumb-gray-color);
`

const Label = styled.p`
  font-weight: 700;
  font-size: 0.875rem;
`

const Value = styled.p`
  font-size: 0.75rem;
  color: var(--breadcrumb-gray-color);
  text-align: right;
`

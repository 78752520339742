/**
 * StyledNotification Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import SuccessIcon from 'icons/SuccessIcon'
import ErrorIcon from 'icons/ErrorIcon'

export default {
  success (msg, options) {
    const SuccessMessage = () => (
      <StyledMessage>
        <SuccessIcon
          height='1.5rem'
          width='1.5rem'
          fill='var(--success-color)'
        />
        <p>{msg}</p>
      </StyledMessage>
    )

    return toast.success(<SuccessMessage />, {
      // Merge additionals options
      closeButton: false,
      ...options,
      className: 'success-toast'
    })
  },

  error (msg, options) {
    const ErrorMessage = () => (
      <StyledMessage>
        <ErrorIcon
          height='1.5rem'
          width='1.5rem'
          fill='var(--error-color)'
        />
        <p>{msg}</p>
      </StyledMessage>
    )
    return toast.error(<ErrorMessage />, {
      closeButton: false,
      ...options,
      className: 'error-toast'
    })
  }
}

const StyledMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  p {
    padding: 0 0 0 1rem;
    word-break: break-word;
    width: 100%;
  }
  > * {
    flex: 0 0 auto;
  }
`

import axios from 'axios'
import mapValues from 'lodash/mapValues'
import ValidationError from 'utils/ValidationError'

import * as Check from 'utils/validations'
import { BASE_API_URL } from 'config'
const LOGIN_URL = BASE_API_URL + '/token/web/'

const VALIDATIONS = {
  username: [Check.isRequired],
  password: [Check.isRequired]
}

const logic = {
  defaultValues: {
    username: { value: '' },
    password: { value: '' }
  },
  login: (form, setForm) => {
    return Promise.resolve().then(() => {
      const validation = Check.checkValidation(form, VALIDATIONS)
      if (validation.invalid) {
        setForm({ ...validation.form })
        throw new ValidationError()
      }
      setForm({ ...validation.form })

      const params = mapValues(form, ({ value }) => value)
      return axios.post(LOGIN_URL, { ...params })
    })
  }
}

export default logic

import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg viewBox='0 0 30 30' {...props}>
      <g fillRule='nonzero' fill={props.fill || '#FFF'}>
        <path d='M21.845 18.464a70.43 70.43 0 01-1.527 2.351c4.64.694 7.016 2.18 7.016 2.92 0 .985-4.21 3.293-12.374 3.293-8.163 0-12.373-2.308-12.373-3.293 0-.74 2.375-2.225 7.017-2.92a70.484 70.484 0 01-1.528-2.35C3.496 19.327 0 21.102 0 23.734c0 3.861 7.527 5.882 14.96 5.882 7.435 0 14.961-2.02 14.961-5.882 0-2.632-3.495-4.407-8.076-5.27z' />
        <path d='M14.994.305a8.782 8.782 0 00-8.783 8.782c0 3.48 4.518 10.136 7.073 13.622a2.12 2.12 0 003.419 0c2.555-3.486 7.073-10.143 7.073-13.622A8.782 8.782 0 0014.994.305zm0 14.235a5.203 5.203 0 01-5.198-5.197 5.203 5.203 0 015.198-5.197 5.203 5.203 0 015.197 5.197 5.202 5.202 0 01-5.197 5.197z' />
        <path d='M14.994 6.182a3.164 3.164 0 00-3.161 3.161 3.165 3.165 0 003.161 3.16 3.165 3.165 0 003.161-3.16 3.164 3.164 0 00-3.16-3.161z' />
      </g>
    </svg>
  )
}

export default SvgComponent

import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#prefix__clip0)'>
        <circle cx={12} cy={12} r={10} fill='#006982' />
        <circle cx={12} cy={12} r={9.5} stroke='#fff' strokeOpacity={0.1} />
        <path
          d='M12.27 6.75H8.5v9.917h2.154V13.36h1.615c1.783 0 3.231-1.482 3.231-3.305 0-1.824-1.448-3.306-3.23-3.306zm.107 4.407h-1.723V8.954h1.723c.592 0 1.077.496 1.077 1.102 0 .606-.485 1.101-1.077 1.101z'
          fill='#fff'
        />
        <g filter='url(#prefix__filter0_d)'>
          <circle cx={19} cy={5} r={3} fill='#FCBD16' />
          <circle cx={19} cy={5} r={2.5} stroke='#fff' strokeOpacity={0.3} />
        </g>
      </g>
      <defs>
        <clipPath id='prefix__clip0'>
          <path fill='#fff' d='M0 0h24v24H0z' />
        </clipPath>
        <filter
          id='prefix__filter0_d'
          x={12}
          y={-2}
          width={14}
          height={14}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent

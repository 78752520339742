/**
 * ResponsiveHandler Component
 * Please write a description
 *
 * @author Your Name <youremail@ubiwhere.com>
 */
import React, { Component } from 'react'
import { Theme } from 'globalStyles'

export const Context = React.createContext('responsive')

class ResponsiveHandler extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isMobile: window.innerWidth < Theme.breakpoints.smallDesktop.min,
      isDesktop: window.innerWidth >= Theme.breakpoints.smallDesktop.min,
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  recalculateDimensions = () => {
    const dimensions = {
      isMobile: window.innerWidth < Theme.breakpoints.smallDesktop.min,
      isDesktop: window.innerWidth >= Theme.breakpoints.smallDesktop.min,
      width: window.innerWidth,
      height: window.innerHeight
    }
    for (let i = 0; i < Object.keys(dimensions).length; i++) {
      if (
        dimensions[Object.keys(dimensions)[i]] !==
        this.state[Object.keys(dimensions)[i]]
      ) {
        this.setState({ ...dimensions })
        return
      }
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.recalculateDimensions)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.recalculateDimensions)
  }

  render () {
    const { children } = this.props
    return (
      <Context.Provider value={{ responsiveHandlers: { ...this.state } }}>
        {children}
      </Context.Provider>
    )
  }
}

export default ResponsiveHandler

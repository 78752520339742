import axios from 'axios'
import mapValues from 'lodash/mapValues'
import ValidationError from 'utils/ValidationError'

import * as Check from 'utils/validations'
import { BASE_API_URL } from 'config'
const DETAILS_URL = BASE_API_URL + '/areas' // without / to better understand on axios
const SENSORSWITHOUTAREA_URL = BASE_API_URL + '/sensors/without_area/'
const GATEWAYSWITHOUTAREA_URL =
  BASE_API_URL + '/gateways/without_area/'

const VALIDATIONS = {
  uid: [Check.isRequired],
  monitoringType: [Check.isRequired],
  capacityMargin: [Check.isRequired],
  capacity: [Check.isRequired],
  name: [Check.isRequired],
  geometry: [Check.isRequired],
  address: [],
  description: [],
  sensors: [],
  gateways: []
}

const logic = {
  defaultValues: {
    uid: { value: null },
    monitoringType: { value: null },
    capacityMargin: { value: null },
    capacity: { value: null },
    name: { value: null },
    geometry: { value: null },

    address: { value: null },
    description: { value: null },
    sensors: { value: [] },
    gateways: { value: [] }
  },
  getAreaDetails: id =>
    Promise.resolve().then(() => {
      return axios.get(`${DETAILS_URL}/${id}/`)
    }),
  getSensorsAndGateways: () =>
    Promise.all([
      axios.get(SENSORSWITHOUTAREA_URL),
      axios.get(GATEWAYSWITHOUTAREA_URL)
    ]),
  updateAreaDetails: (id, form, setForm) =>
    Promise.resolve().then(() => {
      const validation = Check.checkValidation(form, VALIDATIONS)
      if (validation.invalid) {
        setForm({ ...validation.form })
        throw new ValidationError()
      }
      setForm({ ...validation.form })

      const params = mapValues(form, ({ value }) => value)
      return axios.put(`${DETAILS_URL}/${id}/`, { ...params })
    }),
  deleteArea: id =>
    Promise.resolve().then(() => axios.delete(`${DETAILS_URL}/${id}/`))
}

export default logic

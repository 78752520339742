import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24'>
      <g>
        <path
          fill='#fff'
          d='M 11.449219 14.234375 C 8.359375 17.570312 4.457031 19.820312 0.148438 20.445312 C 0.691406 22.535156 2.578125 23.996094 4.734375 23.996094 L 14.453125 23.996094 C 14.089844 21.621094 13.90625 19.21875 13.90625 16.8125 C 13.90625 16.316406 13.933594 15.257812 13.933594 15.230469 C 13.9375 14.644531 13.589844 14.113281 13.050781 13.886719 C 12.511719 13.660156 11.886719 13.78125 11.472656 14.195312 Z M 11.449219 14.234375 '
        />
        <path
          fill='#fff'
          d='M 15.617188 13.199219 C 15.621094 17.09375 16.890625 20.886719 19.238281 23.996094 C 20.5 24 21.707031 23.503906 22.601562 22.613281 C 23.492188 21.722656 23.996094 20.515625 23.996094 19.253906 L 23.996094 4.738281 C 24 2.808594 22.828125 1.070312 21.039062 0.34375 C 17.582031 3.726562 15.628906 8.359375 15.617188 13.199219 '
        />
        <path
          fill='#fff'
          d='M 0 4.738281 L 0 17.679688 C 8.871094 16.101562 15.597656 8.78125 15.597656 0 L 4.734375 0 C 2.117188 0 -0.00390625 2.121094 0 4.738281 '
        />
      </g>
    </svg>
  )
}

export default SvgComponent

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Container from 'components/Container'
import StyledButton from 'components/StyledButton'

import NotFoundIllustration from 'icons/NotFoundIllustration'

export default ({ children, ...props }) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Container height='100%'>
        <ContentWrapper>
          <NotFoundIllustration height='12.875rem' />
          <Title>{t('notfound.title')}</Title>
          <Message>{t('notfound.message')}</Message>
          <BackButton to='/'>
            <StyledButton type='primary' width='100%'>
              {t('back')}
            </StyledButton>
          </BackButton>
        </ContentWrapper>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  height: ${({ height }) => height};
  position: relative;
  overflow: visible;

  background-color: var(--backgroud-color);
`

const ContentWrapper = styled.div`
  grid-column: 3/10;
  margin: auto;

  text-align: center;
`

const Title = styled.h1`
  color: var(--primary-color);
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1em;

  margin: 2.5rem auto 1rem;
`
const Message = styled.p`
  color: var(--error-page-text-color);
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: 1em;

  margin-bottom: 3rem;
`

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 15rem;
  margin: auto;
`

export default (apiError, validations, type) => {
  if (
    type != null &&
    apiError != null &&
    validations != null &&
    validations[type] != null
  ) {
    const steps = Object.keys(validations[type])
    let minStepError = steps.length - 1
    const errorKeys = Object.keys(apiError)
    for (let i = steps.length - 1; i >= 0; i--) {
      if (validations[type][steps[i]] != null) {
        const validationKeys = Object.keys(validations[type][steps[i]])

        const found = errorKeys.some(errKey => {
          return validationKeys.includes(errKey)
        })
        if (found) {
          minStepError = i
        }
      }
    }
    return minStepError
  }
  return null
}

import React from 'react'
import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import StyledInput from 'components/StyledInput'
import { useTranslation } from 'react-i18next'

import LocationOnIcon from '@material-ui/icons/LocationOn'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

export default ({ children, ...props }) => {
  const { t } = useTranslation()
  return (
    <StyledAutoComplete
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      value={props.inputValue}
      onChange={props.onInputChange}
      inputValue={props.value}
      options={props.options}
      getOptionLabel={props.getOptionLabel}
      onInputChange={(event, newInputValue) => {
        props.onChange(newInputValue)
      }}
      loading={props.loading}
      clearText={t('clear')}
      renderInput={params => (
        <StyledInput
          {...params}
          label={props.label}
          variant='outlined'
          size='small'
        />
      )}
      fullWidth
      renderOption={
        props.mapOptions
          ? option => {
            const fallbackDisplay = option.displayName.split(',')
            let display = []

            let label = []
            if (option.address != null) {
              display = [
                option.address.road ? option.address.road : null,
                option.address.street ? option.address.street : null,
                option.address.village ? option.address.village : null,
                option.address.locality ? option.address.locality : null
              ].filter(el => el != null)
              label = [
                option.address.city ? option.address.city : null,
                option.address.town ? option.address.town : null,
                option.address.municipality
                  ? option.address.municipality
                  : null,
                option.address.county ? option.address.county : null,
                option.address.state ? option.address.state : null,
                option.address.country ? option.address.country : null
              ].filter(el => el != null)
            }
            return (
              <Grid container alignItems='center'>
                <Grid item style={{ marginRight: '0.5rem' }}>
                  <LocationOnIcon />
                </Grid>
                <Grid item xs>
                  <StyledSpan>
                    {display != null && display.length > 0
                      ? display.join(', ')
                      : fallbackDisplay[0]}
                  </StyledSpan>

                  <Typography variant='body2' color='textSecondary'>
                    {label != null && label.length > 0
                      ? label.join(', ')
                      : fallbackDisplay.slice(
                        Math.max(fallbackDisplay.length - 2, 0)
                      )}
                  </Typography>
                </Grid>
              </Grid>
            )
          }
          : null
      }
    />
  )
}

const StyledAutoComplete = styled(Autocomplete)``

const StyledSpan = styled.span`
 
`

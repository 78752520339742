/**
 * -----------------------------------------------------------------------------
 * Copyright (C) Ubiwhere, LDA
 * 2012-2018
 *
 *
 * The reproduction, transmission or use of this document or its contents is not
 * permitted without express written authorization. All rights, including rights
 * created by patent grant or registration of a utility model or design, are
 * reserved. Modifications made to this document are restricted to authorized
 * personnel only. Technical specifications and features are binding only when
 * specifically and expressly agreed upon in a written contract.
 * -----------------------------------------------------------------------------
 *
 * Config.js
 * Where we we set all the configurations and constants to be used in the application
 */

import 'interceptors'

const getEnv = (key, defaultValue) => {
  const hasWindowEnvKey = Object.prototype.hasOwnProperty.call(window._env_ ?? {}, key)
  if (hasWindowEnvKey) return window._env_[key]
  return process.env[key] ?? defaultValue
}

export const VERSION =
  process.env.VERSION && process.env.VERSION.replace(/"/g, '')
export const BUILD_DATE = process.env.BUILD_DATE
export const AUTH_TOKEN_TYPE = 'JWT'
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const API_DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT = 'DD-MM-YYYY'
export const AUTOMATIC_EDIT_PERMISSIONS = {
  gateway: ['geometry', 'name'],
  sensor: ['geometry', 'name'],
  spot: ['geometry', 'spotType'],
  repeater: ['geometry', 'name'],
  panel: ['geometry', 'name'],
  area: [
    'geometry',
    'name',
    'capacityMargin',
    'capacity',
    'description',
    'address'
  ]
}

const MAP_CENTER_LAT = getEnv('REACT_APP_MAP_START_LATITUDE')
const MAP_CENTER_LONG = getEnv('REACT_APP_MAP_START_LONGITUDE')
export const MAP_BOX_KEY = getEnv('REACT_APP_MAPBOX_ACCESS_TOKEN')
export const MAPCENTER = [MAP_CENTER_LONG, MAP_CENTER_LAT]
export const MAPZOOM = [14]
export const NOMINATIM_BASE_URL = 'https://nominatim.openstreetmap.org'
export const WS_JOIN_GROUP = 'parking_platform'
export const BASE_API_URL = getEnv('REACT_APP_BASE_API_URL')
export const BASE_MEDIA_URL = getEnv('REACT_APP_BASE_MEDIA_URL')
export const WS_BASE_URL = getEnv('REACT_APP_WS_URL')
export const SIDEBAR_LOGO = getEnv('REACT_APP_SIDEBAR_LOGO')
export const CRUD_PERMISSIONS_GROUP = 'FULL ADMINISTRATOR'
export const UNAVAILABLE_FEATURES = getEnv('REACT_APP_UNAVAILABLE_FEATURES').split(',')
export const MOBILE_DEEPLINK = getEnv('REACT_APP_MOBILE_DEEPLINK')
export const NOMINATIM_OPTIONS = {
  format: 'jsonv2',
  countrycodes: 'pt',
  viewbox: '-8.63834,41.59105,-7.98603,41.33996',
  bounded: 1
}
export const AVAILABLE_SPOT_TYPES = getEnv('REACT_APP_AVAILABLE_SPOT_TYPES').split(',')

import MapIcon from 'icons/MapIcon'
import AreasIcon from 'icons/AreasIcon'
import AssetsIcon from 'icons/AssetsIcon'
// import EventsIcon from 'icons/EventsIcon'
import MetricsIcon from 'icons/MetricsIcon'
import SpotsIcon from 'icons/SpotsIcon'

export const SECTIONS = [
  {
    name: 'sections.homepage',
    icon: MapIcon,
    path: '/',
    key: 'homepage'
  },
  {
    name: 'sections.areas',
    icon: AreasIcon,
    path: '/areas/',
    key: 'areas'
  },
  {
    name: 'sections.spots',
    icon: SpotsIcon,
    path: '/spots/',
    key: 'spots'
  },
  {
    name: 'sections.assets',
    icon: AssetsIcon,
    path: '/assets/',
    key: 'assets'
  },
  /* {
    name: 'sections.events',
    icon: EventsIcon,
    path: '/events/',
    key: 'events'
  }, */
  {
    name: 'sections.metrics',
    icon: MetricsIcon,
    path: '/metrics/',
    key: 'metrics'
  }
]

import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback
} from 'react'
import styled, { css } from 'styled-components'
import get from 'lodash.get'

import { AVAILABLE_SPOT_TYPES, DATETIME_FORMAT } from 'config'

import { Context as AuthContext } from 'providers/AuthProvider/authProvider'
import withResponsive from 'providers/ResponsiveHandler/withResponsive'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import logic, { types, keysToId } from './logic'

import StyledButton from 'components/StyledButton'
import StyledDialog from 'components/StyledDialog'
import StyledSkeleton from 'components/StyledSkeleton'
import toast from 'components/StyledToast'

import InputAndLabel from 'containers/InputAndLabel'
import SelectAndLabel from 'containers/SelectAndLabel'
import CreateNewSpotModal from 'containers/CreateNewSpotModal'

export default withResponsive(({ children, ...props }) => {
  const { actions } = useContext(AuthContext)

  const { t } = useTranslation()
  const { type, id } = useParams()

  const {
    hardwareInDetail,
    setHardwareInDetail,
    isEditing,
    setIsEditing,
    setGeometry,
    geometry,
    allowedOptions,
    fetchedAreas,
    fetchedGateways,
    setTitle,
    setMapHelperText,
    canEdit,
    fetchedHardware,
    setFetchedHardware,
    resetParent,
    hasCRUDPermission,
    update,
    gatewaysTypeOptions,
    responsiveHandlers,
    countingTypeOptions
  } = props

  const [form, setForm] = useState(null)

  const [submitState, setSubmitState] = useState({
    hasSubmitted: false,
    submitting: false,
    apiResponse: null
  })

  const [availableSensors, setAvailableSensors] = useState(null)
  const [availableSpots, setAvailableSpots] = useState(null)

  const [filteredGateways, setFilteredGateways] = useState([])

  const [spotsOpen, setSpotsOpen] = useState(false)

  const [newSpotState, setNewSpotState] = useState({
    hasSubmitted: false,
    submitting: false,
    apiResponse: null
  })
  const [newSpotOpen, setNewSpotOpen] = useState(false)
  const [newSpotForm, setNewSpotForm] = useState(logic.spotDefaultValues)
  const [canDelete, setCanDelete] = useState(true)

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

  const handleClickOpen = useCallback(() => {
    setConfirmDeleteOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setConfirmDeleteOpen(false)
  }, [])

  const spotTypeOptions = useMemo(
    () => [...AVAILABLE_SPOT_TYPES?.map(el => ({ label: el, value: el }))],
    []
  )

  const reset = useCallback(() => {
    setForm(null)
    setSubmitState({
      hasSubmitted: false,
      submitting: false,
      apiResponse: null
    })
    setNewSpotOpen(false)
    resetParent()
  }, [resetParent])

  useEffect(() => {
    if (!newSpotOpen) {
      setNewSpotForm(logic.spotDefaultValues)
      setNewSpotState({
        hasSubmitted: false,
        submitting: false,
        apiResponse: false
      })
    }
  }, [newSpotOpen])

  useEffect(() => {
    if (!isEditing && fetchedHardware != null) {
      setGeometry(fetchedHardware.geometry)
      if (type != null && (type === 'gateway' || type === 'sensor')) {
        logic
          .fetchAvailableSensors()
          .then(response => {
            if (
              response != null &&
              response.data != null &&
              response.data.results != null
            ) {
              setAvailableSensors(response.data)
            } else {
              setAvailableSensors([])
            }
          })
          .catch(err => {
            console.log({ err })
            setAvailableSensors([])
          })
      }

      if (type != null && type === 'sensor') {
        logic
          .fetchAvailableSpots()
          .then(response => {
            if (response != null && response.data != null) {
              const { results } = response.data
              setAvailableSpots(results)
            } else {
              setAvailableSpots([])
            }
          })
          .catch(err => {
            console.log({ err })
            setAvailableSpots([])
          })
      }
    }
    // eslint-disable-next-line
  }, [fetchedHardware])

  useEffect(() => {
    if (
      id != null &&
      !isNaN(Number(id)) &&
      type != null &&
      types.includes(type)
    ) {
      if (!isEditing) {
        setSubmitState(submitState => ({
          ...submitState,
          apiResponse: null,
          hasSubmitted: false,
          submitting: false
        }))
        resetParent()
        logic
          .getHardwareDetails(id, type)
          .then(response => {
            if (response.data) {
              const { data } = response
              setFetchedHardware(data)

              if (data.mode === 'automatic') {
                setCanDelete(false)
              }

              const defaultKeys = Object.keys(logic.defaultValues[type])
              const reduced = Object.keys(data).reduce(
                (acum, key) => {
                  if (defaultKeys.includes(key)) {
                    if (
                      keysToId.includes(key) &&
                      data[key] != null &&
                      data[key].id != null
                    ) {
                      acum[key] = { value: data[key].id }
                    } else if (key === 'spot' || key === 'sensors') {
                      acum[key] = { value: data[key].map(el => el.id) }
                    } else {
                      acum[key] = { value: data[key] }
                    }
                  }
                  return acum
                },
                { ...logic.defaultValues }
              )
              setForm(reduced)

              if (hardwareInDetail == null) {
                setHardwareInDetail(data, type)
              }
            } else {
              reset()
              setHardwareInDetail(null)
              setFetchedHardware(null)
            }
          })
          .catch(err => {
            console.log({ err })
            setHardwareInDetail(null)
            setFetchedHardware(null)
            if (err == null || err.response == null) {
              actions.serverError()
            }
          })
      }
    } else {
      reset()
      setHardwareInDetail(null)
      setFetchedHardware(null)
    }
    // eslint-disable-next-line
  }, [id, type, isEditing, update])

  const checkIfError = useCallback(
    property => {
      const error = get(submitState.apiResponse, property, null)
      if (
        submitState.apiResponse != null &&
        error != null &&
        error[0] != null
      ) {
        return error[0]
      }
      return null
    },
    [submitState.apiResponse]
  )

  const helperTextGenerator = useCallback(
    property => {
      if (submitState.hasSubmitted && form[property] != null) {
        const apiError = checkIfError(property)
        if (apiError != null) {
          return apiError
        } else if (form[property].message) {
          return t(`errors.${form[property].message}`)
        }
      }
      return null
    },
    [checkIfError, form, submitState.hasSubmitted, t]
  )

  const handleChange = useCallback(
    (key, value) => {
      setForm({
        ...form,
        [key]: {
          value: value
        }
      })
    },
    [form]
  )

  const handleNewSpotChange = useCallback(
    (key, value) => {
      setNewSpotForm({
        ...newSpotForm,
        [key]: {
          value: value
        }
      })
    },
    [newSpotForm]
  )

  useEffect(() => {
    if (isEditing) {
      handleChange('geometry', geometry)
    }
    // eslint-disable-next-line
  }, [geometry])

  const handleNewSpotSubmit = useCallback(
    event => {
      event.preventDefault()
      setNewSpotState({
        ...newSpotState,
        submitting: true,
        hasSubmitted: true
      })
      logic
        .submitNewSpot(newSpotForm, changedForm =>
          setNewSpotForm({ ...changedForm })
        )
        .then(response => {
          setNewSpotState({
            ...newSpotState,
            submitting: false
          })
          if (response.data) {
            toast.success(t('spots.create_success'))

            setAvailableSpots([response.data, ...availableSpots])
            setNewSpotOpen(false)
            if (form != null && form.spot != null) {
              handleChange('spot', [response.data.id])
            }
          }
        })
        .catch(err => {
          console.log({ err })
          toast.error(t('spots.create_error'))
          if (err == null || err.response == null) {
            actions.serverError()
          } else {
            if (err.response.data != null) {
              setNewSpotState({
                hasSubmitted: true,
                submitting: false,
                apiResponse: err.response.data
              })
            } else {
              setNewSpotState({
                hasSubmitted: true,
                apiResponse: newSpotState.apiResponse,
                submitting: false
              })
            }
          }
        })
    },
    [actions, availableSpots, form, handleChange, newSpotForm, newSpotState, t]
  )

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      setSubmitState(submitState => ({
        ...submitState,
        hasSubmitted: true,
        submitting: true
      }))
      logic
        .updateHardwareDetails(id, type, form, form => setForm({ ...form }))
        .then(response => {
          setIsEditing(false)
          if (response.data) {
            setHardwareInDetail(response.data, type)
            toast.success(t('assets.edit_success'))
            setSubmitState(submitState => ({
              ...submitState,
              submitting: false
            }))
          }
        })
        .catch(err => {
          console.log({ err })
          toast.error(t('assets.edit_error'))

          if (err == null || err.response == null) {
            actions.serverError()
            setSubmitState(submitState => ({
              ...submitState,
              submitting: false
            }))
          } else {
            if (err.response.data != null) {
              setSubmitState(submitState => ({
                ...submitState,
                submitting: false,
                apiResponse: err.response.data
              }))
            } else {
              setSubmitState(submitState => ({
                ...submitState,
                submitting: false
              }))
            }
          }
        })
    },
    [actions, form, id, setHardwareInDetail, setIsEditing, t, type]
  )

  const handleDelete = useCallback(() => {
    logic
      .deleteHardware(id, type)
      .then(response => {
        setHardwareInDetail(null)
        toast.success(t('assets.delete_success'))
      })
      .catch(err => {
        console.log({ err })
        toast.error(t('assets.delete_error'))

        // ERROR
        if (err == null || err.response == null) {
          actions.serverError()
        }
      })
      .finally(() => handleClose())
  }, [actions, handleClose, id, setHardwareInDetail, t, type])

  useEffect(() => {
    if (fetchedHardware != null && fetchedHardware.uid != null) {
      if (isEditing) {
        setTitle(type != null ? `assets.edit_${type}` : 'assets.edit')
      } else {
        setTitle(type != null ? `assets.details_${type}` : 'assets.details')
      }
    } else if (hardwareInDetail != null && hardwareInDetail.uid != null) {
      if (isEditing) {
        setTitle(type != null ? `assets.edit_${type}` : 'assets.edit')
      } else {
        setTitle(type != null ? `assets.details_${type}` : 'assets.details')
      }
    } else {
      if (isEditing) {
        setTitle(type != null ? `assets.edit_${type}` : 'assets.edit')
      } else {
        setTitle(type != null ? `assets.details_${type}` : 'assets.details')
      }
    }
  }, [fetchedHardware, hardwareInDetail, isEditing, setTitle, t, type])

  const filterGatewaysWithArea = useCallback(
    area => {
      if (
        isEditing &&
        type === 'sensor' &&
        fetchedAreas != null &&
        fetchedGateways != null
      ) {
        if (area != null && area !== '' && form?.gateway != null) {
          const areaFound = fetchedAreas?.find(el => el?.id === area)
          if (areaFound?.id) {
            if (form?.gateway?.value == null) {
              const gatewaysInArea = fetchedGateways?.filter(
                gw => gw?.area?.id === areaFound?.id
              )
              setFilteredGateways(gatewaysInArea)
            } else {
              const gwFound = fetchedGateways?.find(
                gw => gw.id === form?.gateway?.value
              )
              if (gwFound?.area?.id !== areaFound?.id) {
                handleChange('gateway', null)
              }
              setFilteredGateways(fetchedGateways)
            }
          } else {
            setFilteredGateways(fetchedGateways)
          }
        } else {
          setFilteredGateways(fetchedGateways)
        }
      }
    },
    [
      fetchedAreas,
      fetchedGateways,
      handleChange,
      isEditing,
      type,
      form?.gateway
    ]
  )

  useEffect(() => {
    if (isEditing) {
      setMapHelperText(
        <MapHelperText
          error={
            submitState.hasSubmitted &&
            form.geometry != null &&
            (form.geometry.message != null || !!checkIfError('geometry'))
          }
        >
          {checkIfError('geometry') || t('map.asset_edit_text')}
        </MapHelperText>
      )
    }
    if (
      isEditing &&
      type != null &&
      type === 'sensor' &&
      fetchedAreas != null &&
      fetchedGateways != null &&
      form != null &&
      form.area != null
    ) {
      filterGatewaysWithArea(form.area.value)
    } else {
      setFilteredGateways(fetchedGateways)
    }
    // eslint-disable-next-line
  }, [form, isEditing])

  const memoizedLoadingTable = useMemo(() => {
    let numberOfInputs = 13
    switch (type) {
      case 'sensor':
        numberOfInputs = 13
        break
      case 'gateway':
        numberOfInputs = 7
        break
      case 'panel':
        numberOfInputs = 4
        break
      case 'repeater':
        numberOfInputs = 4
        break
      default:
        numberOfInputs = 13
        break
    }
    return [...Array(numberOfInputs)].map((el, index) => (
      <StyledSkeleton
        key={`table-loading-row-${index}`}
        variant='rect'
        width='100%'
        height='3.75rem'
        margin='0 0 1.5rem'
        marginTop='0'
      />
    ))
  }, [type])

  const memoHandleChangeValue = useCallback(
    name => e => handleChange(name, e?.target?.value),
    [handleChange]
  )

  const memoHandleChange = useCallback(name => e => handleChange(name, e), [
    handleChange
  ])

  const memoHandleChangeArray = useCallback(
    name => event =>
      handleChange(
        name,
        event?.target?.value !== '' ? [event?.target?.value] : []
      ),
    [handleChange]
  )

  const memoHandleChangeEmptyString = useCallback(
    name => event =>
      handleChange(
        name,
        event?.target?.value !== '' ? event?.target?.value : null
      ),
    [handleChange]
  )

  const memoNewButton = useMemo(
    () =>
      form?.spot?.value?.[0] == null ? (
        <NewButtonWrapper key='new-spot-button'>
          <StyledButton
            type='primary'
            onClick={e => {
              setSpotsOpen(false)
              setNewSpotOpen(true)
            }}
            width='100%'
          >
            {t('assets.new_spot')}
          </StyledButton>
        </NewButtonWrapper>
      ) : null,
    [form?.spot?.value, t]
  )

  const memoAllowedOptions = useMemo(
    () =>
      allowedOptions.map(el => ({
        ...el,
        label: t(`table.${el.label}`)
      })) ?? [],
    [allowedOptions, t]
  )

  const memoCountingTypeOptions = useMemo(
    () =>
      countingTypeOptions.map(el => ({
        ...el,
        label: t(`table.${el.label}`)
      })),
    [t, countingTypeOptions]
  )

  const memoFetchedAreas = useMemo(
    () =>
      fetchedAreas != null
        ? [
          { value: '', label: t('table.remove') },
          ...fetchedAreas.map(el => ({
            value: el.id,
            label: el.name ?? el.id
          }))
        ]
        : [],
    [fetchedAreas, t]
  )

  const memoFetchedAreasMultiple = useMemo(
    () =>
      fetchedAreas != null
        ? [
          ...fetchedAreas.map(el => ({
            value: el.id,
            label: el.name
          }))
        ]
        : [],
    [fetchedAreas]
  )

  const memoFilteredGateways = useMemo(
    () =>
      filteredGateways != null
        ? [
          { value: '', label: t('table.remove') },
          ...filteredGateways.map(el => ({
            value: el.id,
            label: el.uid ?? el.id
          }))
        ]
        : [],
    [filteredGateways, t]
  )

  const memoGatewaysTypeOptions = useMemo(
    () =>
      gatewaysTypeOptions != null
        ? gatewaysTypeOptions.map(el => ({
          ...el,
          label: t(`table.${el.label}`)
        }))
        : [],
    [gatewaysTypeOptions, t]
  )

  const memoAvailableSpots = useMemo(
    () =>
      availableSpots != null
        ? [
          { value: '', label: t('table.remove') },
          ...availableSpots.map(el => ({
            value: el.id,
            label: el.uid ?? el.id
          }))
        ]
        : [],
    [availableSpots, t]
  )

  const memoAvailableSensors = useMemo(
    () =>
      availableSensors != null
        ? availableSensors.map(el => ({
          value: el.id,
          label: el.uid ?? el.id
        }))
        : [],
    [availableSensors]
  )

  const startEditing = useCallback(() => setIsEditing(true), [setIsEditing])

  const cancelEditing = useCallback(() => setIsEditing(false), [setIsEditing])

  const goBack = useCallback(() => {
    reset()
    setHardwareInDetail(null)
  }, [reset, setHardwareInDetail])

  return (
    <>
      <DetailsWrapper>
        {form == null && <>{memoizedLoadingTable}</>}
        {type === 'sensor' && form != null && (
          <>
            <InputAndLabel
              type='text'
              name='uid'
              value={form?.uid?.value ?? ''}
              onChange={memoHandleChange('uid')}
              label={t('table.uid')}
              error={
                submitState.hasSubmitted &&
                (form?.uid?.message != null || checkIfError('uid'))
              }
              helperText={helperTextGenerator('uid')}
              disabled={!(isEditing && canEdit(fetchedHardware, 'uid', type))}
              isEditing={!!isEditing}
              maxLength={255}
              minLength={1}
              masked
              mask={/^\w+$/}
            />
            <InputAndLabel
              type='text'
              name='factoryUid'
              value={form?.factoryUid?.value ?? ''}
              onChange={memoHandleChange('factoryUid')}
              label={t('table.factory_uid')}
              error={
                submitState.hasSubmitted &&
                (form?.factoryUid?.message != null ||
                  checkIfError('factoryUid'))
              }
              helperText={helperTextGenerator('factoryUid')}
              disabled={
                !(isEditing && canEdit(fetchedHardware, 'factoryUid', type))
              }
              isEditing={!!isEditing}
              maxLength={255}
              minLength={1}
              masked
              mask={/^\w+$/}
            />
            <InputAndLabel
              type='text'
              name='name'
              value={form?.name?.value ?? ''}
              onChange={memoHandleChange('name')}
              label={t('table.name')}
              error={
                submitState.hasSubmitted &&
                (form?.name?.message != null || !!checkIfError('name'))
              }
              helperText={helperTextGenerator('name')}
              disabled={!(isEditing && canEdit(fetchedHardware, 'name', type))}
              isEditing={!!isEditing}
              maxLength={255}
              masked
              mask={/^[\w\s]+$/}
            />
            <InputAndLabel
              type='text'
              name='description'
              value={form?.description?.value ?? ''}
              onChange={memoHandleChange('description')}
              label={t('table.description')}
              error={
                submitState.hasSubmitted &&
                (form?.description?.message != null ||
                  !!checkIfError('description'))
              }
              helperText={helperTextGenerator('description')}
              disabled={
                !(isEditing && canEdit(fetchedHardware, 'description', type))
              }
              isEditing={!!isEditing}
              maxLength={255}
              masked
              mask={/^[\w\s]+$/}
            />
            <InputAndLabel
              name='number'
              value={form?.number?.value ?? ''}
              onChange={memoHandleChange('number')}
              label={t('table.number')}
              error={
                submitState.hasSubmitted &&
                (form?.number?.message != null || !!checkIfError('number'))
              }
              helperText={helperTextGenerator('number')}
              disabled={
                !(isEditing && canEdit(fetchedHardware, 'number', type))
              }
              isEditing={!!isEditing}
              max={255}
              min={0}
              step={1}
              type='number'
              number
            />
            <InputAndLabel
              name='magnetometerThreshold'
              value={form?.magnetometerThreshold?.value ?? ''}
              onChange={memoHandleChangeValue('magnetometerThreshold')}
              label={t('table.magnetometer_threshold')}
              error={
                submitState.hasSubmitted &&
                (form?.magnetometerThreshold?.message != null ||
                  !!checkIfError('magnetometerThreshold'))
              }
              helperText={helperTextGenerator('magnetometerThreshold')}
              /*eslint-disable */
              disabled={
                !(
                  isEditing &&
                  canEdit(fetchedHardware, 'magnetometerThreshold', type)
                )
              }
              /* eslint-enable */
              isEditing={!!isEditing}
              number
              max={65535}
              min={0}
            />
            <InputAndLabel
              name='distanceThreshold'
              value={form?.distanceThreshold?.value ?? ''}
              onChange={memoHandleChangeValue('distanceThreshold')}
              label={t('table.distance_threshold')}
              error={
                submitState.hasSubmitted &&
                (form?.distanceThreshold?.message != null ||
                  !!checkIfError('distanceThreshold'))
              }
              helperText={helperTextGenerator('distanceThreshold')}
              /*eslint-disable */
              disabled={
                !(
                  isEditing &&
                  canEdit(fetchedHardware, 'distanceThreshold', type)
                )
              }
              /* eslint-enable */
              isEditing={!!isEditing}
              number
              max={65535}
              min={0}
            />
            <InputAndLabel
              name='softwareVersion'
              value={form?.softwareVersion?.value ?? ''}
              onChange={memoHandleChangeValue('softwareVersion')}
              label={t('table.software_version')}
              error={
                submitState.hasSubmitted &&
                (form?.softwareVersion?.message != null ||
                  !!checkIfError('softwareVersion'))
              }
              helperText={helperTextGenerator('softwareVersion')}
              /*eslint-disable */
              disabled={
                !(
                  isEditing && canEdit(fetchedHardware, 'softwareVersion', type)
                )
              }
              /* eslint-enable */
              isEditing={!!isEditing}
              number
              max={255}
              min={0}
            />
            <SelectAndLabel
              value={form?.allowed?.value ?? ''}
              onChange={memoHandleChangeValue('allowed')}
              label={t('table.allowed')}
              options={memoAllowedOptions}
              error={
                submitState.hasSubmitted &&
                (form?.allowed?.message != null || !!checkIfError('allowed'))
              }
              helperText={helperTextGenerator('allowed')}
              id='allowed' // OBRIGATORIO POR CAUSA DA LABEL/
              disabled={
                !(isEditing && canEdit(fetchedHardware, 'allowed', type))
              }
              isEditing={!!isEditing}
            />
            <SelectAndLabel
              value={form.monitoringType.value ?? ''}
              onChange={memoHandleChangeValue('monitoringType')}
              label={t('table.monitoring_type')}
              options={memoCountingTypeOptions}
              error={
                submitState.hasSubmitted &&
                (form?.monitoringType?.message != null ||
                  !!checkIfError('monitoringType'))
              }
              helperText={helperTextGenerator('monitoringType')}
              id='monitoringType' // OBRIGATORIO POR CAUSA DA LABEL/
              disabled={
                !(isEditing && canEdit(fetchedHardware, 'monitoringType', type))
              }
              isEditing={!!isEditing}
            />
            <SelectAndLabel
              value={form?.area?.value ?? ''}
              onChange={memoHandleChangeValue('area')}
              label={t('table.area')}
              options={memoFetchedAreas}
              error={
                submitState.hasSubmitted &&
                (form?.area?.message != null || !!checkIfError('area'))
              }
              helperText={helperTextGenerator('area')}
              id='area' // OBRIGATORIO POR CAUSA DA LABEL/
              disabled={!(isEditing && canEdit(fetchedHardware, 'area', type))}
              isEditing={!!isEditing}
            />
            <SelectAndLabel
              value={form?.gateway?.value ?? ''}
              onChange={memoHandleChangeValue('gateway')}
              label={t('table.gateway')}
              options={memoFilteredGateways}
              error={
                submitState.hasSubmitted &&
                (form?.gateway?.message != null || !!checkIfError('gateway'))
              }
              helperText={helperTextGenerator('gateway')}
              id='gateway' // OBRIGATORIO POR CAUSA DA LABEL/
              disabled={
                !(isEditing && canEdit(fetchedHardware, 'gateway', type))
              }
              isEditing={!!isEditing}
            />
            <SelectAndLabel
              value={form?.spot?.value?.[0]}
              onChange={memoHandleChangeArray('spot')}
              label={t('table.spots')}
              options={memoAvailableSpots}
              open={spotsOpen}
              onClose={() => setSpotsOpen(false)}
              onOpen={() => setSpotsOpen(true)}
              newButton={memoNewButton}
              error={
                submitState.hasSubmitted &&
                (form?.spot?.message != null || !!checkIfError('spot'))
              }
              helperText={helperTextGenerator('spot')}
              id='spot' // OBRIGATORIO POR CAUSA DA LABEL/
              disabled={!(isEditing && canEdit(fetchedHardware, 'spot', type))}
              isEditing={!!isEditing}
            />
          </>
        )}
        {type === 'gateway' && form != null && (
          <>
            <InputAndLabel
              type='text'
              name='uid'
              value={form?.uid?.value ?? ''}
              onChange={memoHandleChange('uid')}
              label={t('table.uid')}
              error={
                submitState.hasSubmitted &&
                (form?.uid?.message != null || !!checkIfError('uid'))
              }
              helperText={helperTextGenerator('uid')}
              disabled={!(isEditing && canEdit(fetchedHardware, 'uid', type))}
              isEditing={!!isEditing}
              maxLength={255}
              minLength={1}
              masked
              mask={/^\w+$/}
            />
            <InputAndLabel
              type='text'
              name='factoryUid'
              value={form?.factoryUid?.value ?? ''}
              onChange={memoHandleChange('factoryUid')}
              label={t('table.factory_uid')}
              error={
                submitState.hasSubmitted &&
                (form?.factoryUid?.message != null ||
                  checkIfError('factoryUid'))
              }
              helperText={helperTextGenerator('factoryUid')}
              disabled={
                !(isEditing && canEdit(fetchedHardware, 'factoryUid', type))
              }
              isEditing={!!isEditing}
              maxLength={255}
              minLength={1}
              masked
              mask={/^\w+$/}
            />
            <InputAndLabel
              type='text'
              name='name'
              value={form?.name?.value ?? ''}
              onChange={memoHandleChange('name')}
              label={t('table.name')}
              error={
                submitState.hasSubmitted &&
                (form?.name?.message != null || !!checkIfError('name'))
              }
              helperText={helperTextGenerator('name')}
              disabled={!(isEditing && canEdit(fetchedHardware, 'name', type))}
              isEditing={!!isEditing}
              maxLength={255}
              minLength={1}
              masked
              mask={/^[\w\s]+$/}
            />
            <SelectAndLabel
              value={form?.area?.value ?? ''}
              onChange={event => {}}
              label={t('table.area')}
              options={memoFetchedAreas}
              error={
                submitState.hasSubmitted &&
                (form?.area?.message != null || !!checkIfError('area'))
              }
              helperText={helperTextGenerator('area')}
              id='area' // OBRIGATORIO POR CAUSA DA LABEL/
              disabled={!(isEditing && canEdit(fetchedHardware, 'area', type))}
              isEditing={!!isEditing}
            />
            <InputAndLabel
              type='text'
              name='networkKey'
              value={form?.networkKey?.value ?? ''}
              onChange={memoHandleChangeValue('networkKey')}
              label={t('table.network_key')}
              error={
                submitState.hasSubmitted &&
                (form?.networkKey?.message != null ||
                  !!checkIfError('networkKey'))
              }
              helperText={helperTextGenerator('networkKey')}
              disabled={
                !(isEditing && canEdit(fetchedHardware, 'networkKey', type))
              }
              isEditing={!!isEditing}
              maxLength={255}
              minLength={1}
            />
            <SelectAndLabel
              value={form?.type?.value ?? ''}
              onChange={memoHandleChangeEmptyString('type')}
              label={t('table.type')}
              options={memoGatewaysTypeOptions}
              error={
                submitState.hasSubmitted &&
                (form?.type?.message != null || !!checkIfError('type'))
              }
              helperText={helperTextGenerator('type')}
              id='type' // OBRIGATORIO POR CAUSA DA LABEL/
              disabled={!(isEditing && canEdit(fetchedHardware, 'type', type))}
              isEditing={!!isEditing}
            />
            <SelectAndLabel
              multiple
              value={form?.sensors?.value ?? []}
              onChange={memoHandleChangeValue('sensors')}
              label={t('table.sensors')}
              options={memoAvailableSensors}
              error={
                submitState.hasSubmitted &&
                (form?.sensors?.message != null || !!checkIfError('sensors'))
              }
              helperText={helperTextGenerator('sensors')}
              id='sensors' // OBRIGATORIO POR CAUSA DA LABEL/
              disabled={
                !(isEditing && canEdit(fetchedHardware, 'sensors', type))
              }
              isEditing={!!isEditing}
            />
          </>
        )}
        {type === 'panel' && form != null && (
          <>
            <InputAndLabel
              type='text'
              name='uid'
              value={form?.uid?.value ?? ''}
              onChange={memoHandleChange('uid')}
              label={t('table.uid')}
              error={
                submitState.hasSubmitted &&
                (form?.uid?.message != null || !!checkIfError('uid'))
              }
              helperText={helperTextGenerator('uid')}
              disabled={!(isEditing && canEdit(fetchedHardware, 'uid', type))}
              isEditing={!!isEditing}
              maxLength={255}
              minLength={1}
              masked
              mask={/^\w+$/}
            />
            <InputAndLabel
              type='text'
              name='lastValue'
              value={form?.lastValue?.value ?? ''}
              label={t('table.last_value')}
              error={
                submitState.hasSubmitted &&
                (form?.lastValue?.message != null || !!checkIfError('lastValue'))
              }
              helperText={helperTextGenerator('lastValue')}
              disabled
              maxLength={200}
              minLength={1}
            />
            <InputAndLabel
              type='text'
              name='lastSeen'
              value={form?.lastSeen?.value ? moment(form?.lastSeen?.value).format(DATETIME_FORMAT) : ''}
              label={t('table.last_communication')}
              error={
                submitState.hasSubmitted &&
                (form?.lastSeen?.message != null || !!checkIfError('lastSeen'))
              }
              helperText={helperTextGenerator('lastSeen')}
              disabled
              maxLength={200}
              minLength={1}
            />
            <InputAndLabel
              type='text'
              name='name'
              value={form?.name?.value ?? ''}
              onChange={memoHandleChange('name')}
              label={t('table.name')}
              error={
                submitState.hasSubmitted &&
                (form?.name?.message != null || !!checkIfError('name'))
              }
              helperText={helperTextGenerator('name')}
              disabled={!(isEditing && canEdit(fetchedHardware, 'name', type))}
              isEditing={!!isEditing}
              maxLength={255}
              minLength={1}
              masked
              mask={/^[\w\s]+$/}
            />
            <SelectAndLabel
              value={form?.area?.value?.map(el => el?.id ?? el) ?? []}
              onChange={memoHandleChangeValue('area')}
              label={t('table.area')}
              options={memoFetchedAreasMultiple}
              error={
                submitState.hasSubmitted &&
                (form?.area?.message != null || !!checkIfError('area'))
              }
              multiple
              helperText={helperTextGenerator('area')}
              id='area' // OBRIGATORIO POR CAUSA DA LABEL/
              disabled={!(isEditing && canEdit(fetchedHardware, 'area', type))}
              isEditing={!!isEditing}
            />
          </>
        )}
        {type === 'repeater' && form != null && (
          <>
            <InputAndLabel
              type='text'
              name='uid'
              value={form?.uid?.value ?? ''}
              onChange={memoHandleChange('uid')}
              label={t('table.uid')}
              error={
                submitState.hasSubmitted &&
                (form?.uid?.message != null || !!checkIfError('uid'))
              }
              helperText={helperTextGenerator('uid')}
              disabled={!(isEditing && canEdit(fetchedHardware, 'uid', type))}
              isEditing={!!isEditing}
              maxLength={255}
              minLength={1}
              masked
              mask={/^\w+$/}
            />
            <InputAndLabel
              type='text'
              name='name'
              value={form?.name?.value ?? ''}
              onChange={memoHandleChange('name')}
              label={t('table.name')}
              error={
                submitState.hasSubmitted &&
                (form?.name?.message != null || !!checkIfError('name'))
              }
              helperText={helperTextGenerator('name')}
              disabled={!(isEditing && canEdit(fetchedHardware, 'name', type))}
              isEditing={!!isEditing}
              maxLength={255}
              minLength={1}
              masked
              mask={/^[\w\s]+$/}
            />
            <SelectAndLabel
              value={form?.area?.value ?? ''}
              onChange={memoHandleChangeEmptyString('area')}
              label={t('table.area')}
              options={memoFetchedAreas}
              error={
                submitState.hasSubmitted &&
                (form?.area?.message != null || !!checkIfError('area'))
              }
              helperText={helperTextGenerator('area')}
              id='area' // OBRIGATORIO POR CAUSA DA LABEL/
              disabled={!(isEditing && canEdit(fetchedHardware, 'area', type))}
              isEditing={!!isEditing}
            />
          </>
        )}
      </DetailsWrapper>

      <ActionsWrapper isEditing={isEditing ? 1 : 0}>
        {isEditing ? (
          <>
            <StyledButton
              type='inactive'
              onClick={cancelEditing}
              disabled={submitState.submitting}
            >
              {t('cancel')}
            </StyledButton>
            {responsiveHandlers.isDesktop && (
              <>
                <BlankSpace />
                <BlankSpace />
                <BlankSpace />
              </>
            )}
            <StyledButton
              type='primary'
              onClick={handleSubmit}
              disabled={submitState.submitting}
            >
              {t('submit')}
            </StyledButton>
          </>
        ) : (
          <>
            <StyledButton type='inactive' onClick={goBack}>
              {t('assets.back')}
            </StyledButton>
            {responsiveHandlers.isDesktop && (
              <>
                <BlankSpace />
                <BlankSpace />
              </>
            )}
            {hasCRUDPermission && canDelete ? (
              <StyledButton type='error' onClick={handleClickOpen}>
                {t('assets.delete')}
              </StyledButton>
            ) : (
              responsiveHandlers.isDesktop && <BlankSpace />
            )}
            {hasCRUDPermission && (
              <StyledButton type='primary' onClick={startEditing}>
                {t('assets.edit')}
              </StyledButton>
            )}
          </>
        )}
      </ActionsWrapper>
      {isEditing && newSpotOpen && (
        <CreateNewSpotModal
          form={newSpotForm}
          open={newSpotOpen}
          setOpen={setNewSpotOpen}
          handleChange={handleNewSpotChange}
          apiResponse={newSpotState.apiResponse}
          hasSubmitted={newSpotState.hasSubmitted}
          spotTypeOptions={spotTypeOptions}
          fetchedSensors={availableSensors}
          handleSubmit={handleNewSpotSubmit}
          submitting={newSpotState.submitting}
          fetchLocationOptions={logic.fetchLocationOptions}
          sensor={fetchedHardware}
        />
      )}
      <StyledDialog
        open={confirmDeleteOpen}
        handleClose={handleClose}
        title={t('assets.delete_title')}
        description={t('assets.delete_description')}
        handleSubmit={handleDelete}
        submitText={t('assets.delete')}
        submitType='danger'
      />
    </>
  )
})

const ActionsWrapper = styled.div`
  display: grid;
  margin: 1.5rem 0 0;

  grid-template-columns: minmax(10rem, 1fr);
  row-gap: 1rem;

  ${({ theme }) => theme.smallDesktop`
  grid-template-columns: repeat(5, minmax(12.375rem, 1fr));
  column-gap: 1.5rem;
  margin: 2.5rem 0 0;


  ${props =>
    props.isEditing &&
    css`
      grid-template-columns: repeat(5, minmax(12.375rem, 1fr));
    `}
`}
`

const BlankSpace = styled.div`
  grid-column: span 1;
`

const DetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  flex: 0 0 auto;

  ${({ theme }) => theme.smallDesktop`
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 0.75rem;


`}
`

const NewButtonWrapper = styled.div`
  width: 100%;
  padding: 0.5rem 1.5rem;
  background-color: var(--white-color);
  position: sticky;
  top: 0rem;
  z-index: 10;
  display: flex;
  margin-top: -0.5rem;

  &:focus {
    outline: none;
  }
`

const MapHelperText = styled.p`
  color: var(--primary-color);
  font-size: 0.875rem;
  font-style: italic;
  letter-spacing: 0;
  line-height: 1em;

  margin: 0.5rem 0 0;

  ${props =>
    props.error &&
    css`
      color: var(--error-color);
    `}
`

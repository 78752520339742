import React, { memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import isEqual from 'lodash.isequal'

import InputAndLabel from 'containers/InputAndLabel'
import MapContainer from 'containers/MapContainer'

import {
  SameLineInputs,
  MapHelperText,
  MapAndLabel,
  MapWrapper
} from '../styles'

const InfoSensor = ({
  form,
  hasSubmitted,
  locationSelected,
  textInput,
  searchOptions,
  handleChange,
  checkIfError,
  helperTextGenerator,
  setTextInput,
  setLocationSelected,
  mapSearchProps
}) => {
  const { t } = useTranslation()

  const memoHandleChange = useCallback(name => e => handleChange(name, e), [
    handleChange
  ])

  const memoHandleChangeValue = useCallback(
    name => e => handleChange(name, e?.target?.value),
    [handleChange]
  )

  const flyToLocation = useMemo(
    () =>
      locationSelected?.boundingbox != null
        ? [
          [
              locationSelected?.boundingbox?.[2],
              locationSelected?.boundingbox?.[0]
          ],
          [
              locationSelected?.boundingbox?.[3],
              locationSelected?.boundingbox?.[1]
          ]
        ]
        : null,
    [locationSelected?.boundingbox]
  )

  return (
    <>
      <SameLineInputs>
        <InputAndLabel
          type='text'
          name='name'
          value={form?.name?.value ?? ''}
          onChange={memoHandleChange('name')}
          label={t('table.name')}
          error={
            hasSubmitted &&
            (form?.name?.message != null || !!checkIfError('name'))
          }
          helperText={helperTextGenerator('name')}
          maxLength={255}
          masked
          mask={/^[\w\s]+$/}
        />
      </SameLineInputs>
      <MapAndLabel>
        <MapWrapper
          error={
            hasSubmitted &&
            (form?.geometry?.message != null || !!checkIfError('geometry'))
          }
        >
          <MapContainer
            data={
              form?.geometry?.value ? [{ geometry: form?.geometry?.value }] : []
            }
            hideLegends
            type='assets'
            isDrawAvailable
            setGeometry={memoHandleChange('geometry')}
            enableSearch
            search={mapSearchProps}
            flyToLocation={flyToLocation}
          />
        </MapWrapper>
        <MapHelperText
          error={
            hasSubmitted &&
            (form?.geometry?.message != null || !!checkIfError('geometry'))
          }
        >
          {checkIfError('geometry') || t('map.asset_helper_text')}
        </MapHelperText>
      </MapAndLabel>

      <SameLineInputs>
        <InputAndLabel
          type='text'
          name='uid'
          value={form?.uid?.value ?? ''}
          onChange={memoHandleChange('uid')}
          label={t('table.uid')}
          error={
            hasSubmitted &&
            (form?.uid?.message != null || !!checkIfError('uid'))
          }
          helperText={helperTextGenerator('uid')}
          maxLength={255}
          minLength={1}
          masked
          mask={/^\w+$/}
        />
        <InputAndLabel
          type='text'
          name='factoryUid'
          value={form?.factoryUid?.value ?? ''}
          onChange={memoHandleChange('factoryUid')}
          label={t('table.factory_uid')}
          error={
            hasSubmitted &&
            (form?.factoryUid?.message != null || !!checkIfError('factoryUid'))
          }
          helperText={helperTextGenerator('factoryUid')}
          maxLength={255}
          minLength={1}
          masked
          mask={/^\w+$/}
        />
      </SameLineInputs>

      <SameLineInputs>
        <InputAndLabel
          name='magnetometerThreshold'
          value={form?.magnetometerThreshold?.value ?? ''}
          onChange={memoHandleChangeValue('magnetometerThreshold')}
          label={t('table.magnetometer_threshold')}
          error={
            hasSubmitted &&
            (form?.magnetometerThreshold?.message != null ||
              !!checkIfError('magnetometerThreshold'))
          }
          helperText={helperTextGenerator('magnetometerThreshold')}
          number
          max={65535}
          min={0}
        />
      </SameLineInputs>
    </>
  )
}

export default memo(InfoSensor, isEqual)

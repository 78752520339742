import React, { useState, useContext, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Context as AuthContext } from 'providers/AuthProvider/authProvider'

import { Link } from 'react-router-dom'

import TitleStyle from 'styled/TitleStyle'

import Container from 'components/Container'
import StyledButton from 'components/StyledButton'

import InputAndLabel from 'containers/InputAndLabel'

import Background from 'images/background_smart_parking.jpg'

import logic from './logic'

const Login = ({ children, ...props }) => {
  const { actions } = useContext(AuthContext)
  const { t } = useTranslation()
  const [form, setForm] = useState(logic.defaultValues)

  const [submitState, setSubmitState] = useState({
    hasSubmitted: false,
    submitting: false,
    error: null
  })

  const memoSetForm = useCallback(form => setForm({ ...form }), [])

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      // juntar estes states
      setSubmitState(submitState => ({
        ...submitState,
        error: null,
        hasSubmitted: true,
        submitting: true
      }))
      logic
        .login(form, memoSetForm)
        .then(response => {
          setSubmitState(submitState => ({
            ...submitState,
            submitting: false
          }))
          const { data } = response
          actions.login({ ...data })
        })
        .catch(err => {
          console.log({ err })

          if (err == null || err.response == null) {
            actions.serverError()
          }
          if (err.response != null && err.response.data != null) {
            setSubmitState(submitState => ({
              ...submitState,
              submitting: false,
              error: t('errors.login')
            }))
          } else {
            setSubmitState(submitState => ({
              ...submitState,
              submitting: false
            }))
          }
        })
    },
    [actions, form, t, memoSetForm]
  )

  const handleChange = useCallback(
    (key, value) => {
      setForm({
        ...form,
        [key]: {
          ...form[key],
          value: value
        }
      })
    },
    [form]
  )

  const memoHandleChangeValue = useCallback(
    name => e => {
      handleChange(name, e.target.value)
    },
    [handleChange]
  )

  return (
    <Wrapper>
      <Overlay />
      <BackgroundImage
        src={Background}
        alt='parking image'
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: -10,
          left: 0,
          top: 0,
          objectFit: 'cover',
          opacity: 0.3
        }}
        loading='eager'
      />
      <Container height='100%'>
        {form != null && (
          <StyledForm onSubmit={handleSubmit}>
            <Title>{t('login')}</Title>
            <InputWrapper>
              <InputAndLabel
                type='text'
                value={form?.username?.value ?? ''}
                name='username'
                label={t('username')}
                onChange={memoHandleChangeValue('username')}
                error={
                  submitState.hasSubmitted && form?.username?.message != null
                }
                helperText={
                  submitState.hasSubmitted && form?.username?.message != null
                    ? t(`errors.${form.username.message}`)
                    : ''
                }
                autoComplete='username'
              />
            </InputWrapper>
            <InputWrapper>
              <InputAndLabel
                type='password'
                value={form?.password?.value ?? ''}
                name='password'
                onChange={memoHandleChangeValue('password')}
                label={t('password')}
                error={
                  submitState.hasSubmitted && form?.password?.message != null
                }
                helperText={
                  submitState.hasSubmitted && form?.password?.message != null
                    ? t(`errors.${form.password.message}`)
                    : ''
                }
                autoComplete='current-password'
              />
            </InputWrapper>

            {submitState?.error != null && (
              <ErrorText>{submitState?.error}</ErrorText>
            )}
            <ButtonWrapper>
              <StyledButton type='primary' disabled={submitState.submitting}>
                {t('login')}
              </StyledButton>
            </ButtonWrapper>
            <ForgotPassword to='/recover/'>
              {t('recover_password')}
            </ForgotPassword>
          </StyledForm>
        )}
      </Container>
    </Wrapper>
  )
}

Login.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string
}

export default Login

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -10;
  left: 0;
  top: 0;
  object-fit: cover;
  opacity: 0.3;
  background: ${({ src }) => `url(${src}) center / cover`};
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  opacity: 0.25;
  background-color: var(--overlay-background-color);
`

const ForgotPassword = styled(Link)`
  width: fit-content;
  margin: 1rem 0 0 auto;
  font-size: 0.875rem;
  text-decoration: underline;
  color: var(--primary-color);
  transition: opacity 0.3s ease-in-out;

  &:hover {
    transition: opacity 0.3s ease-in-out;
    opacity: 0.5;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  height: ${({ height }) => height};
  position: relative;
  overflow: visible;

  background-color: var(--backgroud-color);
`

const StyledForm = styled.form`
  grid-column: 1/5;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;

  padding: 2rem;
  margin: auto 0;

  border: 1px solid var(--platinium-gray-color);
  box-shadow: 0 0 0.375rem 0 var(--box-shadow-black-half-opacity);

  ${({ theme }) => theme.smallDesktop`
    grid-column: 5/9;

  `}
`

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  display: grid;
  grid-template-columns: minmax(12.375rem, 1fr);
`

const ErrorText = styled.p`
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  line-heihgt: 1em;
  color: var(--error-color);
`

const Title = styled.h1`
  ${TitleStyle};
  margin-bottom: 1.5rem;
`

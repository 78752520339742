import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={24}
      height={24}
      viewBox='0 0 24 24'
      {...props}
    >
      <defs>
        <path
          d='M12.08 1c-2.69 0-5.22.99-7.13 2.79C3.05 5.59 2 7.98 2 10.52s1.05 4.93 2.95 6.73l5.36 5.06c.47.44 1.1.69 1.77.69.67 0 1.3-.25 1.78-.69.53-.51 4.82-4.55 5.35-5.06 1.91-1.8 2.96-4.19 2.96-6.73s-1.05-4.93-2.96-6.73C17.31 1.99 14.78 1 12.08 1z'
          id='prefix__a__cluster'
        />
        <path
          d='M18.87 4.15c1.81 1.71 2.8 3.97 2.8 6.37s-.99 4.66-2.8 6.37c.02.02.14.14.34.36l-.34-.36-5.36 5.06.35.36c-.21-.22-.32-.34-.35-.36-.37.35-.88.55-1.43.55-.54 0-1.05-.2-1.43-.55-.35-.34-2.14-2.03-5.35-5.06-1.81-1.71-2.8-3.97-2.8-6.37s.99-4.66 2.8-6.37C7.11 2.44 9.51 1.5 12.08 1.5c2.57 0 4.98.94 6.79 2.65z'
          id='prefix__b__cluster'
        />

      </defs>
      <use xlinkHref='#prefix__a__cluster' fill='#0087a7' />
      <use
        xlinkHref='#prefix__a__cluster'
        fillOpacity={0}
        stroke='#000'
        strokeOpacity={0}
      />
      <use xlinkHref='#prefix__b__cluster' fillOpacity={0} />
      <use
        xlinkHref='#prefix__b__cluster'
        fillOpacity={0}
        stroke='#fff'
        strokeOpacity={0.2}
      />
      <g>
        <text
          id='prefix__c__cluster'
          x='50%'
          y='45%'
          fontSize={8}
          fontFamily='Roboto'
          fontWeight='bold'
          fontStyle='normal'
          letterSpacing={0}
          alignmentBaseline='middle'
          style={{
            lineHeight: '100%'
          }}
          fill='#FFFFFF'
          xmlSpace='preserve'
          dominantBaseline='middle'
          textAnchor='middle'
        >
          <tspan dx={0} dy='0em' alignmentBaseline='middle' textAnchor='middle'>
            {props.text ?? ''}
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export default SvgComponent

/**
 * -----------------------------------------------------------------------------
 * Copyright (C) Ubiwhere, LDA
 * 2012-2018
 *
 *
 * The reproduction, transmission or use of this document or its contents is not
 * permitted without express written authorization. All rights, including rights
 * created by patent grant or registration of a utility model or design, are
 * reserved. Modifications made to this document are restricted to authorized
 * personnel only. Technical specifications and features are binding only when
 * specifically and expressly agreed upon in a written contract.
 * -----------------------------------------------------------------------------
 *
 * Router
 * Add new routes here
 */
import toArray from 'lodash/toArray'

// Import page components bellow here
// Keep them organized as the routes object
import HomePage from 'scenes/HomePage'
import Login from 'scenes/Login'
import NotFound from 'scenes/NotFound'

import Areas from 'scenes/Areas'
import Hardware from 'scenes/Hardware'
import Events from 'scenes/Events'
import Spots from 'scenes/Spots'
import ServerError from 'scenes/ServerError'
import RecoverPassword from 'scenes/RecoverPassword'
import Metrics from 'scenes/Metrics'
import PasswordSet from 'scenes/PasswordSet'
import RedirectToMobile from 'scenes/RedirectToMobile'
// Define routes here
export const Routes = {
  Login: {
    key: 'login',
    name: 'Login',
    path: '/login/',
    component: Login,
    crumb: ['inicio'],
    exact: true
  },

  HomePage: {
    key: 'homepage',
    name: 'HomePage',
    path: '/',
    component: HomePage,
    crumb: ['inicio'],
    exact: true,
    private: true
  },

  Areas: {
    key: 'areas',
    name: 'Areas',
    path: '/areas/',
    component: Areas,
    crumb: ['inicio'],
    exact: false,
    private: true
  },

  Hardware: {
    key: 'assets',
    name: 'Hardware',
    path: '/assets/',
    component: Hardware,
    crumb: ['inicio'],
    exact: false,
    private: true
  },

  Events: {
    key: 'events',
    name: 'Events',
    path: '/events/',
    component: Events,
    crumb: ['inicio'],
    exact: true,
    private: true
  },

  Spots: {
    key: 'spots',
    name: 'Spots',
    path: '/spots/',
    component: Spots,
    crumb: ['inicio'],
    exact: false,
    private: true
  },

  ServerError: {
    key: 'ServerError',
    name: 'ServerError',
    path: '/error/',
    component: ServerError,
    crumb: ['inicio'],
    exact: true,
    private: false
  },

  RecoverPassword: {
    key: 'RecoverPassword',
    name: 'RecoverPassword',
    path: '/recover/',
    component: RecoverPassword,
    crumb: ['inicio'],
    exact: true,
    private: false
  },

  Metrics: {
    key: 'Metrics',
    name: 'Metrics',
    path: '/metrics/',
    component: Metrics,
    crumb: ['inicio'],
    exact: true,
    private: true
  },
  ActivateAccountMobile: {
    key: 'ActivateAccountMobile',
    name: 'ActivateAccountMobile',
    path: '/activate_account/',
    component: RedirectToMobile,
    crumb: ['inicio'],
    private: false
  },
  PasswordSetMobile: {
    key: 'PasswordSetMobile',
    name: 'PasswordSetMobile',
    path: '/mobile/password/set/',
    component: RedirectToMobile,
    crumb: ['inicio'],
    exact: true,
    private: false
  },
  PasswordSet: {
    key: 'PasswordSet',
    name: 'PasswordSet',
    path: '/password/set/',
    component: PasswordSet,
    crumb: ['inicio'],
    exact: true,
    private: false
  },

  notfound: {
    key: 'notfound',
    name: 'NotFound',
    path: '/not-found/',
    component: NotFound,
    crumb: ['inicio'],
    exact: true,
    private: false
  }
}

// Maps don't work on object convert it to an array
export default toArray(Routes)

import React, { memo } from 'react'
import styled from 'styled-components'

const StyledMobileTable = ({ children, ...props }) => {
  const { data, template: Template, setInDetail } = props
  return (
    <Wrapper margin={props.margin}>
      {data &&
        data.map((el, index) => (
          <Template key={index} element={el} setInDetail={setInDetail} />
        ))}
    </Wrapper>
  )
}

export default memo(StyledMobileTable)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  margin: ${props => props.margin};
`

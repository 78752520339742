import axios from 'axios'

import { BASE_API_URL } from 'config'

const AREAS_URL = BASE_API_URL + '/areas/'
const DETAILS_URL = BASE_API_URL + '/areas' // without / to better understand on axios
const ALARMS_URL = BASE_API_URL + '/alarms/'
const ALARMS_FILTERED_URL = ALARMS_URL + '?type=forbidden_transit&type=area_maxed_out&type=motorhome_forbidden_parking'

const logic = {
  timestampOptions: [
    {
      label: 'last_30_min',
      value: 'last_30_min'
    },
    {
      label: 'last_hour',
      value: 'last_hour'
    },
    {
      label: 'today',
      value: 'today'
    }
  ],
  getAreas: () => {
    return Promise.resolve().then(() => {
      return axios.get(AREAS_URL)
    })
  },
  getAlarmsFiltered: (date, limit, offset) => {
    return Promise.resolve().then(() => {
      return axios.get(`${ALARMS_FILTERED_URL}&limit=${limit}&offset=${offset}&updated_at__gte=${date}`)
    })
  },
  getAreaDetails: id =>
    Promise.resolve().then(() => {
      return axios.get(`${DETAILS_URL}/${id}/`)
    })
}

export default logic

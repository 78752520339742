import axios from 'axios'

import { BASE_API_URL, NOMINATIM_BASE_URL, NOMINATIM_OPTIONS } from 'config'

const AREAS_URL = BASE_API_URL + '/areas/detailed/'
const DETAILS_URL = BASE_API_URL + '/areas' // without / to better understand on axios

const SEARCH_URL = BASE_API_URL + '/areas/search/'
const CSV_URL = BASE_API_URL + '/areas/csv/'

const NOMINATIM_SEARCH_URL = NOMINATIM_BASE_URL + '/search'

const logic = {
  getAreas: options => {
    return Promise.resolve().then(() => {
      return axios.get(AREAS_URL, options)
    })
  },
  fetchAreasOptions: options => {
    return Promise.resolve().then(() => axios.get(SEARCH_URL, options))
  },
  fetchLocationOptions: options =>
    Promise.resolve().then(() =>
      axios.get(NOMINATIM_SEARCH_URL, {
        ...options,
        params: {
          ...options.params,
          ...NOMINATIM_OPTIONS
        }
      })
    ),
  uploadCSV: options =>
    Promise.resolve().then(() =>
      axios.post(CSV_URL, options, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    ),
  getAreaDetails: id =>
    Promise.resolve().then(() => {
      return axios.get(`${DETAILS_URL}/${id}/`)
    })
}

export default logic

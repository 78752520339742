import axios from 'axios'
import mapValues from 'lodash/mapValues'
import ValidationError from 'utils/ValidationError'

import * as Check from 'utils/validations'

import { BASE_API_URL, NOMINATIM_BASE_URL, NOMINATIM_OPTIONS } from 'config'

const NEWASSET_URL = {
  sensor: BASE_API_URL + '/sensors/',
  gateway: BASE_API_URL + '/gateways/',
  panel: BASE_API_URL + '/panels/',
  repeater: BASE_API_URL + '/repeaters/'
}

const NOMINATIM_SEARCH_URL = NOMINATIM_BASE_URL + '/search'

export const types = ['sensor', 'gateway', 'panel', 'repeater']

export const STEP_VALIDATIONS = {
  sensor: {
    info_sensor: {
      name: [],
      geometry: [Check.isRequired],
      uid: [Check.isRequired],
      factoryUid: [Check.isRequired],
      // number: [Check.isRequired],
      magnetometerThreshold: [Check.isRequired]
    },
    submit_sensor: {
      description: [],
      monitoringType: [Check.isRequired],
      distanceThreshold: [Check.isRequired],
      area: [],
      gateway: [],
      allowed: [Check.isRequired],
      softwareVersion: []
    }
  },
  gateway: {
    submit_gateways: {
      uid: [],
      area: [],
      name: [],
      // networkKey: [],
      sensors: []
    }
  },
  panel: {
    submit_panels: {
      uid: [Check.isRequired],
      area: [Check.isRequired],
      name: [Check.isRequired],
      geometry: [Check.isRequired]
    }
  },
  repeater: {
    submit_repeater: {
      uid: [Check.isRequired],
      area: [Check.isRequired],
      name: [Check.isRequired],
      geometry: [Check.isRequired],
      factoryUid: [Check.isRequired]

    }
  }
}

const VALIDATIONS = {
  sensor: {
    uid: [Check.isRequired],
    factoryUid: [Check.isRequired],
    area: [],
    description: [],
    gateway: [],
    geometry: [Check.isRequired],
    monitoringType: [Check.isRequired],
    name: [],
    // number: [Check.isRequired],
    magnetometerThreshold: [Check.isRequired],
    distanceThreshold: [Check.isRequired],
    allowed: [Check.isRequired],
    softwareVersion: []
  },
  gateway: {
    uid: [Check.isRequired],
    factoryUid: [Check.isRequired],
    geometry: [Check.isRequired],
    area: [],
    name: [],
    // networkKey: [],
    type: [Check.isRequired]
  },
  repeater: {
    uid: [Check.isRequired],
    area: [Check.isRequired],
    name: [Check.isRequired],
    geometry: [Check.isRequired],
    factoryUid: [Check.isRequired]

  },
  panel: {
    uid: [Check.isRequired],
    area: [Check.isRequired],
    name: [Check.isRequired],
    lastValue: [Check.isRequired],
    lastSeen: [Check.isRequired],
    geometry: [Check.isRequired]
  }
}

const logic = {
  defaultValues: {
    sensor: {
      uid: { value: null },
      factoryUid: { value: null },
      area: { value: null },
      description: { value: null },
      gateway: { value: null },
      geometry: { value: null },
      monitoringType: { value: null },
      name: { value: null },
      // number: { value: null },
      magnetometerThreshold: { value: null },
      distanceThreshold: { value: null },
      allowed: { value: true },
      softwareVersion: { value: null }
    },
    gateway: {
      uid: { value: null },
      factoryUid: { value: null },
      area: { value: null },
      name: { value: null },
      // networkKey: { value: null },
      type: { value: null },
      geometry: { value: null }
    },
    panel: {
      uid: { value: null },
      area: { value: null },
      name: { value: null },
      lastValue: { value: null },
      lastSeen: { value: null },
      geometry: { value: null }
    },
    repeater: {
      uid: { value: null },
      area: { value: null },
      name: { value: null },
      geometry: { value: null },
      factoryUid: { value: null }
    }
  },
  submitNewHardware: (type, form, setForm) =>
    Promise.resolve().then(() => {
      const validation = Check.checkValidation(form, VALIDATIONS[type])
      if (validation.invalid) {
        setForm({ ...validation.form })
        throw new ValidationError()
      }

      const params = mapValues(form, ({ value }) => value)
      return axios.post(NEWASSET_URL[type], { ...params })
    }),
  validateStepForm: (type, step, form, setForm) =>
    Promise.resolve().then(() => {
      if (type != null && step != null && form != null) {
        const validation = Check.checkValidation(
          form,
          STEP_VALIDATIONS[type][step]
        )
        if (validation.invalid) {
          setForm({ ...validation.form })
          throw new ValidationError()
        }
        return true
      } else {
        throw new ValidationError()
      }
    }),
  fetchLocationOptions: options =>
    Promise.resolve().then(() =>
      axios.get(NOMINATIM_SEARCH_URL, {
        ...options,
        params: {
          ...options.params,
          ...NOMINATIM_OPTIONS
        }
      })
    )
}

export default logic

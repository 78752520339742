import React, { useCallback, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import StyledModal from 'components/StyledModal'
import StyledButton from 'components/StyledButton'

import InputAndLabel from 'containers/InputAndLabel'
import SelectAndLabel from 'containers/SelectAndLabel'
import MapContainer from 'containers/MapContainer'

import isEqual from 'lodash.isequal'

import {
  NewAreaWrapper,
  SameLineInputs,
  NewAreaMapAndLabel,
  NewAreaMapWrapper,
  ModalTitle,
  NewAreaMapHelperText,
  NewAreaButtonWrapper,
  Blank
} from './styles'

const CreateNewAreaModal = props => {
  const {
    open,
    handleClose,
    form,
    handleChange,
    checkIfError,
    handleSubmit,
    monitoringTypeOptions,
    submitState,
    textInput,
    setTextInput,
    responsiveHandlers,
    searchOptions,
    locationSelected,
    setLocationSelected
  } = props

  const { t } = useTranslation()

  const helperTextGenerator = useCallback(
    property => {
      if (submitState.hasSubmitted && form[property] != null) {
        const apiError = checkIfError(property)
        if (apiError != null) {
          return apiError
        } else if (form[property].message) {
          return t(`errors.${form[property].message}`)
        }
      }
      return null
    },
    [submitState.hasSubmitted, form, checkIfError, t]
  )

  const memoHandleChange = useCallback(name => e => handleChange(name, e), [
    handleChange
  ])
  const memoHandleChangeValue = useCallback(
    name => e => handleChange(name, e?.target?.value),
    [handleChange]
  )

  const onSearchChange = useCallback(event => setTextInput(event), [
    setTextInput
  ])
  const onSearchInputChange = useCallback(
    (event, newValue) => setLocationSelected(newValue),
    [setLocationSelected]
  )

  const getOptionLabel = useCallback(option => option.displayName, [])

  const mapSearchProps = useMemo(
    () => ({
      value: textInput,
      onChange: onSearchChange,
      inputValue: locationSelected,
      onInputChange: onSearchInputChange,
      options: searchOptions,
      label: t('map.search_location'),
      getOptionLabel: getOptionLabel
    }),
    [
      getOptionLabel,
      searchOptions,
      onSearchInputChange,
      locationSelected,
      onSearchChange,
      textInput,
      t
    ]
  )

  const flyToLocation = useMemo(
    () =>
      locationSelected?.boundingbox != null
        ? [
          [
              locationSelected?.boundingbox?.[2],
              locationSelected?.boundingbox?.[0]
          ],
          [
              locationSelected?.boundingbox?.[3],
              locationSelected?.boundingbox?.[1]
          ]
        ]
        : null,
    [locationSelected?.boundingbox]
  )

  return (
    <StyledModal open={open} onClose={handleClose}>
      <NewAreaWrapper>
        <ModalTitle>{t('areas.new')}</ModalTitle>
        <SameLineInputs>
          <InputAndLabel
            type='text'
            name='name'
            value={form?.name?.value ?? ''}
            onChange={memoHandleChange('name')}
            label={t('table.name')}
            error={
              submitState.hasSubmitted &&
              (form?.name?.message != null || !!checkIfError('name'))
            }
            helperText={helperTextGenerator('name')}
            maxLength={254}
            minLength={1}
            masked
            mask={/^[\w\s]+$/}
          />

          <InputAndLabel
            type='text'
            name='uid'
            value={form?.uid?.value ?? ''}
            onChange={memoHandleChange('uid')}
            label={t('table.uid')}
            error={
              submitState.hasSubmitted &&
              (form?.uid?.message != null || !!checkIfError('uid'))
            }
            helperText={helperTextGenerator('uid')}
            maxLength={255}
            minLength={1}
            masked
            mask={/^\w+$/}
          />
        </SameLineInputs>
        <NewAreaMapAndLabel>
          <NewAreaMapWrapper
            error={
              submitState.hasSubmitted &&
              form.geometry != null &&
              (form.geometry.message != null || !!checkIfError('geometry'))
            }
          >
            <MapContainer
              data={form?.geometry?.value ? [form.geometry.value] : []}
              hideLegends
              type='areas'
              isDrawAvailable
              setGeometry={memoHandleChange('geometry')}
              enableSearch
              search={mapSearchProps}
              flyToLocation={flyToLocation}
            />
          </NewAreaMapWrapper>
          <NewAreaMapHelperText
            error={
              submitState.hasSubmitted &&
              (form?.geometry?.message != null || !!checkIfError('geometry'))
            }
          >
            {checkIfError('geometry') || t('map.area_helper_text')}
          </NewAreaMapHelperText>
        </NewAreaMapAndLabel>

        <SameLineInputs>
          <InputAndLabel
            type='text'
            name='address'
            value={form?.address?.value ?? ''}
            onChange={memoHandleChange('address')}
            label={t('table.address')}
            error={
              submitState.hasSubmitted &&
              (form?.address?.message != null || !!checkIfError('address'))
            }
            helperText={helperTextGenerator('address')}
            maxLength={255}
            masked
            mask={/^[\w\s]+$/}
          />
          <InputAndLabel
            name='capacity'
            value={form?.capacity?.value ?? ''}
            onChange={memoHandleChangeValue('capacity')}
            label={t('table.capacity')}
            error={
              submitState.hasSubmitted &&
              form.capacity != null &&
              (form.capacity.message != null || !!checkIfError('capacity'))
            }
            helperText={helperTextGenerator('capacity')}
            number
            max={2147483647}
            min={1}
          />
        </SameLineInputs>

        <SameLineInputs>
          <InputAndLabel
            type='text'
            name='description'
            value={form?.description?.value ?? ''}
            onChange={memoHandleChange('description')}
            label={t('table.description')}
            error={
              submitState.hasSubmitted &&
              (form?.description?.message != null ||
                !!checkIfError('description'))
            }
            helperText={helperTextGenerator('description')}
            maxLength={254}
            masked
            mask={/^[\w\s]+$/}
          />
          <InputAndLabel
            name='table.capacityMargin'
            value={form?.capacityMargin?.value ?? ''}
            onChange={memoHandleChangeValue('capacityMargin')}
            label={t('table.capacity_margin')}
            error={
              submitState.hasSubmitted &&
              (form?.capacityMargin?.message != null ||
                !!checkIfError('capacityMargin'))
            }
            helperText={helperTextGenerator('capacityMargin')}
            number
            max={99}
            min={1}
          />
        </SameLineInputs>

        <SameLineInputs>
          <SelectAndLabel
            value={form?.monitoringType?.value ?? ''}
            onChange={memoHandleChangeValue('monitoringType')}
            label={t('table.monitoring_type')}
            options={monitoringTypeOptions}
            id='monitoringType' // OBRIGATORIO POR CAUSA DA LABEL/
            error={
              submitState.hasSubmitted &&
              (form?.monitoringType?.message != null ||
                !!checkIfError('monitoringType'))
            }
            helperText={helperTextGenerator('monitoringType')}
          />
        </SameLineInputs>
        <NewAreaButtonWrapper>
          {responsiveHandlers.isDesktop && (
            <>
              <Blank />
              <Blank />
            </>
          )}
          <StyledButton
            type='primary'
            onClick={handleSubmit}
            disabled={submitState.submitting}
          >
            {t('create')}
          </StyledButton>
        </NewAreaButtonWrapper>
      </NewAreaWrapper>
    </StyledModal>
  )
}

export default memo(CreateNewAreaModal, isEqual)

import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg viewBox='0 0 30 22' {...props}>
      <g fillRule='nonzero' fill={props.fill || '#FFF'}>
        <path d='M14.976 21.226a2.58 2.58 0 110-5.16 2.58 2.58 0 010 5.16zM7.677 13.346a1.29 1.29 0 001.825 1.825 7.742 7.742 0 0110.95 0 1.29 1.29 0 001.824-1.825c-4.032-4.032-10.567-4.032-14.599 0z' />
        <path d='M4.028 9.697a1.29 1.29 0 001.824 1.824c5.04-5.039 13.21-5.039 18.248 0a1.29 1.29 0 101.825-1.824c-6.046-6.048-15.85-6.048-21.897 0z' />
        <path d='M29.574 6.047c-8.062-8.063-21.133-8.063-29.196 0a1.29 1.29 0 001.825 1.825C9.258.817 20.695.817 27.75 7.872a1.29 1.29 0 001.824-1.825z' />
      </g>
    </svg>
  )
}

export default SvgComponent

export const typeOptions = [
  {
    label: 'all_filter',
    value: ''
  },
  {
    label: 'sensor',
    value: 'sensor'
  },
  {
    label: 'gateway',
    value: 'gateway'
  },
  {
    label: 'panel',
    value: 'panel'
  },
  {
    label: 'repeater',
    value: 'signal_repeater'
  },
  {
    label: 'panel_monitoring_equipment',
    value: 'panel_monitoring_equipment'
  }
]

export const countingTypeOptions = [
  {
    label: 'video_feed',
    value: 'video_feed'
  },
  {
    label: 'counting_sensor',
    value: 'counting_sensor'
  },
  {
    label: 'individual_sensor',
    value: 'individual_sensor'
  }
]

export const statusOptions = [
  {
    label: 'all_filter',
    value: ''
  },
  {
    label: 'ok',
    value: 'ok'
  },
  {
    label: 'not_ok',
    value: 'not_ok'
  },
  {
    label: 'unknown',
    value: 'unknown'
  }
]

export const allowedOptions = [
  {
    value: true,
    label: 'true'
  },
  {
    value: false,
    label: 'false'
  }
]

export const monitoringOptions = [
  {
    label: 'individual_sensor',
    value: 'individual_sensor'
  },
  {
    label: 'video_feed',
    value: 'video_feed'
  },
  {
    label: 'counting_sensor',
    value: 'counting_sensor'
  }
]

export const gatewaysTypeOptions = [
  {
    label: 'configuration',
    value: 'configuration'
  },
  {
    label: 'communication',
    value: 'communication'
  }
]

export const monitoringTypeOptions = [
  {
    label: 'individual_counting',
    value: 'individual_counting'
  },
  {
    label: 'global_counting',
    value: 'global_counting'
  },
  {
    label: 'global_with_reserved_counting',
    value: 'global_with_reserved_counting'
  }
]

export const intervalOptions = [
  {
    value: 'day',
    label: 'day'
  },
  {
    value: 'month',
    label: 'month'
  },
  {
    value: 'year',
    label: 'year'
  }
]

export const occupiedSearchOptions = [
  {
    label: 'all_filter',
    value: ''
  },
  {
    label: 'occupied',
    value: 'true'
  },
  {
    label: 'free',
    value: 'false'
  }
]

export const occupiedOptions = [
  {
    label: 'true',
    value: 'true'
  },
  {
    label: 'false',
    value: 'false'
  }
]

export const occupationOptions = [
  {
    label: 'all_filter',
    value: ''
  },
  {
    label: 'low',
    value: 'low'
  },
  {
    label: 'medium',
    value: 'medium'
  },
  {
    label: 'high',
    value: 'high'
  },
  {
    label: 'full',
    value: 'full'
  },
  {
    label: 'maxed_out',
    value: 'maxed_out'
  }
]

import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import Button from '@material-ui/core/Button'

import Tooltip from '@material-ui/core/Tooltip'

export default memo(({ children, ...props }) => {
  const button = (
    <Wrapper {...props} variant='outlined'>
      {children}
    </Wrapper>
  )
  if (props.tooltip != null) {
    return (
      <Tooltip title={props.tooltip} aria-label={props.tooltip}>
        {button}
      </Tooltip>
    )
  }
  return button
})

const Wrapper = styled(Button)`
  &.MuiButton-outlined {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
  }
  &.MuiButton-root {
    min-width: unset;
    min-height: 2.5rem;
    width: 100%;
    width: ${({ width }) => width};
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1em;
    text-align: center;
    text-transform: uppercase;
    border-radius: 0;

    color: var(--black-color);

    &.MuiButton-outlined.Mui-disabled{
      border: none;
    }

    &.MuiButton-root.Mui-disabled{
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }

    ${props =>
      props.type === 'primary' &&
      css`
        background-color: var(--primary-color);
        color: var(--white-color);
        transition: background-color 0.2s ease-in-out;
        border: none;

        &:hover {
          background-color: var(--darker-primary-color);
          transition: background-color 0.2s ease-in-out;
        }
      `}

    ${props =>
      props.type === 'inactive' &&
      css`
        background-color: var(--breadcrumb-gray-color);
        color: var(--white-color);
        transition: background-color 0.2s ease-in-out;
        border: none;

        &:hover {
          background-color: var(--dark-gray-color);
          transition: background-color 0.2s ease-in-out;
        }
      `}

    ${props =>
      (props.type === 'error' || props.type === 'danger') &&
      css`
        background-color: var(--error-color);
        color: var(--white-color);
        transition: background-color 0.2s ease-in-out;
        border: none;

        &:hover {
          background-color: var(--dark-error-color);
          transition: background-color 0.2s ease-in-out;
        }
      `}
  }
`

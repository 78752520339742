import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default ({ children, ...props }) => {
  const { icon, name, path, active } = props
  const { t } = useTranslation()
  const Icon = icon

  return (
    <Wrapper to={path} active={active}>
      <IconWrapper>
        <Icon height={30} width={30} fill='var(--white-color)' />
      </IconWrapper>
      <Label>{t(name)}</Label>
    </Wrapper>
  )
}

const Wrapper = styled(Link)`
  padding: 0.5rem 1.5rem;

  display: flex;
  align-items: center;
  background-color: transparent;
  color: var(--white-color);
  transition: background-color 0.3s ease-in-out;
  overflow: hidden;

  &:hover,
  &:focus,
  &:active {
    background-color: var(--darker-primary-color);
    transition: background-color 0.3s ease-in-out;
  }

  ${({ theme }) => theme.smallDesktop`
    padding: 1.5rem;

    background-color: var(--dark-primary-color);

    ${props =>
      props.active &&
      css`
        font-weight: bold;
        background-color: var(--darker-primary-color);
      `}
`};

  ${props =>
    props.active &&
    css`
      font-weight: bold;
      background-color: var(--darker-primary-color);
    `}
`

const Label = styled.span`
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1em;

  font-weight: inherit;
  color: inherit;

  white-space: nowrap;
`

const IconWrapper = styled.div`
  min-width: 1rem;
  margin-right: 1rem;

  ${({ theme }) => theme.smallDesktop`
  min-width: 2rem;
  margin-right: 1.5rem;
`}
`

import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import TitleStyle from 'styled/TitleStyle'

import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'

export default ({ children, ...props }) => {
  const { t } = useTranslation()

  return (
    <Wrapper aria-label='breadcrumb' separator={false} margin={props.margin}>
      {props.items != null &&
        props.items.map((el, index) => (
          <StyledLink
            path={el.path}
            key={`bread-crumb-${index}`}
            to={{ pathname: el.path }}
            main={index === 0 ? 1 : 0}
            onClick={el.onClick != null ? () => el.onClick() : null}
          >
            {t(el.label ?? '-')}
          </StyledLink>
        ))}
    </Wrapper>
  )
}

const Wrapper = styled(Breadcrumbs)`
  & .MuiBreadcrumbs-ol {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: minmax(0, auto) minmax(0, auto);
    align-items: center;
  }

  & .MuiBreadcrumbs-li:first-of-type {
    margin-bottom: 0.5rem;
    margin: ${props => props.margin};

    ${({ theme }) => theme.smallDesktop`
      margin-bottom: 1rem;
      margin: ${props => props.margin};
    `}
  }
`

const StyledLink = styled(Link)`
  &.MuiTypography-colorPrimary {
    ${TitleStyle}
    color: var(--breadcrumb-gray-color);

    cursor: pointer;
    grid-row: 2/3;

    ${props =>
      props.main &&
      css`
        font-size: 1rem;
        grid-row: 1/2;
        grid-column: 1/3;
        ${({ theme }) => theme.smallDesktop`
          font-size: 1.125rem;
          line-height: 1.3333333333em;
        `}
      `}
  }
  &.MuiLink-underlineHover:hover {
    text-decoration: underline;

    ${props =>
      props.onClick == null &&
      props.path === '' &&
      css`
        cursor: auto;
        &:hover {
          text-decoration: none;
        }
      `}
  }
`

import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#prefix__clip0)'>
        <path
          d='M19.213 3.788C17.31 1.99 14.777 1 12.083 1c-2.693 0-5.225.99-7.13 2.788C3.05 5.586 2 7.977 2 10.52s1.049 4.933 2.953 6.731l5.357 5.058a2.566 2.566 0 001.773.691c.671 0 1.3-.245 1.773-.691l5.357-5.058c1.905-1.798 2.954-4.189 2.954-6.731 0-2.543-1.05-4.934-2.954-6.732z'
          fill='#08323B'
        />
        <path
          d='M18.87 4.152h0c1.808 1.707 2.797 3.967 2.797 6.368 0 2.4-.989 4.66-2.797 6.367l.343.364-.343-.364-5.357 5.058.343.364-.343-.364a2.066 2.066 0 01-1.43.555c-.548 0-1.053-.2-1.43-.555l-5.356-5.058h0C3.489 15.181 2.5 12.92 2.5 10.52s.989-4.661 2.797-6.368C7.105 2.444 9.514 1.5 12.083 1.5c2.57 0 4.978.944 6.787 2.652z'
          stroke='#fff'
          strokeOpacity={0.1}
        />
        <path
          d='M10.02 12.833h-.103v-2.99l1.061-1.593H9.917v-.208c0-.61-.528-1.1-1.15-1.036-.543.055-.934.552-.934 1.097v4.73H7.73a.73.73 0 00-.729.73v.208c0 .402.327.73.73.73h2.29a.73.73 0 00.73-.73v-.208a.73.73 0 00-.73-.73zM14.376 8.25l-1.667 2.5H14l1.667-2.5h-1.29z'
          fill='#fff'
        />
        <path
          d='M10.313 10.75l1.667-2.5h1.394l-1.667 2.5h-1.394zM16.61 8.337L15 10.75h1.27a.73.73 0 00.73-.729V8.979c0-.279-.16-.52-.39-.642z'
          fill='#fff'
        />
        <g filter='url(#prefix__filter0_d)'>
          <circle cx={20} cy={4} r={3} fill='#FCBD16' />
          <circle cx={20} cy={4} r={2.5} stroke='#fff' strokeOpacity={0.3} />
        </g>
      </g>
      <defs>
        <clipPath id='prefix__clip0'>
          <path fill='#fff' d='M0 0h24v24H0z' />
        </clipPath>
        <filter
          id='prefix__filter0_d'
          x={13}
          y={-3}
          width={14}
          height={14}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent

import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg viewBox='0 0 80 80' {...props}>
      <defs>
        <circle id='prefix__a_flag-uk' cx={40} cy={40} r={40} />
        <rect id='prefix__c_flag-uk' x={0} y={0} width={112} height={80} rx={8} />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='prefix__b_flag-uk' fill='#fff'>
          <use xlinkHref='#prefix__a_flag-uk' />
        </mask>
        <use fill='#D8D8D8' xlinkHref='#prefix__a_flag-uk' />
        <g mask='url(#prefix__b_flag-uk)'>
          <g transform='translate(-16)'>
            <mask id='prefix__d_flag-uk' fill='#fff'>
              <use xlinkHref='#prefix__c_flag-uk' />
            </mask>
            <use fill='#FFF' xlinkHref='#prefix__c_flag-uk' />
            <path fill='#0A17A7' mask='url(#prefix__d_flag-uk)' d='M0 0h112v80H0z' />
            <path
              d='M117.13-7.666l5.965 8.843-37.79 25.49H112v26.666H85.305l37.79 25.49-5.965 8.843-47.798-32.24.001 29.907H42.667V55.426L-5.13 87.666l-5.965-8.843 37.79-25.49H0V26.667h26.694l-37.789-25.49 5.965-8.843 47.796 32.24V-5.334h26.667v29.906L117.13-7.666z'
              fill='#FFF'
              mask='url(#prefix__d_flag-uk)'
            />
            <path
              stroke='#DB1F35'
              strokeWidth={2.667}
              strokeLinecap='round'
              mask='url(#prefix__d_flag-uk)'
              d='M74.672 25.329L125.333-8'
            />
            <path
              stroke='#DB1F35'
              strokeWidth={2.667}
              strokeLinecap='round'
              mask='url(#prefix__d_flag-uk)'
              transform='matrix(1 0 0 -1 0 140.191)'
              d='M80.051 85.401l45.415-30.611'
            />
            <path
              stroke='#DB1F35'
              strokeWidth={2.667}
              strokeLinecap='round'
              mask='url(#prefix__d_flag-uk)'
              d='M32.022 25.242L-15.35-6.684'
            />
            <path
              stroke='#DB1F35'
              strokeWidth={2.667}
              strokeLinecap='round'
              mask='url(#prefix__d_flag-uk)'
              transform='matrix(1 0 0 -1 0 143.661)'
              d='M37.16 89.242L-15.35 54.42'
            />
            <path
              fill='#E6273E'
              mask='url(#prefix__d_flag-uk)'
              d='M0 48h48v32h16V48h48V32H64V0H48v32H0z'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent

import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
  useCallback
} from 'react'
import styled from 'styled-components'
import { AUTOMATIC_EDIT_PERMISSIONS, AVAILABLE_SPOT_TYPES } from 'config'

import Badge from '@material-ui/core/Badge'

import { Context as AuthContext } from 'providers/AuthProvider/authProvider'
import { Context as SocketContext } from 'providers/SocketProvider/socketProvider'
import withResponsive from 'providers/ResponsiveHandler/withResponsive'

import { useTranslation } from 'react-i18next'
import {
  useHistory,
  useRouteMatch,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import columns from './columns'
import logic from './logic'
import { occupiedSearchOptions } from 'utils/options'

import hasCRUDPermissions from 'utils/hasCRUDPermissions'

import UploadIcon from 'icons/UploadIcon'
import FilterIcon from 'icons/FilterIcon'

import StyledBreadCrumb from 'components/StyledBreadCrumb'
import StyledButton from 'components/StyledButton'
import StyledAutoComplete from 'components/StyledAutoComplete'
import StyledSelect from 'components/StyledSelect'
import toast from 'components/StyledToast'

import MapContainer from 'containers/MapContainer'
import SpotsList from 'containers/SpotsList'
import SpotsDetails from 'containers/SpotsDetails'
import FiltersModal from 'containers/FiltersModal'

export default withResponsive(({ children, ...props }) => {
  const { actions, store } = useContext(AuthContext)
  const { store: wsStore } = useContext(SocketContext)

  const { responsiveHandlers } = props

  const { t } = useTranslation()
  const { path } = useRouteMatch()
  const history = useHistory()

  const [spotsState, setSpotsState] = useState({
    spotsCount: 0,
    spots: []
  })

  const [loading, setLoading] = useState(true)
  const [isEditing, setIsEditing] = useState(false)
  const [open, setOpen] = useState(false)
  const [geometry, setGeometry] = useState(null)

  const defaultFilter = useMemo(
    () => ({
      area: '',
      sensor: '',
      spotType: '',
      occupied: '',
      uid: ''
    }),
    []
  )
  const [filters, setFilters] = useState(defaultFilter)
  const [pagination, setPagination] = useState({ offset: 1, limit: 10 })
  const [triggerSet, setTriggerSet] = useState(false)

  const areaTextInputTimeoutRef = useRef(null)
  const sensorTextInputTimeoutRef = useRef(null)
  const uidTextInputTimeoutRef = useRef(null)

  const [textInput, setTextInput] = useState({
    area: '',
    sensor: '',
    uid: ''
  })
  const [searchOptions, setSeachOptions] = useState({
    sensor: [],
    area: [],
    uid: []
  })

  const [mapHelperText, setMapHelperText] = useState(null)
  const [fetchedSpot, setFetchedSpot] = useState(null)

  const [title, setTitle] = useState('')

  const [spotInDetail, setSpotInDetail] = useState(null)
  const [update, setUpdate] = useState(false)

  const locationInputTimeoutRef = useRef(null)
  const [locationOptions, setLocationOptions] = useState([])
  const [locationTextInput, setLocationTextInput] = useState('')
  const [locationSelected, setLocationSelected] = useState(null)

  const hiddenFileInput = useRef(null)
  const [openFilters, setOpenFilters] = useState(false)

  const [submitState, setSubmitState] = useState({
    submitting: false,
    hasSubmitted: false
  })

  const spotTypeOptions = useMemo(
    () => [...AVAILABLE_SPOT_TYPES?.map(el => ({ label: el, value: el }))],
    []
  )

  const handleSetSpotInDetail = useCallback(
    spot => {
      if (spot != null && spot.id != null) {
        setSpotInDetail(spot)
        history.push(`${path}${spot.id}/`)
      } else {
        setSpotInDetail(null)
        setFetchedSpot(null)
        setIsEditing(false)
        setTriggerSet(true)
        history.push(`${path}`)
      }
    },
    [history, path]
  )

  const resetParent = useCallback(() => {
    setLocationTextInput('')
    setLocationSelected(null)
    setLocationOptions([])
  }, [])

  const onFilterChange = useCallback(
    (key, value) => {
      setFilters({
        ...filters,
        [key]: value
      })
    },
    [filters]
  )

  const handleAutocompleteInputs = useCallback(
    (property, value) => {
      setTextInput({
        ...textInput,
        [property]: value
      })
      onFilterChange(property, value)
    },
    [textInput, onFilterChange]
  )

  const canEdit = useCallback(
    property => {
      if (spotInDetail != null && spotInDetail.mode === 'automatic') {
        return AUTOMATIC_EDIT_PERMISSIONS.spot.includes(property)
      }
      return true
    },
    [spotInDetail]
  )

  const fetchSpotAndUpdate = useCallback(
    id => {
      logic.getSpotDetails(id).then(response => {
        if (response.data) {
          const { data } = response
          const spotsCopy = JSON.parse(JSON.stringify(spotsState.spots))
          const found = spotsCopy.findIndex(el => el.id === id)
          if (found >= 0) {
            spotsCopy[found] = data
            setSpotsState({
              ...spotsState,
              spots: spotsCopy
            })
          }
        }
      })
    },
    [spotsState]
  )

  const fetchSpots = useCallback(() => {
    setLoading(true)
    setTriggerSet(false)

    logic
      .getSpots({
        params: {
          ...{
            limit: 'max'
          },
          ...filters
        }
      })
      .then(response => {
        if (response.data) {
          const { data } = response
          setSpotsState({
            spotsCount: data.count,
            spots: data.results
          })
        } else {
          setSpotsState({ spotsCount: 0, spots: [] })
        }
        setLoading(false)
      })
      .catch(err => {
        console.log({ err })
        setSpotsState({ spotsCount: 0, spots: [] })
        setLoading(false)
        if (err == null || err.response == null) {
          actions.serverError()
        }
      })
  }, [actions, filters])

  const handleWSMessages = useCallback(
    msg => {
      if (
        msg != null &&
        (msg.sender === 'spotevent' || msg.sender === 'spot')
      ) {
        if (spotInDetail && msg?.content?.id === spotInDetail.id) {
          if (msg.type === 'update') {
            setUpdate(!update)
          } else if (msg.type === 'delete' && msg.content != null) {
            setSpotInDetail(null)
          }
        } else if (spotInDetail == null) {
          if (msg.type === 'new' || msg.type === 'delete') {
            fetchSpots()
          } else if (msg.type === 'update' && msg?.content?.id) {
            fetchSpotAndUpdate(msg.content.id)
          } else {
            fetchSpots()
          }
        }
      }
    },
    [fetchSpots, fetchSpotAndUpdate, spotInDetail, update]
  )

  const resetFilters = useCallback(() => {
    setFilters(defaultFilter)
    setTextInput({
      area: '',
      sensor: '',
      uid: ''
    })
    setTriggerSet(true)
  }, [defaultFilter])

  const handleClick = useCallback(event => {
    if (hiddenFileInput != null && hiddenFileInput.current != null) {
      hiddenFileInput.current.click()
    }
  }, [])

  const handleChange = useCallback(
    async event => {
      const fileUploaded = await event.target.files[0]

      if (fileUploaded != null) {
        setSubmitState({
          submitting: true,
          hasSubmitted: true
        })
        setLoading(true)
        // eslint-disable-next-line
        const formData = new FormData()

        formData.append('file', fileUploaded)
        await logic
          .uploadCSV(formData)
          .then(response => {
            setSubmitState({
              ...submitState,
              submitting: false
            })
            if (response.status === 200) {
              toast.success(t('spots.create_success'))
            }
          })
          .catch(err => {
            console.log({ err })
            setSubmitState({
              ...submitState,
              submitting: false
            })

            toast.error(t('spots.create_error'))
            if (err == null || err.response == null) {
              actions.serverError()
            }
          })
          .finally(() => setTriggerSet(true))
      }

      // props.handleFile(fileUploaded);
    },
    [actions, submitState, t]
  )

  useEffect(() => {
    if (spotInDetail == null) {
      fetchSpots()
      setPagination({ offset: 1, limit: 10 })
    }
    // eslint-disable-next-line
  }, [triggerSet, spotInDetail])

  useEffect(() => {
    if (locationInputTimeoutRef.current !== null) {
      clearTimeout(locationInputTimeoutRef.current)
    }
    locationInputTimeoutRef.current = setTimeout(() => {
      locationInputTimeoutRef.current = null
      if (logic.fetchLocationOptions) {
        logic
          .fetchLocationOptions({
            params: { q: locationTextInput, format: 'jsonv2' }
          })
          .then(response => {
            if (response.data) {
              const { data } = response
              setLocationOptions(data)
            } else {
              setLocationOptions([])
            }
          })
          .catch(err => {
            console.log({ err })
            setLocationOptions([])
          })
      }
    }, 500)
  }, [locationTextInput])

  useEffect(() => {
    if (wsStore.message != null) {
      handleWSMessages(wsStore.message)
    }
    // eslint-disable-next-line
  }, [wsStore.message])

  useEffect(() => {
    if (areaTextInputTimeoutRef.current !== null) {
      clearTimeout(areaTextInputTimeoutRef.current)
    }

    areaTextInputTimeoutRef.current = setTimeout(() => {
      areaTextInputTimeoutRef.current = null
      logic
        .fetchAreasOptions({
          params: { name: textInput.area !== '' ? textInput.area : 'all' }
        })
        .then(response => {
          if (response.data) {
            const { data } = response
            setSeachOptions(searchOptions => ({
              ...searchOptions,
              area: data.results
            }))
          } else {
            setSeachOptions(searchOptions => ({ ...searchOptions, area: [] }))
          }
        })
        .catch(err => {
          console.log({ err })
          setSeachOptions(searchOptions => ({ ...searchOptions, area: [] }))
        })
    }, 500)
  }, [textInput.area])

  useEffect(() => {
    if (sensorTextInputTimeoutRef.current !== null) {
      clearTimeout(sensorTextInputTimeoutRef.current)
    }

    sensorTextInputTimeoutRef.current = setTimeout(() => {
      sensorTextInputTimeoutRef.current = null
      logic
        .fetchSensorsOptions({
          params: {
            sensor: textInput.sensor !== '' ? textInput.sensor : 'all'
          }
        })
        .then(response => {
          if (response.data) {
            const { data } = response
            setSeachOptions(searchOptions => ({
              ...searchOptions,
              sensor: data.results
            }))
          } else {
            setSeachOptions(searchOptions => ({ ...searchOptions, sensor: [] }))
          }
        })
        .catch(err => {
          console.log({ err })
          setSeachOptions(searchOptions => ({ ...searchOptions, sensor: [] }))
        })
    }, 500)
  }, [textInput.sensor])

  useEffect(() => {
    if (uidTextInputTimeoutRef.current !== null) {
      clearTimeout(uidTextInputTimeoutRef.current)
    }

    uidTextInputTimeoutRef.current = setTimeout(() => {
      uidTextInputTimeoutRef.current = null
      logic
        .fetchUidOptions({
          params: { uid: textInput.uid !== '' ? textInput.uid : 'all' }
        })
        .then(response => {
          if (response.data) {
            const { data } = response
            setSeachOptions(searchOptions => ({
              ...searchOptions,
              uid: data.results
            }))
          } else {
            setSeachOptions(searchOptions => ({ ...searchOptions, uid: [] }))
          }
        })
        .catch(err => {
          console.log({ err })
          setSeachOptions(searchOptions => ({ ...searchOptions, uid: [] }))
        })
    }, 500)
  }, [textInput.uid])

  const hasCRUDPermission = useMemo(() => hasCRUDPermissions(store.user), [
    store.user
  ])

  const spotTypeSearchOptions = useMemo(
    () => [
      {
        label: t('table.all_filter'),
        value: ''
      },
      ...AVAILABLE_SPOT_TYPES?.map(el => ({
        label: t(`table.${el}`),
        value: el
      }))
    ],
    [t]
  )

  const memoHandleAutocompleteInputs = useCallback(
    name => e => handleAutocompleteInputs(name, e),
    [handleAutocompleteInputs]
  )
  const memoOnFilterChangeValue = useCallback(
    name => e => onFilterChange(name, e.target.value),
    [onFilterChange]
  )

  const memoOnFilterChange = useCallback(
    name => (e, newValue) => onFilterChange(name, newValue),
    [onFilterChange]
  )

  const memoOccupiedSearchOptions = useMemo(
    () =>
      occupiedSearchOptions != null
        ? [
          ...occupiedSearchOptions.map(el => ({
            ...el,
            label: t(`table.${el.label}`)
          }))
        ]
        : [],
    [t]
  )

  const filtersInputs = useMemo(
    () => (
      <React.Fragment key='filters-inputs'>
        <StyledAutoComplete
          value={textInput.uid}
          onChange={memoHandleAutocompleteInputs('uid')}
          inputValue={filters.uid}
          onInputChange={memoOnFilterChange('uid')}
          options={searchOptions.uid}
          label={t('table.uid')}
          name='uid'
          variant='standard'
          id='uid'
        />
        <StyledAutoComplete
          value={textInput.area}
          onChange={memoHandleAutocompleteInputs('area')}
          inputValue={filters.area}
          onInputChange={memoOnFilterChange('area')}
          options={searchOptions.area}
          label={t('table.area')}
          name='area'
          variant='standard'
          id='area'
        />
        <StyledAutoComplete
          value={textInput.sensor}
          onChange={memoHandleAutocompleteInputs('sensor')}
          inputValue={filters.sensor}
          onInputChange={memoOnFilterChange('sensor')}
          options={searchOptions.sensor}
          label={t('table.sensor')}
          name='sensor'
          variant='standard'
          id='sensor'
        />
        <StyledSelect
          value={filters?.occupied ?? ''}
          onChange={memoOnFilterChangeValue('occupied')}
          label={t('table.status')}
          options={memoOccupiedSearchOptions}
          id='occupied' // OBRIGATORIO POR CAUSA DA LABEL
        />
        <StyledSelect
          value={filters?.spotType ?? ''}
          onChange={memoOnFilterChangeValue('spotType')}
          label={t('table.type')}
          options={spotTypeSearchOptions}
          id='spotType' // OBRIGATORIO POR CAUSA DA LABEL
        />
      </React.Fragment>
    ),
    [
      filters,
      memoHandleAutocompleteInputs,
      memoOccupiedSearchOptions,
      spotTypeSearchOptions,
      memoOnFilterChangeValue,
      memoOnFilterChange,
      searchOptions,
      t,
      textInput
    ]
  )

  const handleResetFilters = useCallback(() => {
    resetFilters()
    setOpenFilters(false)
  }, [resetFilters])

  const applyFilters = useCallback(() => {
    setTriggerSet(true)
    setOpenFilters(false)
  }, [])

  const filtersButtons = useMemo(
    () => (
      <React.Fragment key='filters-buttons'>
        <StyledButton
          onClick={handleResetFilters}
          disabled={submitState.submitting}
        >
          {t('table.reset')}
        </StyledButton>
        <StyledButton
          type='primary'
          onClick={applyFilters}
          disabled={submitState.submitting}
        >
          {t('table.apply')}
        </StyledButton>
      </React.Fragment>
    ),
    [handleResetFilters, applyFilters, submitState.submitting, t]
  )

  const breadcrumbItems = useMemo(
    () => [
      {
        path: path,
        label: 'sections.spots',
        onClick: () => handleSetSpotInDetail(null)
      },
      {
        path: '',
        label: `${t(title)} ${
          spotInDetail != null ? `- ${spotInDetail.uid}` : ''
        }`
      }
    ],
    [spotInDetail, path, t, title, handleSetSpotInDetail]
  )

  const openNewPopup = useCallback(() => setOpen(true), [])
  const memoSetTriggerSet = useCallback(() => setTriggerSet(true), [])

  const onCloseFilters = useCallback(() => setOpenFilters(false), [])
  const onOpenFilters = useCallback(() => setOpenFilters(true), [])

  const mapData = useMemo(
    () =>
      spotInDetail != null && fetchedSpot != null
        ? fetchedSpot.geometry != null
          ? [fetchedSpot]
          : null
        : spotsState.spots,
    [spotInDetail, fetchedSpot, spotsState.spots]
  )

  const isDrawAvailable = useMemo(() => isEditing && canEdit('geometry'), [
    isEditing,
    canEdit
  ])

  const flyToLocation = useMemo(
    () =>
      locationSelected?.boundingbox != null
        ? [
          [locationSelected.boundingbox[2], locationSelected.boundingbox[0]],
          [locationSelected.boundingbox[3], locationSelected.boundingbox[1]]
        ]
        : null,
    [locationSelected?.boundingbox]
  )

  const onSearchChange = useCallback(event => setLocationTextInput(event), [])
  const onSearchInputChange = useCallback(
    (event, newValue) => setLocationSelected(newValue),
    []
  )

  const getOptionLabel = useCallback(option => option.displayName, [])

  const mapSearchProps = useMemo(
    () => ({
      value: locationTextInput,
      onChange: onSearchChange,
      inputValue: locationSelected,
      onInputChange: onSearchInputChange,
      options: locationOptions,
      label: t('map.search_location'),
      getOptionLabel: getOptionLabel
    }),
    [
      locationTextInput,
      locationSelected,
      getOptionLabel,
      locationOptions,
      onSearchChange,
      onSearchInputChange,
      t
    ]
  )

  return (
    <Wrapper>
      <BreadCrumbRow>
        <StyledBreadCrumb items={breadcrumbItems} />
        {hasCRUDPermission && spotInDetail == null && (
          <ButtonWrapper>
            <StyledButton
              type='primary'
              onClick={openNewPopup}
              disabled={submitState.submitting}
            >
              {t('spots.new')}
            </StyledButton>
            <StyledButton
              type='primary'
              onClick={handleClick}
              disabled={submitState.submitting}
              tooltip={t('upload_csv')}
            >
              <UploadIcon height={24} width={24} fill='var(--white-color)' />
              <input
                type='file'
                ref={hiddenFileInput}
                onChange={handleChange}
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.csv,.xls,.xlsx'
                style={{ display: 'none' }}
              />
            </StyledButton>
            {!responsiveHandlers.isDesktop && (
              <StyledButton
                tooltip={t('filters')}
                type='primary'
                onClick={onOpenFilters}
              >
                <Badge
                  color='secondary'
                  variant='dot'
                  invisible={
                    JSON.stringify(filters) === JSON.stringify(defaultFilter)
                  }
                >
                  <FilterIcon
                    height={24}
                    width={24}
                    fill='var(--white-color)'
                  />
                </Badge>
              </StyledButton>
            )}
          </ButtonWrapper>
        )}
      </BreadCrumbRow>
      {spotInDetail == null && (
        <FiltersWrapper>
          <FiltersRow>{[filtersInputs, filtersButtons]}</FiltersRow>
        </FiltersWrapper>
      )}
      <MapAndTextWrapper>
        <MapWrapper>
          <MapContainer
            data={mapData}
            hideLegends
            type='spots'
            isDrawAvailable={isDrawAvailable}
            setGeometry={setGeometry}
            geometry={geometry}
            inDetail={spotInDetail}
            enableSearch={isDrawAvailable}
            search={mapSearchProps}
            flyToLocation={flyToLocation}
          />
        </MapWrapper>
        {isDrawAvailable && <>{mapHelperText}</>}
      </MapAndTextWrapper>
      <Switch>
        <Route path={`${path}:id/`} exact>
          <SpotsDetails
            setSpotInDetail={handleSetSpotInDetail}
            spotInDetail={spotInDetail}
            setGeometry={setGeometry}
            geometry={geometry}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            spotTypeOptions={spotTypeOptions}
            setTitle={setTitle}
            setMapHelperText={setMapHelperText}
            canEdit={canEdit}
            resetParent={resetParent}
            hasCRUDPermission={hasCRUDPermission}
            update={update}
            fetchedSpot={fetchedSpot}
            setFetchedSpot={setFetchedSpot}
          />
        </Route>
        <Route path={path} exact>
          <SpotsList
            loading={loading}
            spots={spotsState.spots}
            spotsCount={spotsState.spotsCount}
            columns={columns}
            setSpotInDetail={handleSetSpotInDetail}
            open={open}
            setOpen={setOpen}
            spotTypeOptions={spotTypeOptions}
            setTitle={setTitle}
            pagination={pagination}
            setPagination={setPagination}
            triggerSet={memoSetTriggerSet}
          />
        </Route>
        <Route path='*'>
          <Redirect to={path} />
        </Route>
      </Switch>
      <FiltersModal
        open={openFilters}
        onClose={onCloseFilters}
        inputs={filtersInputs}
        buttons={filtersButtons}
        title={t('filters')}
      />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;

  ${({ theme }) => theme.smallDesktop`
    padding: 1.5rem 2rem;
  `}
`

const MapWrapper = styled.div`
  width: 100%;
  min-height: 22rem;
  height: 100%;
`

const MapAndTextWrapper = styled.div`
  margin: 1.5rem 0;
  min-height: 22rem;
  height: calc(
    100vh - 3.75rem - 4rem - 4.125rem - 4rem - 3rem - 25rem - 3.75rem
  );
  flex: 0 0 auto;
`

const BreadCrumbRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  flex: 0 0 auto;

  ${({ theme }) => theme.smallDesktop`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 2.5rem;
  `}
`
const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 10rem 2.5rem 2.5rem;
  column-gap: 0.5rem;
  margin-right: auto;
  ${({ theme }) => theme.smallDesktop`
    grid-template-columns: 14.625rem 2.5rem;
    column-gap: 0.5rem;
    margin: 0;
  `}
`

const FiltersWrapper = styled.div`
  margin: 1.5rem 0 0;
`

const FiltersRow = styled.div`
  display: none;
  ${({ theme }) => theme.smallDesktop`
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    display: grid;
    column-gap: 1rem;
    row-gap: 1rem;
  `}
`


import moment from 'moment'

const getModifiedEndDateMoment = (interval, startDate) => {
  let endDate = moment(startDate)

  switch (interval) {
    case 'year':
      endDate = moment(endDate)
        .endOf('year')
        .isSameOrBefore(moment())
        ? moment(endDate).endOf('year')
        : moment()
      break
    case 'month':
      endDate = moment(endDate)
        .endOf('month')
        .isSameOrBefore(moment())
        ? moment(endDate).endOf('month')
        : moment()
      break
    case 'day':
      endDate = moment(endDate).isSameOrBefore(moment())
        ? moment(endDate)
        : moment()
      break
    default:
      endDate = moment(endDate).isSameOrBefore(moment())
        ? moment(endDate)
        : moment()
      break
  }
  return endDate
}

export default getModifiedEndDateMoment

import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { API_DATE_FORMAT } from 'config'

import StyledDatePicker from 'components/StyledDateTime'

const getModifiedEndDate = (interval, startDate) => {
  let endDate = moment(startDate)

  switch (interval) {
    case 'year':
      endDate = moment(endDate)
        .endOf('year')
        .isSameOrBefore(moment())
        ? moment(endDate).endOf('year')
        : moment()
      break
    case 'month':
      endDate = moment(
        endDate.format('YYYY-MM-') + 28,
        'YYYY-MM-DD'
      ).isSameOrBefore(moment())
        ? moment(endDate.format('YYYY-MM-') + 28, 'YYYY-MM-DD')
        : moment()
      break
    case 'day':
      endDate = moment(endDate).isSameOrBefore(moment())
        ? moment(endDate)
        : moment()
      break
    default:
      endDate = moment(endDate).isSameOrBefore(moment())
        ? moment(endDate)
        : moment()
      break
  }
  return endDate
}

const getModifiedStartDate = (interval, startDate) => {
  const modifiedStartDate = moment(startDate)

  switch (interval) {
    case 'year':
      modifiedStartDate.startOf('year')
      break
    case 'month':
      modifiedStartDate.startOf('month')
      break
    case 'day':
      break
    default:
      break
  }
  return modifiedStartDate
}

const datePicker = (start, end, interval, onChangeFunction, changeBoth) => {
  let tempFormat = 'YYYY'
  const timeFormat = false
  let viewMode = 'years'

  switch (interval) {
    case 'year':
      tempFormat = 'YYYY'
      viewMode = 'years'
      break
    case 'month':
      tempFormat = 'YYYY-MM'
      viewMode = 'months'
      break
    case 'day':
      tempFormat = 'YYYY-MM-DD'
      viewMode = 'days'
      break
    default:
      tempFormat = 'YYYY'
      viewMode = 'years'
      break
  }

  return (
    <>
      <StyledDatePicker
        inputProps={{ disabled: true }}
        isValidDate={(currentDate, selectedDate) =>
          currentDate.isSameOrBefore(end) &&
          currentDate.isSameOrBefore(moment())}
        dateFormat={tempFormat}
        timeFormat={timeFormat}
        value={moment(start)}
        viewMode={viewMode}
        open
        onChange={value => {
          if (moment(end).isBefore(moment(value))) {
            changeBoth(interval, value)
          } else {
            onChangeFunction('dateAfter', value, interval)
          }
        }}
      />
      <StyledDatePicker
        inputProps={{ disabled: true }}
        isValidDate={(currentDate, selectedDate) =>
          currentDate.isSameOrAfter(start) &&
          currentDate.isSameOrBefore(moment())}
        dateFormat={tempFormat}
        timeFormat={timeFormat}
        viewMode={viewMode}
        open
        value={moment(end)}
        onChange={value => {
          onChangeFunction('dateBefore', moment(value), interval)
        }}
      />
    </>
  )
}

export default ({ children, ...props }) => {
  const {
    form,
    replaceForm,
    intervalOptions
    // changedForm,
    // setChangedForm
  } = props

  const { t } = useTranslation()

  const modifyFormValue = (key, value, interval) => {
    replaceForm({
      ...form,
      [key]:
        key === 'dateBefore'
          ? moment(getModifiedEndDate(interval, value)).format(API_DATE_FORMAT)
          : moment(value).format(API_DATE_FORMAT)
    })
  }

  const modifyBoth = (interval, value) => {
    replaceForm({
      ...form,
      dateAfter: moment(value).format(API_DATE_FORMAT),
      dateBefore: moment(getModifiedEndDate(interval, value)).format(
        API_DATE_FORMAT
      )
    })
  }

  const modifyIntervalValue = value => {
    replaceForm({
      ...form,
      interval: value,
      dateBefore: moment(
        getModifiedEndDate(value, moment(form.dateAfter))
      ).format(API_DATE_FORMAT),
      dateAfter: moment(
        getModifiedStartDate(value, moment(form.dateAfter))
      ).format(API_DATE_FORMAT)
    })
  }

  // // useEffect(() => {
  // //   replaceForm({
  // //     ...form
  // //   })
  // // }, [form, replaceForm])

  // useEffect(() => {
  //   if (triggerSet) {
  //     console.log('trigger', {
  //       ...form,
  //       dateBefore: moment(
  //         getModifiedEndDateFinal(form.interval, form.dateBefore)
  //       ).format(API_DATE_FORMAT)
  //     })
  //     // replaceForm({
  //     //   ...form,
  //     //   dateBefore: moment(
  //     //     getModifiedEndDateFinal(form.interval, form.dateBefore)
  //     //   ).format(API_DATE_FORMAT)
  //     // })
  //     resetTriggerSet()
  //   }
  //   // eslint-disable-next-line
  // }, [triggerSet])

  return (
    <Wrapper>
      <ColumnFlexer>
        <TimeOptionsWrapper>
          <InfoText>{t('choose_interval')}:</InfoText>
          {intervalOptions.map(el => (
            <OptionSpan
              key={'span' + el.label}
              selected={form.interval === el.value}
              onClick={() => modifyIntervalValue(el.value)}
            >
              {t(el.label)}
            </OptionSpan>
          ))}
        </TimeOptionsWrapper>
      </ColumnFlexer>

      {datePicker(
        form.dateAfter,
        form.dateBefore,
        form.interval,
        modifyFormValue,
        modifyBoth
      )}
    </Wrapper>
  )
}

const ColumnFlexer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
`

const Wrapper = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  position: relative;
  z-index: 1000;
  padding: 1.5rem;

  ${({ theme }) => theme.smallDesktop`
  flex-direction: row;

  `}
`

const TimeOptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0;
  padding: 1rem;
  width: 200px;

  ${({ theme }) => theme.smallDesktop`
    display: flex;
    flex-direction: column;
    width: auto;
    align-items: center;
    margin: 1rem 2rem;
  `}
`

const InfoText = styled.span`
  font-weight: 700;
  white-space: nowrap;
  margin-bottom: 1rem;

  grid-column: span 3;
`

const OptionSpan = styled.span`
  color: ${props =>
    props.selected ? 'var(--white-color)' : 'var(--black-color)'};
  background-color: ${props => props.selected && 'var(--primary-color)'};
  width: 100%;
  text-align: center;
  margin: 1rem 0px;
  padding: 0.5rem;
  cursor: ${props => (props.selected ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: rgba(211, 211, 211, 0.25);
  }
`

import styled from 'styled-components'
import Pagination from '@material-ui/lab/Pagination'

export default styled(Pagination)`
  margin: 1rem 0 1rem auto;
  & .MuiPagination-ul {
    & li:last-child .MuiPaginationItem-root {
      border-right: 1px solid var(--platinium-gray-color);
    }
  }
  & .MuiPaginationItem-root {
    border-radius: 0;
    height: 1.75rem;
    width: 1.75rem;

    font-family: "PT Sans";
    font-size: 0.75rem;
    letter-spacing: 0;
    line-height: 1em;
    text-align: center;
    border: 1px solid var(--platinium-gray-color);
    border-right: none;
    background-color: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  & .MuiPaginationItem-page.Mui-selected {
    background-color: var(--primary-color);
    color: var(--white-color);

    &:hover {
      background-color: var(--dark-primary-color);
    }
  }
`

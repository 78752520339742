import React, { useEffect } from 'react'
import { hot } from 'react-hot-loader/root'
import styled, { ThemeProvider } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStyle, { media, materialTheme } from 'globalStyles'
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { Switch, Route, withRouter, Redirect } from 'react-router'
import Routes from 'routes'
import { UNAVAILABLE_FEATURES } from 'config'

import withAuthState from 'providers/AuthProvider/withAuthState'
import SocketProvider from 'providers/SocketProvider/socketProvider'

import { setLanguageInterceptor } from 'interceptors'

import Navbar from 'containers/Navbar'
import Sidebar from 'containers/Sidebar'
import Footer from 'containers/Footer'

import PrivateRoute from 'components/PrivateRoute'

const App = ({ children, ...props }) => {
  const { i18n } = useTranslation()

  const {
    store: { isLoggedIn }
  } = props

  useEffect(() => {
    if (i18n != null && i18n.language != null) {
      switch (i18n.language) {
        case 'pt':
          setLanguageInterceptor('pt-PT')
          break
        case 'en':
          setLanguageInterceptor('en-GB')
          break

        default:
          setLanguageInterceptor('pt-PT')
          break
      }
    }
    // eslint-disable-next-line
  }, [i18n.language])

  return (
    <ThemeProvider theme={{ ...media }}>
      <MaterialThemeProvider theme={materialTheme}>
        <SocketProvider>
          <GlobalStyle />
          <Main>
            <Navbar />
            {isLoggedIn && <Sidebar />}
            <SceneWrapper isLoggedIn={isLoggedIn}>
              <Switch>
                {Routes.filter(
                  el => !UNAVAILABLE_FEATURES.includes(el.key)
                ).map(route =>
                  route.private ? (
                    <PrivateRoute
                      exact={!!route.exact}
                      key={route.key}
                      path={route.path}
                      component={route.component}
                    />
                  ) : (
                    <Route
                      exact={!!route.exact}
                      key={route.key}
                      path={route.path}
                      component={route.component}
                    />
                  )
                )}
                <Redirect to='/not-found/' />
              </Switch>
            </SceneWrapper>
            {isLoggedIn && !UNAVAILABLE_FEATURES.includes('footer') && (
              <Footer />
            )}
          </Main>
          <ToastContainer pauseOnFocusLoss={false} hideProgressBar />
        </SocketProvider>
      </MaterialThemeProvider>
    </ThemeProvider>
  )
}

export default hot(withRouter(withAuthState(App)))

const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SceneWrapper = styled.div`
  height: calc(100vh - 3.75rem);
  margin-top: 3.75rem;
  /* height: 100%; */
  width: 100%;
  flex: 1 100%;
  z-index: 1;
  overflow: hidden;

  ${({ theme }) => theme.smallDesktop`

  width: ${({ isLoggedIn }) => (isLoggedIn ? 'calc(100% - 5rem)' : '100%')};
  margin-left: ${({ isLoggedIn }) => (isLoggedIn ? '5rem' : '0')};

`}
`

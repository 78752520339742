import React from 'react'
import styled from 'styled-components'

const columns = [
  {
    key: 'uid',
    label: 'table.uid',
    render: ({ setAreaInDetail, t, ...element }) => (
      <StyledLink onClick={() => setAreaInDetail(element)}>
        {element.uid}
      </StyledLink>
    )
  },
  {
    key: 'name',
    label: 'table.name'
  },
  {
    key: 'monitoringType',
    label: 'table.monitoring_type',
    render: ({ t, ...element }) =>
      element.monitoringType != null
        ? t(`table.${element.monitoringType}`)
        : '-'
  },
  {
    key: 'currentOccupation',
    label: 'table.occupancy_rate'
  },
  {
    key: 'occupation.all',
    label: 'table.all'
  },
  {
    key: 'occupation.cp',
    label: 'table.cp',
    render: ({ t, ...element }) =>
      element.occupation.cp === '0/0'
        ? '-'
        : element.occupation.cp
  },
  {
    key: 'occupation.handicapped',
    label: 'table.handicapped',
    render: ({ t, ...element }) =>
      element.occupation.handicapped === '0/0'
        ? '-'
        : element.occupation.handicapped
  },

  {
    key: 'occupation.motorhome',
    label: 'table.motorhome',
    render: ({ t, ...element }) =>
      element.occupation.motorhome === '0/0'
        ? '-'
        : element.occupation.motorhome
  },

  {
    key: 'forbiddenTransitCount30Min',
    label: 'table.irregular',
    render: ({ t, ...element }) =>
      element.forbiddenTransitCount30Min != null
        ? element.forbiddenTransitCount30Min
        : '-'
  }
]

export default columns

const StyledLink = styled.span`
  color: var(--primary-color);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

import { WS_BASE_URL, WS_JOIN_GROUP } from 'config'

const logic = {
  createWebSocketConnection ({ setSocket }) {
    return new Promise((resolve, reject) => {
      const actualToken = window.localStorage.getItem('access_token')
      if (actualToken != null) {
        const socket = new window.WebSocket(
          `${WS_BASE_URL}?token=${actualToken}`
        )

        socket.onopen = function () {
          socket.send(
            JSON.stringify({ command: 'join', group: WS_JOIN_GROUP })
          )
          console.info(
            `%c🔌 WebSocket: joined #${WS_JOIN_GROUP}`,
            'color:green'
          )
          resolve(socket)
        }

        socket.onerror = function (evt) {
          reject(new Error(evt))
        }
        socket.onclose = () => {
          setSocket(null)
        }
      } else {
        reject(new Error('403'))
      }
    })
  }
}

export default logic

import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  memo
} from 'react'
import styled, { css } from 'styled-components'
import get from 'lodash.get'
import isEqual from 'lodash.isequal'

import toast from 'components/StyledToast'

import { Context as AuthContext } from 'providers/AuthProvider/authProvider'
import withResponsive from 'providers/ResponsiveHandler/withResponsive'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import StyledButton from 'components/StyledButton'
import StyledDialog from 'components/StyledDialog'
import StyledSkeleton from 'components/StyledSkeleton'

import InputAndLabel from 'containers/InputAndLabel'
import SelectAndLabel from 'containers/SelectAndLabel'

import logic from './logic'

const keysToMapArrays = ['sensor']

const SpotsDetails = ({ children, ...props }) => {
  const { actions } = useContext(AuthContext)

  const { t } = useTranslation()
  const { id } = useParams()
  const {
    setSpotInDetail,
    spotInDetail,
    isEditing,
    setIsEditing,
    setGeometry,
    geometry,
    spotTypeOptions,
    setTitle,
    setMapHelperText,
    canEdit,
    resetParent,
    hasCRUDPermission,
    update,
    fetchedSpot,
    setFetchedSpot,
    responsiveHandlers
  } = props

  const [form, setForm] = useState(logic.defaultValues)

  const [submitState, setSubmitState] = useState({
    hasSubmitted: false,
    submitting: false,
    apiResponse: null
  })

  const [fetchedSensors, setFetchedSensors] = useState(null)
  const [canDelete, setCanDelete] = useState(true)

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

  const handleClickOpen = useCallback(() => {
    setConfirmDeleteOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setConfirmDeleteOpen(false)
  }, [])
  const reset = useCallback(() => {
    setForm(logic.defaultValues)
    setSubmitState({
      hasSubmitted: false,
      submitting: false,
      apiResponse: null
    })
    resetParent()
  }, [resetParent])

  useEffect(() => {
    if (!isEditing && fetchedSpot != null) {
      setGeometry(fetchedSpot.geometry)
    }
    // eslint-disable-next-line
  }, [fetchedSpot])

  useEffect(() => {
    reset()
    logic
      .fetchAvailableSensors()
      .then(response => {
        if (response.data != null && response.data.results != null) {
          setFetchedSensors(response.data.results)
        }
      })
      .catch(err => {
        console.log({ err })
        setFetchedSensors([])
        // ERROR
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (id != null && !isNaN(Number(id))) {
      if (!isEditing) {
        setSubmitState({
          hasSubmitted: false,
          submitting: false,
          apiResponse: null
        })
        logic
          .getSpotDetails(id)
          .then(response => {
            if (response.data) {
              const { data } = response
              setFetchedSpot(data)

              if (data.mode === 'automatic') {
                setCanDelete(false)
              }

              const defaultKeys = Object.keys(logic.defaultValues)
              const reduced = Object.keys(data).reduce(
                (acum, key) => {
                  if (defaultKeys.includes(key)) {
                    if (keysToMapArrays.includes(key)) {
                      acum[key] = { value: data[key].map(el => el.id) }
                    } else {
                      acum[key] = { value: data[key] }
                    }
                  }
                  return acum
                },
                { ...logic.defaultValues }
              )
              setForm(reduced)

              if (spotInDetail == null) {
                setSpotInDetail(data)
              }
            } else {
              reset()
              setSpotInDetail(null)
              setFetchedSpot(null)
            }
          })
          .catch(err => {
            console.log({ err })
            setSpotInDetail(null)
            setFetchedSpot(null)
            // ERROR
            if (err == null || err.response == null) {
              actions.serverError()
            }
          })
      }
    } else {
      reset()
      setSpotInDetail(null)
      setFetchedSpot(null)
    }
    // eslint-disable-next-line
  }, [id, isEditing, update])

  const checkIfError = useCallback(
    property => {
      const error = get(submitState.apiResponse, property, null)
      if (
        submitState.apiResponse != null &&
        error != null &&
        error[0] != null
      ) {
        return error[0]
      }
      return null
    },
    [submitState.apiResponse]
  )

  const helperTextGenerator = useCallback(
    property => {
      if (submitState.hasSubmitted && form[property] != null) {
        const apiError = checkIfError(property)
        if (apiError != null) {
          return apiError
        } else if (form[property].message) {
          return t(`errors.${form[property].message}`)
        }
      }
      return null
    },
    [form, t, checkIfError, submitState.hasSubmitted]
  )

  const handleChange = useCallback(
    (key, value) => {
      setForm({
        ...form,
        [key]: {
          value: value
        }
      })
    },
    [form]
  )

  useEffect(() => {
    if (isEditing) {
      handleChange('geometry', geometry)
    }
    // eslint-disable-next-line
  }, [geometry])

  useEffect(() => {
    if (isEditing) {
      setMapHelperText(
        <MapHelperText
          error={
            submitState.hasSubmitted &&
            form.geometry != null &&
            (form.geometry.message != null || !!checkIfError('geometry'))
          }
        >
          {checkIfError('geometry') || t('map.spot_edit_text')}
        </MapHelperText>
      )
    }
    // eslint-disable-next-line
  }, [form, isEditing])

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      setSubmitState(submitState => ({
        ...submitState,
        hasSubmitted: true,
        submitting: true
      }))
      logic
        .updateSpotDetails(id, form, form => setForm({ ...form }))
        .then(response => {
          setIsEditing(false)
          if (response.data) {
            setSpotInDetail(response.data)
            toast.success(t('spots.edit_success'))
            setSubmitState(submitState => ({
              ...submitState,
              submitting: false
            }))
          }
        })
        .catch(err => {
          console.log({ err })
          toast.error(t('spots.edit_error'))

          if (err == null || err.response == null) {
            actions.serverError()
            setSubmitState(submitState => ({
              ...submitState,
              submitting: false
            }))
          } else {
            if (err.response.data != null) {
              setSubmitState(submitState => ({
                ...submitState,
                submitting: false,
                apiResponse: err.response.data
              }))
            } else {
              setSubmitState(submitState => ({
                ...submitState,
                submitting: false
              }))
            }
          }
        })
    },
    [actions, form, id, setIsEditing, setSpotInDetail, t]
  )

  const handleDelete = useCallback(() => {
    setSubmitState(submitState => ({
      ...submitState,
      submitting: true
    }))
    logic
      .deleteSpot(id)
      .then(response => {
        setSpotInDetail(null)
        toast.success(t('spots.delete_success'))
        setSubmitState(submitState => ({
          ...submitState,
          submitting: false
        }))
      })
      .catch(err => {
        console.log({ err })
        toast.error(t('spots.delete_error'))
        if (err == null || err.response == null) {
          actions.serverError()
          setSubmitState(submitState => ({
            ...submitState,
            submitting: false
          }))
        }
        // ERROR
        if (err.response != null && err.response.data != null) {
          setSubmitState(submitState => ({
            ...submitState,
            submitting: false,
            apiResponse: err.response.data
          }))
        } else {
          setSubmitState(submitState => ({
            ...submitState,
            submitting: false
          }))
        }
      })
      .finally(() => handleClose())
  }, [actions, handleClose, id, setSpotInDetail, t])

  useEffect(() => {
    if (fetchedSpot != null && fetchedSpot.uid != null) {
      setTitle(isEditing ? 'spots.edit' : 'spots.details')
    } else if (spotInDetail != null && spotInDetail.uid != null) {
      setTitle(isEditing ? 'spots.edit' : 'spots.details')
    } else {
      setTitle(isEditing ? 'spots.edit' : 'spots.details')
    }
  }, [fetchedSpot, spotInDetail, isEditing, setTitle, t])

  const memoizedLoadingTable = useMemo(
    () =>
      [...Array(3)].map((el, index) => (
        <StyledSkeleton
          key={`table-loading-row-${index}`}
          variant='rect'
          width='100%'
          height='3.75rem'
          margin='0 0 1.5rem'
          marginTop='0'
        />
      )),
    []
  )

  const memoSpotTypeOptions = useMemo(
    () =>
      spotTypeOptions != null
        ? spotTypeOptions.map(el => ({
          ...el,
          label: t(`table.${el.label}`)
        }))
        : [],
    [spotTypeOptions, t]
  )

  const memoFetchedSensors = useMemo(
    () =>
      fetchedSensors != null
        ? fetchedSensors.map(el => ({
          value: el.id,
          label: el.uid ?? el.id
        }))
        : [],
    [fetchedSensors]
  )

  const memoHandleChangeValue = useCallback(
    name => e => handleChange(name, e?.target?.value),
    [handleChange]
  )

  const memoHandleChange = useCallback(name => e => handleChange(name, e), [
    handleChange
  ])

  const memoHandleChangeArray = useCallback(
    name => event =>
      handleChange(
        name,
        event?.target?.value?.length !== 0 ? event?.target?.value : null
      ),
    [handleChange]
  )

  const startEditing = useCallback(() => setIsEditing(true), [setIsEditing])

  const cancelEditing = useCallback(() => setIsEditing(false), [setIsEditing])

  const goBack = useCallback(() => {
    reset()
    setSpotInDetail(null)
  }, [reset, setSpotInDetail])

  return (
    <>
      <DetailsWrapper>
        {fetchedSpot == null && <>{memoizedLoadingTable}</>}
        {fetchedSpot != null && (
          <>
            <InputAndLabel
              type='text'
              name='uid'
              value={form?.uid?.value ?? ''}
              onChange={memoHandleChange('uid')}
              label={t('table.uid')}
              error={
                submitState.hasSubmitted &&
                (form?.uid?.message != null || !!checkIfError('uid'))
              }
              helperText={helperTextGenerator('uid')}
              disabled={!(isEditing && canEdit('uid'))}
              isEditing={!!isEditing}
              maxLength={255}
              minLength={1}
              masked
              mask={/^\w+$/}
            />
            <SelectAndLabel
              value={form?.spotType?.value ?? ''}
              onChange={memoHandleChangeValue('spotType')}
              label={t('table.spot_type')}
              options={memoSpotTypeOptions}
              id='spotType' // OBRIGATORIO POR CAUSA DA LABEL/
              error={
                submitState.hasSubmitted &&
                (form?.spotType?.message != null || !!checkIfError('spotType'))
              }
              helperText={helperTextGenerator('spotType')}
              disabled={!(isEditing && canEdit('spotType'))}
              isEditing={!!isEditing}
            />
            <SelectAndLabel
              multiple
              value={form?.sensor?.value ?? []}
              onChange={memoHandleChangeArray('sensor')}
              label={t('table.sensors')}
              options={memoFetchedSensors}
              id='sensor' // OBRIGATORIO POR CAUSA DA LABEL/
              error={
                submitState.hasSubmitted &&
                (form?.sensor?.message != null || !!checkIfError('sensor'))
              }
              helperText={helperTextGenerator('sensor')}
              disabled={!(isEditing && canEdit('sensor'))}
              isEditing={!!isEditing}
            />
          </>
        )}
      </DetailsWrapper>
      <ActionsWrapper isEditing={isEditing ? 1 : 0}>
        {isEditing ? (
          <>
            <StyledButton
              type='inactive'
              onClick={cancelEditing}
              disabled={submitState.submitting}
            >
              {t('cancel')}
            </StyledButton>
            {responsiveHandlers.isDesktop && (
              <>
                <BlankSpace />
                <BlankSpace />
                <BlankSpace />
              </>
            )}
            <StyledButton
              type='primary'
              onClick={handleSubmit}
              disabled={submitState.submitting}
            >
              {t('submit')}
            </StyledButton>
          </>
        ) : (
          <>
            <StyledButton
              type='inactive'
              onClick={goBack}
            >
              {t('spots.back')}
            </StyledButton>
            {responsiveHandlers.isDesktop && (
              <>
                <BlankSpace />
                <BlankSpace />
              </>
            )}
            {hasCRUDPermission && canDelete ? (
              <StyledButton type='error' onClick={handleClickOpen}>
                {t('spots.delete')}
              </StyledButton>
            ) : (
              responsiveHandlers.isDesktop && <BlankSpace />
            )}
            {hasCRUDPermission && (
              <StyledButton type='primary' onClick={startEditing}>
                {t('spots.edit')}
              </StyledButton>
            )}
          </>
        )}
      </ActionsWrapper>
      <StyledDialog
        open={confirmDeleteOpen}
        handleClose={handleClose}
        title={t('spots.delete_title')}
        description={t('spots.delete_description')}
        handleSubmit={handleDelete}
        submitText={t('spots.delete')}
        submitType='danger'
      />
    </>
  )
}

export default memo(withResponsive(SpotsDetails), isEqual)

const ActionsWrapper = styled.div`
  display: grid;
  margin: 1.5rem 0 0;

  grid-template-columns: minmax(10rem, 1fr);
  row-gap: 1rem;

  ${({ theme }) => theme.smallDesktop`
  grid-template-columns: repeat(5, minmax(12.375rem, 1fr));
  column-gap: 1.5rem;
  margin: 2.5rem 0 0;


  ${props =>
    props.isEditing &&
    css`
      grid-template-columns: repeat(5, minmax(12.375rem, 1fr));
    `}
`}
`

const BlankSpace = styled.div`
  grid-column: span 1;
`

const DetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  ${({ theme }) => theme.smallDesktop`
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 0.75rem;


`}
`

const MapHelperText = styled.p`
  color: var(--primary-color);
  font-size: 0.875rem;
  font-style: italic;
  letter-spacing: 0;
  line-height: 1em;

  margin: 0.5rem 0 0;

  ${props =>
    props.error &&
    css`
      color: var(--error-color);
    `}
`

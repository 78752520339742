import React from 'react'
import styled, { css } from 'styled-components'

import { IMaskInput } from 'react-imask'

export default React.forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledMaskedInput
      lazy
      {...props}
      error={props.error ? 'true' : 'false'}
      key='maskedinput'
      value={props.value}
      ref={ref}
      inputRef={ref}
      onAccept={props.onChange}
      onChange={e => {}}
    />
  )
})

const StyledMaskedInput = styled(IMaskInput)`
  color: var(--breadcrumb-gray-color);
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1em;
  border-style: solid;
  border-width: 1px;
  border-color: var(--platinium-gray-color);
  border-radius: 0;
  margin: 0;
  width: 100%;
  outline: none;
  margin: 0;
  padding: 8.75px 14px 8.75px;
  font-family: var(--primary-font);

  &::placeholder {
    color: var(--platinium-gray-color);
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1rem;
    text-transform: uppercase;
  }

  &:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }

  &:focus,
  &:active {
    outline: 2px solid var(--primary-color);
    border: none;
  }

  &:disabled {
    background: transparent;
    &:hover,
    &:focus,
    &:active {
      border-color: var(--platinium-gray-color);
      border-style: solid;
      border-width: 1px;
      outline: none;
    }

    ${props =>
      props.isEditing &&
      css`
        color: var(--platinium-gray-color);
        cursor: not-allowed;
      `}
  }
  ${props =>
    props.error === 'true' &&
    css`
      border-color: var(--material-error-color);
    `}
`

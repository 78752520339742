import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import isEqual from 'lodash.isequal'

import StyledInput from 'components/StyledInput'

const InputAndLabel = ({ children, ...props }) => (
  <Wrapper error={props.error} disabled={props.isEditing && props.disabled}>
    <Label
      aria-label={`label-${props.name}`}
      disabled={props.isEditing && props.disabled}
    >
      {props.label}
    </Label>
    <StyledInput
      {...props}
      label=''
      fullWidth
      InputLabelProps={{
        shrink: false
      }}
      padding='0.75rem 0.5rem'
    />
    {props.masked && props.error && (
      <StyledError>{props.helperText}</StyledError>
    )}
  </Wrapper>
)

export default memo(InputAndLabel, isEqual)

const Wrapper = styled.div`
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  ${props =>
    props.error &&
    css`
      margin-bottom: calc(1.5rem - 1.66em);
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
`

const Label = styled.p`
  color: var(--breadcrumb-gray-color);
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1em;

  margin-bottom: 0.5rem;

  ${props =>
    props.disabled &&
    css`
      color: var(--platinium-gray-color);
    `}
`

const StyledError = styled.p`
  color: var(--material-error-color);
  font-family: var(--primary-font);
  margin: 0;

  font-size: 0.75rem;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
`

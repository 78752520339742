import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#prefix__clip0)'>
        <path
          d='M19.213 3.788C17.31 1.99 14.777 1 12.083 1c-2.693 0-5.225.99-7.13 2.788C3.05 5.586 2 7.977 2 10.52s1.049 4.933 2.953 6.731l5.357 5.058a2.566 2.566 0 001.773.691c.671 0 1.3-.245 1.773-.691l5.357-5.058c1.905-1.798 2.954-4.189 2.954-6.731 0-2.543-1.05-4.934-2.954-6.732z'
          fill='#08323B'
        />
        <path
          d='M18.87 4.152h0c1.808 1.707 2.797 3.967 2.797 6.368 0 2.4-.989 4.66-2.797 6.367l.343.364-.343-.364-5.357 5.058.343.364-.343-.364a2.066 2.066 0 01-1.43.555c-.548 0-1.053-.2-1.43-.555l-5.356-5.058h0C3.489 15.181 2.5 12.92 2.5 10.52s.989-4.661 2.797-6.368C7.105 2.444 9.514 1.5 12.083 1.5c2.57 0 4.978.944 6.787 2.652z'
          stroke='#fff'
          strokeOpacity={0.1}
        />
        <path
          d='M9.127 11.078A3.143 3.143 0 018.2 8.84c0-.845.33-1.64.927-2.237a.351.351 0 11.497.497 2.445 2.445 0 00-.72 1.74c0 .658.256 1.276.72 1.74a.352.352 0 01-.497.498zm5.97-2.238c0 .658-.257 1.276-.721 1.74a.351.351 0 10.497.498A3.144 3.144 0 0015.8 8.84c0-.845-.33-1.64-.927-2.237a.351.351 0 10-.497.497c.464.465.72 1.083.72 1.74zm-4.928 1.492a.351.351 0 10.497-.497 1.397 1.397 0 01-.411-.995c0-.375.146-.728.411-.994a.351.351 0 10-.497-.497 2.096 2.096 0 00-.617 1.491c0 .564.219 1.093.617 1.492zm3.576-1.492c0 .376-.146.73-.412.995a.352.352 0 00.498.497c.398-.399.617-.928.617-1.492 0-.563-.219-1.093-.617-1.491a.352.352 0 10-.497.497c.265.266.411.619.411.994zm-.76 4.823a.44.44 0 100 .88.44.44 0 000-.88zm-1.987 0a.44.44 0 100 .88.44.44 0 000-.88zm5.502 1.485a.352.352 0 01-.352.352H7.852a.352.352 0 01-.352-.352 2.446 2.446 0 012.443-2.443h1.705v-2.87a1.056 1.056 0 111.406-.994c0 .458-.293.848-.702.993v2.871h1.705a2.446 2.446 0 012.443 2.443zM11.648 8.84a.352.352 0 10.704 0 .352.352 0 00-.704 0z'
          fill='#fff'
        />
        <g filter='url(#prefix__filter0_d)'>
          <circle cx={20} cy={4} r={3} fill='#E63C2F' />
          <circle cx={20} cy={4} r={2.5} stroke='#fff' strokeOpacity={0.3} />
        </g>
      </g>
      <defs>
        <clipPath id='prefix__clip0'>
          <path fill='#fff' d='M0 0h24v24H0z' />
        </clipPath>
        <filter
          id='prefix__filter0_d'
          x={13}
          y={-3}
          width={14}
          height={14}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent

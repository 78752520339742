import React, { memo } from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

export default memo(({ children, ...props }) => (
  <FormControl component='fieldset' error={props.error}>
    <FormLabel component='legend'>{props.label}</FormLabel>
    <RadioGroup name={props.name} value={props.value} onChange={props.onChange}>
      {props.options != null
        ? props.options.map((el, index) => (
          <FormControlLabel
            key={`styled-option-${index}`}
            value={el.value}
            control={<Radio color='primary' />}
            label={el.label ?? el.value}
          />
        ))
        : []}
    </RadioGroup>
    <FormHelperText>{props.errorMessage}</FormHelperText>
  </FormControl>
))

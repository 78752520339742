import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  memo,
  useMemo,
  useCallback
} from 'react'
import styled from 'styled-components'
import { AUTOMATIC_EDIT_PERMISSIONS } from 'config'

import Badge from '@material-ui/core/Badge'

import { Context as AuthContext } from 'providers/AuthProvider/authProvider'
import { Context as SocketContext } from 'providers/SocketProvider/socketProvider'
import withResponsive from 'providers/ResponsiveHandler/withResponsive'

import { useTranslation } from 'react-i18next'
import {
  useHistory,
  useRouteMatch,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import StyledBreadCrumb from 'components/StyledBreadCrumb'
import StyledButton from 'components/StyledButton'
import StyledAutoComplete from 'components/StyledAutoComplete'
import StyledSelect from 'components/StyledSelect'
import StyledMenu from 'components/StyledMenu'
import toast from 'components/StyledToast'

import MapContainer from 'containers/MapContainer'
import HardwareList from 'containers/HardwareList'
import HardwareDetails from 'containers/HardwareDetails'
import FiltersModal from 'containers/FiltersModal'

import UploadIcon from 'icons/UploadIcon'
import FilterIcon from 'icons/FilterIcon'

import hasCRUDPermissions from 'utils/hasCRUDPermissions'

import columns from './columns'
import logic from './logic'
import {
  typeOptions,
  monitoringOptions,
  countingTypeOptions,
  statusOptions,
  allowedOptions,
  gatewaysTypeOptions
} from 'utils/options'

const Hardware = ({ children, ...props }) => {
  const { actions, store } = useContext(AuthContext)
  const { store: wsStore } = useContext(SocketContext)

  const { responsiveHandlers } = props

  const { t } = useTranslation()
  const { path } = useRouteMatch()
  const history = useHistory()

  const [isEditing, setIsEditing] = useState(false)
  const [open, setOpen] = useState(false)
  const [geometry, setGeometry] = useState(null)
  const [fetchedHardware, setFetchedHardware] = useState(null)

  const [mapHelperText, setMapHelperText] = useState(null)

  const [fetchedAreasAndGateways, setfetchedAreasAndGateways] = useState({
    areas: [],
    gateways: []
  })

  const [hardwareState, setHardwareState] = useState({
    hardwareCount: 0,
    hardware: [],
    loading: true
  })

  const memoColumns = useMemo(() => columns, [])
  const memoData = useMemo(() => hardwareState?.hardware, [
    hardwareState?.hardware
  ])
  const defaultFilter = useMemo(
    () => ({
      uid: '',
      name: '',
      type: '',
      monitoring_type: '',
      counting_type: '',
      status: '',
      area: ''
    }),
    []
  )

  const [filters, setFilters] = useState(defaultFilter)
  const [pagination, setPagination] = useState({ offset: 1, limit: 10 })
  const [triggerSet, setTriggerSet] = useState(false)

  const [title, setTitle] = useState('')

  const locationInputTimeoutRef = useRef(null)
  const [locationOptions, setLocationOptions] = useState([])
  const [locationTextInput, setLocationTextInput] = useState('')
  const [locationSelected, setLocationSelected] = useState(null)

  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  const [uploadType, setUploadType] = useState(null)
  const hiddenFileInput = useRef(null)

  const [submitState, setSubmitState] = useState({
    submitting: false,
    hasSubmitted: false
  })

  const uidTextInputTimeoutRef = useRef(null)
  const nameTextInputTimeoutRef = useRef(null)
  const areaTextInputTimeoutRef = useRef(null)

  const [textInput, setTextInput] = useState({
    uid: '',
    name: '',
    area: ''
  })
  const [searchOptions, setSeachOptions] = useState({
    uid: [],
    name: [],
    area: []
  })

  const [hardwareInDetail, setHardwareInDetail] = useState(null)
  const [update, setUpdate] = useState(false)

  const [openFilters, setOpenFilters] = useState(false)

  const onFilterChange = useCallback(
    (key, value) => {
      setFilters({
        ...filters,
        [key]: value
      })
    },
    [filters]
  )

  const handleAutocompleteInputs = useCallback(
    (property, value) => {
      setTextInput({
        ...textInput,
        [property]: value
      })
      onFilterChange(property, value)
    },
    [textInput, onFilterChange]
  )

  useEffect(() => {
    if (locationInputTimeoutRef.current !== null) {
      clearTimeout(locationInputTimeoutRef.current)
    }
    locationInputTimeoutRef.current = setTimeout(() => {
      locationInputTimeoutRef.current = null
      if (logic.fetchLocationOptions) {
        logic
          .fetchLocationOptions({
            params: { q: locationTextInput, format: 'jsonv2' }
          })
          .then(response => {
            if (response.data) {
              const { data } = response
              setLocationOptions(data)
            } else {
              setLocationOptions([])
            }
          })
          .catch(err => {
            console.log({ err })
            setLocationOptions([])
          })
      }
    }, 500)
  }, [locationTextInput])

  const handleSetHardwareInDetail = useCallback(
    (hardware, deviceType) => {
      if (
        hardware != null &&
        hardware.id != null &&
        (hardware.deviceType != null || deviceType != null)
      ) {
        const type = hardware.deviceType || deviceType
        setHardwareInDetail(hardware)
        history.push(`${path}${type}/${hardware.id}/`)
      } else {
        setHardwareInDetail(null)
        setFetchedHardware(null)
        setIsEditing(false)
        setTriggerSet(true)
        history.push(`${path}`)
      }
    },
    [history, path]
  )

  const resetParent = useCallback(() => {
    setLocationTextInput('')
    setLocationSelected(null)
    setLocationOptions([])
  }, [])

  useEffect(() => {
    logic
      .fetchAreasAndGateways()
      .then(response => {
        const [areasResults, gatewayResults] = response
        if (
          areasResults.data != null &&
          areasResults.data.results != null &&
          gatewayResults.data != null &&
          gatewayResults.data.results
        ) {
          setfetchedAreasAndGateways({
            areas: [...areasResults.data.results],
            gateways: [...gatewayResults.data.results]
          })
        }
      })
      .catch(err => {
        console.log({ err })
        setfetchedAreasAndGateways({
          areas: [],
          gateways: []
        })
        // ERROR
      })
  }, [hardwareInDetail])

  useEffect(() => {
    if (areaTextInputTimeoutRef.current !== null) {
      clearTimeout(areaTextInputTimeoutRef.current)
    }

    areaTextInputTimeoutRef.current = setTimeout(() => {
      areaTextInputTimeoutRef.current = null
      logic
        .fetchAreasOptions({
          params: { name: textInput.area !== '' ? textInput.area : 'all' }
        })
        .then(response => {
          if (response.data) {
            const { data } = response
            setSeachOptions(searchOptions => ({
              ...searchOptions,
              area: data.results
            }))
          } else {
            setSeachOptions(searchOptions => ({ ...searchOptions, area: [] }))
          }
        })
        .catch(err => {
          console.log({ err })
          setSeachOptions(searchOptions => ({ ...searchOptions, area: [] }))
        })
    }, 500)
  }, [textInput.area])

  useEffect(() => {
    if (uidTextInputTimeoutRef.current !== null) {
      clearTimeout(uidTextInputTimeoutRef.current)
    }

    uidTextInputTimeoutRef.current = setTimeout(() => {
      uidTextInputTimeoutRef.current = null
      logic
        .fetchAssetsOptions({
          params: { uid: textInput.uid !== '' ? textInput.uid : 'all' }
        })
        .then(response => {
          if (response.data) {
            const { data } = response
            setSeachOptions(searchOptions => ({
              ...searchOptions,
              uid: data.results
            }))
          } else {
            setSeachOptions(searchOptions => ({ ...searchOptions, uid: [] }))
          }
        })
        .catch(err => {
          console.log({ err })
          setSeachOptions(searchOptions => ({ ...searchOptions, uid: [] }))
        })
    }, 500)
  }, [textInput.uid])

  useEffect(() => {
    if (nameTextInputTimeoutRef.current !== null) {
      clearTimeout(nameTextInputTimeoutRef.current)
    }

    nameTextInputTimeoutRef.current = setTimeout(() => {
      nameTextInputTimeoutRef.current = null
      logic
        .fetchAssetsOptions({
          params: { name: textInput.name !== '' ? textInput.name : 'all' }
        })
        .then(response => {
          if (response.data) {
            const { data } = response
            setSeachOptions(searchOptions => ({
              ...searchOptions,
              name: data.results
            }))
          } else {
            setSeachOptions(searchOptions => ({ ...searchOptions, name: [] }))
          }
        })
        .catch(err => {
          console.log({ err })
          setSeachOptions(searchOptions => ({ ...searchOptions, name: [] }))
        })
    }, 500)
  }, [textInput.name])

  useEffect(() => {
    if (hardwareInDetail == null) {
      fetchHardware()
      setPagination(() => ({ offset: 1, limit: 10 }))
    }
    // eslint-disable-next-line
  }, [triggerSet])

  const fetchHardwareAndUpdate = useCallback(
    (id, type) => {
      let urlType
      switch (type) {
        case 'sensor':
          urlType = 'sensors'
          break
        case 'gateway':
          urlType = 'gateways'
          break
        case 'informationpanel':
          urlType = 'panels'
          break
        default:
          urlType = null
          break
      }
      if (urlType != null) {
        logic
          .getHardwareDetails(id, urlType)
          .then(response => {
            if (response.data) {
              const { data } = response
              const hardwareCopy = JSON.parse(
                JSON.stringify(hardwareState.hardware)
              )
              const found = hardwareCopy.findIndex(el => el.id === id)
              if (found >= 0) {
                hardwareCopy[found] = data
                setHardwareState({
                  ...hardwareState,
                  hardware: hardwareCopy
                })
              }
            }
          })
          .catch(err => console.log('could not update resources: ', { err }))
      }
    },
    [hardwareState]
  )

  const fetchHardware = useCallback(() => {
    setHardwareState({ ...hardwareState, loading: true })
    setTriggerSet(false)

    logic
      .getHardware({
        params: {
          ...{
            limit: 'max'
          },
          ...filters
        }
      })
      .then(response => {
        if (response.data) {
          const { data } = response
          setHardwareState({
            hardwareCount: data.count,
            hardware: data.results,
            loading: false
          })
        } else {
          setHardwareState({
            hardwareCount: 0,
            hardware: [],
            loading: false
          })
        }
      })
      .catch(err => {
        console.log({ err })
        setHardwareState({ hardwareCount: 0, hardware: [], loading: false })
        if (err == null || err.response == null) {
          actions.serverError()
        }
      })
  }, [filters, actions, hardwareState])

  const canEdit = useCallback((asset, property, deviceType) => {
    if (asset != null) {
      if (asset.deviceType === 'sensor' || deviceType === 'sensor') {
        if (property === 'number') {
          return false
        }
      }
      if (asset.mode === 'automatic') {
        const type = asset.deviceType || deviceType
        return AUTOMATIC_EDIT_PERMISSIONS?.[type]?.includes(property)
      }
      return true
    }
    return false
  }, [])

  const resetFilters = useCallback(() => {
    setFilters(defaultFilter)
    setTextInput({
      uid: '',
      name: '',
      area: ''
    })
    setTriggerSet(true)
  }, [defaultFilter])

  const handleMenuOpen = useCallback(event => {
    setMenuAnchorEl(event.currentTarget)
  }, [])

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null)
  }, [])

  const handleMenuClick = useCallback(
    clickType => {
      setUploadType(clickType)
      if (hiddenFileInput != null && hiddenFileInput.current != null) {
        hiddenFileInput.current.click()
        handleMenuClose()
      }
    },
    [hiddenFileInput, handleMenuClose]
  )

  const handleChange = useCallback(
    async event => {
      const fileUploaded = await event.target.files[0]
      if (fileUploaded != null && uploadType != null) {
        setSubmitState({
          submitting: true,
          hasSubmitted: true
        })
        setHardwareState({ ...hardwareState, loading: true })
        // eslint-disable-next-line
        const formData = new FormData()
        formData.append('file', fileUploaded)
        await logic
          .uploadCSV(formData, uploadType)
          .then(response => {
            setSubmitState({
              ...submitState,
              submitting: false
            })
            if (response.status === 200) {
              toast.success(t('assets.create_success'))
            }
          })
          .catch(err => {
            console.log({ err })
            setSubmitState({
              ...submitState,
              submitting: false
            })
            toast.error(t('assets.create_error'))
            if (err == null || err.response == null) {
              actions.serverError()
            }
          })
          .finally(() => setTriggerSet(true))
      }
    },
    [uploadType, hardwareState, submitState, actions, t]
  )

  const handleWSMessages = useCallback(
    msg => {
      if (
        msg != null &&
        (msg.sender === 'informationpanel' ||
          msg.sender === 'sensor' ||
          msg.sender === 'gateway')
      ) {
        if (
          hardwareInDetail != null &&
          msg?.sender === hardwareInDetail.deviceType &&
          msg?.content?.id === hardwareInDetail.id
        ) {
          if (msg.type === 'update') {
            setUpdate(!update)
          } else if (msg.type === 'delete' && msg.content != null) {
            setHardwareInDetail(null)
          }
        } else if (hardwareInDetail == null) {
          if (msg.type === 'new' || msg.type === 'delete') {
            fetchHardware()
          } else if (msg.type === 'update' && msg?.content?.id) {
            fetchHardwareAndUpdate(msg.content.id, msg.sender)
          } else {
            fetchHardware()
          }
        }
      }
    },
    [fetchHardware, fetchHardwareAndUpdate, hardwareInDetail, update]
  )

  useEffect(() => {
    if (wsStore.message != null) {
      handleWSMessages(wsStore.message)
    }
    // eslint-disable-next-line
  }, [wsStore.message])

  const hasCRUDPermission = hasCRUDPermissions(store.user)

  const memoStatusOptions = useMemo(
    () =>
      statusOptions.map(el => ({
        ...el,
        label: t(`table.${el.label}`)
      })),
    [t]
  )

  const memoTypeOptions = useMemo(
    () =>
      typeOptions.map(el => ({
        ...el,
        label: t(`table.${el.label}`)
      })),
    [t]
  )

  const memoHandleAutocompleteInputs = useCallback(
    name => e => handleAutocompleteInputs(name, e),
    [handleAutocompleteInputs]
  )

  const memoOnFilterChange = useCallback(
    name => (e, newValue) => onFilterChange(name, newValue),
    [onFilterChange]
  )

  const memoOnFilterChangeValue = useCallback(
    name => e => onFilterChange(name, e?.target?.value),
    [onFilterChange]
  )
  const filtersInputs = useMemo(
    () => (
      <React.Fragment key='filters-inputs'>
        <StyledAutoComplete
          value={textInput.uid}
          onChange={memoHandleAutocompleteInputs('uid')}
          inputValue={filters.uid}
          onInputChange={memoOnFilterChange('uid')}
          options={searchOptions.uid}
          label={t('table.uid')}
          name='uid'
          variant='standard'
          id='uid'
        />
        <StyledAutoComplete
          value={textInput.name}
          onChange={memoHandleAutocompleteInputs('name')}
          inputValue={filters.name}
          onInputChange={memoOnFilterChange('name')}
          options={searchOptions.name}
          label={t('table.name')}
          name='name'
          variant='standard'
          id='name'
        />
        <StyledAutoComplete
          value={textInput.area}
          onChange={memoHandleAutocompleteInputs('area')}
          inputValue={filters.area}
          onInputChange={memoOnFilterChange('area')}
          options={searchOptions.area}
          label={t('table.area')}
          name='area'
          variant='standard'
          id='area'
        />
        <StyledSelect
          value={filters.type}
          onChange={memoOnFilterChangeValue('type')}
          label={t('table.type')}
          options={memoTypeOptions}
          id='type' // OBRIGATORIO POR CAUSA DA LABEL
        />
        <StyledSelect
          value={filters.status}
          onChange={memoOnFilterChangeValue('status')}
          label={t('table.status')}
          options={memoStatusOptions}
          id='status' // OBRIGATORIO POR CAUSA DA LABEL
        />
      </React.Fragment>
    ),
    [
      textInput,
      filters,
      searchOptions.area,
      searchOptions.name,
      searchOptions.uid,
      t,
      memoStatusOptions,
      memoTypeOptions,
      memoOnFilterChangeValue,
      memoHandleAutocompleteInputs,
      memoOnFilterChange
    ]
  )

  const handleReset = useCallback(() => {
    resetFilters()
    setOpenFilters(false)
  }, [resetFilters])

  const handleApply = useCallback(() => {
    setTriggerSet(true)
    setOpenFilters(false)
  }, [])

  const filtersButtons = useMemo(
    () => (
      <React.Fragment key='filters-buttons'>
        <StyledButton onClick={handleReset} disabled={submitState.submitting}>
          {t('table.reset')}
        </StyledButton>
        <StyledButton
          type='primary'
          onClick={handleApply}
          disabled={submitState.submitting}
        >
          {t('table.apply')}
        </StyledButton>
      </React.Fragment>
    ),
    [submitState.submitting, t, handleApply, handleReset]
  )

  const removeHardwareInDetail = useCallback(
    () => handleSetHardwareInDetail(null),
    [handleSetHardwareInDetail]
  )

  const breadCrumbItems = useMemo(
    () => [
      {
        path: path,
        label: 'sections.assets',
        onClick: removeHardwareInDetail
      },
      {
        path: '',
        label: `${t(title)} ${
          hardwareInDetail != null ? ` - ${hardwareInDetail.uid}` : ''
        }`
      }
    ],
    [hardwareInDetail, path, title, removeHardwareInDetail, t]
  )

  const openNewPopup = useCallback(() => setOpen(true), [])

  const mapData = useMemo(
    () =>
      hardwareInDetail != null && fetchedHardware != null
        ? fetchedHardware?.geometry != null
          ? [fetchedHardware]
          : null
        : memoData,
    [hardwareInDetail, fetchedHardware, memoData]
  )

  const showSpots = useMemo(
    () =>
      hardwareInDetail != null &&
      fetchedHardware != null &&
      fetchedHardware?.deviceType != null &&
      fetchedHardware?.deviceType === 'sensor',
    [hardwareInDetail, fetchedHardware]
  )

  const showSensors = useMemo(
    () =>
      hardwareInDetail != null &&
      fetchedHardware != null &&
      fetchedHardware?.deviceType != null &&
      fetchedHardware?.deviceType === 'gateway',
    [hardwareInDetail, fetchedHardware]
  )

  const flyToLocation = useMemo(
    () =>
      locationSelected?.boundingbox != null
        ? [
          [locationSelected.boundingbox[2], locationSelected.boundingbox[0]],
          [locationSelected.boundingbox[3], locationSelected.boundingbox[1]]
        ]
        : null,
    [locationSelected?.boundingbox]
  )

  const onSearchChange = useCallback(event => setLocationTextInput(event), [])
  const onSearchInputChange = useCallback(
    (event, newValue) => setLocationSelected(newValue),
    []
  )

  const getOptionLabel = useCallback(option => option.displayName, [])

  const mapSearchProps = useMemo(
    () => ({
      value: locationTextInput,
      onChange: onSearchChange,
      inputValue: locationSelected,
      onInputChange: onSearchInputChange,
      options: locationOptions,
      label: t('map.search_location'),
      getOptionLabel: getOptionLabel
    }),
    [
      locationTextInput,
      locationSelected,
      getOptionLabel,
      locationOptions,
      onSearchChange,
      onSearchInputChange,
      t
    ]
  )

  const memoSetTriggerSet = useCallback(() => setTriggerSet(true), [])

  const onCloseFilters = useCallback(() => setOpenFilters(false), [])
  const onOpenFilters = useCallback(() => setOpenFilters(true), [])

  const downloadTypeOptions = useMemo(
    () => [
      { label: t('assets.sensor'), value: 'sensor' },
      { label: t('assets.gateway'), value: 'gateway' },
      { label: t('assets.panel'), value: 'panel' },
      { label: t('assets.repeater'), value: 'repeater' }
    ],
    [t]
  )

  const isDrawAvailable = useMemo(() => isEditing && canEdit('geometry'), [
    isEditing,
    canEdit
  ])

  return (
    <Wrapper>
      <BreadCrumbRow>
        <StyledBreadCrumb items={breadCrumbItems} />
        {hardwareInDetail == null && hasCRUDPermission && (
          <ButtonWrapper>
            <StyledButton
              type='primary'
              onClick={openNewPopup}
              disabled={submitState.submitting}
            >
              {t('assets.new')}
            </StyledButton>
            <StyledButton
              type='primary'
              onClick={handleMenuOpen}
              disabled={submitState.submitting}
              tooltip={t('upload_csv')}
            >
              <UploadIcon height={24} width={24} fill='var(--white-color)' />
            </StyledButton>
            <StyledMenu
              options={downloadTypeOptions}
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
              onClick={handleMenuClick}
            />
            <input
              type='file'
              ref={hiddenFileInput}
              onChange={handleChange}
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.csv,.xls,.xlsx'
              style={{ display: 'none' }}
            />
            {!responsiveHandlers.isDesktop && (
              <StyledButton
                tooltip={t('filters')}
                type='primary'
                onClick={onOpenFilters}
              >
                <Badge
                  color='secondary'
                  variant='dot'
                  invisible={
                    JSON.stringify(filters) === JSON.stringify(defaultFilter)
                  }
                >
                  <FilterIcon
                    height={24}
                    width={24}
                    fill='var(--white-color)'
                  />
                </Badge>
              </StyledButton>
            )}
          </ButtonWrapper>
        )}
      </BreadCrumbRow>
      {hardwareInDetail == null && responsiveHandlers.isDesktop && (
        <FiltersWrapper>
          <FiltersRow>{[filtersInputs, filtersButtons]}</FiltersRow>
        </FiltersWrapper>
      )}
      <MapAndTextWrapper>
        <MapWrapper>
          <MapContainer
            data={mapData}
            hideLegends
            type='assets'
            isDrawAvailable={isDrawAvailable}
            setGeometry={setGeometry}
            geometry={geometry}
            showSpots={showSpots}
            showSensors={showSensors}
            inDetail={hardwareInDetail}
            enableSearch={isDrawAvailable}
            search={mapSearchProps}
            flyToLocation={flyToLocation}
            filters={filters}
          />
        </MapWrapper>
        {isDrawAvailable && <>{mapHelperText}</>}
      </MapAndTextWrapper>
      <Switch>
        <Route path={`${path}:type/:id/`} exact>
          <HardwareDetails
            setHardwareInDetail={handleSetHardwareInDetail}
            hardwareInDetail={hardwareInDetail}
            countingTypeOptions={countingTypeOptions}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            setGeometry={setGeometry}
            geometry={geometry}
            allowedOptions={allowedOptions}
            fetchedAreas={fetchedAreasAndGateways.areas}
            fetchedGateways={fetchedAreasAndGateways.gateways}
            setTitle={setTitle}
            setMapHelperText={setMapHelperText}
            canEdit={canEdit}
            fetchedHardware={fetchedHardware}
            setFetchedHardware={setFetchedHardware}
            resetParent={resetParent}
            hasCRUDPermission={hasCRUDPermission}
            update={update}
            gatewaysTypeOptions={gatewaysTypeOptions}
          />
        </Route>
        <Route path={path} exact>
          <HardwareList
            loading={hardwareState.loading}
            hardware={memoData}
            hardwareCount={hardwareState.hardwareCount}
            columns={memoColumns}
            monitoringOptions={monitoringOptions}
            setHardwareInDetail={handleSetHardwareInDetail}
            open={open}
            setOpen={setOpen}
            allowedOptions={allowedOptions}
            fetchedAreas={fetchedAreasAndGateways.areas}
            fetchedGateways={fetchedAreasAndGateways.gateways}
            setTitle={setTitle}
            pagination={pagination}
            setPagination={setPagination}
            triggerSet={memoSetTriggerSet}
            gatewaysTypeOptions={gatewaysTypeOptions}
          />
        </Route>
        <Route path='*'>
          <Redirect to={path} />
        </Route>
      </Switch>
      <FiltersModal
        open={openFilters}
        onClose={onCloseFilters}
        inputs={filtersInputs}
        buttons={filtersButtons}
        title={t('filters')}
      />
    </Wrapper>
  )
}

export default memo(withResponsive(Hardware))

const Wrapper = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;

  ${({ theme }) => theme.smallDesktop`
    padding: 1.5rem 2rem;
  `}
`

const MapWrapper = styled.div`
  width: 100%;
  min-height: 22rem;
  height: 100%;
`

const MapAndTextWrapper = styled.div`
  margin: 1.5rem 0;
  /* height: calc(22rem + 0.875rem + 0.5rem); */
  /* 100vh - navbar - wrapper_padding - breadcrumb_height - filters_height+margin - this.margin - table_maxheight - pagination */
  min-height: 22rem;

  height: calc(
    100vh - 3.75rem - 4rem - 4.125rem - 4rem - 3rem - 25rem - 3.75rem
  );
  flex: 0 0 auto;
`

const BreadCrumbRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  flex: 0 0 auto;

  ${({ theme }) => theme.smallDesktop`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 2.5rem;
  `}
`
const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 10rem 2.5rem 2.5rem;
  column-gap: 0.5rem;
  margin-right: auto;
  ${({ theme }) => theme.smallDesktop`
    grid-template-columns: 14.625rem 2.5rem;
    column-gap: 0.5rem;
    margin: 0;
  `}
`

const FiltersWrapper = styled.div`
  margin: 1.5rem 0 0rem;
`

const FiltersRow = styled.div`
  display: none;
  ${({ theme }) => theme.smallDesktop`
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    display: grid;
    column-gap: 1rem;
    row-gap: 1rem;

  `}
`

import React from 'react'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import StyledButton from 'components/StyledButton'

export default ({ children, ...props }) => {
  const { t } = useTranslation()

  return (
    <Wrapper
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>
      {props.description != null && props.body == null && (
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {props.description}
          </DialogContentText>
        </DialogContent>
      )}
      {props.description == null && props.body != null && (
        <DialogContent>{props.body}</DialogContent>
      )}
      <DialogActions>
        {props.handleClose != null && (
          <StyledButton onClick={props.handleClose} type='secondary'>
            {props.cancelText ? props.cancelText : t('cancel')}
          </StyledButton>
        )}
        {props.handleSubmit != null && (
          <StyledButton
            onClick={props.handleSubmit}
            type={props.submitType || 'primary'}
          >
            {props.submitText ? props.submitText : t('submit')}
          </StyledButton>
        )}
      </DialogActions>
    </Wrapper>
  )
}

const Wrapper = styled(Dialog)``

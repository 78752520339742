import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

export default ({ children, ...props }) => (
  <Wrapper {...props}>
    <CircularProgress />
  </Wrapper>
)

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  grid-columns: 1/5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(0.5rem);
  z-index: 1001;

  ${({ theme }) => theme.smallDesktop`
    grid-column: 1/13;
  `}
`

import axios from 'axios'
import mapValues from 'lodash/mapValues'
import ValidationError from 'utils/ValidationError'

import * as Check from 'utils/validations'
import { BASE_API_URL, NOMINATIM_BASE_URL, NOMINATIM_OPTIONS } from 'config'
// const SEARCH_URL = BASE_API_URL + '/areas/search/'
const NEWAREA_URL = BASE_API_URL + '/areas/'
const NOMINATIM_SEARCH_URL = NOMINATIM_BASE_URL + '/search'

const VALIDATIONS = {
  uid: [Check.isRequired],
  monitoringType: [Check.isRequired],
  capacityMargin: [Check.isRequired],
  capacity: [Check.isRequired],
  name: [Check.isRequired],
  geometry: [Check.isRequired],
  address: [],
  description: [],
  sensors: [],
  gateways: []
}

const logic = {
  defaultValues: {
    uid: { value: null },
    monitoringType: { value: null },
    capacityMargin: { value: null },
    capacity: { value: null },
    name: { value: null },
    geometry: { value: null },

    address: { value: null },
    description: { value: null },
    sensors: { value: [] },
    gateways: { value: [] }
  },
  // fetchAreasOptions: options => {
  //   return Promise.resolve().then(() => axios.get(SEARCH_URL, options))
  // },
  submitNewArea: (form, setForm) =>
    Promise.resolve().then(() => {
      const validation = Check.checkValidation(form, VALIDATIONS)
      if (validation.invalid) {
        setForm({ ...validation.form })
        throw new ValidationError()
      }

      const params = mapValues(form, ({ value }) => value)
      return axios.post(NEWAREA_URL, { ...params })
    }),
  fetchLocationOptions: options =>
    Promise.resolve().then(() =>
      axios.get(NOMINATIM_SEARCH_URL, {
        ...options,
        params: {
          ...options.params,
          ...NOMINATIM_OPTIONS
        }
      })
    )
}

export default logic

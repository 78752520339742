import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg viewBox='0 0 44 44' {...props}>
      <g fill={props.fill} fillRule='nonzero'>
        <path d='M3.942 15.838L.04 41.697l19.512-10.344zM22.168 33.05L1.634 43.936H43.95L42.308 33.05zM39.433 14h-3.318c-2.576 5.613-6.774 9.836-7.028 10.089a2.746 2.746 0 01-3.873 0c-.254-.253-4.451-4.476-7.028-10.089H5.966l16.427 16.329h19.504L39.433 14z' />
        <g>
          <path d='M27.295 0A8.294 8.294 0 0019 8.294c0 3.286 4.267 9.573 6.68 12.865a2.002 2.002 0 003.229 0c2.413-3.292 6.68-9.58 6.68-12.865A8.294 8.294 0 0027.295 0zm0 13.444a4.914 4.914 0 01-4.91-4.908 4.914 4.914 0 014.91-4.909 4.914 4.914 0 014.908 4.909 4.913 4.913 0 01-4.908 4.908z' />
          <path d='M27.295 5.55a2.989 2.989 0 00-2.986 2.986 2.989 2.989 0 002.986 2.985 2.989 2.989 0 002.985-2.985 2.989 2.989 0 00-2.985-2.986z' />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent

import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
    >
      <g>
        <path
          fill='#fff'
          d='M 10.992188 4.730469 C 12.296875 4.730469 13.355469 3.671875 13.355469 2.363281 C 13.355469 1.058594 12.296875 0 10.992188 0 C 9.683594 0 8.625 1.058594 8.625 2.363281 C 8.625 3.671875 9.683594 4.730469 10.992188 4.730469 Z M 10.992188 4.730469 '
        />
        <path
          fill='#fff'
          d='M 14.644531 18.132812 C 14.019531 20.3125 12.007812 21.917969 9.625 21.917969 C 6.738281 21.917969 4.394531 19.570312 4.394531 16.6875 C 4.394531 14.554688 5.683594 12.714844 7.519531 11.902344 L 7.519531 9.6875 C 4.511719 10.59375 2.3125 13.386719 2.3125 16.6875 C 2.3125 20.71875 5.59375 24 9.625 24 C 12.5625 24 15.09375 22.253906 16.257812 19.746094 L 15.429688 18.125 C 15.199219 18.128906 14.929688 18.128906 14.644531 18.132812 Z M 14.644531 18.132812 '
        />
        <path
          fill='#fff'
          d='M 21.535156 20.941406 L 18.367188 14.710938 C 18.132812 14.246094 17.65625 13.953125 17.132812 13.953125 L 13.074219 13.953125 L 13.074219 13.027344 L 16.566406 13.027344 C 16.914062 13.027344 17.207031 12.84375 17.398438 12.582031 C 17.519531 12.410156 17.609375 12.210938 17.609375 11.984375 C 17.609375 11.410156 17.140625 10.945312 16.566406 10.945312 L 13.074219 10.945312 L 13.074219 7.804688 C 13.074219 6.941406 12.433594 5.722656 10.992188 5.722656 C 9.839844 5.722656 8.910156 6.65625 8.910156 7.804688 L 8.910156 14.605469 C 8.910156 15.777344 9.859375 16.730469 11.035156 16.730469 L 16.28125 16.730469 L 19.0625 22.199219 C 19.304688 22.679688 19.792969 22.957031 20.300781 22.957031 C 20.511719 22.957031 20.726562 22.910156 20.929688 22.808594 C 21.613281 22.460938 21.886719 21.625 21.535156 20.941406 Z M 21.535156 20.941406 '
        />
      </g>
    </svg>
  )
}

export default SvgComponent

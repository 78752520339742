import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg
      width={24}
      height={24}
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#prefix__clip0)'>
        <path
          d='M19.213 3.788C17.31 1.99 14.777 1 12.083 1c-2.693 0-5.225.99-7.13 2.788C3.05 5.586 2 7.977 2 10.52s1.049 4.933 2.953 6.731l5.357 5.058a2.566 2.566 0 001.773.691c.671 0 1.3-.245 1.773-.691l5.357-5.058c1.905-1.798 2.954-4.189 2.954-6.731 0-2.543-1.05-4.934-2.954-6.732z'
          fill='#08323B'
        />
        <path
          d='M18.87 4.152h0c1.808 1.707 2.797 3.967 2.797 6.368 0 2.4-.989 4.66-2.797 6.367l.343.364-.343-.364-5.357 5.058.343.364-.343-.364a2.066 2.066 0 01-1.43.555c-.548 0-1.053-.2-1.43-.555l-5.356-5.058h0C3.489 15.181 2.5 12.92 2.5 10.52s.989-4.661 2.797-6.368C7.105 2.444 9.514 1.5 12.083 1.5c2.57 0 4.978.944 6.787 2.652z'
          stroke='#fff'
          strokeOpacity={0.1}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.786 9.031h-1.5v3.938h.857v-1.313h.643c.709 0 1.285-.588 1.285-1.312 0-.724-.576-1.313-1.285-1.313zm.043 1.75h-.686v-.875h.686c.235 0 .428.197.428.438 0 .24-.193.437-.428.437zM13.29 12.753a.809.809 0 01-.6.26h-.86a.821.821 0 01-.604-.258.849.849 0 01-.255-.62V9.953c0-.242.085-.448.255-.62a.821.821 0 01.604-.257h.86a.81.81 0 01.6.26.856.856 0 01.253.617v2.184a.856.856 0 01-.252.617zm-1.46-2.801v1.486l.854-1.565c-.062-.241-.204-.362-.427-.362a.407.407 0 00-.3.128.43.43 0 00-.127.313zm.147 2.53c.07.063.164.095.28.095.12 0 .222-.044.306-.132a.432.432 0 00.126-.31V10.65l-.848 1.56c.02.118.066.21.136.272zM16.377 12.753a.809.809 0 01-.602.26h-.859a.821.821 0 01-.604-.258.849.849 0 01-.255-.62V9.953c0-.242.085-.448.255-.62a.821.821 0 01.604-.257h.859a.81.81 0 01.602.26.856.856 0 01.252.617v2.184a.856.856 0 01-.252.617zm-1.461-2.801v1.486l.854-1.565c-.062-.241-.204-.362-.427-.362a.407.407 0 00-.301.128.43.43 0 00-.126.313zm.147 2.53c.07.063.163.095.28.095.12 0 .222-.044.306-.132a.432.432 0 00.126-.31V10.65l-.849 1.56c.02.118.066.21.137.272z'
          fill='#fff'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.6 7.5c-.331 0-.6.274-.6.613v5.774c0 .339.269.613.6.613h10.8c.331 0 .6-.274.6-.613V8.113a.606.606 0 00-.6-.613H6.6zm.086 6.3V8.2h10.628v5.6H6.686z'
          fill='#fff'
        />
        <g filter='url(#prefix__filter0_d)'>
          <circle cx={20} cy={4} r={3} fill='#FCBD16' />
          <circle cx={20} cy={4} r={2.5} stroke='#fff' strokeOpacity={0.3} />
        </g>
      </g>
      <defs>
        <clipPath id='prefix__clip0'>
          <path fill='#fff' d='M0 0h24v24H0z' />
        </clipPath>
        <filter
          id='prefix__filter0_d'
          x={13}
          y={-3}
          width={14}
          height={14}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent

import axios from 'axios'

import { BASE_API_URL, NOMINATIM_BASE_URL, NOMINATIM_OPTIONS } from 'config'
const HARDWARE_URL = BASE_API_URL + '/assets/'
const AREAS_SEARCH_URL = BASE_API_URL + '/areas/search/'
const ASSETS_SEARCH_URL = BASE_API_URL + '/assets/search/'
const AREAS_URL = BASE_API_URL + '/areas/'
const GATEWAYS_URL = BASE_API_URL + '/gateways/'

const SENSOR_CSV_URL = BASE_API_URL + '/sensors/csv/'
const GW_CSV_URL = BASE_API_URL + '/gateways/csv/'
const PANEL_CSV_ULR = BASE_API_URL + '/panels/csv/'

const NOMINATIM_SEARCH_URL = NOMINATIM_BASE_URL + '/search'

const logic = {
  getHardware: options => {
    return Promise.resolve().then(() => {
      return axios.get(HARDWARE_URL, {
        ...options
      })
    })
  },
  fetchAreasOptions: options => {
    return Promise.resolve().then(() => axios.get(AREAS_SEARCH_URL, options))
  },
  fetchAssetsOptions: options => {
    return Promise.resolve().then(() => axios.get(ASSETS_SEARCH_URL, options))
  },
  fetchAreasAndGateways: () =>
    Promise.all([axios.get(AREAS_URL), axios.get(GATEWAYS_URL)]),
  fetchLocationOptions: options =>
    Promise.resolve().then(() =>
      axios.get(NOMINATIM_SEARCH_URL, {
        ...options,
        params: {
          ...options.params,
          ...NOMINATIM_OPTIONS
        }
      })
    ),
  uploadCSV: (options, type) =>
    Promise.resolve().then(() => {
      let finalUrl = null
      if (type === 'sensor') {
        finalUrl = SENSOR_CSV_URL
      } else if (type === 'gateway') {
        finalUrl = GW_CSV_URL
      } else if (type === 'panel') {
        finalUrl = PANEL_CSV_ULR
      }
      if (finalUrl != null) {
        return axios.post(finalUrl, options, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      }
      return new Error()
    }),
  getHardwareDetails: (id, type) =>
    Promise.resolve().then(() => axios.get(`${BASE_API_URL}/${type}/${id}/`))
}

export default logic
